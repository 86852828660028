import img from 'pools/images/finblox.jpg';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { ChartDropdownButton } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const finblox: PoolConfig = {
  // id is used in URL
  id: 'finblox',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0x7E73E34813826Dc643D5448D7DA955394024685f',
    tokensForSale: toWeiBN('31666666.67'),
    startDate: '2023-05-09T14:00:00.000Z',
    rate: 166.7,
    durationHours: 23,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('31666666.669999999999991798'),
    raised: toWeiBN('189962.007618476304739003'),
    baseAllocation: toWeiBN('1755.624805668508661671'),
    status: 5,
    refunded: true,
    registerDate: '2023-05-07T14:00:00.000Z',
    registerDuration: 46,
    fcfsDuration: 0.6666666666666666,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    totalWeights: 18004030,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'ETH',
    claimerVersion: 'v4',
    vesting: '20% unlocked at TGE, then 20% monthly',
    claimerAddress: '0xe5A6c2294c3B9c8F139c67918eFAf1a4fa160DCE',
    schedule: {
      '2023-05-18T12:15:00.000Z': 20,
    },
  },
  finishedInfo: (
    <div className="flex items-center gap-3">
      <Button
        href="https://www.gate.io/trade/FBX_USDT"
        variant="main"
        size="sm">
        FBX on Gate.io
      </Button>
      <ChartDropdownButton
        address="0x5de597849cf72c72f073e9085bdd0dadd8e6c199"
        chain="ETH"
      />
    </div>
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0x5de597849cf72c72f073e9085bdd0dadd8e6c199',
    name: 'Finblox',
    symbol: 'FBX',
    imageSrc: img.src,
    // Format: "2023-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2023-05-18T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Gate.io & MEXC',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(240_000_000),
    totalSupply: toWeiBN(10_000_000_000),
    coingeckoId: 'finblox',
    coinmarketcapId: 'finblox',
  },
  social: {
    website: 'https://finblox.com/',
    twitter: 'https://twitter.com/finblox',
    telegram: '',
    telegramAnno: 'https://t.me/finbloxchat',
    medium: '',
    github: '',
    whitepaper: 'https://docs.finblox.com/overview/finblox-litepaper',
    announcement: '',
  },
  description: (
    <p>
      Finblox is a crypto super-app with gamified features that helps users grow
      digital assets by earning rewards in simple and fun ways. Users can earn
      in-kind or universal rewards by participating in the ecosystem designed
      for entertainment, financial, and lifestyle purposes. The Finblox token
      (FBX) is a governance and utility token native to the Finblox ecosystem.
    </p>
  ),
};
