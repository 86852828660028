import img from 'pools/images/rebaked.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const rebaked: PoolConfig = {
  // id is used in URL
  id: 'rebaked',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x647308569cC79De709123112bA50A850E94CCE1d',
    tokensForSale: toWeiBN('3400000'),
    startDate: '2021-10-22T12:00:00.000Z',
    rate: 33.333,
    durationHours: 4,
    hasWhitelist: true,
    isTiered: true,
    participants: 1206,
    tokensSold: toWeiBN('3399999.999999999999997978'),
    raised: toWeiBN('102001.020010200102000964'),
    baseAllocation: toWeiBN('706.857899575371549893'),
    status: 5,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('21'),
    },
    registerDate: '2021-10-20T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 4728,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x32515ffdc3a84cfbf9ad4db14ef8f0a535c7afd6',
    name: 'reBaked',
    symbol: 'BAKED',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(2_666_666),
    totalSupply: toWeiBN(300_000_000),
    listingTime: '2021-10-26T12:00:00.000Z',
    coingeckoId: 'baked-token',
    coinmarketcapId: 'rebaked',
  },
  distribution: {
    network: 'POLY',
    type: 'claim_us',
    vesting: '33% on TGE, then 33% monthly over 2 months',
    claimerVersion: 'v2',
    claimerAddress: '0x01636263573232AB4867746B00469E938412999e',
    schedule: {
      '2021-10-26T12:00:00.000Z': 33,
      '2021-11-26T12:00:00.000Z': 33,
      '2021-12-26T12:00:00.000Z': 34,
    },
  },
  finishedInfo: (
    <>
      <QuickswapChartFinishedInfo
        symbol="BAKED"
        address="0x32515ffdc3a84cfbf9ad4db14ef8f0a535c7afd6"
        pairAddress="0x32515ffdc3a84cfbf9ad4db14ef8f0a535c7afd6"
      />
      <a href="https://www.gate.io/trade/BAKED_USDT">Trade on Gate.io.</a>
    </>
  ),
  social: {
    website: 'https://www.rebaked.com/',
    twitter: 'https://twitter.com/rebakedinc',
    telegram: 'https://t.me/rebaked',
    telegramAnno: 'https://t.me/rebakedann',
    medium: 'https://medium.com/rebakedinc',
    github: '',
    whitepaper: '',
    announcement:
      'https://trustpad.medium.com/rebaked-are-launching-on-trustpad-95d111cf9beb',
  },
  description: (
    <p>
      reBaked are building a set of governance, management, and operations tools
      for DAOs and Web 3.0 ecosystems, improving the distribution and management
      of treasuries, shifting the focus to value creation.
    </p>
  ),
};
