import { NftPoolConfig } from '@trustpad/launchpad';

import plasticImg from 'pools/finished/wastedlands/images/Level1_Close.png';
import steelImg from 'pools/finished/wastedlands/images/Level2_Close.png';
import silverImg from 'pools/finished/wastedlands/images/Level3_Close.png';
import goldImg from 'pools/finished/wastedlands/images/Level4_Close.png';
import logoImg from 'pools/finished/wastedlands/images/logo.jpg';
import React from 'react';

import { BNB } from '~/config/tokens';
import { toWeiBN } from '~/utils';

export const wastedlands: NftPoolConfig = {
  // id is used in URL
  id: 'wastedlands',
  // public, seed or private
  access: 'public',
  fundToken: BNB,
  isNft: true,
  sale: {
    address: '0x729732CFe327F8DFA843A9b20c80eB4CAf25d340',
    tokensForSale: toWeiBN('645'),
    startDate: '2021-12-30T12:30:00.000Z',
    rate: 0,
    durationHours: 24,
    hasWhitelist: true,
    isTiered: true,
    participants: 447,
    tokensSold: toWeiBN('0.000000000000000645'),
    raised: toWeiBN('160'),
    baseAllocation: toWeiBN('1.2'),
    status: 5,
    inventory: [
      {
        id: 'plastic',
        name: 'Plastic',
        image: plasticImg.src,
        supply: 275,
        totalSupply: 1500,
        price: toWeiBN('0.15'),
        internalId:
          '0x706c617374696300000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 275,
        raised: toWeiBN('41.25'),
      },
      {
        id: 'steel',
        name: 'Steel',
        image: steelImg.src,
        supply: 275,
        totalSupply: 1500,
        price: toWeiBN('0.25'),
        internalId:
          '0x737465656c000000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 275,
        raised: toWeiBN('68.75'),
      },
      {
        id: 'silver',
        name: 'Silver',
        image: silverImg.src,
        supply: 75,
        totalSupply: 1500,
        price: toWeiBN('0.4'),
        internalId:
          '0x73696c7665720000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 75,
        raised: toWeiBN('30'),
      },
      {
        id: 'gold',
        name: 'Gold',
        image: goldImg.src,
        supply: 20,
        totalSupply: 1500,
        price: toWeiBN('1'),
        internalId:
          '0x676f6c6400000000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 20,
        raised: toWeiBN('20'),
      },
    ],
    registerDate: '2021-12-28T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0,
    totalWeights: 4580,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('0'),
    },
    isPublic: false,
    levelsOpenAll: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    address: '',
    name: 'Wasted Lands',
    symbol: '',
    imageSrc: logoImg.src,
    decimals: 18,
    listingTime: '',
    listingInfo: '',
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'mint_them',
    vesting: (
      <>
        See your items on{' '}
        <a href="https://thewastedlands.io/inventory">wasted lands platform</a>
      </>
    ),
    claimUrl: 'https://thewastedlands.io/inventory',
  },
  social: {
    website: 'https://thewastedlands.io/',
    twitter: 'https://twitter.com/thewastedlands',
    telegram: 'https://t.me/TheWastedLands',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      The Wasted Land is a true Puzzle-RPG-Strategy game where players immerse
      themselves in a world of adventure across the post-apocalyptic world and
      have put their strategic thinking to the test. The game focuses on
      balancing both Free-to-play and Play-to-Earn models to help players gain
      good profit in the long term as well as have fun while playing the game.
    </p>
  ),
};
