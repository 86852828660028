import { Menu } from '@headlessui/react';

import { FaCaretDown } from 'react-icons/fa';

import { Button } from '~features/ui';

import { MetamaskButton } from './MetamaskButton';
import { TrustWalletButton } from './TrustWalletButton';
import { WalletConnectButton } from './WalletConnectButton';

export function ConnectWalletButton({ size }: { size?: string }) {
  return (
    <Menu as="div" className="relative ">
      <Menu.Button className="" as="div">
        <Button
          className="focus-visible:outline-none whitespace-nowrap"
          type="main"
          size={size === 'sm' ? 'md' : 'lg'}
          rightAddon={false}>
          Connect Wallet
          <FaCaretDown className="inline ml-1 " />
        </Button>
      </Menu.Button>
      <Menu.Items className="absolute top-[42px] right-0 z-[10000] px-4 pt-2 pb-4 bg-[#06040D] select-none rounded-lg border-2 border-main focus:outline-none">
        <Menu.Item as="div" className="mt-2">
          <MetamaskButton />
        </Menu.Item>
        <Menu.Item as="div" className="mt-2">
          <WalletConnectButton />
        </Menu.Item>
        <Menu.Item as="div" className="mt-2">
          <TrustWalletButton />
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

