import axios from 'axios';
import { useEffect, useState } from 'react';

const emptyPrice = { price: 0, athPrice: 0 };
let idsToFetch = new Set();
let batchPromise;

const fetchBatch = () => {
  return axios
    .get(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${Array.from(
        idsToFetch,
      ).join()}`,
    )
    .catch(() => undefined);
};

const fetchPrices = (id) => {
  if (!id) {
    return Promise.resolve(emptyPrice);
  }

  idsToFetch.add(id);

  return new Promise((resolve) => {
    if (!batchPromise) {
      batchPromise = new Promise((resolve) => {
        setTimeout(() => {
          if (idsToFetch.size > 0) {
            fetchBatch().then(resolve);
          }

          batchPromise = undefined;
          idsToFetch.clear();
        }, 200);
      });
    }
    batchPromise.then((res) => {
      const item = res?.data.find((x) => x.id === id);
      const price = parseFloat(item?.['current_price']);
      const athPrice = parseFloat(item?.['ath']);

      resolve(price && athPrice ? { price, athPrice } : undefined);
    });
  });
};

export function usePriceCoinGecko(id) {
  const [prices, setPrices] = useState<{ price: number; athPrice: number }>();

  useEffect(() => {
    if (id) {
      fetchPrices(id).then(setPrices);
    }
  }, [id]);

  return prices;
}
