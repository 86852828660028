import { Menu } from '@headlessui/react';
import { useUser, useUserTier, useWeb3Provider } from '@trustpad/launchpad';
import { User } from '@trustpad/launchpad/dist/shared/hooks/useUser';

import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiCopy } from 'react-icons/bi';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { KYCVerifyButton } from '~/features/pools/components/KYCVerifyButton/KYCVerifyButton';
import { ConnectWalletButton } from '~common/ConnectButtons/ConnectWalletButton';
import { EthereumIdenticon } from '~common/Wallet/EthereumIdenticon';
import { TierWithMultiplier } from '~features/tiers/components/TierName';
import { Button } from '~features/ui';
import { addressShort, fNum, getAddressUrl } from '~utils';

export function UserWallet({ connectSize }: { connectSize?: 'sm' }) {
  const {
    logout,
    connectedChain: { symbol: chainSymbol, token },
    defaultChain,
  } = useWeb3Provider();
  const user = useUser();
  const { tierState } = useUserTier();

  if (!user.account) {
    return <ConnectWalletButton size={connectSize} />;
  }

  return (
    <div className="text-white border-2 border-main py-0 px-3 rounded-lg flex items-center">
      <div className="mr-3 my-[2.5px] text-sm font-medium flex flex-col leading-tight">
        <span>
          {fNum(user.funds, 4)} {token.symbol}
        </span>
        {chainSymbol === defaultChain.symbol && (
          <span>
            <span className="text-pink">{fNum(user.tokens, 0)} TPAD</span>
          </span>
        )}
      </div>

      <Account account={user.account} />

      <Menu as="div" className="relative h-6">
        <Menu.Button className="focus-visible:outline-none" as="button">
          <div className="hover:bg-black hover:opacity-70 rounded-full transition">
            <EthereumIdenticon address={user.account} diameter={24} />
          </div>
        </Menu.Button>

        <Menu.Items className="absolute top-[32px] -right-[14px] z-20 pt-3 px-4 pb-4 bg-[#06040D] text-white select-none rounded-lg border-2 border-main focus:outline-none">
          <div className="text-white ">
            <TierWithMultiplier tier={tierState.tier} />
            <div className="mt-1">
              <KYCStatus user={user} />
            </div>
            <Button
              href={getAddressUrl(user.account, chainSymbol)}
              type="primaryOutline"
              size="sm"
              className="mt-3">
              {
                {
                  BSC: 'BscExplorer',
                  ETH: 'EthExplorer',
                  POLY: 'Polyscan',
                  AVAX: 'SnowTrace',
                }[chainSymbol]
              }
            </Button>
            <Button
              onClick={logout}
              size="sm"
              type="primaryDanger"
              className="mt-4">
              Logout
            </Button>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}

function Account({ account }: { account: string }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={account} onCopy={() => setCopied(true)}>
      <button className="flex items-center p-0 m-0 mr-3 outline-none">
        {/* below before utilities as the fix to gradients unable to transition */}
        {/* prettier-ignore-attribute (ClassName) */}
        <div
          className={`
          relative top-0
          flex items-center justify-between
          text-xs
          brand-bg 
          py-1 px-3
          rounded-full
          z-10

          before:content-['']
          before:absolute before:inset-0
          before:bg-black
          before:rounded-full
          before:-z-10
          before:opacity-0
          before:transition-opacity
          
          before:hover:opacity-30
          `}>
          <span className={copied ? 'visible absolute' : 'hidden'}>Copied</span>
          <span className={`${copied ? 'invisible' : ''}  `}>
            {addressShort(account)}
          </span>
          <BiCopy className="inline align-bottom text-sm ml-1 mb-[1px]" />
        </div>
      </button>
    </CopyToClipboard>
  );
}

const CustomToggle = React.forwardRef(
  (
    { children, onClick }: { children: any; onClick: Function },
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="outline-none">
      {children}
    </a>
  ),
);

CustomToggle.displayName = 'CustomToggle';

export function KYCStatus({
  user,
  accountView,
}: {
  user: User;
  accountView?: boolean;
}) {
  const { kyc } = user;
  const msg = {
    new: (
      <span className="flex flex-col">
        <a href={kyc.formUrl} target="_blank" rel="noreferrer">
          Continue
        </a>
      </span>
    ),
    nothing: <b className="tracking-wider">Not applied</b>,
    pending: (
      <>
        <b className="tracking-wider">In review</b>{' '}
        <span className={accountView ? 'inline' : 'flex flex-col'}>
          <a
            className={accountView ? 'ml-2' : ''}
            href={kyc.formUrl}
            target="_blank"
            rel="noreferrer">
            Start over
          </a>
        </span>
      </>
    ),
    processing: (
      <>
        <b className="tracking-wider">In review</b>
      </>
    ),
    valid: (
      <>
        <FaCheck className="text-green-600 ml-1 inline" /> [{user.kyc.country}]
      </>
    ),
    invalid: (
      <>
        <FaTimes className="text-red-600 ml-1 inline" />
      </>
    ),
  }[kyc.status];

  if (kyc.status === 'nothing' || (kyc.status === 'new' && !kyc.formUrl)) {
    return (
      <div className={`${accountView ? 'mb-5' : ''}`}>
        <KYCVerifyButton size="sm" />
      </div>
    );
  }

  return (
    <div className={`w-full ${accountView ? 'mb-5' : ''}`}>
      <div className="flex items-center">
        {accountView ? null : <div>KYC:</div>}
        <div className={accountView ? 'text-md' : 'text-sm ml-1'}>{msg}</div>
      </div>
      {kyc.status === 'invalid' && (
        <KYCVerifyButton size="sm" label="Try again" />
      )}
    </div>
  );
}
