import img from 'pools/images/tapfantasy.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const tapFantasyCommon = {
  fundToken: BUSD,
  kyc: true,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x35bEdBF9291b22218a0dA863170dcC9329Ef2563',
    name: 'Tap Fantasy',
    symbol: 'TAP',
    imageSrc: img.src,
    listingTime: '2022-02-22T12:00:00.000Z',
    listingInfo: 'Lists on ByBit and Gate.io',
    decimals: 18,
    listingRate: 25,
    initialSupply: toWeiBN(24400000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'tap-fantasy',
    athXManual: 12.4,
    coinmarketcapId: 'tap-fantasy',
  },
  social: {
    website: 'https://www.tapfantasy.io/#/',
    twitter: 'https://twitter.com/tapfantasy2021',
    telegram: 'https://t.me/tap_fantasy',
    telegramAnno: 'https://t.me/tapfantasy_announcement',
    medium: 'https://medium.com/@πtapfantasy2021',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="TAP"
        address="0x35bEdBF9291b22218a0dA863170dcC9329Ef2563"
        pairAddress="0x9b7778ac51f1152914dd97c1dead4bbe447f4d64"
      />
      <div className="mt-3">
        <a href="https://www.bybit.com/en-US/trade/spot/TAP/USDT">
          Trade on ByBit
        </a>{' '}
        or <a href="https://www.gate.io/trade/TAP_USDT">Trade on Gate.io</a>
      </div>
    </div>
  ),
  description: (
    <p>
      Tap Fantasy is an MMORPG blockchain game developed in HTML 5. Players dont
      have to download the game and purchase NFT items, just open it directly on
      the web page or wallet, and can start the game immediately. After
      purchasing NFT skins, players can enter the VIP hunting area and obtain
      $TAP and NFT skins, thereby starting the mode of earning while playing.
      200+ NFT skins with different colours are ready before the game launches.
      Each skin includes exquisite paintings, 3D models, and dubbing by
      well-known Japanese voice actors.
    </p>
  ),
};

export const tapfantasy: PoolConfig = {
  ...tapFantasyCommon,
  // id is used in URL
  id: 'tapfantasy',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0xb9b6498e7FBd503323AC4CB9701D7e06a80AcC17',
    tokensForSale: toWeiBN('7500000'),
    startDate: '2022-01-08T11:00:00.000Z',
    rate: 25,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('7500000'),
    raised: toWeiBN('300000'),
    baseAllocation: toWeiBN('940.997684291779235816'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('75'),
    },
    registerDate: '2022-01-06T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7815,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting:
      'TGE 12%, cliff 3 months, the rest 10% linear release every month until released',
    claimerAddress: '0x54AE04530e172ceBeab9ca7DdAc42b36ec3F7627',
    claimerVersion: 'v4',
    schedule: {
      '2022-02-22T12:20:00.000Z': 12,
      '2022-05-22T12:00:00.000Z': 10,
      '2022-06-22T12:00:00.000Z': 10,
      '2022-07-22T12:00:00.000Z': 10,
      '2022-08-22T12:00:00.000Z': 10,
      '2022-09-22T12:00:00.000Z': 10,
      '2022-10-22T12:00:00.000Z': 10,
      '2022-11-22T12:00:00.000Z': 10,
      '2022-12-22T12:00:00.000Z': 10,
      '2023-01-22T12:00:00.000Z': 8,
    },
  },
};

export const tapfantasyprivate: PoolConfig = {
  ...tapFantasyCommon,
  token: {
    ...tapFantasyCommon.token,
    athXManual: 16.6,
  },
  // id is used in URL
  id: 'tapfantasyprivate',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x4DdeAC6B60C16240d3795eB37857F5dFdd1091C9',
    tokensForSale: toWeiBN('1666667'),
    startDate: '2022-01-09T11:00:00.000Z',
    rate: 33.3333,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1666666.99999999999999996'),
    raised: toWeiBN('50000.06000006000006'),
    baseAllocation: toWeiBN('3200'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-06T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0,
    totalWeights: 2072,
    minAllowedLevelMultiplier: 28,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting:
      'TGE 7.5%, cliff 3 months, the rest 8% linear release every month until released',
    claimerAddress: '0x67D84eDdca2CdD4bc76B5e7F65F2f08A7B916bc6',
    claimerVersion: 'v2',
    schedule: {
      '2022-02-22T12:20:00.000Z': 7.5,
      '2022-05-22T12:00:00.000Z': 8,
      '2022-06-22T12:00:00.000Z': 8,
      '2022-07-22T12:00:00.000Z': 8,
      '2022-08-22T12:00:00.000Z': 8,
      '2022-09-22T12:00:00.000Z': 8,
      '2022-10-22T12:00:00.000Z': 8,
      '2022-11-22T12:00:00.000Z': 8,
      '2022-12-22T12:00:00.000Z': 8,
      '2023-01-22T12:00:00.000Z': 8,
      '2023-02-22T12:00:00.000Z': 8,
      '2023-03-22T12:00:00.000Z': 8,
      '2023-04-22T12:00:00.000Z': 4.5,
    },
  },
};
