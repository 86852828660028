import img from 'pools/images/trd.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const therealmdefenders: PoolConfig = {
  // id is used in URL
  id: 'therealmdefenders',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xc6c2899932851aF66FE5eDCedcEb9F81f3969f23',
    tokensForSale: toWeiBN('2000000'),
    startDate: '2021-11-18T12:00:00.000Z',
    rate: 20,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1999999.99999999999999896'),
    raised: toWeiBN('99999.999999999999999948'),
    baseAllocation: toWeiBN('289.337529342723004694'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('13.9377'),
    },
    registerDate: '2021-11-16T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6846,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x2a1f0ad3cab040e28845734d932f3ff0a24b14f4',
    name: 'The Realm Defenders',
    symbol: 'TRD',
    imageSrc: img.src,
    listingTime: '2021-11-22T10:30:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(475000),
    totalSupply: toWeiBN(200000000),
    coingeckoId: 'the-realm-defenders',
  },
  distribution: {
    type: 'claim_us',
    vesting: '25% unlocked at TGE, then 25% monthly',
    network: 'POLY',
    claimerAddress: '0x23a591b8589a3eAa9269773A91A10Bd323A206a4',
    claimerVersion: 'v2',
    schedule: {
      '2021-11-22T10:30:00.000Z': 25,
      '2021-12-22T10:30:00.000Z': 25,
      '2022-01-22T10:30:00.000Z': 25,
      '2022-02-22T10:30:00.000Z': 25,
    },
  },
  finishedInfo: (
    <QuickswapChartFinishedInfo
      symbol="TRD"
      address="0x2a1f0ad3cab040e28845734d932f3ff0a24b14f4"
      pairAddress="0xea056be5b4943c1071ffea7a4170eb12fbbae352"
    />
  ),
  social: {
    website: 'https://therealmdefenders.com/',
    twitter: 'https://twitter.com/realm_defenders',
    telegram: 'https://t.me/TheRealmDefenders',
    telegramAnno: 'https://t.me/TheRealmDefendersAnn',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      The Realm Defenders is a Free to Play and Play to Earn RPG style strategy
      game based on Polygon blockchain. You are defending your kingdom against
      the demons. The ecosystem consists of RPG Game, Upgrading characters and
      NFT marketplace to trade your heroes. Players will gain rewards in the
      form of $TRD tokens and unique NFT Cards.
    </p>
  ),
};
