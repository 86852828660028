import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/trustfi.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const trustfi: PoolConfig = {
  // id is used in URL
  id: 'trustfi',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  token: {
    address: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
    name: 'TrustFi',
    symbol: 'TFI',
    imageSrc: img.src,
    decimals: 18,
    listingTime: '2021-06-07T16:00:00.000Z',
    type: 'ERC-20',
    pairAddress: '',
    listingRate: 12.5,
    totalSupply: toWeiBN('100,000,000'),
    // BSC, ETH or SOL
    chain: 'BSC',
    extraChains: ['ETH', 'DOT'],
    coingeckoId: 'trustfi-network-token',
    coinmarketcapId: 'trustfi-network',
  },
  sale: {
    address: '0x58688e4a83bd7457137d00e7a517f43a655CfB76',
    status: SaleStatus.finished,
    // Rate tokens per currency, for example: NetVRk is 6.66666 tokens for 1 BUSD
    // Leave empty for TBA
    rate: 12.5,
    // How many tokens are for sale?
    tokensForSale: toWeiBN('1,250,000'),
    // When sale starts? Format: "2021-05-05T18:00:00.000Z", leave empty for TBA
    startDate: '2021-06-07T16:00:00.000Z',
    baseAllocation: toWeiBN('576'),
    // Duration in hours
    durationHours: 1,
    // Is whitelist sale?
    hasWhitelist: true,
    // Can tiers participate?
    isTiered: true,
    // Is public sale? (cancels whitelist and tiers)
    isPublic: false,
    // Total raise, e.g. "$100,000" or "TBA"
    hardCap: '$100,000',
    // No need to touch:
    participants: 0,
    tokensSold: toWeiBN('1,250,000'),
    raised: toWeiBN('100,000'),
    limits: null,
    update: false,
  },
  social: {
    twitter: 'https://twitter.com/trustfiorg',
    medium: 'https://trustfi.medium.com/',
    telegram: 'https://t.me/trustfi_fans',
    website: 'https://trustfi.org/',
  },
  description: (
    <div>
      TrustFi stands out from other DeFi products. It is designed to expand the
      BaaS (Blockchain as a Service) offering for real world business to utilise
      blockchain technology.
    </div>
  ),
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="$TFI"
      address="0x7565ab68d3f9dadff127f864103c8c706cf28235"
      pairAddress="0xb1c508f942c1d1b1a44384bcc92885e43887d0fa"
    />
  ),
};
