import img from 'pools/images/manufactory.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const manufactoryCommon = {
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x36953b5ec00a13edceceb3af258d034913d2a79d',
    name: 'ManuFactory',
    symbol: 'MNFT',
    imageSrc: img.src,
    listingTime: '2021-12-16T18:00:00.000Z',
    decimals: 12,
    listingRate: 0,
    initialSupply: toWeiBN(21_250_000),
    totalSupply: toWeiBN(500_000_000),
    coingeckoId: 'manufactory-2',
  },
  finishedInfo: (
    <>
      <PancakeChartFinishedInfo
        symbol="MNFT"
        address="0x36953B5Ec00A13eDcEceB3aF258D034913D2A79D"
        pairAddress="0xfb06514b99d6a1a5a5ce39aa613cebfb48903d19"
      />
    </>
  ),
  distribution: {
    type: 'claim_us' as const,
    vesting: '5% released every month',
    claimerAddress: '0xb0a23e0fdd53f1682220eec75f6d98ac2dd1376e',
    claimerVersion: 'v2' as const,
    schedule: {
      '2021-12-16T18:00:00.000Z': 5,
      '2022-01-16T18:00:00.000Z': 5,
      '2022-02-16T18:00:00.000Z': 5,
      '2022-03-16T18:00:00.000Z': 5,
      '2022-04-16T18:00:00.000Z': 5,
      '2022-05-16T18:00:00.000Z': 5,
      '2022-06-16T18:00:00.000Z': 5,
      '2022-07-16T18:00:00.000Z': 5,
      '2022-08-16T18:00:00.000Z': 5,
      '2022-09-16T18:00:00.000Z': 5,
      '2022-10-16T18:00:00.000Z': 5,
      '2022-11-16T18:00:00.000Z': 5,
      '2022-12-16T18:00:00.000Z': 5,
      '2023-01-16T18:00:00.000Z': 5,
      '2023-02-16T18:00:00.000Z': 5,
      '2023-03-16T18:00:00.000Z': 5,
      '2023-04-16T18:00:00.000Z': 5,
      '2023-05-16T18:00:00.000Z': 5,
      '2023-06-16T18:00:00.000Z': 5,
      '2023-07-16T18:00:00.000Z': 5,
    },
  },
  social: {
    website: 'https://manufactory.gg/',
    twitter: 'https://twitter.com/ManuFactoryGG',
    telegram: 'https://t.me/manufactoryofficial',
    telegramAnno: '',
    medium: 'https://medium.com/@Manufactorygg',
    github: '',
    whitepaper: '',
    announcement: '',
  },
};

export const manufactory: PoolConfig = {
  ...manufactoryCommon,
  // id is used in URL
  id: 'manufactory',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xCd4d0be618F40d775A88EFf362698310cdc32Bc5',
    tokensForSale: toWeiBN('10833299.9992'),
    startDate: '2021-12-10T12:40:00.000Z',
    rate: 33.333,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('10833299.999199999999997646'),
    raised: toWeiBN('325002.249998499984999801'),
    baseAllocation: toWeiBN('1502.3565'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-12-08T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6989,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  description: (
    <p>
      ManuFactory is a construction and management simulation game focused on
      resource gathering and strategy combined with survival elements; all
      developed in a truly Play-and-Earn mentality inspired by the hit indie
      game Factorio.
    </p>
  ),
};
