import React from 'react';

import { usePriceConverters, useSaleRate } from '~features/pools';

import { fNum } from '../../../../utils';
import { PoolConfig } from '../../types';

export const PoolRate = ({ pool }: { pool: PoolConfig }) => {
  const { sale, token, fundToken } = pool;
  const { usdToCurrency } = usePriceConverters();
  const { currencyPerToken } = useSaleRate();

  if (sale.rateString) {
    return <div className="text-main inline">{sale.rateString}</div>;
  }

  return (
    <div className="text-main inline">
      {sale.rate ? (
        <span>
          1 {token.symbol} ={' '}
          {sale.fixedUsdPrice
            ? fNum(usdToCurrency(sale.fixedUsdPrice))
            : fNum(currencyPerToken, 10)}{' '}
          {fundToken.symbol}
        </span>
      ) : (
        'TBA'
      )}
    </div>
  );
};
