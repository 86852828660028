import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/gensometa.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

// Refunded to relinquished: https://bscscan.com/tx/0x3bf8f3e0b2a0ebffd011d2079c9a641a0ccddb95e722672d27c736d169ace6bf
// Second refund: https://bscscan.com/tx/0x19130a5ef95c3948a5b43d728b38d2b56290cbbe6c532acf9658e2ab6f5e656d
export const gensokishi: PoolConfig = {
  // id is used in URL
  id: 'gensokishi',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xf208Bc4c45e32551Cd28B67CBba6A34669FD1543',
    tokensForSale: toWeiBN('10526316'),
    startDate: '2022-01-18T12:00:00.000Z',
    rate: 52.631,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('10526315.999999999999997802'),
    raised: toWeiBN('200002.204024244266686901'),
    baseAllocation: toWeiBN('1264.80790350444225074'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-16T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 8160,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  finishedInfo: (
    <div>
      <p className="mb-3">
        Trade{' '}
        <a href="https://www.bybit.com/en-US/trade/spot/MV/USDT">
          MV-USDT on ByBit
        </a>
        .
      </p>
      <QuickswapChartFinishedInfo
        symbol="MV"
        pairAddress="0x690a87ed8972e451e755b8f2dc1fc2b28e3c6566"
        address="0xa3c322ad15218fbfaed26ba7f616249f7705d945"
      />
    </div>
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xa3c322ad15218fbfaed26ba7f616249f7705d945',
    name: 'GensoKishi',
    symbol: 'MV',
    imageSrc: img.src,
    listingTime: '2022-01-27T10:00:00.000Z',
    listingInfo: 'Jan 27 - Bybit, Jan 29 - DEX',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(2_000_000_000),
    coingeckoId: 'gensokishis-metaverse',
    coinmarketcapId: 'gensokishis-metaverse',
  },
  distribution: {
    type: 'claim_us',
    vesting: '11.2% at TGE, then 11.1% every 3.5 months',
    network: 'POLY',
    claimerVersion: 'v4',
    claimerAddress: '0xcbD468fBDf75c632440f2FA1a1aAA4eBf81140eA',
    schedule: {
      '2022-01-27T10:20:00.000Z': 11.2,
      '2022-05-15T12:00:00.000Z': 11.1,
      '2022-08-30T12:00:00.000Z': 11.1,
      '2022-12-15T12:00:00.000Z': 11.1,
      '2023-03-30T12:00:00.000Z': 11.1,
      '2023-07-15T12:00:00.000Z': 11.1,
      '2023-10-30T12:00:00.000Z': 11.1,
      '2024-02-15T12:00:00.000Z': 11.1,
      '2024-05-30T12:00:00.000Z': 11.1,
    },
  },
  social: {
    website: 'https://genso.game/',
    twitter: 'https://twitter.com/genso_meta',
    telegram: 'https://t.me/gensometamain',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      GENSOKISHI Online META WORLD is the new GameFi of Elemental Knights Online
      that won the "Gold Award of Game of the year" in Taiwan in 2012 with a
      total downloads of over 8 million. Incorporates UGC and Play to Earn to
      realize a metaverse space in a fantasy world. Players from various fields
      such as MMORPG developers, blockchain developers, and cryptocurrency
      finance experts from all over the world are developing the game as one
      team.
    </p>
  ),
};
