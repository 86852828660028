import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';
import { DateTimeFormat } from '~config/constants';

export function NotificationsPanel({ notifications, onClose }) {
  return ReactDOM.createPortal(
    <div className="fixed w-full h-screen right-0 top-0 z-50">
      <div className="relative ml-auto max-w-3xl h-screen p-4 bg-dark1 z-50 shadow-2xl border-l-2 border-dark-grayMix2">
        <div className="flex justify-between items-baseline mb-4">
          <h2>Notifications</h2>
          <button onClick={onClose}>
            <FaTimes className="text-2xl" />
          </button>
        </div>

        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="bg-black shadow-lg rounded-md p-4 mb-4">
            <h3 className="mb-0">{notification.title}</h3>
            <p className="text-xs text-secondary">
              {dayjs(notification.date).format(DateTimeFormat.noYear)}
            </p>
            <div className="mt-3">
              {documentToReactComponents(notification.body)}
            </div>
          </div>
        ))}
      </div>
      <div
        className="absolute top-0 w-full h-full bg-black/75"
        onClick={onClose}></div>
    </div>,
    document.querySelector('body'),
  );
}
