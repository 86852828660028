import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/bullperks.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const bullperks: PoolConfig = {
  // id is used in URL
  id: 'bullperks',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  distribution: {
    type: 'airdrop_us',
    schedule: {
      '2021-06-16T15:00:00.000Z': 50,
      '2021-07-16T15:00:00.000Z': 25,
      '2021-08-16T15:00:00.000Z': 25,
    },
  },
  token: {
    address: '0xfE1d7f7a8f0bdA6E415593a2e4F82c64b446d404',
    name: 'Bullperks',
    symbol: 'BLP',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingTime: '2021-06-16T15:00:00.000Z',
    listingRate: 0,
    totalSupply: toWeiBN(''),
    // BSC, ETH, DOT or SOL
    chain: 'BSC',
    coingeckoId: 'bullperks',
    coinmarketcapId: 'bullperks',
  },
  sale: {
    address: '0x22CAa13DC722fc606660dd9DeC62B90efC8E48f9',
    status: SaleStatus.finished,
    rate: 20,
    tokensForSale: toWeiBN('1500000'),
    startDate: '2021-06-16T12:00:00.000Z',
    baseAllocation: toWeiBN('519.930675909878682842'),
    durationHours: 3,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$75,000',
    participants: 684,
    tokensSold: toWeiBN('1500000'),
    raised: toWeiBN('75000'),
    update: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-06-15T12:00:00.000Z',
    registerDuration: 23,
    fcfsDuration: 0.5,
    levelsOpenAll: true,
  },
  social: {
    twitter: 'https://twitter.com/bullperks',
    medium: 'https://medium.com/bull-perks',
    telegram: 'https://t.me/BullPerks_Official',
    website: 'https://bullperks.com',
  },
  description: (
    <div>
      Bullperks is a community of like minded individuals who want to leverage
      their power and invest together in the best projects at the same terms as
      VC’s.
    </div>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="$BLP"
        address="0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404"
        pairAddress="0xdf7ce4a3fd1e2998ba732b650987bed60ea0a981"
      />
    </div>
  ),
};
