import { SaleStatus } from '@trustpad/launchpad';

import Link from 'next/link';
import { landImgs, netvrkImg } from 'pools/finished/netvrk/images';
import React from 'react';

import { ConnectWalletButton } from '~common/ConnectButtons/ConnectWalletButton';
import { BuyUniSwapButton } from '~features/launched';
import { NftPoolConfig } from '~features/pools/types';
import { getTokenPriceUsd } from '~features/pools/utils';
import { Token } from '~types';
import { toWeiBN } from '~utils';

export const NETVRK: Token = {
  network: 'ETH',
  address: '0xFc0d6Cf33e38bcE7CA7D89c0E292274031b7157A',
  name: 'NTVRK',
  symbol: 'NTVRK',
  imageSrc: netvrkImg.src,
  decimals: 18,
  price: { usd: 2 },
  priceRefresher: () => getTokenPriceUsd('netvrk'),
  pairAddress: '0xddf8390ced9fad414b1ca1dd4fe14f881c2cfa70',
};

export const netvrkLand: NftPoolConfig = {
  // id is used in URL
  id: 'netvrk-land',
  access: 'levels_fcfs' as const,
  fundToken: NETVRK,
  network: 'ETH' as const,
  kyc: false,
  isNft: true,
  sale: {
    address: '',
    tokensForSale: toWeiBN(1000),
    startDate: '2021-08-30T12:00:00.000Z',
    // Empty=TBA, tokens per currency
    rate: 0,
    durationHours: 3,
    hasWhitelist: true,
    isTiered: true,
    wholeTokens: true,
    // No need to touch:
    participants: 0,
    // tokensSold: toWeiBN(1176),
    tokensSold: toWeiBN('0.000000000000001176'),
    raised: toWeiBN(616831),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
    hardCap: '$1,532,000',
    inventory: [
      {
        id: 'epic',
        name: 'EPIC',
        supply: 10,
        sold: 10,
        totalSupply: 0,
        price: toWeiBN(16500),
      },
      {
        id: 'giant',
        name: 'GIANT',
        supply: 20,
        sold: 20,
        totalSupply: 0,
        price: toWeiBN(5500),
      },
      {
        id: 'large',
        name: 'LARGE',
        supply: 70,
        sold: 70,
        totalSupply: 0,
        price: toWeiBN(2750),
      },
      {
        id: 'medium',
        name: 'MEDIUM',
        supply: 300,
        sold: 300,
        totalSupply: 0,
        price: toWeiBN(1375),
      },
      {
        id: 'standard',
        name: 'STANDARD',
        supply: 600,
        sold: 600,
        totalSupply: 0,
        price: toWeiBN(500),
      },
      {
        id: 'mega',
        name: 'MEGA',
        supply: 176,
        sold: 176,
        totalSupply: 0,
        price: toWeiBN(1000),
      },
    ],
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'ETH',
    address: '',
    name: 'NetVRK Land Parcels',
    symbol: 'LAND',
    imageSrc: netvrkImg.src,
    listingTime: '2022-01-20T12:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    totalSupply: toWeiBN(15000),
  },
  social: {
    twitter: 'https://twitter.com/netvrk1',
    medium: 'https://netvrk.medium.com',
    telegram: 'https://t.me/NetVRk_Official',
    website: 'https://netvrk.co',
    announcement:
      'https://trustpad.medium.com/netvrk-land-presale-30-aug-393307b136cd',
  },
  description: (
    <div className="space-y-3">
      <p>
        Netvrk’s Land Presale of the Genesis Islands, the core of the metaverse.{' '}
        <br />
        Netvrk is a digital landscape where users can own premium digital real
        estate on the platform. Shape the land itself, create, share, and
        monetize activity in multiple ways with Netvrk’s own 3D creation system
        and NFT marketplace. It is also where Netvrk’s premium content, rewards,
        benefits, experiences, and new technology will be shared first.
      </p>
      <div>
        <h3 className="text-lg font-semibold">How to buy?</h3>
        <ul className="list-disc list-inside">
          <li>
            Stake TPAD on{' '}
            <Link href="/levels">
              Levels
            </Link>{' '}
            page (you need to be on BSC network)
          </li>
          <li>Open the pool page</li>
          <li>
            Switch to the <b className="font-semibold">ETH network</b>
          </li>
          <li>
            Press the buy button, you can buy{' '}
            <b className="font-semibold">1 land per transaction</b>
          </li>
          <li>
            Consider the <b className="font-semibold">limit</b> (based on level
            multiplier), it applies{' '}
            <b className="font-semibold">TO ALL LAND POOLS</b> - e.g. MEMBER can
            buy only 2 lands (of any size)
          </li>
          <li>
            Pay with NTVRK{' '}
            <BuyUniSwapButton
              address="0xFc0d6Cf33e38bcE7CA7D89c0E292274031b7157A"
              symbol="NTVRK"
            />
          </li>
        </ul>
      </div>
      <div className="flex flex-col">
        <img src={landImgs.parcels.src} className="h-80" />
        <img src={landImgs.land.src} className="h-80" />
        <img src={landImgs.mega.src} className="h-80" />
      </div>
    </div>
  ),
  distribution: {
    type: 'airdrop_them' as const,
    vesting: 'Released after 2 months',
  },
  descriptionShort: (
    <div>
      <p>
        Netvrk’s Land Presale of the Genesis Islands, the core of the metaverse.
      </p>
    </div>
  ),
  finishedInfo: ({ account }) => (
    <div>
      <h3 className="text-xl text-center mb-3">Lands you own</h3>
      {account ? (
        <table className="table-auto w-full">
          <tbody>
            <tr>
              <td className="brand-text text-lg">
                {owners[account]?.[0] || 0} EPIC
              </td>
            </tr>
            <tr>
              <td className="brand-text text-lg">
                {owners[account]?.[1] || 0} GIANT
              </td>
            </tr>
            <tr>
              <td className="brand-text text-lg">
                {owners[account]?.[2] || 0} LARGE
              </td>
            </tr>
            <tr>
              <td className="brand-text text-lg">
                {owners[account]?.[3] || 0} MEDIUM
              </td>
            </tr>
            <tr>
              <td className="brand-text text-lg">
                {owners[account]?.[4] || 0} STANDARD
              </td>
            </tr>
            <tr>
              <td className="brand-text text-lg">
                {owners[account]?.[5] || 0} MEGA
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="text-center">
          <ConnectWalletButton />
        </div>
      )}
    </div>
  ),
};

const owners = {
  '0x21B2923D6689C8eb7a3D2592cc5ED380E2875aA6': ['1', 0, '2', 0, 0, '1'],
  '0x5E9c49D9c95e79f853Fd6CF8b6065B3995C9b10C': ['1', 0, '1', 0, 0, 0],
  '0xdaA743d983BEcf832b8F9842dCeBb7FBbac8DaB7': ['1', 0, '1', 0, 0, '1'],
  '0x22cC65BFd1e518ae2502b96Ef7C667A8444Bd612': ['1', 0, 0, 0, 0, '1'],
  '0x7022fD64c1a454f89D604001658Ad9dc531bbc8c': ['1', 0, 0, '1', '2', 0],
  '0x79e443c3a85E01FC44277a1f82643414a2a7695c': ['1', 0, 0, '3', '24', '1'],
  '0xD152bf4f92218E38BAF77b4AC4Dc06bD1d1a211B': ['1', '1', 0, '3', '7', '5'],
  '0xd234c98Ec5c87390618f31bc457267782b4DDEf1': ['1', '2', '1', '2', '6', '8'],
  '0x907B936Ea21482166ccf4e80a122fDbBDC21C16e': ['1', 0, 0, 0, 0, '1'],
  '0x8454E1EE9a5324a291516B95364a929d723B2308': ['1', '1', 0, '2', '4', '1'],
  '0x40d18fe02143AaA98f82f287FD239Bc6B66421C3': [0, '1', 0, 0, 0, 0],
  '0x9458C54A975E7d61c9fB7103553692A590c28e08': [0, '1', 0, 0, '1', '1'],
  '0x22402C8C5057925420c607d650d151bF722B56BC': [0, '1', 0, 0, 0, 0],
  '0x6fd2fb6Db7c3a8aD03e40578F8a8A7EBD350309b': [0, '1', '2', '6', '3', '3'],
  '0xc51a5cfc07714c0719A5a90d0d006923611E101D': [0, '1', '2', '3', '6', '5'],
  '0x7df2B505d5BDf08Cca4742C12447CE465151e678': [0, '1', '2', '6', '3', 0],
  '0x4E678c641613b2CeFC9d1116c3C58430Ed7C429b': [0, '1', '2', '3', 0, '10'],
  '0xdB6bdB4EB36c0eFe661ac7Ed7EDc4A69adf86999': [0, '1', '1', '4', '7', 0],
  '0x96423bD4686BB0d15205e24fF7D3fB3faDDD993c': [0, '1', '2', '2', '1', '1'],
  '0x2BF7944B7bc75B6732766e831B5fB13409Dc7b1B': [0, '1', 0, 0, '5', '1'],
  '0x47A040862237785D11DD411c71F71bd5A85aAfe8': [0, '1', '1', '5', '13', '2'],
  '0xCEe0c9022120a24C720516247C8118C7Be175aE8': [0, '1', 0, 0, 0, 0],
  '0x07A280e8a764B0cb493c7c5652Db248c828D2487': [0, '1', '1', 0, 0, '1'],
  '0xFEE86ad5f76f546A870d93F44f080E67458B6402': [0, '1', '2', '3', '2', '1'],
  '0x389b8Bd4FAc72ff9Aa5fD888a4B3283Ac4c14b28': [0, '1', 0, 0, 0, '1'],
  '0xebDeA0159E397B46E0Ec35d68DDba57897a2121b': [0, '1', 0, 0, 0, 0],
  '0x9f5035913C97FDE2D9Af3Ef514b15C3833de0249': [0, 0, '1', 0, '1', '1'],
  '0xc6cB96CC1727eC701E5483C565195B01E3C1da2b': [0, 0, '1', 0, 0, 0],
  '0xbd2c152256f33805c8581c7DD6F68DbdE150994A': [0, 0, '2', '5', 0, '1'],
  '0x45c5d06827801854a424bC866cCc974FBf4bF7ee': [0, 0, '1', '4', '2', 0],
  '0xd4b65Db36026dDF08859B8175c02c611FDE0A020': [0, 0, '1', '1', 0, 0],
  '0x175052854d6b4a1FcA26340b1218b54F74d0f0E4': [0, 0, '2', 0, 0, '1'],
  '0xB64cBB25419A0C2c73836D0Af676F48E42472036': [0, 0, '1', '3', '2', '1'],
  '0xc742fA5E837286DfA0AAF74756DCA391b101C170': [0, 0, '1', '2', 0, '1'],
  '0xC888478eb9E371E2fe6C31721e13F7998Abc8e7C': [0, 0, '1', '5', 0, '1'],
  '0x006F2Bc81839510e36633E6Fcf0277D2b2F623A9': [0, 0, '2', '2', '2', '2'],
  '0xCe325A6fE7B6cb6a5CeDC0D83564C03713518d9B': [0, 0, '2', '1', 0, 0],
  '0xb5B882Bc92245398352c225D7375196f1DCd9815': [0, 0, '1', '1', 0, 0],
  '0xf77a9cde32f125C0Fac0876e8Fb3d82c928f9269': [0, 0, '1', '1', 0, 0],
  '0xf5AB6B4a8d578807491ef59cE855982990932617': [0, 0, '1', '1', 0, 0],
  '0xed6E4576849826081fE2ffD0d94753838ed0E1b4': [0, 0, '1', 0, 0, 0],
  '0xf0352681BcDBA8296b716d4EE085C27433Fff690': [0, 0, '1', 0, 0, 0],
  '0xe5A3F1e72D1dFa5C361516AC2779E9602106fEa6': [0, 0, '1', 0, 0, 0],
  '0xb7df9A000Ab1439BF1BCe916109fA43b61220b44': [0, 0, '2', '6', '4', '1'],
  '0x4a60AdC9dbA099a2F0c2c50BefEA1C017c70f43B': [0, 0, '1', '2', 0, '1'],
  '0xA3bB19647FB2bBCFF1B288C90328bCceD05AC261': [0, 0, '1', '1', '3', '1'],
  '0xf83719280BA7f4b1e390bb1524ad42b427c80F4E': [0, 0, '1', '2', '3', '1'],
  '0x7090E61E52A0210288a2866209f450D9e13338Cf': [0, 0, '1', 0, '1', 0],
  '0x6D72680C1055A050F70feb90dAB8946307524C85': [0, 0, '2', '1', 0, '1'],
  '0x45745C9b881860dE8E25b5ba7f055D007F252cA3': [0, 0, '1', '2', '7', 0],
  '0x8fabeBF75d01627Ba72F774A755347EFBE18a2B1': [0, 0, '1', 0, '1', '1'],
  '0x55D35116e5dDfC16D89f552984274ec377539f3c': [0, 0, '1', 0, 0, '1'],
  '0x4cd8BDa7C7dD00656F2FC7520Ed1035e76B24399': [0, 0, '1', '1', '2', 0],
  '0x27D6DCE85a5Bed62F4d8Ef48d79B8CC4D77E1599': [0, 0, '1', 0, 0, 0],
  '0xb80C2690379ECc0b4AC1e988a747d4180fCE12f7': [0, 0, '1', '3', 0, '1'],
  '0x629acdcAcEaAE311D5eC4652db4bbe2Af99989fa': [0, 0, '1', '2', 0, '1'],
  '0xAe6475D591c449e1a04aA3FA482FdDc700Dc4CF1': [0, 0, '1', '1', 0, '1'],
  '0x945FC81C9edc66609F54cD19Cdb4500713F8a825': [0, 0, '1', '1', '1', '2'],
  '0x6e698f9CB4fd87945AFE3978650636EBc3e854B1': [0, 0, '1', '2', 0, 0],
  '0x42238FEb30ddb3991c57Ae74aF2F05f26cF41139': [0, 0, '1', 0, '11', 0],
  '0xC63A7794b034471c7ea446d33bF344aB76F7c833': [0, 0, '1', '5', '2', '2'],
  '0xBF696b0C593B4a10C390eeb469FCB663aE3301C9': [0, 0, '1', '2', 0, '2'],
  '0xD09fB8070299B88e3d0987f422776B79F56Bb2FD': [0, 0, '1', '1', 0, '1'],
  '0x6375885ac19FA185744DA0738cB1BC6a8dd159A5': [0, 0, '1', '1', '3', '1'],
  '0x7b58d449904A57E5C4C1D1E2561787fc0Ce12127': [0, 0, '1', '2', 0, 0],
  '0x9c799bF3cD9a601191BbB7BB0B4bff3cAb4f5315': [0, 0, '1', '2', '1', 0],
  '0x088424bCb69CcFD8Fc2DDa8c043dc4FeDb005928': [0, 0, '1', '2', 0, '1'],
  '0xe1EcF8EF96A4A01E1cB713321564530DC3165A8C': [0, 0, '1', '1', 0, 0],
  '0x02b6dE3443e1BfE39B29449159C5e74D35425073': [0, 0, '1', '2', '9', '1'],
  '0x02DEE48A27027B09b62ad062DC5e08B78eeE59E2': [0, 0, '1', 0, 0, 0],
  '0x7e9ea305b9A2df7f49EC9BB3A9ECAECa577220c8': [0, 0, 0, '3', 0, '1'],
  '0x23813458252830c9D7F15aD96FC837f388533bfA': [0, 0, 0, '1', '2', 0],
  '0x9fa97af8bCC4CeEC9e97F71D212c883736238A42': [0, 0, 0, '3', '1', '1'],
  '0x941b902fFd52d5CF01F2949a0a742D2dDCF7bE89': [0, 0, 0, '3', 0, '1'],
  '0x1e852af6ed8417D31086F42ea4E1EEB031FB3D7B': [0, 0, 0, '2', 0, '1'],
  '0x21aB54fe42Df33812524A9914c602a1bab700c4E': [0, 0, 0, '3', '2', '1'],
  '0x03026b4B075d6F037150cb4fB01223E5Ac0de981': [0, 0, 0, '1', 0, 0],
  '0x2dBC97FAFd68396bf4beC82b89Aa8E78Af2e3077': [0, 0, 0, '1', 0, 0],
  '0x991Fb89Df7F8aA26aB65EEDE2651f6a71371163C': [0, 0, 0, '1', '1', 0],
  '0xB0D6CB1DBd2b2C8329aA7d9113dc3D768712c639': [0, 0, 0, '1', 0, 0],
  '0x0A65C215CE8abC782A4B763FFfA10a41652Ac42C': [0, 0, 0, '1', '1', '1'],
  '0xa7748ed21289a1AFdbd5c5c7E519Ff219227BB5C': [0, 0, 0, '1', '1', '1'],
  '0x31cc30274a7c8c62dac5Bf005Bf58a9f8D6470E7': [0, 0, 0, '2', '1', 0],
  '0x154500Ccb9cC55A0E390966AA53Fe10E7DA1047d': [0, 0, 0, '2', 0, '1'],
  '0xBb3038CA46e21de389bf28b09ea093697384958a': [0, 0, 0, '2', 0, '1'],
  '0x097e83D5317A217aDCDA36c76501C0436eDB402A': [0, 0, 0, '2', 0, '1'],
  '0x148AFbce5CE5417e966E92D2c04Bd81D8cB0e04e': [0, 0, 0, '2', '4', '4'],
  '0x3bb608aCC9943E7C59A7AEF76e43c26E7A99C522': [0, 0, 0, '2', 0, 0],
  '0x8D62c1F9a0B8ffCfb8927B5C6CbE3971a666CAE2': [0, 0, 0, '2', '4', '1'],
  '0x45bEaCBB0e144718e17DEAc4DD810a7501244c89': [0, 0, 0, '1', '1', 0],
  '0xFb9Cf1477A33fdc6fF35F494FDB24FCbb3d70630': [0, 0, 0, '1', '2', 0],
  '0xCB03605a37415fC32F1E2899E9f8492F61f82351': [0, 0, 0, '3', 0, 0],
  '0x513DAea5A2353A8D03F5f62997C7BAD7a26d8c02': [0, 0, 0, '2', 0, '1'],
  '0xEAFd76F0AF95279B96f846ae4891Ce7abc9a2314': [0, 0, 0, '1', '1', '1'],
  '0xa38b14a921b98f5b5dbB0C3bCBFaAc375743E200': [0, 0, 0, '1', 0, 0],
  '0x4BB9eEF5e3192F1f0D78F5595b0262a289A9783c': [0, 0, 0, '1', '1', 0],
  '0xF5F36c4c5A29938D0fbcEF74Fa7af9B038B8b2A9': [0, 0, 0, '2', 0, '1'],
  '0x583Ae442a93b098bbc0556A5e4D892C66aEA6b47': [0, 0, 0, '2', 0, 0],
  '0x69a1C8BAf95EE4A36dCd6E9BCdaAE42B055D4e82': [0, 0, 0, '2', 0, '1'],
  '0x06e71164219d52dad9cBA10759402B5A3C391E38': [0, 0, 0, '3', 0, 0],
  '0x573DCD3bE09CD6d483f79BA5625aB9Db4EA93BEb': [0, 0, 0, '1', 0, 0],
  '0xc13456A34305e9265E907F70f76B1BA6E2055c8B': [0, 0, 0, '2', 0, '1'],
  '0x34Df9Ce7995776EA457d3cCe536be492d6589cB0': [0, 0, 0, '2', 0, '1'],
  '0xcEaC3415398545c2A93E006514db277eCbf51FF9': [0, 0, 0, '2', '1', '1'],
  '0xF5eD3153Ba479e86B3bfe2278c689D35b1045aa7': [0, 0, 0, '2', 0, '1'],
  '0x5f991BdCCCff8a5C6C00A5A708dc4f649Eb5887C': [0, 0, 0, '2', 0, 0],
  '0xA75BA5C6531af74Aa8176249750f17a0D5945A1D': [0, 0, 0, '2', 0, 0],
  '0xE6A1253e743e040552196DCC504cCFADda15dc0D': [0, 0, 0, '3', 0, '3'],
  '0x158e61A181959844D6Ac426a2A50eec065B3a943': [0, 0, 0, '1', '1', 0],
  '0xbb3277089e951FCfE4514ae000AC8376429ea54a': [0, 0, 0, '2', '10', '3'],
  '0xf9BE8Af9F649113a10529526e4c4dbe0f1F90289': [0, 0, 0, '1', '9', 0],
  '0x431b5DDB0AcE97eBC3d936403ea25831BaD832B6': [0, 0, 0, '2', 0, '1'],
  '0x328790A864bf986adA4f7761cFDfDd2b920D0D40': [0, 0, 0, '1', '1', '1'],
  '0xef6786d9843b20f1Aa169f2563F3919E7A362A39': [0, 0, 0, '2', 0, '1'],
  '0x475d7D6EeEc0309bf4BAd2a32F0507C24A1f146A': [0, 0, 0, '1', '1', 0],
  '0x80cb46375282e6de5d29C54685587ddF57D8fDBC': [0, 0, 0, '2', 0, 0],
  '0x05119f872A41176e47164665EEEd417Bb5E73fd7': [0, 0, 0, '1', 0, 0],
  '0xE0306069FCb6F26FecB0101F86E1f8BF2BcC6144': [0, 0, 0, '2', '1', '1'],
  '0x9821888988289d6cB3C403223B2f922E59B3239a': [0, 0, 0, '1', 0, 0],
  '0xF548C915E430E986D6d12bF49CF4E018344cE75C': [0, 0, 0, '2', '1', '1'],
  '0x6457c90DD48086B08E22288D6521a251E02d2617': [0, 0, 0, '1', '1', '1'],
  '0xA537F95fd7aAa5F3EF832E734488aD4730075812': [0, 0, 0, '1', '1', '1'],
  '0xae11bbce41bEb11bA99F6a531FAca59F2E844F2E': [0, 0, 0, '1', 0, 0],
  '0x99329a9d73f7136EE28E344Bd85132eeAfae3FDC': [0, 0, 0, '1', 0, 0],
  '0xF4Dc66D1802d76D3B663556ff96F1F35dD01a9BB': [0, 0, 0, '1', '1', '2'],
  '0xFea3F3F6Be3c1146A678e1B1B286Cf78717F7835': [0, 0, 0, '2', '1', '1'],
  '0x6a7217C003aAb08543657B404aC9988a844cBa4B': [0, 0, 0, '2', 0, '1'],
  '0xF2ac87A0aF7fF1B159985ff56Caa7204DF8481b0': [0, 0, 0, '2', 0, 0],
  '0x6DF73E759825f0488fd7931FEA8b331081adD215': [0, 0, 0, '1', 0, 0],
  '0x04eaB620551431316C69bF71Bc9c049e3deD03f7': [0, 0, 0, '1', '2', 0],
  '0x1C75a8bE04Ccf2c4a3a7B0a3EA88f9c1620b598A': [0, 0, 0, '1', '4', 0],
  '0x5ef22D551ca109f5CC7996502709065b39CdD23B': [0, 0, 0, '1', 0, 0],
  '0x0B98202E7222A3D6a60588Ba5a4b2B4EF4226216': [0, 0, 0, '1', '1', 0],
  '0xc63eAF51081C53C86CDDC21b30d4Ac72Df8b486A': [0, 0, 0, '1', 0, 0],
  '0x6a439840eCcA8E9394CC692f660dc88973DF0Fa5': [0, 0, 0, '2', 0, '2'],
  '0x6aC84b779Dc4DD683f9c321f5AbD5EBB07f2a9Ad': [0, 0, 0, '1', 0, 0],
  '0xf604Ab8aAf7130bdC1eB5589bD161054BaBfbC3c': [0, 0, 0, '1', '1', 0],
  '0x1BE48189187F72F95974B95D87B1155B47F8E2C6': [0, 0, 0, '3', 0, 0],
  '0x054C950A46c720125554683227159CFe525D9dd9': [0, 0, 0, '2', '1', '1'],
  '0x58A1a444E4621051376A6172ae43046977cA565C': [0, 0, 0, '1', '1', 0],
  '0x1Bf8BA061664339bAf1E1d016D8f6cea9455F404': [0, 0, 0, '1', '1', 0],
  '0xd9390b46a1749DEe5325A490490491db9a826D1F': [0, 0, 0, '1', '1', 0],
  '0xC578e5ba6962F59746261b507aee8b9520acF674': [0, 0, 0, '2', 0, 0],
  '0xa209F48835C6E4D8bcAd9828acb2658b6d73BdDf': [0, 0, 0, '3', '3', 0],
  '0xD3fBFC43e789F691c15120454A32ceDcD52bCF58': [0, 0, 0, '2', 0, 0],
  '0x4105a2b2695128Eb9E7Af7c90314Ff95b0AB9A81': [0, 0, 0, '2', '4', '1'],
  '0x8AC32Be3fE998bFBb08F9e6b4A263e44f7E871c0': [0, 0, 0, '1', '1', 0],
  '0xEB002AF9ca6AA416BaACEfA78E5D505759286355': [0, 0, 0, '1', '1', 0],
  '0x10f24FBC2a4ADDF445adf143Beb5E4236319f50B': [0, 0, 0, '1', 0, 0],
  '0x94813404773Ecaf70BE3C245e4fcD1DcD69a839e': [0, 0, 0, '1', 0, 0],
  '0xcbE03bc598DCa60fd2AA0a43ff3d2771F5A7C425': [0, 0, 0, '1', '1', 0],
  '0x80850E24989D001200CbF0A2215674Cc3C20C51e': [0, 0, 0, '2', '1', '1'],
  '0xC7f6a80311Ee85f7b78fCA69fbD7c098524cd27B': [0, 0, 0, '1', 0, 0],
  '0x50f028Bd83AE3290b2d06E223B99be78b665d1eA': [0, 0, 0, '2', '3', '1'],
  '0xaBAc45a6BC6851b87AD95839aF9f463124eb774E': [0, 0, 0, '2', 0, 0],
  '0xcE37B185721B7eD7DDd6D07BDE2Ff9c8bAba5519': [0, 0, 0, '3', '3', 0],
  '0xb0Ba26deDC9C1ddAe319de606C3B1279e26be57e': [0, 0, 0, '1', '1', 0],
  '0x6A76EBe9F75459846455a9a7c7D10E1eD4375322': [0, 0, 0, '3', '9', '1'],
  '0x45a52F9bbC2cE2b779654C6dfB40fD4a1947d5DA': [0, 0, 0, '2', 0, '1'],
  '0xF2a4E4EE289DFD154f569ced3045747a845Dc024': [0, 0, 0, '2', '4', '1'],
  '0xc33582462149F077d9734C5Dd65D5eEE5fA79588': [0, 0, 0, '2', 0, '1'],
  '0x35076725BD257F2d7EB1638Eca12ce28098e83f6': [0, 0, 0, '2', 0, '1'],
  '0x50060fdAc9A4617Bf64a16d7Ab422EE439cd08F7': [0, 0, 0, '1', '1', 0],
  '0x09CB3405FEd20bA3a370cE77E828550c8475CD46': [0, 0, 0, '2', 0, 0],
  '0xE908E593E3AEED1F2632718e31587Ab7eD0d4819': [0, 0, 0, '3', 0, '1'],
  '0x547954C57e3fE920cFB341a07A14bF54b6BE838A': [0, 0, 0, '2', 0, 0],
  '0xEF9170c02155Fb931a41E3A25a81547FACd23A55': [0, 0, 0, '2', 0, '1'],
  '0xB0877c5b6CB5b013651a1E291FfE6dBe34E7e375': [0, 0, 0, '1', '1', 0],
  '0x1c7CDF52b1cE0c7611a5129Dda384a3fEFf9487a': [0, 0, 0, '1', '1', 0],
  '0x49ed1AFe35B048C51e74f88985192d12693D2c10': [0, 0, 0, '2', 0, 0],
  '0x07473Aa32353f73602cCF795c3816418574f8f27': [0, 0, 0, '2', '1', '1'],
  '0xd864c63A23f9c13c9D3E4D10631883B46caDd258': [0, 0, 0, '2', 0, 0],
  '0x77D352647BD6054CE541Ee9c08f2830cf31b878f': [0, 0, 0, '2', '1', 0],
  '0xC5B6B100D0c756D5c0aA188e84827774DFde2245': [0, 0, 0, '1', '1', 0],
  '0xb11171351e638cB4B7c49EC52f9FdA3EA1f363e4': [0, 0, 0, '2', 0, 0],
  '0x64bCfA2bB7BA97448aF5B3E594B02a90c8FB6e2F': [0, 0, 0, '1', '1', '1'],
  '0xFC9Ca7e990CB63C6f94729401cd35409bEd304C4': [0, 0, 0, '1', '1', '1'],
  '0x35F34185F753D5FeF739d46dBA7a26b2bD88C83D': [0, 0, 0, '1', '2', 0],
  '0xA8a0f26f537a75E29E42301358EeC413dA1c4967': [0, 0, 0, '1', '5', 0],
  '0x79344B5FAfc56238BC0dF15AB499d38f1842163e': [0, 0, 0, '1', '3', 0],
  '0x297A046955072b0101499aafD06bb14B400EC73f': [0, 0, 0, '1', 0, 0],
  '0x6bEE4079849E1993bFDaB33Ee5392AD821798618': [0, 0, 0, '1', '1', 0],
  '0x5863a2AfDF344da52D3b5FCAA933FE4EC391430a': [0, 0, 0, '1', '5', '1'],
  '0xeB6a24e62e2f337dD65c6c2086488E6f8CF7eb31': [0, 0, 0, '1', '1', 0],
  '0xD9F2d30098449eebD1052F71164F4e336a399E65': [0, 0, 0, '1', '1', 0],
  '0x7DE6D32bfFE6C99d41Fd311171dC6C0fc45c70Bd': [0, 0, 0, '1', '1', '1'],
  '0xc70dAF7356bC5f2eF6008E0FaA7e076C47C6e7f3': [0, 0, 0, '1', '5', '5'],
  '0x0b4eCF28692614C10Deb7d8579F4878Be3Fd2DE9': [0, 0, 0, '1', '1', 0],
  '0xA069F2204CAB4862c861925AD40b113D158A5E2F': [0, 0, 0, 0, '2', 0],
  '0x74dBCd50170D5b1E4bD40a4e054F5Fd2FFd8c5ab': [0, 0, 0, 0, '2', '1'],
  '0x02bF9d046e1C0Ae7fD4819D298744473eB229171': [0, 0, 0, 0, '2', 0],
  '0x0492533E1A3c911C40A127A4c56A826f835388b2': [0, 0, 0, 0, '2', 0],
  '0x3Bf6C943562E3aEff119153B68240c3Cdd4cD0a2': [0, 0, 0, 0, '4', 0],
  '0x3EC0cAFF18dFF120a73eF24987afBEc122BfA79e': [0, 0, 0, 0, '2', 0],
  '0x83Fe4Ef209Fe2C89B3868002590aC8d455Ff7517': [0, 0, 0, 0, '1', 0],
  '0x7F2cDf9c1805900e6f2da11011b52829AE37395b': [0, 0, 0, 0, '1', 0],
  '0xb2E6f214d4014822de13285bdf384362dBffF219': [0, 0, 0, 0, '6', '1'],
  '0xa5F11624F0F51C76bfb5861076f9e8c4b56f3E49': [0, 0, 0, 0, '3', 0],
  '0xB7576716aAc3cD7F62448ff62C06ddd85D202339': [0, 0, 0, 0, '6', '1'],
  '0xE52e4d3B9032905105f7fa7c9B6DDF8A161f4930': [0, 0, 0, 0, '2', 0],
  '0x240e820B082094F2fA8aF45faC27b870d0632aa3': [0, 0, 0, 0, '5', 0],
  '0x1f5Be9B215D91cB9dF6aa971f546d1eA5551097d': [0, 0, 0, 0, '5', '1'],
  '0xEe7C4aCA7D64075550F1b119b4bb4A0Aa889c340': [0, 0, 0, 0, '1', 0],
  '0x5Fb0058280611B57E7fd96dc4bA3616bD0d29806': [0, 0, 0, 0, '2', 0],
  '0x70b4bBd6b71996c6C1097c49a27e556E8D468A28': [0, 0, 0, 0, '2', 0],
  '0x8Fc87c199203332c1cc43430b9FaD2B1868E44D0': [0, 0, 0, 0, '2', 0],
  '0x7A609FFE93D751BA5AFAA728D5fb5dc48A3cA685': [0, 0, 0, 0, '2', 0],
  '0x79f1D64a18008652FcA2a97bB59807E14d82Cb4C': [0, 0, 0, 0, '5', 0],
  '0xEd1E925A7c3fAE940A721FD121a91fE3121a8361': [0, 0, 0, 0, '1', 0],
  '0x181D63cD2fC21d5Ef1213aDCA4b94B77BF6af449': [0, 0, 0, 0, '2', 0],
  '0xA9152BcE1EE45f3cCfe949C085385793948a5275': [0, 0, 0, 0, '1', 0],
  '0x9479c9Ba111F0a146D08d2b8a19eaC404D2f147B': [0, 0, 0, 0, '3', '3'],
  '0xa6c4194d906e79dCB586f7E5aCc9FD0005147b76': [0, 0, 0, 0, '2', 0],
  '0xF1B0428a0cF288eAc1c1CC93e7c701A116a2F9C3': [0, 0, 0, 0, '2', 0],
  '0xCcddE45bcE9E5f3c1FAB2b8d2e9a42FEff05A34C': [0, 0, 0, 0, '2', 0],
  '0x7a11bFb0276FC062156654A7f64FB26cfc20f49a': [0, 0, 0, 0, '2', 0],
  '0xD1e3BfDaBF108C6868D9e5bf864aBB693992eFCe': [0, 0, 0, 0, '3', '1'],
  '0x8dEAa64Dd47F766B561A4ca767A2974FFC56f4cE': [0, 0, 0, 0, '2', '1'],
  '0xaeb83CF455aDD9FaDcDe369fcb1c7BefB4AF1634': [0, 0, 0, 0, '1', '1'],
  '0x31F2b32D20377345ae1F5fD789CB233Eb4A31c91': [0, 0, 0, 0, '6', 0],
  '0xc01D54bBA22C780c502319B0e977dC327E87Bc25': [0, 0, 0, 0, '1', 0],
  '0x39B946e35b3DC215455cc95b446eDd8368928d10': [0, 0, 0, 0, '2', 0],
  '0xcE7DEb8fc70838F019a33797e1D2A5De8B7Ae2cC': [0, 0, 0, 0, '2', 0],
  '0xE6C085d07730605FC6cdaaa01831985025DCef14': [0, 0, 0, 0, '2', '1'],
  '0x9101d1990818065d2BF3f5EE9d658Bf255a0475d': [0, 0, 0, 0, '2', 0],
  '0xEcAbaaf0764D5de2921cbAC981a76cd58d1F99F8': [0, 0, 0, 0, '2', 0],
  '0x8DDe59Eea5F8FCcD17C17e28884FB025a3a9FC6a': [0, 0, 0, 0, '3', 0],
  '0xBc1610224aC23Fc26eD45abD13BED80E982A7bBb': [0, 0, 0, 0, '2', 0],
  '0x133937476926A1A9b93e7AD71a4af2F60df31c0F': [0, 0, 0, 0, '1', 0],
  '0xC4b2B185962E10263AFE05599C3A5a5ccdb5c5B9': [0, 0, 0, 0, '1', 0],
  '0x90d5Ac633219DF1de774d2007E691c90E496aD9A': [0, 0, 0, 0, '2', 0],
  '0xd40f0D8f08Eb702Ce5b4Aa039a7B004043433098': [0, 0, 0, 0, '1', 0],
  '0xB2d934237A4444B107df8bF190a9cb34f52Fa723': [0, 0, 0, 0, '3', 0],
  '0x765d9C485bEBb431872eA51d000A20623c1E5b94': [0, 0, 0, 0, '2', 0],
  '0xca73AA1b4F4bCb67BE2dDA5348af1A306B103892': [0, 0, 0, 0, '2', 0],
  '0x9902767Aa58E0210BAbf3De8164b9377f28ace7d': [0, 0, 0, 0, '1', 0],
  '0xB8351613034ce5B7a8250AaA4AfB96EB2a4620B0': [0, 0, 0, 0, '2', 0],
  '0xEc9161515BaaD7C3056d16ac9687873d32db646B': [0, 0, 0, 0, '4', 0],
  '0xab57AC2C9C9427E3859B608dCCa5cd6632681b27': [0, 0, 0, 0, '2', 0],
  '0x44E7E928801fd87aBB8458F7804EAC9dc05C3b7F': [0, 0, 0, 0, '1', 0],
  '0x1bDb7606Fc0008C99DCB2784C41Af11fE42E07Ba': [0, 0, 0, 0, '1', 0],
  '0xb4DEBfFe85E8790581697d7ef9ffA1cA8C3e4290': [0, 0, 0, 0, '2', 0],
  '0x3027419B08f62840067726f760913267d41885ea': [0, 0, 0, 0, '1', 0],
  '0x93c0DAe7E52b4733404549F1f793B6591Fef129B': [0, 0, 0, 0, '2', 0],
  '0xB2459dBAAB6EE3a6E27D66c4aC6d5fa411ee6a21': [0, 0, 0, 0, '2', '1'],
  '0xc7e318811aAc4e3975BF49d6A7C6FBC82D2305a7': [0, 0, 0, 0, '6', '2'],
  '0x20CB6Ae10D7C23986D525C767AD4b39D4dFDf32d': [0, 0, 0, 0, '1', '1'],
  '0x3bC1A8d4Eae6791A9C9eFbA19d6BEf122E050e0B': [0, 0, 0, 0, '2', '1'],
  '0x7512657BC0ff46A1F391594Aec2F143318Bc7BF5': [0, 0, 0, 0, '2', 0],
  '0x20Cd114EcF70C79502Cc2b47b7d70C3a5F6b508e': [0, 0, 0, 0, '2', 0],
  '0x2A67B3C9B9392ca241b38D03BD3502937C024B07': [0, 0, 0, 0, '2', 0],
  '0x9893117455Cf1E8AC3e58E41c5885ad5Adb1c9FF': [0, 0, 0, 0, '3', 0],
  '0x397FE6Ab9EaE33a1C06E07a4d5D1Acc2E8017E61': [0, 0, 0, 0, '4', '1'],
  '0x24BB4De554CC1A3fde5F10f1Fcdaf4A66EcAB35E': [0, 0, 0, 0, '2', 0],
  '0xB8095c7f1b86008781D343276B1a66DC6CD998C4': [0, 0, 0, 0, '2', '1'],
  '0xDC6657A6e3eE8ec7004DADD99A7A030b975556f8': [0, 0, 0, 0, '2', 0],
  '0x2DB24cCcE499eBa41DF32a5807c31c1aa2E493c0': [0, 0, 0, 0, '2', 0],
  '0x984e946C8B2f139CFd7215267cC0f570C32FbA15': [0, 0, 0, 0, '2', 0],
  '0x1C7b949ab51f7d1a981d5b8AdB0CAb4D7Dd3C316': [0, 0, 0, 0, '3', '1'],
  '0x84a68C3965e548352CA057486719FC335DD96B71': [0, 0, 0, 0, '2', '1'],
  '0x445c372fC4005Fe10dd09a1FcBD6be0433E23344': [0, 0, 0, 0, '2', '3'],
  '0x6e196E712007ca4816Cd8AF573cDEE4322Ea249A': [0, 0, 0, 0, '1', 0],
  '0x0941Ae5A513CC77c99dF2b8f9F35281003d49510': [0, 0, 0, 0, '3', '1'],
  '0x6C7CBF357bfC2415166a3d2D3F9295bECb5a165A': [0, 0, 0, 0, '1', 0],
  '0x34b4E20B145955bfb893c580584e815F52567620': [0, 0, 0, 0, '12', 0],
  '0x7bc63A4e022986409EBD73C76f5eE08A99528880': [0, 0, 0, 0, '2', 0],
  '0x2bC600FB327183807c3be83F3536c1549441e69a': [0, 0, 0, 0, '1', 0],
  '0xF77558Cbb61aD8a15dE1d325bE84E04709Ee002d': [0, 0, 0, 0, '2', 0],
  '0xe4c60a132e7B66dCc8F1c89A921BEbB5CE4A8911': [0, 0, 0, 0, '2', '1'],
  '0x5c1913732424aB8fb7d7407beFf1845e2aD52304': [0, 0, 0, 0, '3', 0],
  '0xE0919fc99F29ce7f5F91B81769fE45aC8680d6Ad': [0, 0, 0, 0, '1', 0],
  '0x5b62019942865Cc459697B728c075e39a0AA5084': [0, 0, 0, 0, '2', 0],
  '0xC9fE451251398F7Ba82296DD6eC2E3f43ee8d93F': [0, 0, 0, 0, '1', 0],
  '0xCc6584A04c01908d0691E66EE39796e5eb069525': [0, 0, 0, 0, '2', 0],
  '0x224b9fa302eE40eCb4752F57b3C3C580a6D620F0': [0, 0, 0, 0, '1', 0],
  '0x080A4Fc5b3d6CE7f6Ca6D8A752c0a114146c2887': [0, 0, 0, 0, '1', 0],
  '0x1193C78E1DC6Df1eD3a141e6bCdeFD355FC44f2D': [0, 0, 0, 0, '2', 0],
  '0xc2B3512afdC4b060Fa79C95BAeB65C6d1Bcaf6E0': [0, 0, 0, 0, '1', 0],
  '0x7B7B77693dD8FA5F0f3D1e1E290F049aDF2D4773': [0, 0, 0, 0, '1', 0],
  '0xca17b2B666a5071FF8e7b230180726d112D72e8c': [0, 0, 0, 0, '1', 0],
  '0x90BCf5d9c74a45926999DeAF1529B3894aFc40c0': [0, 0, 0, 0, '1', 0],
  '0xB3D8B792bD678e433C3C581e47e88673D81A04d8': [0, 0, 0, 0, '3', '1'],
  '0x2faA140Bc6BC42c56f43e6760Dca18412D4CCbEb': [0, 0, 0, 0, '1', 0],
  '0x88e2878B650d04c9b54b82dE4028Ef9C44027335': [0, 0, 0, 0, '2', '1'],
  '0xb25F5cAC63c92C1034cA37d712B85D008b3B8789': [0, 0, 0, 0, '2', 0],
  '0xba95a79706DC4b655eB302Bdb16509A02f04ADe3': [0, 0, 0, 0, '1', 0],
  '0xC02e0BCd78C1aB02c125b6118790492f75D4addd': [0, 0, 0, 0, '1', 0],
  '0x89E77c6d7Dde755704638534AE6be54AF6F67Dcd': [0, 0, 0, 0, '2', 0],
  '0xEeae0b2b09F69aa6395B8BB4a8De5Faa720C9A05': [0, 0, 0, 0, '1', 0],
  '0x4AaE8078C2FEe3638BBbEe5E3d525F016CC656e4': [0, 0, 0, 0, '3', '1'],
  '0xcf30a61c99138c7DafFAdBFe0d074445f2DBA814': [0, 0, 0, 0, '3', 0],
  '0xDEfc8eE1683D7D78647C0F1E7147482a9a862fBf': [0, 0, 0, 0, '1', 0],
  '0x2630b921F1959759e5181b526642270d6165D95e': [0, 0, 0, 0, '1', 0],
  '0xBeF1FeFE4Bb6DDA516E9Af3A3F793d182cf053Bc': [0, 0, 0, 0, '1', 0],
  '0xeFED3CCdA9b7891d123D31a51DA6Be7882dF2f03': [0, 0, 0, 0, '1', 0],
  '0x55f92432Db2ed79E4E96857091171CA44b6885fc': [0, 0, 0, 0, '1', 0],
  '0x1Ac037d994Fab808be5F965A3FEA630B3C72Ab4b': [0, 0, 0, 0, '2', '1'],
  '0xA12B26E34177623BDeE2c2c610AD0EA567636459': [0, 0, 0, 0, '1', 0],
  '0x0a40aDcCC898404a132541F04aA3dA611910926d': [0, 0, 0, 0, '2', 0],
  '0xC0108CA6BDAEEeedfc9A9A4eF6602Db5A71Df263': [0, 0, 0, 0, '1', 0],
  '0x243C9Dc0f20e617326ab5aE34adEC887324af05e': [0, 0, 0, 0, '1', 0],
  '0xd45B2e4BAD581Fe6b43cc6D6532487d8986c6d38': [0, 0, 0, 0, '1', 0],
  '0x241AbeFc7014e7c38831bbAf8800d54f15C64CC5': [0, 0, 0, 0, '2', '1'],
  '0x562eD81E568d860d2B7Ce395a6ae364398e2A100': [0, 0, 0, 0, '3', 0],
  '0x9d5e80912Cf2B11e3fEaa727A6b5919975Ab8989': [0, 0, 0, 0, '6', '1'],
  '0x6cB6161913A1aC477Da7cC890E54284839991136': [0, 0, 0, 0, '1', 0],
  '0xb767855ada3f3268AFf07dE5840188120bA60Cd4': [0, 0, 0, 0, '12', '1'],
  '0xb98cdF907C7607a08C5BC60677Ff6EEFAb8Aa551': [0, 0, 0, 0, '1', 0],
  '0xDC61B2b6918ea88a977D2F8701b029b25496ba97': [0, 0, 0, 0, '5', 0],
  '0xb1dFdbb11EdCcE3d2Da35DE71e8e8DBE3a3547C7': [0, 0, 0, 0, '1', 0],
  '0xd2B41B2209de76ed2B6224e9204248d055ee20c8': [0, 0, 0, 0, '2', '1'],
  '0xf93126885C9328Acd564a10DaE85D9BE543f7996': [0, 0, 0, 0, '1', 0],
  '0x4Ba18AC212fE67813667DA955014887C5840B0D6': [0, 0, 0, 0, '3', '1'],
  '0x5f5F7BdAF27c7700159fA2E52cfb5cdb95f9Cdf1': [0, 0, 0, 0, '2', 0],
  '0x4DA1eFeFB6EC6109C9eC4DcD296246a183177359': [0, 0, 0, 0, '2', 0],
  '0xC47145A82F21C5Bb731e0Dd419a668a5014A7037': [0, 0, 0, 0, '1', 0],
  '0xBfaeA07dcBB51621CC9ea9d95A6E062668A2Cc80': [0, 0, 0, 0, '2', 0],
  '0xAcF5c924Ca4a12E484254ddfb89a2E7a383014B5': [0, 0, 0, 0, '1', 0],
  '0x0e61979eb7b67dB9C27a8D7a195781eE3a9debA8': [0, 0, 0, 0, '1', 0],
  '0x1BD8AbC42c180Fe8b0AD37C8A902288861Cc9e7e': [0, 0, 0, 0, '5', 0],
  '0x0DcE3A4BD03e9676B1e00Dc1d1f103ED9c39178C': [0, 0, 0, 0, '1', 0],
  '0x49dE9eC7E35015799724897832c3C75aB78c55B3': [0, 0, 0, 0, '5', 0],
  '0x679c3D812F69042671aB45Bc1BEB124299c870B2': [0, 0, 0, 0, '2', 0],
  '0xF5886667D7c9aF73b5E1C54064265721d871a98F': [0, 0, 0, 0, '1', 0],
  '0xC3D652320f90f7394B1b9Bf9F322140B936895da': [0, 0, 0, 0, '2', 0],
  '0x8525b90360437CC5d2ca4e19D6902F34F168d3F1': [0, 0, 0, 0, '2', 0],
  '0x3cfAd5D67249dE057BC1C9b47Eb8a0Cf7B4F6cF5': [0, 0, 0, 0, '1', 0],
  '0x4c612330d1EeBa9C04752265DC8B64433495D4a8': [0, 0, 0, 0, '2', '1'],
  '0xf68CE846669a673C48824e514170264C07Ecaa16': [0, 0, 0, 0, '1', 0],
  '0x1F7798d402b35f2C7b18d1185a88Fa6fb7618628': [0, 0, 0, 0, '1', 0],
  '0x33387dDbf18cEFac99971Ada9cdD9622bc607233': [0, 0, 0, 0, '3', 0],
  '0xd5eaa7a18D77B9890aFD750a96c6476682ABEaf6': [0, 0, 0, 0, '1', 0],
  '0xCce51B1E3d7aCBC660A5e8f5e80a4f883EC2Fd37': [0, 0, 0, 0, '2', 0],
  '0x8352e9d559f50F3AC699615E7D9B4cbEd294EF16': [0, 0, 0, 0, '5', 0],
  '0xdD80E70f92863d2FCf722F1D0e5916E4E8Cd2A82': [0, 0, 0, 0, '3', 0],
  '0xA4C350f7Ee6d318215a76A22D6D1D10D62Cd2137': [0, 0, 0, 0, '2', 0],
  '0xb5E975642e63332F6F194f3E57d777cA86E6b746': [0, 0, 0, 0, '2', 0],
  '0x94D5F820538974f0f15cD182d18cf0801be7b9Ad': [0, 0, 0, 0, '1', 0],
  '0x66A081F2cA3Cd6d950e9640C4e34D10bbA032521': [0, 0, 0, 0, '1', 0],
  '0x452CB6A7c29EB22A11fFfC5a3E1748D17EAAb39C': [0, 0, 0, 0, '3', 0],
  '0x0249bEa8BD7820ce8C8F122Dc48ca1a2aDDa1ca6': [0, 0, 0, 0, 0, '2'],
  '0x296349E2fC001eA2210CF934E398C45128d127ac': [0, 0, 0, 0, 0, '1'],
  '0x601Fa6dd74dC92276E7D48188D32eA0bA49a6911': [0, 0, 0, 0, 0, '1'],
  '0xF5eFB67a179e0935E5bfd65C52cb098142Ee6E8f': [0, 0, 0, 0, 0, '1'],
};
