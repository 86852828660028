import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/shush.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const shush: PoolConfig = {
  // id is used in URL
  id: 'shush',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0x2ecD755A4Ff06552393F8a84a64e3e7007784f6B',
    tokensForSale: toWeiBN('158333333.333'),
    startDate: '2022-08-22T20:00:00.000Z',
    rate: 1666.667,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('158333333.332999999999933128'),
    raised: toWeiBN('94999.9809998038000392'),
    baseAllocation: toWeiBN('13224.519205175770564892'),
    status: 5,
    registerDate: '2022-08-20T20:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.6666666666666666,
    totalWeights: 12072310,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '20% at TGE, 20% allocated every month after',
    claimerAddress: '0x1c83432Aef80BF75252171a565BE260004B45dbe',
    claimerVersion: 'v3',
    schedule: {
      '2022-08-24T15:20:00.000Z': 20,
      '': 20,
      ' ': 20,
      '  ': 20,
      '   ': 20,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x4c3031Ad4cf224BFA893775271CB39f845716e8F',
    name: 'Shush',
    symbol: 'SHUSH',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-08-24T15:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(499800000),
    totalSupply: toWeiBN(100_000_000_000),
    coinmarketcapId: 'shush-club',
  },
  social: {
    website: 'https://www.shushshush.com/',
    twitter: 'https://twitter.com/shush_club',
    telegram: 'https://t.me/shushclub',
    telegramAnno: 'https://t.me/ShushClubAnn',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Shush Club, currently in development, will pioneer a revolutionary
      infrastructure to support the rapidly-growing creator economy. Shush Club
      offers a blockchain-powered alternative to contemporary content platforms
      and empowers Creators and Fans alike by providing access to profitable
      marketing and investment opportunities in a space that is: Secure,
      Efficient and Fair for All.
    </p>
  ),
};
