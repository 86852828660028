import img from 'pools/images/soldex.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { SolanaChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const soldexCompensation: PoolConfig = {
  // id is used in URL
  id: 'soldex-compensation',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x9054c6d089F583a6D06630a08a15EFFC7Fca6545',
    tokensForSale: toWeiBN('833333'),
    startDate: '2021-12-08T12:00:00.000Z',
    rate: 8.33333,
    durationHours: 7,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('833332.999999999999999797'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('472.5'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-06T12:00:00.000Z',
    registerDuration: 0.016666666666666666,
    fcfsDuration: 1,
    totalWeights: 2360,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: 'CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn',
    name: 'SOLDEX (comp)',
    symbol: 'SOLX',
    imageSrc: img.src,
    decimals: 9,
    listingRate: 0,
    listingTime: '2021-12-10T12:30:00.000Z',
    initialSupply: toWeiBN(52_000_000),
    totalSupply: toWeiBN(500000000),
    coingeckoId: 'soldex',
    coinmarketcapId: 'soldex',
  },
  finishedInfo: (
    <SolanaChartFinishedInfo
      symbol="SOLX"
      address={'CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn'}
    />
  ),
  distribution: {
    type: 'airdrop_us',
    vesting: '20% at listing, 8 months vesting, monthly distribution',
    schedule: {
      '2021-12-10T12:30:00.000Z': 20,
      '2022-01-10T22:00:00.000Z': 10,
      '2022-02-10T22:00:00.000Z': 10,
      '2022-03-10T22:00:00.000Z': 10,
      '2022-04-10T22:00:00.000Z': 10,
      '2022-05-10T22:00:00.000Z': 10,
      '2022-06-10T22:00:00.000Z': 10,
      '2022-07-10T22:00:00.000Z': 10,
      '2022-08-10T22:00:00.000Z': 10,
    },
  },
  social: {
    website: 'https://soldex.ai/',
    twitter: 'https://twitter.com/soldexai',
    telegram: 'https://t.me/Soldexai',
    telegramAnno: 'https://t.me/soldexann',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <>
      <p>
        Soldex.ai works on all market conditions 24/7 with emotionless
        machine-learning and neural network algorithms. It’s like hiring an
        expert to do crypto trading for you while you can sit back and watch.
      </p>
      <p className="mt-3">
        <strong>
          Compensation pool for the participants who missed on Ariadne. All the
          participants are automatically whitelisted.
        </strong>
      </p>
    </>
  ),
};
