import { SaleStatus } from '@trustpad/launchpad';

import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';
import { commonVaulty } from './vaulty';

export const vaultyPrivate: PoolConfig = {
  ...commonVaulty,
  // id is used in URL
  id: 'vaulty-black',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  sale: {
    address: '0x16737eCfb5640eFE6a6c9bAfC30D390fFe3f0C36',
    status: SaleStatus.finished,
    rate: 1.818181,
    tokensForSale: toWeiBN('72727.27'),
    startDate: '2021-08-09T14:00:00.000Z',
    baseAllocation: toWeiBN('325'),
    durationHours: 24,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$40,000',
    participants: 2,
    tokensSold: toWeiBN('18181.81'),
    raised: toWeiBN('10000'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('5000'),
    },
    registerDate: '2021-08-08T10:00:00.000Z',
    registerDuration: 27,
    fcfsDuration: 0.5,
    levelsOpenAll: true,
  },
  distribution: {
    type: 'claim_us',
    vesting: '22.5% on TGE, then 15.5% monthly over 5 months',
    claimerVersion: 'v1',
    claimerAddress: '0x539bCf49E11B695308F336eA2A4D6409746E95E2',
    schedule: {
      '2021-08-17T14:30:00.000Z': 22.5,
      '2021-09-17T14:30:00.000Z': 15.5,
      '2021-10-17T14:30:00.000Z': 15.5,
      '2021-11-17T14:30:00.000Z': 15.5,
      '2021-12-17T14:30:00.000Z': 15.5,
      '2022-01-17T14:30:00.000Z': 15.5,
    },
  },
};
