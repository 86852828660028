import { useWeb3Provider } from '@trustpad/launchpad';

import { Button } from '~features/ui';

import img from './wallet-trustwallet.svg';

export function TrustWalletButton() {
  const { connectTrustWallet } = useWeb3Provider();

  return (
    <Button
      type="walletConnect"
      onClick={() => connectTrustWallet()}
      className="bg-sky-700 hover:bg-sky-900">
      <img
        src={img.src}
        alt="TrustWallet"
        className="inline-block w-5 mr-3 ml-[1px]"
      />
      <span>TrustWallet</span>
    </Button>
  );
}
