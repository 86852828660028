/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Button } from 'react-bootstrap';

import { TOKEN_ADDRESS } from '~config/addresses';
import apeswapImg from '~features/launched/images/apeswap.svg';
import pancakeImg from '~features/launched/images/pancakeswap.svg';
import quickswapImg from '~features/launched/images/quickswap.png';
import raydiumSvg from '~features/launched/images/raydium.svg';
import spookyImg from '~features/launched/images/spookyswap.png';
import uniswapImg from '~features/launched/images/uniswap.png';
import { getTradeUrl } from '~utils';

export { pancakeImg };

export function BuyPancakeSwapButton({
  address = TOKEN_ADDRESS,
  symbol = 'TPAD',
  size = 'lg',
}) {
  return (
    <Button
      title="Buy on PancakeSwap"
      variant="main"
      href={getTradeUrl(address, 'CAKE')}
      target="_blank"
      className={`btn-sm no-hover ${size === 'sm' ? 'py-1 px-2' : 'py-2'}`}>
      <img src={pancakeImg.src} alt="PancakeSwap" className="h-6 inline mr-1" />{' '}
      Buy {symbol}
    </Button>
  );
}

export function BuyApeSwapButton({
  address,
  symbol,
}: {
  address: string;
  symbol: string;
}) {
  return (
    <Button
      title="Buy on ApeSwap"
      variant="main"
      href={getTradeUrl(address, 'APE')}
      target="_blank"
      className="btn-sm py-2 no-hover">
      <img src={apeswapImg.src} alt="ApeSwap" className="h-6 inline mr-1" /> Buy{' '}
      {symbol}
    </Button>
  );
}

export function BuyUniSwapButton({
  address,
  symbol,
}: {
  address: string;
  symbol: string;
}) {
  return (
    <Button
      title="Buy on Uniswap"
      variant="main"
      href={getTradeUrl(address, 'UNISWAP')}
      target="_blank"
      className="btn-sm py-2 no-hover">
      <img src={uniswapImg.src} alt="UniSwap" className="h-6 inline" /> Buy{' '}
      {symbol}
    </Button>
  );
}

export function BuyQuickswapButton({
  address,
  symbol,
}: {
  address: string;
  symbol: string;
}) {
  return (
    <Button
      title="Buy on QuickSwap"
      variant="main"
      href={getTradeUrl(address, 'QUICK')}
      target="_blank"
      className="btn-sm py-2 no-hover">
      <img src={quickswapImg.src} alt="QuickSwap" className="h-6 inline" /> Buy{' '}
      {symbol}
    </Button>
  );
}

export function BuyRaydiumButton({
  address,
  symbol,
}: {
  address: string;
  symbol: string;
}) {
  return (
    <Button
      title="Buy on Raydium"
      variant="main"
      href={`https://raydium.io/swap/?from=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&to=${address}`}
      target="_blank"
      className="btn-sm py-2 no-hover">
      <img src={raydiumSvg.src} alt="Raydium" className="h-6 inline mr-1" /> Buy{' '}
      {symbol}
    </Button>
  );
}

export function BuySpookySwapButton({
  address,
  symbol,
}: {
  address: string;
  symbol: string;
}) {
  return (
    <Button
      title="Buy on SpookySwap"
      variant="main"
      href={`https://spookyswap.finance/swap?outputCurrency=${address}&inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75`}
      target="_blank"
      className="btn-sm py-2 no-hover">
      <img src={spookyImg.src} alt="SpookySwap" className="h-6 inline mr-1" />{' '}
      Buy {symbol}
    </Button>
  );
}
