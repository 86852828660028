import {
  useLaunchpadProvider,
  usePool,
  useUser,
  useUserSaleTier,
} from '@trustpad/launchpad';
import { isNFTSale } from '@trustpad/launchpad/dist/pool/utils';

import Link from 'next/link';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaCircleNotch } from 'react-icons/fa';
import { SaleRegisterDialog } from '~features/pools/components/SaleRegisterButton/SaleRegisterDialog';

import { TierName } from '~features/tiers';
import { TierWithMultiplier } from '~features/tiers/components/TierName';
import { errorTx, successTx } from '~utils/toast';

const Wrapper = ({
  children,
  center,
}: {
  children: React.ReactNode;
  center: boolean;
}) => (
  <div
    className={
      center
        ? 'space-y-3 text-center border-t border-main py-3 border-opacity-30 relative z-20'
        : 'space-y-3 relative z-20'
    }>
    {children}
  </div>
);

export function SaleRegisterButton({ center }: { center?: boolean }) {
  const { levels } = useLaunchpadProvider();
  const {
    pool: { sale, token },
    saleInstance,
  } = usePool();
  const { account } = useUser();
  const {
    tierState: { tier },
    isWhitelisted,
    isCurrentTierLoading,
    registeredTier,
    userState: { weight, isLotteryWinner },
    refresh,
  } = useUserSaleTier();
  const [isLoading, setIsLoading] = useState(false);

  function register() {
    if (!account) return;

    setIsLoading(true);

    return saleInstance.methods
      .register()
      .send({ from: account })
      .then((tx) => {
        refresh();
        successTx({
          content: `You registered for the "${token.name}" sale!`,
          tx: tx,
        });
      })
      .catch((e) => {
        errorTx({
          content: `Failed to register in "${token.name}" sale!`,
          // tx: tx,
          error: e,
        });
      })
      .finally(() => setIsLoading(false));
  }

  if (!account) {
    return null;
  }

  if (!sale.isTiered) {
    return null;
  }

  if (isCurrentTierLoading) {
    return (
      <FaCircleNotch size={40} className="inline fa-spin opacity-50 mx-auto" />
    );
  }

  const ButtonWithInfo = ({
    buttonLabel,
    showPopup,
  }: {
    buttonLabel?: string;
    showPopup?: boolean;
  }) => {
    return (
      <SaleRegisterDialog
        tokenName={token.name}
        isLoading={isLoading}
        onConfirm={register}
        buttonLabel={buttonLabel}
        showPopup={showPopup}
      />
    );
  };

  // When whitelisted
  if (!weight && isWhitelisted) {
    if (tier.multiplier > 0) {
      return (
        <Wrapper center={center}>
          <p>
            You are whitelisted! But you also can register as{' '}
            <TierName name={tier.name} />.
          </p>

          <SaleRegisterDialog
            tokenName={token.name}
            isLoading={isLoading}
            onConfirm={register}
            showPopup
          />
        </Wrapper>
      );
    } else {
      return (
        <Wrapper center={center}>
          <p>You are whitelisted!</p>
        </Wrapper>
      );
    }
  }

  // User has not registered, but has a high enough level
  if (!registeredTier.multiplier && tier.multiplier > 0) {
    if (
      sale.minAllowedLevelMultiplier &&
      tier.multiplier < sale.minAllowedLevelMultiplier
    ) {
      const minRequired = Object.values(levels).find(
        (l) => l.multiplier >= sale.minAllowedLevelMultiplier,
      );
      return (
        <Wrapper center={center}>
          <p>
            This IDO is private. You need at least the{' '}
            <TierName name={minRequired.name} /> level.
          </p>

          <Link href="/levels" passHref legacyBehavior>
            <Button variant="main">Stake more</Button>
          </Link>
        </Wrapper>
      );
    }

    return (
      <Wrapper center={center}>
        <p>
          Your level is <TierWithMultiplier tier={tier} />.
        </p>
        <SaleRegisterDialog
          tokenName={token.name}
          isLoading={isLoading}
          onConfirm={register}
          showPopup
        />
      </Wrapper>
    );
  }

  // User has "none" level
  if (!registeredTier.multiplier) {
    return (
      <Wrapper center={center}>
        <p>
          Your level <TierName name={tier.name} /> is too low to participate.
        </p>

        <Link href="/levels" passHref legacyBehavior>
          <Button variant="main">Stake more</Button>
        </Link>
      </Wrapper>
    );
  }

  if (
    tier.multiplier > 0 &&
    (tier.multiplier > registeredTier.multiplier ||
      (!tier.random && registeredTier.random) ||
      (tier.random && registeredTier.random && tier.odds > registeredTier.odds))
  ) {
    return (
      <Wrapper center={center}>
        <p>
          You registered as <TierWithMultiplier tier={registeredTier} /> level,
          but your current <TierWithMultiplier tier={tier} /> is higher.
        </p>

        <SaleRegisterDialog
          tokenName={token.name}
          isLoading={isLoading}
          onConfirm={register}
          buttonLabel="Up your level"
        />
      </Wrapper>
    );
  }

  // User registered as a lottery level
  if (registeredTier.random) {
    return (
      <Wrapper center={center}>
        {isLotteryWinner ? (
          <>
            You've won a place in the lottery as{' '}
            <TierWithMultiplier tier={registeredTier} /> level!
          </>
        ) : (
          <>
            You've got a place in the lottery as{' '}
            <TierWithMultiplier tier={registeredTier} /> level. <br />
            <br />
            Winners will be picked before the IDO start.
          </>
        )}
      </Wrapper>
    );
  }

  if (isNFTSale(sale)) {
    return (
      <Wrapper center={center}>
        You&apos;ve got an allocation as{' '}
        <TierWithMultiplier tier={registeredTier} /> level. It's not guaranteed.
        This sale is FCFS.
      </Wrapper>
    );
  }

  // User registered as guaranteed level
  return (
    <Wrapper center={center}>
      You&apos;ve got a guaranteed allocation as{' '}
      <TierWithMultiplier tier={registeredTier} /> level.
    </Wrapper>
  );
}
