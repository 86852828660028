// TODO: export as Description.Img / Description.Text
import { ImageProps } from 'next/image';

export function DescriptionImg({ src }: { src: ImageProps['src'] }) {
  return (
    <img
      src={typeof src === 'string' ? src : 'src' in src ? src?.src : null}
      loading="lazy"
      alt="IDO Image"
    />
  );
}
