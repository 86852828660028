import img from 'pools/images/strongnode.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const strongnode: PoolConfig = {
  // id is used in URL
  id: 'strongnode',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x4F073965cB14090208bf51864B5Cf4904e23727C',
    tokensForSale: toWeiBN('53333333'),
    startDate: '2021-10-21T12:00:00.000Z',
    rate: 666.666,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1119,
    tokensSold: toWeiBN('53333332.999999999999957197'),
    raised: toWeiBN('80000.079500079500079436'),
    baseAllocation: toWeiBN('10875.89185945002182453'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-10-19T12:00:00.000Z',
    registerDuration: 46,
    fcfsDuration: 1,
    totalWeights: 4582,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x32934cb16da43fd661116468c1b225fc26cf9a8c',
    name: 'Strong Node',
    symbol: 'SNE',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(270_000_000),
    totalSupply: toWeiBN(10_000_000_000),
    listingTime: '2021-11-22T15:00:00.000Z',
    coingeckoId: 'strongnode',
  },
  distribution: {
    type: 'claim_us',
    vesting: '20% at TGE, rest vested over 4 months',
    schedule: {
      '2021-10-22T15:00:00.000Z': 20,
      '2021-11-22T15:00:00.000Z': 20,
      '2021-12-22T15:00:00.000Z': 20,
      '2022-01-22T15:00:00.000Z': 20,
      '2022-02-22T15:00:00.000Z': 20,
    },
    network: 'POLY',
    claimerVersion: 'v1',
    claimerAddress: '0x3be7BF7fcF7709D5a26A20b7c1003912C5c848B2',
  },
  finishedInfo: (
    <QuickswapChartFinishedInfo
      symbol="SNE"
      address="0x32934cb16da43fd661116468c1b225fc26cf9a8c"
      pairAddress="0x23baf6d86c80eb18b1799763ea47eae6fe727767"
    />
  ),
  social: {
    website: 'https://strongnode.io/',
    twitter: 'https://twitter.com/strongnodeedge',
    telegram: 'https://t.me/strongnodechat',
    telegramAnno: 'https://t.me/strongnode',
    medium: 'https://medium.com/@strongnode',
    github: '',
    whitepaper:
      'https://drive.google.com/file/d/1OyCWECngeez2GaosvMeA1d6niXBGPH8I/view',
    announcement: '',
  },
  description: (
    <p>
      StrongNode.io is an Infrastructure-as-a-Service technology company and
      innovation lab pioneering a new paradigm in digital connectivity.
    </p>
  ),
};
