import { chainToExplorerLabel } from '@trustpad/launchpad';

import { tpadLogoImg } from 'assets';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import {
  FaBullhorn,
  FaDiscord,
  FaMedium,
  FaTelegramPlane,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { TOKEN_ADDRESS } from '~config/addresses';
import {
  DISCORD_URL,
  MEDIUM_URL,
  TELEGRAM_ANNO_URL,
  TELEGRAM_URL,
  TWITTER_URL,
  APPLY_FORM,
} from '~config/constants';
import { CoingeckoLink, CoinMarketCapLink } from '~features/launched';
import { pancakeImg } from '~features/launched/BuyButtons';
import { getAddressUrl, getTradeUrl } from '~utils';

import { certikImg, cryptoRankImg, quillAuditsImg, zokyoImg } from './images';

const Title = ({ children }) => (
  <p className="mb-4 text-lg text-secondary">{children}</p>
);

const AL = ({ href, children }) => (
  <li className="mb-4 last:mb-0">
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer nofollow"
      className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">
      {children}
    </a>
  </li>
);

export function Footer() {
  return (
    <footer className="bg-dark2 relative border-t border-gray-500 border-opacity-25">
      <TopFooter />

      <BottomFooter />
    </footer>
  );
}

function TopFooter() {
  return (
    <div className="flex flex-col px-4 pt-10 mx-auto container lg:px-5 pb-14 gap-20 md:gap-12">
      <div className="flex flex-col md:flex-row justify-between gap-12 text-sm font-normal">
        <div className="flex flex-col xl:flex-row gap-6">
          <BrandLink />
          <p className="text-secondary text-sm flex-1">
            TrustPad is a decentralized multi-chain fundraising platform
            enabling projects to raise capital and promise safety to early stage
            participants. Stake TrustPad tokens to get priority-access to
            promising projects.
          </p>
        </div>

        <div>
          <SocialLinks />
        </div>
      </div>

      <div className="flex flex-wrap md:justify-between w-full gap-12">
        <GeneralLinks />
        <HelpLinks />
        <CommunityLinks />
        <TokenLinks />
        <AuditsLinks />
      </div>
    </div>
  );
}

function BrandLink() {
  return (
    <Link
      href="/"
      className="text-white mr-auto no-underline pr-4 flex items-center no-hover">
      <div>
        <img src={tpadLogoImg.src} alt="Logo" className="mr-2 -mb-1 h-14" />
      </div>
      <div>
        <div className="text-xl font-secondary">TrustPad</div>
        <div className="text-xs tracking-wide">Making DeFi a Safer Place.</div>
      </div>
    </Link>
  );
}

function SocialLinks() {
  return (
    <ul className="flex items-center space-x-8">
      <li>
        <a
          href={TELEGRAM_ANNO_URL}
          target="_blank"
          className="text-main"
          rel="noreferrer">
          <FaBullhorn size={'2em'} />
        </a>
      </li>
      <li>
        <a
          href={TELEGRAM_URL}
          target="_blank"
          className="text-main"
          rel="noreferrer">
          <FaTelegramPlane size={'2em'} />
        </a>
      </li>
      <li>
        <a
          href={MEDIUM_URL}
          target="_blank"
          className="text-main"
          rel="noreferrer">
          <FaMedium size={'2em'} />
        </a>
      </li>
      <li>
        <a
          href={TWITTER_URL}
          target="_blank"
          className="text-main"
          rel="noreferrer">
          <FaXTwitter size={'2em'} />
        </a>
      </li>
      {/*<li>*/}
      {/*  <a*/}
      {/*    href={DISCORD_URL}*/}
      {/*    target="_blank"*/}
      {/*    className="text-main"*/}
      {/*    rel="noreferrer">*/}
      {/*    <FaDiscord size={'2em'} />*/}
      {/*  </a>*/}
      {/*</li>*/}
      <li>
        <a
          href={getAddressUrl(TOKEN_ADDRESS, 'BSC')}
          target="_blank"
          className="text-main"
          rel="noreferrer"
          title="TrustPad on BscScan">
          <chainToExplorerLabel.BSC className="h-6 w-6" />
        </a>
      </li>
    </ul>
  );
}

function GeneralLinks() {
  return (
    <div className="text-sm">
      <Title>General</Title>

      <ul>
        <AL href="https://trustpad.io/levels">Levels Staking</AL>
        <AL href="https://trustpad.io/staking">Farm</AL>

        <AL href={APPLY_FORM}>
          Apply for IDO
        </AL>
        <AL href="/privacy">Privacy Policy</AL>
        <AL href="/tos">Terms of use</AL>
        {false && <AL href="#">Branding</AL>}
      </ul>
    </div>
  );
}

function CommunityLinks() {
  return (
    <div className="text-sm">
      <Title>Communities</Title>

      <ul>
        <AL href="https://t.me/TrustPad">🇺🇸 English</AL>
        {/*<AL href="https://discord.gg/4AG5B8Y8gW">🇺🇸 English Discord</AL>*/}
        <AL href="https://t.me/TrustPadRu">🇷🇺 Russian</AL>
        <AL href="https://t.me/TrustPad_CN">🇨🇳 Chinese</AL>
        <AL href="https://t.me/TrustPadTurkiye">🇹🇷 Turkish</AL>
        <AL href="https://t.me/TrustPad_KOR">🇰🇷 Korean</AL>
      </ul>
    </div>
  );
}

function HelpLinks() {
  return (
    <div className="text-sm">
      <Title>Help</Title>

      <ul>
        <AL href="https://docs.trustpad.io/trustpad/how-to-participate-in-an-ido">
          How to participate in IDO
        </AL>
        <AL href="https://trustpad.io/levels#faq">FAQ</AL>
        <AL href="https://docs.trustpad.io/trustpad">Documentation</AL>
      </ul>
    </div>
  );
}

function TokenLinks() {
  return (
    <div className="text-sm">
      <Title>Token</Title>

      <ul>
        <li className="mb-4 last:mb-0">
          <CoingeckoLink
            id="trustpad"
            className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">
            CoinGecko
          </CoingeckoLink>
        </li>
        <li className="mb-4 last:mb-0">
          <CoinMarketCapLink
            id="trustpad-new"
            className="no-underline text-white hover:text-main active:text-mainDark focus:text-main">
            CoinMarketCap
          </CoinMarketCapLink>
        </li>
        <AL href="https://cryptorank.io/fundraising-platforms/trust-pad">
          <img src={cryptoRankImg.src} className="h-6 inline-block mr-2" />
          CryptoRank
        </AL>
        <AL href={getTradeUrl(TOKEN_ADDRESS, 'CAKE')}>
          <img src={pancakeImg.src} className="h-6 inline-block mr-2" />
          PancakeSwap
        </AL>
      </ul>
    </div>
  );
}

function AuditsLinks() {
  return (
    <div className="text-sm">
      <Title>Audits</Title>

      <div className="gap-6 flex flex-col">
        <a
          href="https://www.certik.org/projects/trustpad"
          target="_blank"
          rel="noreferrer">
          <img
            src={certikImg.src}
            alt="Certik Trustpad audit"
            className="h-7"
          />
        </a>
        <a
          href="/QuillAudits-TrustPad-Smart-Contract-Audit-Report.pdf"
          target="_blank">
          <img
            src={quillAuditsImg.src}
            alt="QuillAudits Trustpad audit"
            className="h-7"
          />
        </a>
        {false && (
          <a href="/zokyo-TrustPad-Audit.pdf" target="_blank">
            <Image src={zokyoImg} alt="Zokyo Trustpad audit" className="h-9" />
          </a>
        )}
      </div>
    </div>
  );
}

function BottomFooter() {
  return (
    <div className="container pb-3 text-secondary">
      <div className="text-xs mt-3">
        Participants/Citizens from the following countries are strictly
        excluded/not allowed to participate in the IDOs: United States (of
        America), Bolivia, Cambodia, Iran, Iraq, Libya, Nepal, Zimbabwe,
        Liberia, Myanmar, North Korea, British Virgin Islands, Canada, Puerto
        Rico, US Virgin Islands.
      </div>

      <div className="flex justify-between items-center mt-6 gap-3 flex-col-reverse md:flex-row">
        <div className="text-xs">
          © Copyright TrustPad {new Date().getFullYear()}. All rights reserved.
        </div>
      </div>
    </div>
  );
}
