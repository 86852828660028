import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/playzap.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { ChartDropdownButton } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { Button } from '~features/ui';
import { toWeiBN } from '~utils';

export const playzap: PoolConfig = {
  // id is used in URL
  id: 'playzap',
  // public, seed or private
  access: 'public',
  kyc: true,
  sale: {
    address: '0x46C725841629daB84608dAF2174ceEc61715aa00',
    tokensForSale: toWeiBN('2249921'),
    startDate: '2023-04-16T14:00:00.000Z',
    rate: 8.333,
    durationHours: 24,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2249920.999999999999999886'),
    raised: toWeiBN('270001.3200528021120845'),
    baseAllocation: toWeiBN('113.742955582295246587'),
    status: SaleStatus.finished,
    fcfsDuration: 0.6666666666666666,
    registerDate: '2023-04-14T14:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    totalWeights: 19637890,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BSC',
    vesting: '20% at listing, 1 month cliff, then 6 months linear vesting',
    claimerAddress: '0x3cd7eDa9f47940478805FCB4a5b22324e835436D',
    config: {
      initialUnlockDate: '2023-04-19T10:00:00.000Z',
      initialPercent: 20,
      vestingDuration: 6 * 30 * 24 * 60 * 60,
      vestingStartDate: '2023-05-19T10:00:00.000Z',
    },
  },
  finishedInfo: (
    <div className="flex gap-3 flex-wrap">
      <Button
        type="primaryOutline"
        href="https://www.kucoin.com/trade/PZP-USDT"
        target="_blank"
        size="sm">
        Buy PLAYZAP on Kucoin
      </Button>
      {/*<ChartDropdownButton*/}
      {/*  address="0x6ad9E9c098a45B2B41b519119C31c3DcB02ACcB2"*/}
      {/*  chain="BSC"*/}
      {/*/>*/}
      <Button
        type="primaryOutline"
        href="https://app.mojitoswap.finance/swap"
        target="_blank"
        size="sm">
        Buy PLAYZAP on MojitoSwap
      </Button>
      <Button
        type="primaryOutline"
        size="sm"
        href="https://app.multichain.org/#/router?srcchainid=56&toChainId=321&bridgetoken=PZP"
        target="_blank">
        BNB ↔ KCC bridge
      </Button>
    </div>
  ),
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x6ad9E9c098a45B2B41b519119C31c3DcB02ACcB2',
    name: 'PlayZap',
    symbol: 'PZP',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2023-04-19T10:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Will be listed at MojitoSwap and KuCoin',
    decimals: 18,
    listingRate: 8.333,
    initialSupply: toWeiBN(3_749_851),
    totalSupply: toWeiBN(150_000_000),
    coingeckoId: 'playzap',
    coinmarketcapId: 'playzap',
  },
  social: {
    website: 'https://playzap.games/',
    twitter: 'https://twitter.com/PlayZapGames',
    telegram: 'https://t.me/PlayZapOfficial',
    telegramAnno: 'https://t.me/playzap',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },

  description: (
    <p>
      The ZapVerse is a powerful ecosystem built and powered by proven solutions
      and infrastructure, providing a satisfying core experience to our users.
      Users will have a wide array of in-house and partnered games, played by
      thousands of people globally. Those low-skill-floor high-skill-ceiling
      games will provide value corresponsive to the player's skill level. With a
      truly free-to-play economy, the monetization aspect will come directly
      from the players interacting actively with each other, playing games,
      earning prizes and exchanging assets.
    </p>
  ),
};
