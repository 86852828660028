import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/soulsofmeta.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const soulsofmetaRerun: PoolConfig = {
  // id is used in URL
  id: 'soulsofmeta-rerun',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0x0cA7DD7D8A29549f324e222712aDA22Cc0dfD875',
    tokensForSale: toWeiBN('16041666.7'),
    startDate: '2022-08-16T20:00:00.000Z',
    rate: 166.667,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('16041666.699999999999999555'),
    raised: toWeiBN('96249.8077003845992308'),
    baseAllocation: toWeiBN('1317.689577942785009236'),
    status: 5,
    registerDate: '2022-08-14T11:00:00.000Z',
    registerDuration: 54,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.6666666666666666,
    totalWeights: 12264520,
    minAllowedLevelMultiplier: 0,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BSC',
    claimerAddress: '0x0416643A03e0136c76aD6E94432a11E39eb611A6',
    vesting:
      '8% release at TGE followed by 1-month lock and 11-month linear vesting',
    config: {
      vestingStartDate: '2022-09-18T15:00:00.000Z',
      vestingDuration: 11 * 30 * 24 * 3600,
      initialPercent: 8,
      initialUnlockDate: '2022-08-18T15:00:00.000Z',
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x31c573d1a50a745b01862edaf2ae72017cea036a',
    name: 'Souls of Meta',
    symbol: 'SOM',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-08-18T15:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(22_800_000),
    totalSupply: toWeiBN(3_000_000_000),
    coingeckoId: 'souls-of-meta',
  },
  social: {
    website: 'https://soulsofmeta.io/',
    twitter: 'https://twitter.com/SoulsOfMeta',
    telegram: 'https://t.me/SoulsOfMeta',
    telegramAnno: 'https://t.me/SoulsOfMetaOfficial',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Souls of Meta is a cross-game multi-chain Fun-2-Earn 3rd-person action RPG
      Fantasy NFT gaming metaverse of blades and sorcery where you can own,
      play, and monetize NFT assets through GameFi and SocialFi, and travel
      through community-created realms, fight monsters, collaborate with other
      players (PvE and PvP), solve quests and beyond, to have fun playing and
      earn at the same time!
    </p>
  ),
};
