// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Error.stackTraceLimit = 15;
Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn:
    SENTRY_DSN ||
    'https://03c376bf3e9a4be6b31a10f042db27d7@o854788.ingest.sentry.io/5819509',
  tracesSampleRate: 0.25,
  attachStacktrace: true,
  autoSessionTracking: true,
  normalizeDepth: 4,

  ignoreErrors: [
    /header not found/i,
    /metamask/i,
    /Failed to fetch/i,
    /missing trie node/i,
    /Failed to execute 'removeChild' on 'Node'/i,
    /Response has no error/i,
    /rpc error/i,
    /user rejected the request/i,
    /MaxListenersExceededWarning/i, // from wallet connect
    /ObjectMultiplex - orphaned data/,
    /NetworkError when attempting to fetch resource/i,
  ],
  integrations: [
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      // When changing this value, make sure to update `normalizeDepth` of the whole SDK
      // to `depth + 1` in order to get it serialized properly - https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
      depth: 3,
    }),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['warn', 'error'],
    }),
  ],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
