import { Tier } from '@trustpad/launchpad';
import React from 'react';

export function TierName({
  className = '',
  name,
}: {
  className?: string;
  name: string;
}) {
  return (
    <span className={`font-secondary tracking-wide uppercase ${className}`}>
      {name}
    </span>
  );
}

export const TierWithMultiplier = ({
  tier,
  className = '',
  multiplierClassName = '',
}: {
  tier: Tier;
  className?: string;
  multiplierClassName?: string;
}) => (
  <>
    <TierName name={tier.name} />{' '}
    {tier.aag ? <span className={className}>+</span> : ''}{' '}
    <span className={`text-sm ${multiplierClassName}`}>
      [{tier.multiplier}x]
    </span>
  </>
);
