import { tpadLogoImg } from 'assets';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import { Notifications } from '~common/Notifications/Notifications';
import { UserWallet } from '~common/Wallet/UserWallet';
import { useFeatureFlag } from '~common/hooks/useFeatureFlag';
import { DateTimeFormat } from '~config/constants';

export function Navbar({ children }: { children?: React.ReactNode }) {
  const notificationsEnabled = useFeatureFlag('notifications');

  return (
    <header>
      <nav className="navbar flex flex-col lg:flex-row container">
        <Link
          href="/"
          className="text-white no-underline pr-4 flex items-center no-hover mb-3 lg:mb-0">
          <div>
            <img src={tpadLogoImg.src} alt="Logo" className="mr-2 -mb-1 h-14" />
          </div>
          <div>
            <div className="text-xl font-secondary">TrustPad</div>
            <div className="text-xs tracking-wide">
              The #1 Multi-Chain Launchpad.
            </div>
          </div>
        </Link>

        <div className="flex flex-col lg:flex-row justify-between items-center lg:ml-auto">
          <div className="lg:mr-3 py-3 lg:py-0 flex items-center flex-wrap">
            {children}
          </div>

          <div className="relative py-3">
            <UserWallet connectSize="sm" />
            <div className="absolute right-0">
              <CurrentTime />
            </div>

            {notificationsEnabled && <Notifications />}
          </div>
        </div>
      </nav>
    </header>
  );
}

function CurrentTime() {
  const [now, setNow] = useState(null);

  useEffect(() => {
    setNow(dayjs());
    const id = setInterval(() => setNow(dayjs()), 30000);
    return () => clearInterval(id);
  }, []);

  if (!now) return null;

  return (
    <div
      className="opacity-70 text-gray-300 tracking-wide"
      style={{ fontSize: '0.75rem' }}>
      {now.utc().format(DateTimeFormat.noYear)}
    </div>
  );
}
