import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/ledgity.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const ledgity: PoolConfig = {
  // id is used in URL
  id: 'ledgity',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  kyc: true,
  sale: {
    address: '0x9a117EAc35d46f250A0D2d64638e86be60caC3Ae',
    status: SaleStatus.finished,
    rate: 250,
    tokensForSale: toWeiBN('25000000'),
    startDate: '2021-08-23T16:00:00.000Z',
    baseAllocation: toWeiBN('8423.62344582593250444'),
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    participants: 763,
    tokensSold: toWeiBN('25000000'),
    raised: toWeiBN('100000'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-08-17T12:00:00.000Z',
    registerDuration: 144,
    fcfsDuration: 1,
    levelsOpenAll: true,
  },
  token: {
    address: '0x85Ffb35957203dfD12061eAeCD708dB623Bd567C',
    name: 'Ledgity',
    symbol: 'LTY',
    imageSrc: img.src,
    decimals: 18,
    type: 'ERC-20',
    pairAddress: '',
    listingRate: 250,
    initialSupply: toWeiBN(76_000_000),
    totalSupply: toWeiBN(2_760_000_000),
    // BSC, ETH, DOT or SOL
    chain: 'ETH',
    listingTime: '2021-08-24T16:10:00.000Z',
    coingeckoId: 'ledgity',
    coinmarketcapId: 'ledgity',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '100% on TGE, sent in ETH network',
    schedule: {
      '2021-08-24T16:10:00.000Z': 100,
    },
  },
  social: {
    website: 'https://ledgity.com/',
    twitter: 'https://twitter.com/LedgityPlatform',
    telegram: 'https://t.me/ledgityapp',
    telegramAnno: 'https://t.me/ledgityannouncement',
    medium: 'https://medium.com/ledgity',
  },
  description: (
    <div>
      Ledgity has redesigned private banking through an intuitive, secure,
      transparent financial services platform, opening new investment horizons,
      making it easier for financial advisors and their clients to widen their
      investment scope and access digital assets.
    </div>
  ),
  finishedInfo: (
    <div>
      <UniswapChartFinishedInfo
        symbol="LTY"
        address="0x85Ffb35957203dfD12061eAeCD708dB623Bd567C"
        pairAddress="0xbe5b2c7d8e990b71767cf6d95573386e1d3d1995"
      />
    </div>
  ),
};
