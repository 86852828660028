import img from 'pools/images/samurailegends.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const samurailegends: PoolConfig = {
  // id is used in URL
  id: 'samurailegends',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0xCC29e07C2af62b3a4D29117b869F0049fA9CE49f',
    tokensForSale: toWeiBN('6333333'),
    startDate: '2022-03-25T11:00:00.000Z',
    rate: 16.666,
    durationHours: 25,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    // tokensSold: toWeiBN('6198719.022610105349549218'),
    tokensSold: toWeiBN('6333333'),
    // raised: toWeiBN('371938.0188773614154296'),
    raised: toWeiBN('380015'),
    baseAllocation: toWeiBN('6000'),
    status: 5,
    registerDate: '2022-03-23T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('95'),
    },
    fcfsDuration: 23.916666666666668,
    totalWeights: 6436,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: [
    {
      type: 'claim_linear',
      network: 'BSC',
      vesting: '20% at TGE, then 2 months linear vesting',
      claimerAddress: '0xB30ad1208Eb7e6A9Bc6f8DE75fCE791c2552EbA2',
      config: {
        initialPercent: 20,
        initialUnlockDate: '2022-03-30T18:15:00.000Z',
        vestingStartDate: '2022-03-31T18:15:00.000Z',
        vestingDuration: 60 * 24 * 3600,
      },
    },
  ],
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x3Ca994D9f723736381d44388bC8dD1e7eE8C1653',
    name: 'Samurai Legends',
    symbol: 'SMG',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-03-30T18:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(8_266_667),
    totalSupply: toWeiBN(600_000_000),
    coingeckoId: 'samurai-legends',
    coinmarketcapId: 'samurai-legends',
  },
  social: {
    website: 'https://samurailegends.io/',
    twitter: 'https://twitter.com/samuraiverse',
    telegram: 'https://t.me/samurailegends',
    telegramAnno: 'https://t.me/samurailegends_ann',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Samurai Legends is a GameFi metaverse set in feudal Japan. Rule digital
      patches of land, build, battle and earn riches in your quest to become
      shogun of the metaverse! A combination of NFTs, play-to-earn mechanics and
      samurai aesthetics make Samurai Legends one of the most standout gaming
      experiences on Binance Smart Chain.
    </p>
  ),
};
