import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/drunkrobots.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const drunkrobots: PoolConfig = {
  // id is used in URL
  id: 'drunkrobots',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x3196dD5383D0FEa4fB7E5d1F03e722D92729714E',
    tokensForSale: toWeiBN('5000000'),
    startDate: '2022-04-07T11:00:00.000Z',
    rate: 100,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('5000000'),
    raised: toWeiBN('50000'),
    baseAllocation: toWeiBN('729.633637347348894728'),
    status: 5,
    registerDate: '2022-04-05T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('25'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6729,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '10% at TGE, then 15% per month',
    claimerAddress: '0x0a32ADEb0C2135Aae7B3C80FA30E19205508F43e',
    claimerVersion: 'v2',
    schedule: {
      '2022-04-08T13:30:00.000Z': 10,
      '2022-05-08T13:30:00.000Z': 15,
      '2022-06-08T13:30:00.000Z': 15,
      '2022-07-08T13:30:00.000Z': 15,
      '2022-08-08T13:30:00.000Z': 15,
      '2022-09-08T13:30:00.000Z': 15,
      '2022-10-08T13:30:00.000Z': 15,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x200c234721b5e549c3693ccc93cf191f90dc2af9',
    name: 'Drunk Robots',
    symbol: 'METAL',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-04-08T13:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo:
      'Will be listed on PancakeSwap (13:00 UTC) and Gate (13:15 UTC)',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(19_550_000),
    totalSupply: toWeiBN(2750000000),
    coingeckoId: 'drunk-robots',
  },
  social: {
    website: 'https://www.drunk-robots.com/',
    twitter: 'https://twitter.com/drunk_robots',
    telegram: 'https://t.me/drunkrobots_chat',
    telegramAnno: 'https://t.me/drunkrobots',
    medium: 'https://drunk-robots.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Drunk Robots is a play-to-earn game on the BNB Chain. Your mission is to
      survive and seize the city overrun by the drunk, junky robots whose only
      interests are metal beer and violence.{' '}
    </p>
  ),
};
