import img from 'pools/images/sportsicon.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const sportsicon: PoolConfig = {
  // id is used in URL
  id: 'sportsicon',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x10A19333B3ADFe52D30f76Be887f938286F076D8',
    tokensForSale: toWeiBN('240000'),
    startDate: '2021-11-24T12:00:00.000Z',
    rate: 1.33333,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('239999.999999999999999968'),
    raised: toWeiBN('180000.45000112500281314'),
    baseAllocation: toWeiBN('31.849142868442292171'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('71'),
    },
    registerDate: '2021-11-22T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6251,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    extraChains: ['ETH'],
    address: '0xe896aa5e01ef58789f2f17f740af5df33d45f311',
    name: 'SportsIcon',
    symbol: 'ICONS',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    listingTime: '2021-11-25T12:15:00.000Z',
    initialSupply: toWeiBN(1_270_000),
    totalSupply: toWeiBN(30_000_000),
    coingeckoId: 'sportsicon',
    coinmarketcapId: 'sportsicon',
  },
  finishedInfo: (
    <>
      <PancakeChartFinishedInfo
        symbol="ICONS"
        address="0xe896aa5e01ef58789f2f17f740af5df33d45f311"
        pairAddress="0xcb06e4089f04a4c120ce4dbc4f06632020e33fa6"
      />
      <p>
        Also tradeable on Gate.io:{' '}
        <a
          href="https://www.gate.io/trade/ICONS_USDT"
          target="_blank"
          rel="noreferrer">
          https://www.gate.io/trade/ICONS_USDT
        </a>
      </p>
    </>
  ),
  distribution: {
    type: 'claim_us',
    vesting: '4 months, 25% at TGE (airdropped), 25% every month',
    claimerAddress: '0x985eef7808f0d918df88c714269f588736e9d4db',
    claimerVersion: 'v2',
    schedule: {
      '2021-11-25T12:15:00.000Z': 25,
      '2021-12-25T12:00:00.000Z': 25,
      '2022-01-25T12:00:00.000Z': 25,
      // have to buy 76666. 4x (30664) + 3x purchased, 3x left
      '2022-02-25T12:00:00.000Z': 25,
    },
  },
  social: {
    website: 'https://sportsicon.com/',
    twitter: 'https://twitter.com/sportsicon',
    telegram: 'https://t.me/sportsiconchat',
    telegramAnno: 'https://t.me/SportsIconOfficial',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      SportsIcon is creating the world’s leading platform for sports
      entertainment, knowledge transfer and digital collectibles. Through
      lovingly filmed insights with athletes who also curate their own bespoke
      digital art collection, SportsIcon is building the ultimate platform for
      fans to get closer to their sporting heroes.
    </p>
  ),
};
