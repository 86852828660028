import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/pix.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const privi: PoolConfig = {
  // id is used in URL
  id: 'privi-pix',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xF5003237001dF86B3BDf1F069b8324C2991AB0D5',
    status: SaleStatus.finished,
    rate: 20,
    tokensForSale: toWeiBN('2000000'),
    startDate: '2021-08-26T12:00:00.000Z',
    durationHours: 3.5,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: 'TBA',
    participants: 1097,
    tokensSold: toWeiBN('2000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('505.058572949946751863'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-08-22T12:00:00.000Z',
    registerDuration: 95,
    fcfsDuration: 0.5,
    levelsOpenAll: true,
  },
  token: {
    address: '0xc875d22eaa3bc358887bd1483e98c8796b215942',
    name: 'Privi PIX',
    symbol: 'PIX',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 20,
    initialSupply: toWeiBN(1_550_000),
    totalSupply: toWeiBN(100_000_000),
    // BSC, ETH, DOT or SOL
    chain: 'BSC',
    listingTime: '2021-08-26T16:00:00.000Z',
    coingeckoId: 'privi-pix',
  },
  social: {
    website: 'https://www.privi.store/#/zoo',
    twitter: 'https://twitter.com/priviprotocol',
    telegram: 'https://t.me/protocolprivi',
    telegramAnno: 'https://t.me/thepriviprotocol',
    medium: 'https://medium.com/privi',
    github: '',
    whitepaper: '',
  },
  description: (
    <div>
      Privi Pix is a decentralized web and mobile NFT app available within the
      Privi Zoo, and is governed by the $PIX Token. Think of Privi Pix as a
      haven for NFTs, Instagram meets OpenSea.
    </div>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="PIX"
        address="0xc875d22eaa3bc358887bd1483e98c8796b215942"
      />
    </div>
  ),
  distribution: {
    type: 'claim_them',
    vesting:
      '15% TGE unlock distributed by Trustpad, linear daily vesting over 3 months after Aug 27',
    claimUrl: 'https://www.privi.store/#/zoo/claim',
  },
};
