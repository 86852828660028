import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/mechachain.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const mechachain: PoolConfig = {
  // id is used in URL
  id: 'mechachain',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    refunded: true,
    address: '0xd725011baf965f4177A8A3Ab4e97976060c25BB4',
    tokensForSale: toWeiBN('428571'),
    startDate: '2022-03-14T11:00:00.000Z',
    rate: 1.4285,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('428570.999999999999999966'),
    raised: toWeiBN('300014.700735036751838'),
    baseAllocation: toWeiBN('65.621038125861276986'),
    status: 5,
    registerDate: '2022-03-12T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6561,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xacd4e2d936be9b16c01848a3742a34b3d5a5bdfa',
    name: 'MechaChain',
    symbol: 'MECHA',
    imageSrc: img.src,
    listingTime: '2022-03-21T16:45:00.000Z',
    listingInfo: 'Lists in Uniswap',
    decimals: 10,
    listingRate: 0,
    initialSupply: toWeiBN(3_945_715),
    totalSupply: toWeiBN(100_000_000),
    coingeckoId: 'mechachain',
    coinmarketcapId: 'mechachain',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="MECHA"
      address="0xacd4e2d936be9b16c01848a3742a34b3d5a5bdfa"
      pairAddress="0xacd4e2d936be9b16c01848a3742a34b3d5a5bdfa"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '20% on TGE, then 20% per month',
    network: 'POLY',
    claimerAddress: '0x532100f6cbbdec2D75E5B6DE2b981Cf7d8a8328B',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-21T16:45:00.000Z': 20,
    },
  },
  social: {
    website: 'https://mechachain.io/',
    twitter: 'https://twitter.com/mechachain',
    telegram: 'https://t.me/Mechachainofficial',
    telegramAnno: 'https://t.me/mechachain',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      MechaChain is a 3D play-to-earn video game about robot combat and space
      conquest. Each robot, called “Mecha”, is a collection of NFT composed of
      robot parts, which can be purchased online with the game cryptocurrency
      called Mechanium, Ethereum, or by card. These parts once assembled give
      birth to a robot in a PvP fighting video game.
    </p>
  ),
};
