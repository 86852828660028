import { useGasPrice, useUser, useWeb3Provider } from '@trustpad/launchpad';
import {
  Contract,
  GenericContractSendMethod,
} from '@trustpad/launchpad/dist/types';
import BN from 'bn.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AbiItem } from 'web3-utils';
import { debug } from '~utils';
import BurnClaimerABI from './BurnClaimerABI.json';

type Config = {
  unitOfTime: BN;
  initialPercent: BN;
  initialUnlockDate: BN;
  vestingStartDate: BN;
  vestingDuration: BN;
  feeAddress: string;
  initialFeePercent: BN;
};
type TotalStats = {
  tokens: BN;
  claimedTokens: BN;
  claimableTokens: BN;
  burnedTokens: BN;
  collectedFeeTokens: BN;
  pausedAt: BN;
  currentFeePercent: BN;
};

type UserStats = {
  tokens: BN;
  claimedTokens: BN;
  claimableTokens: BN;
  burnedTokens: BN;
  collectedFeeTokens: BN;
};
type StakingPoolMigration = {
  pool: string;
  amount: BN;
  lockStart: BN;
  pendingReward: BN;
  migrated: boolean;
};
type StakingStats = {
  tokens: BN;
  claimedTokens: BN;
  claimableTokens: BN;
  burnedTokens: BN;
  migratedUsers: BN;
  burned: boolean;
};

function normMethodReturnStruct<T>(struct: T): T {
  const t = Object.entries(struct).reduce((acc, [key, value]) => {
    if (/^\d+$/.test(key)) return acc;
    const v =
      typeof value === 'string' && /^\d+$/.test(value) ? new BN(value) : value;
    if (BN.isBN(v)) {
      Object.assign(v, { _str: v.toString() });
    }
    return {
      ...acc,
      [key]: v,
    };
  }, {} as T);
  debug(t);

  return t;
}

export function useBurnClaimer(address: string) {
  const { account } = useUser();
  const instance = useBurnClaimerContract(address);

  const [config, setConfig] = useState<Config>();
  const [totalStats, setTotalStats] = useState<TotalStats>();
  const [userStats, setUserStats] = useState<UserStats>();
  const [stakingStats, setStakingStats] = useState<StakingStats>();
  const [stakingPools, setStakingPools] = useState<StakingPoolMigration[]>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const refresh = useCallback(() => {
    if (!instance) return;
    Promise.all([
      instance.methods
        .config()
        .call()
        .then(normMethodReturnStruct)
        .then((config) => {
          setConfig(config);
        }),
      instance.methods
        .getTotalStats()
        .call()
        .then(normMethodReturnStruct)
        .then(setTotalStats),
      instance.methods
        .getStakingStats()
        .call()
        .then(normMethodReturnStruct)
        .then(setStakingStats),
    ]).then(() => {
      setIsLoaded(true);
    });
  }, [instance]);

  const refreshUser = useCallback(() => {
    if (!instance || !account) return;
    instance.methods
      .getAccountStats(account)
      .call()
      .then(normMethodReturnStruct)
      .then(setUserStats);
    instance.methods
      .getStakingPoolsToMigrate(account)
      .call()
      .then((v) => v.map(normMethodReturnStruct))
      .then(setStakingPools);
  }, [instance, account]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (
      !config?.initialUnlockDate ||
      config.initialUnlockDate.toNumber() * 1000 < Date.now()
    )
      return;
    const timer = setTimeout(() => {
      refresh();
      refreshUser();
    }, config.initialUnlockDate.toNumber() * 1000 - Date.now());
    return () => clearTimeout(timer);
  }, [config?.initialUnlockDate]);

  function claimAll() {
    if (!account) return;

    setIsClaiming(true);
    return instance.methods
      .claimAll(account)
      .send({ from: account })
      .then(() => {
        refreshUser();
        refresh();
        return { message: 'Claimed all' };
      })
      .catch(() => {
        return {
          error: 'Failed to claim',
        };
      })
      .finally(() => setIsClaiming(false));
  }

  function claimStakingPools() {
    if (!account) return;

    setIsClaiming(true);
    return instance.methods
      .claimStakingPools(account)
      .send({ from: account })
      .then(() => {
        refreshUser();
        refresh();
        return { message: 'Migrated all staking pools' };
      })
      .catch(() => {
        return {
          error: 'Failed to migrate staking pools',
        };
      })
      .finally(() => setIsClaiming(false));
  }

  const daysPassed = Math.floor(
    (Date.now() / 1000 - config?.vestingStartDate.toNumber()) / 86400,
  );
  const nextFeeDecreaseAt = totalStats?.currentFeePercent.gtn(0)
    ? config?.vestingStartDate.toNumber() + (daysPassed + 1) * 86400
    : undefined;

  return {
    account,
    config,
    totalStats,
    stakingStats,
    userStats,
    stakingPools,
    nextFeeDecreaseAt,
    isLoaded,
    isClaiming,
    claimAll,
    claimStakingPools,
  };
}

export function useBurnClaimerContract(address: string): BurnClaimerContract {
  const { web3 } = useWeb3Provider();
  const { gasPrice } = useGasPrice();

  return useMemo(() => {
    if (!web3 || !address) return undefined;

    return new web3.eth.Contract(BurnClaimerABI as AbiItem[], address, {
      gasPrice,
    });
  }, [web3, address, gasPrice]);
}

export interface BurnClaimerContract extends Contract {
  methods: {
    token(): GenericContractSendMethod<string>;
    config(): GenericContractSendMethod<Config>;
    getTotalStats(): GenericContractSendMethod<TotalStats>;
    getStakingStats(): GenericContractSendMethod<StakingStats>;
    getAccountStats(account: string): GenericContractSendMethod<UserStats>;
    hasUnclaimed(account: string): GenericContractSendMethod<boolean>;

    getAccounts(_unused: number): GenericContractSendMethod<string[]>;

    claim(account: string, idx: number): GenericContractSendMethod;
    claimAll(account: string): GenericContractSendMethod;
    batchAddAllocation(
      addresses: string[],
      allocations: (BN | string)[],
    ): GenericContractSendMethod;
    setAllocation(
      account: string,
      newTotal: BN | string,
    ): GenericContractSendMethod;
    withdrawToken(
      tokenAddress: string,
      amount: BN | string,
    ): GenericContractSendMethod;

    claimStakingPools(account: string): GenericContractSendMethod;
    getStakingPoolsToMigrate(
      account: string,
    ): GenericContractSendMethod<StakingPoolMigration[]>;
  };
}
