import img from 'pools/images/animalconcerts.png';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { BuyApeSwapButton, ChartDropdownButton } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const animalconcerts: PoolConfig = {
  // id is used in URL
  id: 'animalconcerts',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0xD2Ba4CB7A3dF37A57902093d08D8Cde524658775',
    tokensForSale: toWeiBN('44444400'),
    startDate: '2022-03-27T10:00:00.000Z',
    rate: 222.222,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('44444399.999999999999999808'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('6969.873321234119782214'),
    status: 5,
    registerDate: '2022-03-25T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6048,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'POLY',
    vesting: '25% on TGE, then 25% every 2 months over 6 months',
    claimerVersion: 'v2',
    claimerAddress: '0xB04fa37B2D5f52c7A13a4A1b02cDE8bD07809B63',
    schedule: {
      '2022-03-29T17:00:00.000Z': 25,
      '2022-05-29T17:00:00.000Z': 25,
      '2022-07-29T17:00:00.000Z': 25,
      '2022-09-29T17:00:00.000Z': 25,
    },
  },
  finishedInfo: (
    <div className="flex flex-wrap gap-3">
      <BuyApeSwapButton
        address="0x06FDA0758c17416726f77Cb11305EAC94C074Ec0"
        symbol="ANML"
      />{' '}
      <Button href="https://www.gate.io/trade/ANML_USDT" variant="main">
        ANML on Gate.io
      </Button>
      <Button
        href="https://www.huobi.com/en-us/exchange/anml_usdt"
        variant="main">
        ANML on Huobi
      </Button>
      <ChartDropdownButton
        address="0xecc4176b90613ed78185f01bd1e42c5640c4f09d"
        pairAddress="0x06FDA0758c17416726f77Cb11305EAC94C074Ec0"
      />
      <br />
    </div>
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    extraChains: ['ETH'],
    address: '0xecc4176b90613ed78185f01bd1e42c5640c4f09d',
    addresses: {
      ETH: '0x38B0e3A59183814957D83dF2a97492AED1F003e2',
      BSC: '0x06FDA0758c17416726f77Cb11305EAC94C074Ec0',
    },
    name: 'Animal Concerts',
    symbol: 'ANML',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-29T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'On ApeSwap, Gate.io, BitMart and Huobi',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(399_600_000),
    totalSupply: toWeiBN(12_500_000_000),
    coingeckoId: 'animal-concerts-token',
    coinmarketcapId: 'animal-concerts',
  },
  social: {
    website: 'https://animalconcerts.com/',
    twitter: 'https://twitter.com/animalconcerts',
    telegram: 'https://t.me/animalconcertsofficial',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Animal Concerts is building a platform for remarkable metaverse concerts,
      connecting western and K-Pop artists with fans via interactive and
      immersive environments (such as Decentraland), whilst using NFTs to
      personalize, enhance and monetize such experiences.
    </p>
  ),
};
