import React from 'react';
import { Badge } from 'react-bootstrap';

import { CustomBadge } from '~common/CustomBadge';
import { PoolConfig, SaleStatus } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const StatusBadge = ({
  pool,
  isListed,
}: {
  pool: PoolConfig;
  isListed: boolean;
}) => {
  const isFilled = pool.sale.tokensForSale
    .sub(pool.sale.tokensSold)
    .lt(pool.sale.tokensForSale.div(toWeiBN(100))); // <= 1% left

  if (isListed) {
    return (
      <CustomBadge className="bg-opacity-40 bg-green-700 text-green-500">
        Listed
      </CustomBadge>
    );
  }

  const status = pool.sale.status as SaleStatus;
  if (
    [SaleStatus.static, SaleStatus.init, SaleStatus.preRegister].includes(
      status,
    )
  ) {
    return (
      <CustomBadge className="bg-gray-400 bg-opacity-20 text-gray-300">
        Upcoming
      </CustomBadge>
    );
  } else if (status === SaleStatus.register) {
    return (
      <CustomBadge className="bg-opacity-40 bg-green-700 text-green-500">
        Register
      </CustomBadge>
    );
  } else if (status === SaleStatus.open) {
    return (
      <CustomBadge className="bg-opacity-40 bg-green-700 text-green-500">
        Live
      </CustomBadge>
    );
  } else if (status === SaleStatus.finished) {
    return (
      <CustomBadge className="bg-gray-400 bg-opacity-20 text-gray-300">
        {isFilled ? 'Filled' : 'Ended'}
      </CustomBadge>
    );
  }

  return null;
};
