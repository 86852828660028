import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/flourishing.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const flourishing: PoolConfig = {
  id: 'flourishing',
  access: 'public',
  fundToken: BUSD,
  linkToDetailPage: false,
  kyc: true,
  sale: {
    address: '0x69dDa4CA4e95E39936ece8a5Db193a9388095b07',
    rate: 8.333333,
    tokensForSale: toWeiBN('625000'),
    startDate: '2021-09-21T12:00:00.000Z',
    durationHours: 3,
    hasWhitelist: true,
    isTiered: true,
    participants: 1090,
    tokensSold: toWeiBN('625000'),
    raised: toWeiBN('75000.00300000012'),
    baseAllocation: toWeiBN('107.514468978574067233'),
    status: SaleStatus.finished,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-09-16T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 5414,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    address: '0xa9b038285f43cd6fe9e16b4c80b4b9bccd3c161b',
    name: 'Flourishing Capital',
    symbol: 'AI',
    imageSrc: img.src,
    decimals: 18,
    pairAddress: '',
    listingRate: 0,
    listingTime: '2021-09-17T17:00:00.000Z',
    totalSupply: toWeiBN(55_000_000),
    chain: 'BSC',
    coingeckoId: 'flourishing-ai-token',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="AI"
      address="0xa9b038285f43cd6fe9e16b4c80b4b9bccd3c161b"
      pairAddress="0xb993adc9d0d2206f2dc210b8c37306f54499ee6f"
    />
  ),
  social: {
    twitter: 'https://twitter.com/flourishingai',
    telegram: 'https://t.me/flourishingcapital',
    website: 'https://flourishingcapital.io/',
    medium: 'https://medium.com/flourishing-capital',
    announcement:
      'https://trustpad.medium.com/flourishing-ai-is-launching-its-ido-on-trustpad-fabf6abf2aa0',
  },
  distribution: {
    type: 'claim_them',
    claimUrl: 'https://vesting.flourishingcapital.io',
    vesting: '33% TGE and 3% daily',
  },
  description: (
    <div>
      Flourishing Capital AI makes crypto investing simple and smart using deep
      analysis of real time market data. Trade like an experienced investor.
      Already integrated with Binance.
    </div>
  ),
};
