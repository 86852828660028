import img from 'pools/images/niftypays.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const niftypays: PoolConfig = {
  // id is used in URL
  id: 'niftypays',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xa1A6BbfA456bf6aD06e5D2EA88EDF810ACF10469',
    tokensForSale: toWeiBN('1500000'),
    startDate: '2021-11-18T12:00:00.000Z',
    rate: 10,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1499999.99999999999999932'),
    raised: toWeiBN('149999.999999999999999932'),
    baseAllocation: toWeiBN('223.576902132808954772'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('21.9331'),
    },
    registerDate: '2021-11-16T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.5,
    totalWeights: 6625,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="NIFTY"
      address="0x448e2671b8b074fd8e71d7aa62d6cc3fad5dd793"
      pairAddress="0xf964cf2075c96c3271504ac1bade3be6a64dee09"
    />
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x448e2671b8b074fd8e71d7aa62d6cc3fad5dd793',
    name: 'NiftyPays',
    symbol: 'NIFTY',
    imageSrc: img.src,
    listingTime: '2022-01-31T12:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(1_000_000),
    totalSupply: toWeiBN(180_000_000),
    coingeckoId: 'niftypays',
  },
  distribution: {
    type: 'claim_us',
    vesting: '3 months',
    claimerAddress: '0xb79Cf05FA1B2fE5F451e909Bba0266Db7CEEefdB',
    claimerVersion: 'v2',
    schedule: {
      '2022-01-31T12:00:00.000Z': 33,
      '2022-02-28T12:00:00.000Z': 33,
      '2022-03-31T12:00:00.000Z': 34,
    },
  },
  social: {
    website: 'https://www.niftypays.com/',
    twitter: 'https://twitter.com/nifty_pays',
    telegram: 'https://t.me/NiftyPays_com',
    telegramAnno: 'https://t.me/niftypays',
    medium: 'https://medium.com/@social.nifty',
    github: '',
    whitepaper: 'https://niftyknight.gitbook.io/niftypays/whitepaper',
    announcement: '',
  },
  description: (
    <p>
      NiftyPays is an innovative NFT ecosystem, enabling participants to benefit
      from their NFTs by staking them as collateral for loans.The ecosystem
      revolves around $NIFTY - a utility token which introduces an incentive
      mechanism to allow NFT holders to transact in a peer-to-peer manner for
      various useful functions, such as fractionalisation of NFTs, or lending
      and borrowing of NFTs.{' '}
    </p>
  ),
};
