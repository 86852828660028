import img from 'pools/images/amasa.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const amasa: PoolConfig = {
  // id is used in URL
  id: 'amasa',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xcfC375EDB4960F5A9672A0497535526Dff7c3862',
    tokensForSale: toWeiBN('1562000'),
    startDate: '2021-09-30T12:00:00.000Z',
    rate: 12.5,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1549,
    tokensSold: toWeiBN('1561999.99999999999999905'),
    raised: toWeiBN('124959.999999999999999924'),
    baseAllocation: toWeiBN('255.916780587833219412'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-09-25T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 5852,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'ETH',
    extraChains: ['POLY'],
    address: '0x65a8fbA02F641a13Bb7B01d5E1129b0521004f52',
    name: 'AMASA',
    symbol: 'AMAS',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(11_575_000),
    totalSupply: toWeiBN(200_000_000),
    listingTime: '2021-10-05T12:00:00.000Z',
    coingeckoId: 'amasa',
    coinmarketcapId: 'amasa',
  },
  distribution: {
    type: 'airdrop_them',
    vesting: '100% unlock on listing',
  },
  social: {
    website: 'https://www.amasa.io/',
    twitter: 'https://twitter.com/amasa_io',
    telegram: 'https://t.me/amasa_community',
    telegramAnno: 'https://t.me/amasa_updates',
    medium: 'https://amasa.medium.com/',
    github: '',
    whitepaper: '',
    announcement:
      'https://trustpad.medium.com/amasa-amas-are-launching-on-trustpad-d9500d6aac61',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="AMAS"
      address="0x65a8fbA02F641a13Bb7B01d5E1129b0521004f52"
      pairAddress="0x4c877141147fdf2d3b963a6c897a4af7c113e25f"
    />
  ),
  description: (
    <p>
      AMASA is a micro stream investment app connecting millions of users to the
      Semantic Web and DeFi to improve their financial situations. The team have
      packaged this into a product into three simple steps — Combine. Stabilise.
      Amplify. — to enable a seamless user experience.
    </p>
  ),
};
