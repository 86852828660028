import { Chain } from '~types';
import { finished } from './finished';
import { metaclash } from './metaclash';
import { xraise } from './xraise';
import { noi } from './noi';

export const allPools = [
  metaclash,
  xraise,
  noi,
  ...finished,
];

export const teaserPools: Chain[] = [];
