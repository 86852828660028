import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/finished/2crazy/images/2crazy.jpg';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const twoCrazy: PoolConfig = {
  // id is used in URL
  id: '2crazy',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  distribution: {
    type: 'airdrop_us',
    schedule: {
      '2021-06-25T13:00:00.000Z': 33,
      '2021-07-25T13:00:00.000Z': 33,
      '2021-08-25T13:00:00.000Z': 34,
    },
  },
  sale: {
    address: '0xaa1e1df4C44bdF462A1AE8859F4c1F8148f0BEB4',
    status: SaleStatus.finished,
    rate: 25,
    tokensForSale: toWeiBN('5625000'),
    startDate: '2021-06-25T06:00:00.000Z',
    baseAllocation: toWeiBN('42144'),
    durationHours: 6.333333333333333,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$225,000',
    participants: 721,
    tokensSold: toWeiBN('5625000'),
    raised: toWeiBN('225000'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('12800'),
    },
    registerDate: '2021-06-23T06:00:00.000Z',
    registerDuration: 46,
    fcfsDuration: 1,
    levelsOpenAll: true,
  },
  token: {
    address: '',
    addresses: {
      ETH: '0x2c9c19ce3b15ae77c6d80aec3c1194cfd6f7f3fa',
      BSC: '0x3a6e8b36645d6c252714daddd28ec0673535a109',
    },
    name: '2crazy',
    listingTime: '',
    listingInfo: '2021-06-25T13:00:00.000Z',
    symbol: '2CRZ',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingRate: 25,
    totalSupply: toWeiBN(''),
    // BSC, ETH, DOT or SOL
    chain: 'BSC',
    extraChains: ['ETH'],
    initialSupply: toWeiBN('9,547,500'),
    coingeckoId: '2crazynft',
  },
  social: {
    website: 'https://www.2crazynft.com/',
    twitter: 'https://twitter.com/2crazylive',
    telegram: 'https://www.2crazynft.com/',
    medium: 'https://2crazy.medium.com/',
  },
  description: (
    <div>The most advanced eSports NFT's in the Crypto atmosphere.</div>
  ),
  finishedInfo: (
    <div className="space-y-3">
      <div className="space-y-3 text-center">
        <PancakeChartFinishedInfo
          symbol="$2CRZ"
          address="0x3a6e8b36645d6c252714daddd28ec0673535a109"
          pairAddress="0x186937a5f228fe3b8f2395341acce42c307c5fa7"
        />
        <Button size="sm" href="http://bridge.2crazynft.com/" target="_blank">
          BEP-20 ↔ ERC-20 swap
        </Button>
      </div>
    </div>
  ),
};
