import { useUser } from '@trustpad/launchpad';
import Web3 from 'web3';

export function useIsAdmin() {
  const { account } = useUser();
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  return (
    account &&
    Web3.utils.isAddress(account) &&
    process.env.NEXT_PUBLIC_ADMIN_ADDRESSES?.split(',').includes(account)
  );
}
