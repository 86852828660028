import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/dogatoshi.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';
import { LaunchProtocolProtected } from '~features/pools/components/PoolSecondaryBlock/LaunchProtocolProtected';

export const dogatoshi: PoolConfig = {
  // id is used in URL
  id: 'dogatoshi',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0x62C897f739e9eea00EBD261eC8a82B0044f1C6A3',
    tokensForSale: toWeiBN(100_000_000_000),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-06-24T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 500_000,
    durationHours: 23,
    isProtected: false,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(0),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.static,
  },
  distribution: {
    type: 'claim_us',
    network: 'BASE',
    claimerVersion: 'v4',
    vesting: '100% at TGE',
    claimerAddress: '0xC6A3F95E336568fA43E6A41bb2EAc35d0A50d185',
    schedule: {
      '2024-06-26T09:00:00.000Z': 100,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY, ARB
    chain: 'BASE',
    address: '0x7598a53fbf03ffc42f9c47c02525bbb91fa66abc',
    name: 'Dogatoshi',
    symbol: 'DOGA',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-06-26T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Listed on UniSwap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(725_000_000_000),
    totalSupply: toWeiBN(1_000_000_000_000),
  },
  social: {
    website: 'https://dogatoshi.dog/',
    twitter: 'https://twitter.com/4dogatoshi',
    telegram: 'https://t.me/dogatoshi',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <>
      <p>
        Introducing Dogatoshi, where woofs meet wealth in the world of meme tokens!
        Revolutionizing the memepaw game with cutting-edge doggy calculations,
        crafting the most bark-tastic smart contract known to dogkind!
        <br />
        DOGATOSHI is powered by Ordify.
      </p>


      <LaunchProtocolProtected period="12 hours" />

      <p>Fill in <a target="_blank" href='https://forms.gle/PRCa9V9yPsanZ6KWA'>the form</a> if you want to request a refund.</p>
    </>
  ),
};
