import img from 'pools/images/penguinkarts.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { BuySpookySwapButton, ChartDropdownButton } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const penguinkarts: PoolConfig = {
  // id is used in URL
  id: 'penguinkarts',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  noCountries: [],
  sale: {
    address: '0xe2DCd978e2D3e1Ed97cE8FB7c5a1e1373a8c03FD',
    tokensForSale: toWeiBN('4750000'),
    startDate: '2022-03-21T11:00:00.000Z',
    rate: 25,
    durationHours: 5.333333333333333,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4750000'),
    raised: toWeiBN('190000'),
    baseAllocation: toWeiBN('679.647318148420279206'),
    status: 5,
    registerDate: '2022-03-19T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.5,
    totalWeights: 6861,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_them',
    vesting: '10% at TGE, 1 month cliff, then 5 months linear',
    claimUrl: 'http://claim.penguinkarts.com',
    schedule: {
      '2022-03-23T14:30:00.000Z': 10,
    },
  },
  finishedInfo: (
    <div className="flex flex-wrap gap-3">
      <BuySpookySwapButton
        address="0x188a168280589bc3e483d77aae6b4a1d26bd22dc"
        symbol="PGK"
      />{' '}
      <ChartDropdownButton
        chain="FTM"
        address="0x188a168280589bc3e483d77aae6b4a1d26bd22dc"
      />
    </div>
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'FTM',
    address: '0x188a168280589bc3e483d77aae6b4a1d26bd22dc',
    name: 'Penguin Karts',
    symbol: 'PGK',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-23T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'SpookySwap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(2_941_425),
    totalSupply: toWeiBN(200_000_000),
    coingeckoId: 'penguin-karts',
    coinmarketcapId: 'penguin-karts',
  },
  social: {
    website: 'https://penguinkarts.com/',
    twitter: 'https://twitter.com/penguinkarts',
    telegram: 'https://t.me/penguinkarts',
    telegramAnno: 'https://t.me/penguinkartsannouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      PenguinKarts is first of its kind battle-racing 3D multiplayer game on the
      blockchain. It combines the best elements of arcade style kart racing,
      mobile gaming and Race-to-Earn NFT dynamics.
    </p>
  ),
};
