import img from 'pools/images/colizeum.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const colizeum: PoolConfig = {
  // id is used in URL
  id: 'colizeum',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xd99D4D0D74A78C5b963e2935F344aBD981C1B033',
    tokensForSale: toWeiBN('2533333'),
    startDate: '2022-05-03T11:00:00.000Z',
    rate: 13.333,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2533332.999999999999998999'),
    raised: toWeiBN('190004.7251181279531988'),
    baseAllocation: toWeiBN('253.775540568369028006'),
    status: 5,
    registerDate: '2022-05-01T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 9725,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '25% initial unlock, 3 months cliff, 3 months vesting',
    claimerAddress: '0xdB740F283078561388f57989B4a9401970FFFdCf',
    claimerVersion: 'v3',
    schedule: {
      '2022-05-04T03:00:00.000Z': 25,
      // '2022-09-04T03:00:00.000Z': 25,
      // '2022-10-04T03:00:00.000Z': 25,
      // '2022-11-04T03:00:00.000Z': 25,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    extraChains: ['ETH'],
    address: '0x482e6bd0a178f985818c5dfb9ac77918e8412fba',
    name: 'Colizeum',
    symbol: 'ZEUM',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-05-04T03:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(51_670_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'colizeum',
    coinmarketcapId: 'colizeum',
  },
  social: {
    website: 'https://colizeum.com/',
    twitter: 'https://twitter.com/Colizeumcom',
    telegram: 'https://t.me/colizeumcom',
    telegramAnno: 'https://t.me/colizeumannouncements',
    medium: 'https://medium.com/@colizeum',
    github: '',
    whitepaper: '',
    announcement: '',
    voting:
      'https://snapshot.org/#/trustpad.io/proposal/0x10e8f7660a8ea4f2105900415bf306ad840e334af9dba7f963e399d6d6f342f9',
  },
  description: (
    <p>
      Colizeum is a virtual Play to Earn driven environment that, similar to
      Steam, hosts various games and other applications, thereby creating the
      general outline of a user funnel. Colizeum offers connectivity to the
      blockchain based infrastructure for (a) Play-to-Earn, (b) Prediction
      Markets, (c) Tokenized Tournaments, (d) Attention Marketplace, all as a
      service, all with one-click deploy, all on-chain and transparent, designed
      to empower influencers and game developers to fully benefit from the value
      they generate without middlemen, uncertainties and opaque payout schemes.
      Colizeum empowers game developers to build and benefit from blockchain
      games simpler than ever before with easy to use Colizeum SDK.
      <br />
      <br />
      Refunded and rescheduled (Mar-11-2022 12:03:55 PM UTC), see the{' '}
      <a
        href="https://bscscan.com/tx/0x70922461ddbb070bbe17f40ee0ac8127f8e3d810b7cd0f026698ad74862fefdb"
        target="_blank"
        rel="noreferrer">
        TX
      </a>
      .
      <br />
      <br />
      Refunded 75% (May-08-2022 06:23:12 PM UTC), see the{' '}
      <a href="https://bscscan.com/tx/0x20418b8e8346c2744806833196637927d2d9ec71cce8aab1e0b738f77b4d0c71">
        TX
      </a>
      .
    </p>
  ),
};
