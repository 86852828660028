import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FaCircleNotch } from 'react-icons/fa';
import { useMaxUserPool } from '~features/tiers/useMaxUserPool';
import { Dialog } from '~features/ui';
import { fNum, toWeiBN } from '~utils';

export function SaleRegisterDialog({
  buttonLabel,
  tokenName,
  showPopup = false,
  isLoading,
  onConfirm,
}: {
  tokenName: string;
  buttonLabel?: string;
  showPopup?: boolean;
  isLoading: boolean;
  onConfirm: () => Promise<void>;
}) {
  const { maxPool } = useMaxUserPool();
  const confirmRef = useRef(null);
  if (!showPopup) {
    return (
      <Button
        variant="main"
        disabled={isLoading}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onConfirm();
        }}>
        {buttonLabel || 'Register in IDO'}{' '}
        {isLoading && <FaCircleNotch size={20} className="inline fa-spin" />}
      </Button>
    );
  }

  const lockList = [maxPool]
    .filter(Boolean)
    .map((state) => {
      // Ignore pools with 0 staked
      if (state.amount.lt(toWeiBN(1))) {
        return null;
      }
      // Ignore already locked pools which do not re-lock
      if (!state.isUnlocked && !state.alwaysLockOnRegister) {
        return null;
      }

      return state;
    })
    .filter(Boolean);

  return (
    <Dialog
      title={`Register in ${tokenName} sale`}
      initialFocus={confirmRef}
      openButton={(open) => (
        <Button variant="main" disabled={isLoading} onClick={open}>
          {buttonLabel || 'Register in IDO'}{' '}
          {isLoading && <FaCircleNotch size={20} className="inline fa-spin" />}
        </Button>
      )}
      buttons={({ close }) => (
        <>
          <Button
            variant="outline-secondary"
            disabled={isLoading}
            onClick={close}>
            Cancel
          </Button>
          <Button
            variant="main"
            disabled={isLoading}
            ref={confirmRef}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onConfirm().then(close);
            }}>
            {buttonLabel || 'Register'}{' '}
            {isLoading && (
              <FaCircleNotch size={20} className="inline fa-spin" />
            )}
          </Button>
        </>
      )}>
      <Dialog.Sections>
        <p>
          By registering for the {tokenName} IDO you accept the terms of the
          sale and the following tokens distribution.
        </p>

        <Dialog.SectionItem iconStatus="info">
          <p>
            This sale is under the{' '}
            <a
              href="https://trustpad.medium.com/faq-frequently-asked-questions-for-diamond-hands-rules-90309648cade"
              target="_blank"
              rel="noreferrer"
              tabIndex={-1}>
              Diamond Hands rules
            </a>
            : you are required to keep the same level on TrustPad for the
            duration of the project's vesting period to receive the unvested
            tokens.
          </p>
        </Dialog.SectionItem>

        {lockList.length > 0 && (
          <Dialog.SectionItem iconStatus="warning">
            <div>
              <p>
                The registration will lock your tokens in the following pools.
                The lock starts from the sale start date.
              </p>
              <p className="text-sm text-white/80 mt-2">
                {lockList.map((v, idx) => (
                  <span key={v.id}>
                    {idx > 0 && <span className="px-2">&bull;</span>}
                    {fNum(v.amount)} TPAD in {v.id}
                  </span>
                ))}
              </p>
            </div>
          </Dialog.SectionItem>
        )}
      </Dialog.Sections>
    </Dialog>
  );
}
