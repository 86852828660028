import img from 'pools/images/galaxywar.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const galaxywar: PoolConfig = {
  // id is used in URL
  id: 'galaxywar',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xF15a16e131d191097B9d3cC00DF9092A3FA7D51b',
    tokensForSale: toWeiBN('5000000'),
    startDate: '2021-12-28T12:00:00.000Z',
    rate: 33.333,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4999999.999999999999999772'),
    raised: toWeiBN('150001.5000150001500015'),
    baseAllocation: toWeiBN('675.990909090909090909'),
    status: 5,
    levelsOpenAll: false,

    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-26T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7212,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x552594612f935441c01c6854edf111f343c1ca07',
    name: 'Galaxy War',
    symbol: 'GWT',
    imageSrc: img.src,
    listingTime: '2022-01-02T14:00:00.000Z',
    listingRate: 0,
    decimals: 18,
    initialSupply: toWeiBN(18_500_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'galaxy-war',
    coinmarketcapId: 'galaxy-war',
  },
  distribution: {
    type: 'claim_them',
    vesting: '20% TGE, 1 month cliff, daily linear for 4 months',
    claimUrl: 'https://vesting-bsc.galaxywar.io/',
    schedule: {
      '2022-01-02T15:00:00.000Z': 20,
    },
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="GWT"
      address="0x552594612f935441c01c6854edf111f343c1ca07"
      pairAddress="0xb72a4fa964d8af8835262b655c5de67777bff9dd"
    />
  ),
  social: {
    website: 'https://galaxywar.io/',
    twitter: 'https://twitter.com/galaxywarnft',
    telegram: 'https://t.me/GalaxyWarNFT',
    telegramAnno: 'https://t.me/galaxywarann',
    medium: '',
    github: '',
    whitepaper: 'https://whitepaper.galaxywar.io/',
    announcement: '',
  },
  description: (
    <p>
      Galaxy War is a Defi and Multichain space strategy classic NFT game.
      Compete against thousands of other players on multiple chains for supreme
      control of the Multiverse!
    </p>
  ),
};
