import React from 'react';
import type { PlacesType, VariantType } from 'react-tooltip';

export function Tooltipped({
  id = 'tpad-tooltip',
  content,
  children,
  place,
  variant,
  className = '',
}: {
  id?: string;
  content?: string;
  children: React.ReactNode;
  place?: PlacesType;
  variant?: VariantType;
  className?: string;
}) {
  if (!content) {
    return <>{children}</>;
  }
  return (
    <a
      className={`underline decoration-dashed text-white ${className}`}
      data-tooltip-id={id}
      data-tooltip-content={content}
      data-tooltip-place={place}
      data-tooltip-variant={variant}>
      {children}
    </a>
  );
}
