import img from 'pools/images/lockness.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const lockness: PoolConfig = {
  // id is used in URL
  id: 'lockness',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x3523613d3e664B0Abcb1b89694396190d62D4e22',
    tokensForSale: toWeiBN('13333200'),
    startDate: '2022-01-11T12:00:00.000Z',
    rate: 66.666,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('13333199.999999999999999196'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('1710.981109808518952715'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('30'),
    },
    registerDate: '2022-01-09T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7733,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x31acfce536b824ad0739e8d7b27cefaa4b8e4673',
    name: 'Lockness',
    symbol: 'LKN',
    imageSrc: img.src,
    listingTime: '2022-01-18T09:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(30_000_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'lockness',
    athXManual: 9.53,
    coinmarketcapId: 'lockness',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="LKN"
      address="0x31acfce536b824ad0739e8d7b27cefaa4b8e4673"
      pairAddress="0x31acfce536b824ad0739e8d7b27cefaa4b8e4673"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '30% release, 1 month Lock, 3 Month linear vesting',
    claimerAddress: '0x88fca6b21297fCAAFe3D6860DB431F0B6CD60d50',
    claimerVersion: 'v2',
    schedule: {
      '2022-01-18T09:00:00.000Z': 30,
      '2022-02-18T09:00:00.000Z': 23.4,
      '2022-03-18T09:00:00.000Z': 23.3,
      '2022-04-18T09:00:00.000Z': 23.3,
    },
  },
  social: {
    website: 'https://lockness.io/',
    twitter: 'https://twitter.com/LockNess_BSC',
    telegram: 'https://t.me/LocknessOfficial',
    telegramAnno: 'https://t.me/locknessanouncement',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Inspired by the powerful mythical creature, Lockness aims on providing a
      safe and secure spending environment for users within the crypto space.
      For the first time users can buy and sell goods and services on any
      platform at any time, under the protection of a P2P escrow system.
      Lockness is the PayPal of crypto!
    </p>
  ),
};
