import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/orangedx.jpeg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const orangedx: PoolConfig = {
  // id is used in URL
  id: 'orangedx',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0xeCAEAaA5C3a13bA1d439b14dA2b86194f12e9978',
    tokensForSale: toWeiBN(2_235_294.11),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-03-22T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 11.7647,
    durationHours: 23,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(2_235_294.11),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    claimerVersion: 'v4',
    vesting: '100% unlock',
    claimerAddress: '0x1C485a78Ac5b530fa5cf188389Fd15A97d6Bfdd7',
    schedule: {
      '2024-03-26T14:20:00.000Z': 100,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x062ca2d2f1af8c203fa3ad5298fd6faa4e44e897',
    name: 'OrangeDX',
    symbol: 'O4DX',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-03-26T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: (
      <div>
        Listed on PancakeSwap, UniSat, and OrangeDX
        <br />
        <strong>Please note:</strong> CEX listing at 2:00 PM UTC, DEX listing at
        2:15 PM UTC, distribution at 2:20 PM UTC
      </div>
    ),
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(27_500_000),
    totalSupply: toWeiBN(100_000_000),
  },
  social: {
    website: 'https://orangedx.com/',
    twitter: 'https://twitter.com/OrangDx_BRC20',
    telegram: 'https://t.me/OrangeDx_Official_Chat',
    telegramAnno: 'https://t.me/Orangedx_Official',
    medium: 'https://medium.com/@orange_dex',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      OrangeDX is a pioneering Bitcoin DeFi platform where users can trade,
      swap, secure, and launch tokens. It offers borrowing, lending, and a DEX
      aggregator for BRC-20 tokens. With an Initial Liquidity Offering and
      decentralized governance via a DAO, OrangeDX bridges Bitcoin stability
      with DeFi's innovation.
    </p>
  ),
};
