import { PoolConfig } from '@trustpad/launchpad';

import {
  FaBullhorn,
  FaFileWord,
  FaGithub,
  FaGlobe,
  FaMedium,
  FaTelegramPlane,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

export function SocialLinks({
  social,
  size = 'text-2xl',
  tableStyles,
}: {
  social: PoolConfig['social'];
  size?: string;
  tableStyles?: boolean;
}) {
  if (!social) {
    return null;
  }
  return (
    <ul
      className={`${
        tableStyles
          ? 'grid grid-cols-3 lg:gap-4 xll:gap-0 xll:inline-flex xll:space-x-6'
          : 'flex space-x-6'
      } flex-wrap items-center text-main  z-20 relative ${size}`}>
      {social.telegram && (
        <li>
          <a
            href={social.telegram}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaTelegramPlane />
          </a>
        </li>
      )}
      {social.telegramAnno && (
        <li>
          <a
            href={social.telegramAnno}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaBullhorn />
          </a>
        </li>
      )}
      {social.twitter && (
        <li>
          <a
            href={social.twitter}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaXTwitter />
          </a>
        </li>
      )}
      {social.medium && (
        <li>
          <a
            href={social.medium}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaMedium />
          </a>
        </li>
      )}
      {social.website && (
        <li>
          <a
            href={social.website}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaGlobe />
          </a>
        </li>
      )}
      {social.github && (
        <li>
          <a
            href={social.github}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaGithub />
          </a>
        </li>
      )}
      {social.whitepaper && (
        <li>
          <a
            href={social.whitepaper}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            className="text-main"
            rel="noreferrer">
            <FaFileWord />
          </a>
        </li>
      )}
    </ul>
  );
}
