import React from 'react';
import { CgClose } from 'react-icons/cg';
import { PatternImage } from '~common/TopBar/PatternImage';
import { useBarPersistentDisplayState } from '~common/TopBar/useBarPersistentDisplayState';

export function ProposalVotingBar() {
  const [display, setDisplay] = useBarPersistentDisplayState('proposal');

  if (!display) {
    return null;
  }

  return (
    <div className="flex overflow-hidden relative justify-end bg-green-500 text-black py-3">
      <PatternImage side="left" startWith="bottom-0" />
      <PatternImage side="right" startWith="top-0" />

      <div className="mr-auto ml-auto pl-7 z-10">
        ⚠️ <b> Important Note:</b> please read and vote accordingly.{' '}
        <b>
          <a
            className="text-black "
            href="~common/TopBar/BreakingNewsBar#/trustpad.io/proposal/0x0b8f917549c64ea287ca2465f1b49bb08b289b90ac5c334c910b90c079549d5b"
            target="_blank"
            rel="noreferrer">
            Vote here.
          </a>
        </b>{' '}
        The voting ends at Jul 25, 00:00 UTC ⚠️
      </div>

      <button
        className="text-xl px-7 text-black hover:text-gray-300"
        onClick={() => setDisplay(false)}>
        <CgClose />
      </button>
    </div>
  );
}
