import { NO_US, SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/wizardia.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

const wizardiaCommon = {
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xfa40d8fc324bcdd6bbae0e086de886c571c225d4',
    extraChains: ['SOL'],
    name: 'Wizardia',
    symbol: 'WZRD',
    imageSrc: img.src,
    listingTime: '2022-03-24T12:00:00.000Z',
    listingInfo: 'Listing on GATE.IO and PancakeSwap',
    decimals: 18,
    listingRate: 8.333,
    initialSupply: toWeiBN(2_766_666),
    totalSupply: toWeiBN(300_000_000),
    coingeckoId: 'wizardia',
  },
  social: {
    website: 'https://wizardia.io/',
    twitter: 'https://twitter.com/PlayWizardia',
    telegram: 'https://t.me/Wizardia',
    telegramAnno: '',
    medium: 'https://medium.com/@wizardia',
    github: '',
    whitepaper: 'https://lightpaper.wizardia.io/wizardia-lightpaper/',
    announcement: '',
  },
  description: (
    <p>
      Wizardia is a Play-to-Earn (P2E) online role-playing strategy game built
      on three core gameplay pillars: innovative turn-based combat,
      base-building with sophisticated economy model at its heart,
      player-decision driven game evolution. Each player gets to part take in or
      even specialize in different game modes such as PvP and PvE battles,
      base-building and exploration, item crafting and research of crafting
      recipes. Wizardia rewards those who contribute to the games metaverse and
      its real-valued economy system so there are no one-way investments.
    </p>
  ),
};

export const wizardia: PoolConfig = {
  ...wizardiaCommon,
  // id is used in URL
  id: 'wizardia',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  noCountries: NO_US,
  sale: {
    address: '0xda7231Bc77cC1Dc99739311c3eEa04567A1A6B31',
    tokensForSale: toWeiBN('1666666'),
    startDate: '2022-03-19T11:00:00.000Z',
    rate: 8.333,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1666665.99999999999999927'),
    raised: toWeiBN('200007.9203168126725069'),
    baseAllocation: toWeiBN('243.994144144144144144'),
    status: 5,
    registerDate: '2022-03-17T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6716,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting:
      '1.43% daily week 1, 1.43% daily week 5 (in total 20% first month), then 20% monthly',
    // Fully funded
    claimerAddress: '0x8141f9b0F40E57b9dBF55235A3E1015f0cba749D',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-24T13:00:00.000Z': 1.43,
      '2022-03-25T13:00:00.000Z': 1.43,
      '2022-03-26T13:00:00.000Z': 1.43,
      '2022-03-27T13:00:00.000Z': 1.43,
      '2022-03-28T13:00:00.000Z': 1.43,
      '2022-03-29T13:00:00.000Z': 1.43,
      '2022-03-30T13:00:00.000Z': 1.43,
      // week 4
      '2022-04-21T13:00:00.000Z': 1.43,
      '2022-04-22T13:00:00.000Z': 1.43,
      '2022-04-23T13:00:00.000Z': 1.43,
      '2022-04-24T13:00:00.000Z': 1.43,
      '2022-04-25T13:00:00.000Z': 1.43,
      '2022-04-26T13:00:00.000Z': 1.43,
      '2022-04-27T13:00:00.000Z': 1.41,
      // monthly
      '2022-05-27T13:00:00.000Z': 20,
      '2022-06-26T13:00:00.000Z': 20,
      '2022-07-26T13:00:00.000Z': 20,
      '2022-08-25T13:00:00.000Z': 20,
    },
  },
};

export const wizardiaPrivate: PoolConfig = {
  ...wizardiaCommon,
  // id is used in URL
  id: 'wizardiaprivate',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  noCountries: NO_US,
  sale: {
    address: '0xC2274B70ed98A1e38eFE5821f92819f2B5793BD6',
    tokensForSale: toWeiBN('625000'),
    startDate: '2022-03-21T11:00:00.000Z',
    rate: 12.5,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('625000'),
    raised: toWeiBN('50000'),
    baseAllocation: toWeiBN('221.631205673758865248'),
    status: 5,
    registerDate: '2022-03-19T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 2848,
    minAllowedLevelMultiplier: 22,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting:
      '1.43% daily week 1, 0.71% daily week 4 (in total 15% first month), 1 month cliff, 5% month 2, then 10% monthly',
    // Fully funded.
    claimerAddress: '0xB35671679f28c61aee4Bf740a314bC4A7e3A987a',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-24T13:00:00.000Z': 1.43,
      '2022-03-25T13:00:00.000Z': 1.43,
      '2022-03-26T13:00:00.000Z': 1.43,
      '2022-03-27T13:00:00.000Z': 1.43,
      '2022-03-28T13:00:00.000Z': 1.43,
      '2022-03-29T13:00:00.000Z': 1.43,
      '2022-03-30T13:00:00.000Z': 1.45,
      // week 4
      '2022-04-14T13:00:00.000Z': 0.71,
      '2022-04-15T13:00:00.000Z': 0.71,
      '2022-04-16T13:00:00.000Z': 0.71,
      '2022-04-17T13:00:00.000Z': 0.71,
      '2022-04-18T13:00:00.000Z': 0.71,
      '2022-04-19T13:00:00.000Z': 0.71,
      '2022-04-20T13:00:00.000Z': 0.71,
      // monthly after 1 month cliff
      '2022-05-20T13:00:00.000Z': 5,
      '2022-07-19T13:00:00.000Z': 10,
      '2022-08-18T13:00:00.000Z': 10,
      '2022-09-17T13:00:00.000Z': 10,
      '2022-10-17T13:00:00.000Z': 10,
      '2022-11-16T13:00:00.000Z': 10,
      '2022-12-16T13:00:00.000Z': 10,
      '2023-01-15T13:00:00.000Z': 10,
      '2023-02-14T13:00:00.000Z': 10,
    },
  },
};
