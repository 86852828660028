import { useWeb3Provider } from '@trustpad/launchpad';

import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Tooltip } from 'react-tooltip';

import { ApplyForIDO } from '~common/BaseLayout/ApplyForIDO';
import { Footer } from '~common/BaseLayout/Footer';
import { TopBarAlerts } from '~common/BaseLayout/TopBarAlerts';
import { CLAIMER_ADDRESS } from '~features/tpadv2/TpadV2Page';
import { useBurnClaimerContract } from '~features/tpadv2/useBurnClaimer';
import { Button, Dialog } from '~features/ui';

import { ScrollTopButton } from './ScrollTopButton';

function getErrorMessage(error: Error) {
  if (error?.name === 'NoEthereumProviderError') {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
  } else if (error?.name === 'UnsupportedChainIdError') {
    return "You're connected to an unsupported network.";
  } else if (
    error?.name === 'UserRejectedRequestErrorInjected' ||
    error?.name === 'UserRejectedRequestErrorWalletConnect'
  ) {
    return 'Please authorize this website to access your Ethereum account.';
  } else {
    return 'An unknown error occurred. Check the console for more details.';
  }
}

export function BaseLayout({ children }: { children: React.ReactNode }) {
  const { error, isValidChain, targetChain } = useWeb3Provider();

  useEffect(() => {
    if (!isValidChain) {
      toast.warn(
        `You're connected to an unsupported network. Please switch to ${targetChain.symbol}`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
          closeButton: false,
          toastId: 'invalidNetwork',
        },
      );
    } else {
      toast.dismiss('invalidNetwork');
    }
  }, [isValidChain, targetChain]);
  useEffect(() => {
    // @ts-expect-error ignore "Request of type 'wallet_requestPermissions' already pending"
    if (error?.code === -32002) return;
    if (error) {
      toast.warn(getErrorMessage(error), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        toastId: 'web3Error',
        closeButton: false,
      });
    } else {
      toast.dismiss('web3Error');
    }
  }, [error]);

  return (
    <div className="font-sans antialiased">
      <TopBarAlerts />

      {children}

      <ApplyForIDO />
      <Footer />

      <ToastContainer theme="colored" />

      <ScrollTopButton />

      <Tooltip id="tpad-tooltip" place="top" />

      <MigratePopup />
    </div>
  );
}

function MigratePopup() {
  const { account } = useWeb3Provider();
  const [display, setDisplay] = useState(false);
  const instance = useBurnClaimerContract(CLAIMER_ADDRESS);
  const key = `migrationPopupDismissed-${account}`;

  useEffect(() => {
    if (!account || !instance) return;

    const dismissed = localStorage.getItem(key) === '1';
    if (dismissed) return;

    instance.methods
      .hasUnclaimed(account)
      .call()
      .then((res) => {
        setDisplay(res);
      });
  }, [display, account, instance, setDisplay]);

  function dismiss() {
    localStorage.setItem(key, '1');
    setDisplay(false);
  }

  if (!account || !display) return null;

  return (
    <Dialog
      title="TPAD V2 Migration is now LIVE"
      initialOpen
      openButton={() => null}
      buttons={({ close }) => (
        <>
          <Button
            href="/tpadv2"
            onClick={() => {
              dismiss();
            }}>
            Migrate now
          </Button>

          <Button
            type="secondaryOutline"
            className="w-full"
            onClick={() => {
              dismiss();
              close();
            }}>
            Close
          </Button>
        </>
      )}>
      <Dialog.Sections>
        For all <span className="brand-text text-lg"> TPAD V1</span> holders and
        stakers; it's time to migrate your tokens to
        <span className="brand-text text-lg"> TPAD V2</span>.
        <br />
        <br />
        🚨 Deadline: January 3rd, 2024 at 15:00 UTC
        <br />
        <br />
        🔥 <b>IMPORTANT:</b> Unclaimed TPAD V2 tokens will be permanently burnt.
        Make sure to migrate your TPAD V1 tokens before the deadline.
      </Dialog.Sections>
    </Dialog>
  );
}
