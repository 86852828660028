import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/deadknight.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { SolanaChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

// They sent us in total: 8790273
// Raised on NFTPad: 977774
// Raised on Trustpad: 7812500
// Should be: 7812500 + 977774
// Initial unlock: 1318540.95 (sent)
export const deadknight: PoolConfig = {
  // id is used in URL
  id: 'deadknight',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0x624b960d3bc60ecE893Fe04927334931930E82C5',
    tokensForSale: toWeiBN('7812500'),
    startDate: '2022-03-28T11:00:00.000Z',
    rate: 31.25,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('7812500'),
    raised: toWeiBN('250000'),
    baseAllocation: toWeiBN('4000'),
    status: 5,
    registerDate: '2022-03-26T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('60'),
    },
    fcfsDuration: 1.3333333333333333,
    totalWeights: 5614,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_server',
    vesting: '15% to be released on TGE, then 10% every month, last month 5%',
    schedule: {
      '2022-03-30T12:15:00.000Z': 15,
    },
  },

  finishedInfo: (
    <SolanaChartFinishedInfo
      symbol="DKM"
      address={'HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W'}
    />
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: 'HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W',
    name: 'Dead Knight',
    symbol: 'DKM',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-30T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'On Raydium. At 15:00 UTC on MEXC',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(12750000),
    totalSupply: toWeiBN(500000000),
    coingeckoId: 'dead-knight',
    coinmarketcapId: 'dead-knight',
  },
  social: {
    website: 'https://deadknight.io/',
    twitter: 'https://twitter.com/DeadKnight_SOL',
    telegram: 'https://t.me/DeadKnightMeta_Official',
    telegramAnno: 'https://t.me/DeadKnightMeta_Ann',
    medium: 'https://medium.com/@deadknightnft',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Dead Knight is a Play-To-Earn NFT gaming ecosystem based on the Solana
      blockchain with 9999 NFT collectibles, unique randomly generated
      attributes based on blockchain technology, will be released first,
      followed by the game. Dead Knight NFT collectibles also come in many
      different types, each with a unique set of attributes that appear on the
      NFT. Players will use their Knight as their characters in an exciting
      Play-To-Earn NFT Game.
      <br />
      <br />
      Refunded 90%, see the{' '}
      <a href="https://bscscan.com/tx/0xdbe9e63139b725db7f1be26d9468494c4fa0e898effa3150e7e4d7eda41ed1cf">
        TX
      </a>
      .
    </p>
  ),
};
