import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/realmsofethernity.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const realmsofethernity: PoolConfig = {
  // id is used in URL
  id: 'realmsofethernity',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x77722EDf59af12e890e37C0acC3b9ba1Fd4C698f',
    tokensForSale: toWeiBN('5383333.33'),
    startDate: '2022-04-13T11:00:00.000Z',
    rate: 33.3,
    durationHours: 5,
    refunded: true,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('5383333.32999999999999795'),
    raised: toWeiBN('161661.6615615615615615'),
    baseAllocation: toWeiBN('846.737396001289906481'),
    status: 5,
    registerDate: '2022-04-11T11:00:00.000Z',
    registerDuration: 45,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('40'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6268,
    minAllowedLevelMultiplier: 0,
  },
  distribution: {
    type: 'claim_linear',
    network: 'POLY',
    vesting:
      '10% at TGE and linear unlock of 15% on every month. Total unlock for public sale buyers in on 6th month.',
    claimerAddress: '0xEF5A2b2F1559Aa04458325A265E285301922e49D',
    config: {
      initialPercent: 10,
      initialUnlockDate: '2022-05-02T12:01:00.000Z',
      vestingStartDate: '2022-05-02T12:01:01.000Z',
      vestingDuration: 6 * 30 * 24 * 3600,
    },
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="RETH"
      address="0x614af88dac8bbffc5a167bb79ff3f5e0769f1a3f"
      chain="POLY"
      pairAddress="0x292e3eb0b341ba5346bad9de2100b4f33ae68e08"
    />
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x614af88dac8bbffc5a167bb79ff3f5e0769f1a3f',
    name: 'Realms of Ethernity',
    symbol: 'RETH',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-05-02T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Realms of Ethernity will list on Uniswap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(17_500_000),
    totalSupply: toWeiBN(1_378_000_000),
    coingeckoId: 'realms-of-ethernity',
  },
  social: {
    website: 'https://realmsofethernity.com/',
    twitter: 'https://twitter.com/realmsofeth',
    telegram: 'https://t.me/realmsofethernityMMORPG',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: 'https://realmsofethernity.com/whitepaper',
    announcement: '',
  },
  description: (
    <p>
      Realms of Ethernity, the WOW of WEB 3.0 gaming which provides AAA gameplay
      experience. RoE is an MMORPG that takes some of the best lessons learned
      from games like WoW, Ultima Online, Runescape and Dota, and spins them up
      with a reimagined meta and new game modes to create a unique and
      compelling gameplay experience. Built for first-release on desktop, you
      play a customisable NFT avatar, each with their own appearance, play-style
      and identity. Unlike other play-to-earn games, Realms of Ethernity gives
      you complete ownership over your in-game progress. Within Realms of
      Ethernity, developers and players can build, own, and monetize their
      gaming experiences.
      <br />
      <br />
      <b>
        Refunded 75%:{' '}
        <a href="https://bscscan.com/tx/0x214ecb704f27caa987b553ac9c3df91f035d0be6e3c3479ef4d0fc95ff2b5de7">
          TX
        </a>
      </b>
    </p>
  ),
};
