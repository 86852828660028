import * as React from 'react';

export function ProgressBar({
  progress,
  withLabel = false,
  height = '0.5rem',
  className = 'bg-primary',
}: {
  progress: number;
  withLabel?: boolean;
  className?: string;
  height?: string;
}) {
  return (
    <div
      className={`bg-gray-600 flex overflow-hidden rounded-2xl justify-center items-center relative`}
      style={{ boxShadow: 'inset 0 0.1rem 0.1rem rgb(0 0 0 / 10%)', height }}>
      <div
        role="progressbar"
        className={`${className} absolute h-full left-0 transition-all`}
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      />
      {withLabel && (
        <span className="text-lg secondary-text z-10">
          {Number(progress).toFixed(1)}%
        </span>
      )}
    </div>
  );
}
