import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/arenumgames.png';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const arenumgames: PoolConfig = {
  // id is used in URL
  id: 'arenumgames',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xe4c882e35394168Cc8C75de8ae51Eec9F329D242',
    tokensForSale: toWeiBN('533333'),
    startDate: '2022-01-17T11:00:00.000Z',
    rate: 2.666,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('533332.999999999999999851'),
    raised: toWeiBN('200049.8874718679669918'),
    baseAllocation: toWeiBN('67.798498694516971279'),
    status: 5,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-15T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7716,
    minAllowedLevelMultiplier: 0,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: '3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD',
    addresses: {
      BSC: '0xD9063a24630f24cd4Dc99a9C7aBe73F82Fc6B722',
    },
    name: 'Arenum Games',
    symbol: 'ARNM',
    imageSrc: img.src,
    listingTime: '2022-04-12T10:00:00.000Z',
    listingInfo: 'On Kucoin and HitBTC at 10:00 UTC. On Raydiun at 19:00 UTC.',
    decimals: 6,
    listingRate: 0,
    initialSupply: toWeiBN(2_500_000),
    totalSupply: toWeiBN(100_000_000),
    coingeckoId: 'arenum',
    coinmarketcapId: 'arenum',
  },
  finishedInfo: (
    <>
      <Button
        title="Buy on Kucoin"
        variant="main"
        href="https://www.kucoin.com/trade/ARNM-USDT"
        target="_blank"
        className="btn-sm py-2 no-hover">
        Buy ARNM on Kucoin
      </Button>
    </>
  ),
  distribution: {
    type: 'claim_server',
    vesting: '20% at TGE, 1 month cliff, then 4 months linear',
    schedule: {
      '2022-04-12T10:00:00.000Z': 20,
      '2022-06-12T10:00:00.000Z': 20,
      '2022-07-12T10:00:00.000Z': 20,
      '2022-08-12T10:00:00.000Z': 20,
      '2022-09-12T10:00:00.000Z': 20,
    },
  },
  social: {
    website: 'https://arenum.io/',
    twitter: 'https://twitter.com/arenum_official',
    telegram: 'https://t.me/arenum_community',
    telegramAnno: 'https://t.me/arenum_announcements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  descriptionShort: (
    <p>
      Arenum is the evolution of mobile gaming and cryptocurrency which has a
      feature-rich ecosystem to attract players and engage them for the long
      term while providing rewarding incentives for developers and gamers alike.
    </p>
  ),
  description: (
    <>
      <p>
        Arenum is the evolution of mobile gaming and cryptocurrency which has a
        feature-rich ecosystem to attract players and engage them for the long
        term while providing rewarding incentives for developers and gamers
        alike.
      </p>
      <p>
        Arenum fuses internal publishing services, and an engine for
        player-versus-player tournaments as a service, into a unique metasystem
        existing on a layer above the games, integrated. Integration into
        Arenums metasystem is entirely autonomous and is seamlessly enabled
        through a powerful developer SDK.
      </p>
      <p>
        Arenum is not starting its journey anew, the service has been on the
        market since 2018 with a proven track record of 150,000 paying users.
        Arenum is the next step in the evolution of web3, it incorporates a
        unique and exciting metaverse with NFT collecting and battling and
        leverages its competitive play-to-earn mechanics for any type of game on
        its roster, Arenum is the web3-powered matching engine for eSports.
      </p>
    </>
  ),
};
