// The fees collector (second acc of launchpad deployer): https://bscscan.com/address/0x396a7c3ff178bff10938ad4d4afb0d364fb030b3
import { LevelPool } from '@trustpad/launchpad';
import { StakingPool } from '@trustpad/launchpad/dist/staking/types';

import { TPAD } from '~config/tokens';

// 180 = 30 % APY - 26.30 % APR
// 90 = 18 % APY - 16.58 % APR
// 60 = 12 % APY - 11.35 % APR
// 30 = 5 % APY - 4.88 % APR
// 15 = 0 % APY

type ExtendedLevelPool = LevelPool & {
  createdAtBlock: number;
};

const days = (n: number) => n * 24 * 3600;

export const LPLevelPool: LevelPool = {
  id: 'LP',
  lockPeriod: '14 days',
  lockPeriodSeconds: days(14),
  // address: '0x6070e810F5A399d3de5ba276B5254dca16241128', // TPAD v1
  address: '',
  apy: 0,
  vipEligible: false,
  isAAG: false,
};

export const levelPools: ExtendedLevelPool[] = [
  // {
  //   id: '7 days',
  //   lockPeriod: '7 days',
  //   lockPeriodSeconds: days(7),
  //   address: '0x4587eAF87310f3fC6BBFD579cA4Abb2D7E7E9CfC',
  //   apy: 0,
  //   vipEligible: false,
  //   deprecated: false,
  //   isAAG: false,
  //   alwaysLockOnRegister: true,
  //   createdAtBlock: 33917955,
  // },
  {
    id: '14 days',
    lockPeriod: '14 days',
    lockPeriodSeconds: days(14),
    address: '0x785a3a9bb8de68539f7b3b1Cf3eFEA2Cb7E538A4',
    // apy: 2,
    apy: 0,
    vipEligible: false,
    isAAG: false,
    createdAtBlock: 33918075,
    boosters: {
      lottery: 0,
      guaranteed: 0,
      aag: 0,
      saag: 0,
    },
  },
  {
    id: '30 days',
    lockPeriod: '30 days',
    lockPeriodSeconds: days(30),
    address: '0xe85a1cD83c4eEF722161983726e78c53D19598C0',
    // apy: 2.11,
    apy: 0,
    vipEligible: false,
    isAAG: false,
    createdAtBlock: 33918172,
    boosters: {
      lottery: 0,
      guaranteed: 6,
      aag: 8,
      saag: 10,
    },
  },
  {
    id: '60 days',
    lockPeriod: '60 days',
    lockPeriodSeconds: days(60),
    address: '0x50a4496525c6414878807f3D9182340d695c04bC',
    // apy: 2.81,
    apy: 0,
    vipEligible: true,
    isAAG: false,
    createdAtBlock: 33918172,
    boosters: {
      lottery: 0,
      guaranteed: 15,
      aag: 20,
      saag: 25,
    },
  },
  {
    id: '90 days',
    lockPeriod: '90 days',
    lockPeriodSeconds: days(90),
    address: '0xa331362EcAaC1b3d11A022FEECeBE2BD3693A51f',
    // apy: 3.46,
    apy: 0,
    vipEligible: true,
    isAAG: false,
    createdAtBlock: 33918172,
    boosters: {
      lottery: 0,
      guaranteed: 40,
      aag: 45,
      saag: 50,
    },
  },
  {
    id: '180 days',
    lockPeriod: '180 days',
    lockPeriodSeconds: days(180),
    address: '0xe4194557be67ffE54424F669929C85c06F89a68F',
    apy: 0,
    vipEligible: true,
    isAAG: true,
    boosters: {
      lottery: 0,
      guaranteed: 80,
      aag: 85,
      saag: 90,
    },
    createdAtBlock: 33918172,
  },
  {
    id: '365 days',
    lockPeriod: '365 days',
    lockPeriodSeconds: days(365),
    maturityPeriod: 'every 3 months',
    maturityPeriodSeconds: days(3 * 30),
    address: '0x172dC9463157BfF7E653E142362976e31E86ae7A',
    apy: 0,
    vipEligible: true,
    isAAG: true,
    boosters: {
      lottery: 0,
      guaranteed: 170,
      aag: 180,
      saag: 190,
    },
    createdAtBlock: 33918172,
  },
  {
    id: '730 days',
    lockPeriod: '730 days',
    lockPeriodSeconds: days(730),
    maturityPeriod: 'every 2 months',
    maturityPeriodSeconds: days(2 * 30),
    address: '0x85484B9D0525891283eFdeE87Bce0c2521D65876',
    apy: 0,
    vipEligible: true,
    isAAG: true,
    boosters: {
      lottery: 0,
      guaranteed: 280,
      aag: 290,
      saag: 300,
    },
    createdAtBlock: 33918172,
  },
  {
    id: '1095 days',
    lockPeriod: '1095 days',
    lockPeriodSeconds: days(1095),
    maturityPeriod: 'every month',
    maturityPeriodSeconds: days(30),
    address: '0x0a5aDd3b55Aa68F11aCd0f0ed784E5Af7F688c80',
    apy: 3,
    vipEligible: true,
    isAAG: true,
    boosters: {
      lottery: 0,
      guaranteed: 380,
      aag: 390,
      saag: 400,
    },
    createdAtBlock: 33918172,
  },
  // {
  //   id: '4years',
  //   lockPeriod: '1460 days',
  //   lockPeriodSeconds: days(1460),
  //   maturityPeriod: 'every month',
  //   maturityPeriodSeconds: days(30),
  //   address: '0x9aE19aEA3f6EB8c31489d1652D95428D95935737',
  //   apy: 17.5,
  //   vipEligible: true,
  //   isAAG: true,
  // },
  // ...(process.env.NODE_ENV === 'development'
  //   ? [
  //       {
  //         id: 'TEST',
  //         lockPeriod: 'TEST',
  //         address: '0x2f3ef0DdeA64808DCBBC2A9cA0D8D57Ee205156C',
  //         apy: 20,
  //         vipEligible: true,
  //         isAAG: false,
  //       },
  //     ]
  //   : []),
];

export const levelStakingPools: StakingPool[] = levelPools.map((pool) => ({
  address: pool.address,
  decimals: TPAD.decimals,
  stakeToken: TPAD,
  rewardToken: TPAD,
  title: pool.lockPeriod,
  deprecated: pool.deprecated || false,
}));
