import { PoolConfig } from '@trustpad/launchpad';

import { Badge } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';

const labels = {
  levels_fcfs: 'Levels FCFS',
  private: (
    <>
      Private <FaLock className="inline-block mt-[-3px]" />
    </>
  ),
  seed: (
    <>
      Seed <FaLock className="inline" />
    </>
  ),
  whitelsit: 'Whitelist',
  kol: 'KOL',
};

export function AccessBadge({ pool: { access } }: { pool: PoolConfig }) {
  const className =
    'py-1 px-2 font-normal tracking-wider h-6 leading-4 uppercase';

  return labels[access] ? (
    <Badge
      pill
      variant="secondary"
      className={`${className} bg-gray-400 bg-opacity-20 text-gray-300`}>
      {labels[access]}
    </Badge>
  ) : null;
}
