import img from 'pools/images/endlessbattlefield.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

const endlessBattlefieldCommon = {
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '',
    name: 'Endless Battlefield',
    symbol: 'EB',
    imageSrc: img.src,
    listingTime: '2022-02-25T16:00:00.000Z',
    decimals: 18,
    listingRate: 5,
    initialSupply: toWeiBN(16245000),
    totalSupply: toWeiBN(1000000000),
    coingeckoId: '',
  },
  social: {
    website: 'https://ebgame.io/',
    twitter: 'https://twitter.com/EBMetaverse',
    telegram: 'https://t.me/ebgame_global',
    telegramAnno: 'https://t.me/EBgame_channel',
    medium: 'https://ebgame.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Endless Battlefield, abbreviated as EB, is a military strategy and
      exploration sandbox metaverse built on UE engine. Using planets as the
      map, it provides multiple modes to fulfill the needs of different players.
      The game can be played in FPS mode for hardcore players. Casual players
      also can find various activities to start their adventure including
      hunting, fishing, and building. Players will be rewarded by collecting
      materials, constructing sites and winning battles.
    </p>
  ),
};

export const endlessBattlefield: PoolConfig = {
  ...endlessBattlefieldCommon,
  // id is used in URL
  id: 'endlessbattlefield',
  // public, seed or private
  access: 'public',
  sale: {
    refunded: true,
    address: '0xb06f99397CFA44538e10CE14E2834831A4BA5d63',
    tokensForSale: toWeiBN('3000000'),
    startDate: '2022-02-23T11:00:00.000Z',
    rate: 5,
    durationHours: 11.166666666666666,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2779020.6996646269293385'),
    raised: toWeiBN('555804.1399329253858677'),
    baseAllocation: toWeiBN('391.50630391506303915'),
    status: 5,
    registerDate: '2022-02-21T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7619,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: 'TGE 25%, remaining vesting monthly for 4 months',
    // schedule: {
    //   '2022-02-25T16:00:00.000Z': 25,
    //   '2022-03-25T16:00:00.000Z': 18.75,
    //   '2022-04-25T16:00:00.000Z': 18.75,
    //   '2022-05-25T16:00:00.000Z': 18.75,
    //   '2022-06-25T16:00:00.000Z': 18.75,
    // },
  },
};

export const endlesssBattlefieldPrivate: PoolConfig = {
  ...endlessBattlefieldCommon,
  id: 'endlessbattlefieldprivate',
  // public, seed or private
  access: 'private',
  sale: {
    refunded: true,
    address: '0xf122fCC009ebB5E1c8F19BA2869CE28ADaEd4998',
    tokensForSale: toWeiBN('1500000'),
    startDate: '2022-02-23T11:00:00.000Z',
    rate: 10,
    durationHours: 11,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1500000'),
    raised: toWeiBN('150000'),
    baseAllocation: toWeiBN('509.510869565217391304'),
    status: 5,
    registerDate: '2022-02-21T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 3028,
    minAllowedLevelMultiplier: 22,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: 'TGE 8.33%, remaining vesting monthly for 24 months',
    // schedule: {
    //   '2022-02-25T16:00:00.000Z': 8.33,
    //   '2022-03-25T16:00:00.000Z': 3.833,
    //   '2022-04-24T16:00:00.000Z': 3.819,
    //   '2022-05-24T16:00:00.000Z': 3.819,
    //   '2022-06-23T16:00:00.000Z': 3.819,
    //   '2022-07-23T16:00:00.000Z': 3.819,
    //   '2022-08-22T16:00:00.000Z': 3.819,
    //   '2022-09-21T16:00:00.000Z': 3.819,
    //   '2022-10-21T16:00:00.000Z': 3.819,
    //   '2022-11-20T16:00:00.000Z': 3.819,
    //   '2022-12-20T16:00:00.000Z': 3.819,
    //   '2023-01-19T16:00:00.000Z': 3.819,
    //   '2023-02-18T16:00:00.000Z': 3.819,
    //   '2023-03-20T16:00:00.000Z': 3.819,
    //   '2023-04-19T16:00:00.000Z': 3.819,
    //   '2023-05-19T16:00:00.000Z': 3.819,
    //   '2023-06-18T16:00:00.000Z': 3.819,
    //   '2023-07-18T16:00:00.000Z': 3.819,
    //   '2023-08-17T16:00:00.000Z': 3.819,
    //   '2023-09-16T16:00:00.000Z': 3.819,
    //   '2023-10-16T16:00:00.000Z': 3.819,
    //   '2023-11-15T16:00:00.000Z': 3.819,
    //   '2023-12-15T16:00:00.000Z': 3.819,
    //   '2024-01-14T16:00:00.000Z': 3.819,
    //   '2024-02-13T16:00:00.000Z': 3.819,
    // },
  },
};
