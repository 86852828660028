import img from 'pools/images/fitburn.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const fitburn: PoolConfig = {
  // id is used in URL
  id: 'fitburn',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x398D8F87D003249fC748bB6297e1e33f27cE94C4',
    tokensForSale: toWeiBN('2857142'),
    startDate: '2023-05-12T14:00:00.000Z',
    rate: 28.571,
    durationHours: 23,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2857141.999999999999998083'),
    raised: toWeiBN('100001.4700220503307549'),
    baseAllocation: toWeiBN('157.227453399537641089'),
    status: 5,
    registerDate: '2023-05-10T14:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 0.3333333333333333,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    totalWeights: 18172030,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    claimerVersion: 'v4',
    claimerAddress: '0x599b350E8C9C99bFb6613D898f6499f8AdCc7de1',
    vesting: '50% unlocked at TGE, then 16,67% every month for 3 months',
    schedule: {
      '2023-05-15T08:30:00.000Z': 50,
      '2023-06-15T08:30:00.000Z': 16.67,
      '2023-07-15T08:30:00.000Z': 16.67,
      '2023-08-15T08:30:00.000Z': 16.66,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x859C940F080B197659b3EfFc804fD622Df66f0a1',
    name: 'FitBurn AI',
    symbol: 'CAL',
    imageSrc: img.src,
    // Format: "2023-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2023-05-15T08:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Will be listed on Gate, Bitget, MEXC, PancakeSwap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(24_590_000),
    totalSupply: toWeiBN(2_000_000_000),
    coingeckoId: 'calorie',
    coinmarketcapId: 'fitburn',
  },
  social: {
    website: 'https://www.fitburn.ai/',
    twitter: 'https://twitter.com/fitburn_ai',
    telegram: 'https://t.me/fitburngroup',
    telegramAnno: 'https://t.me/fitburnai',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Fitburn is the world's first AI-powered burn-to-earn fitness app, which is
      revolutionizing lifestyle & health. This innovative fitness app combines
      cutting-edge AI mechanics with NFT rewards to motivate users to reach
      their fitness goals. Earn rewards for exercising and join a community of
      like-minded fitness enthusiasts.
    </p>
  ),
};
