import img from 'pools/images/govworld.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const govworld: PoolConfig = {
  // id is used in URL
  id: 'govworld',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xB2b70d9CC87834176D00634102C6e8164Dca3508',
    tokensForSale: toWeiBN('1610317'),
    startDate: '2021-10-05T12:00:00.000Z',
    rate: 16.10317,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1555,
    tokensSold: toWeiBN('1610316.999999999999999261'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('250'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-09-27T18:00:00.000Z',
    registerDuration: 185,
    fcfsDuration: 1,
    totalWeights: 6815,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xcc7a91413769891de2e9ebbfc96d2eb1874b5760',
    name: 'GOV',
    symbol: 'GOV',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(1_500_000),
    totalSupply: toWeiBN(100_000_000),
    extraChains: ['ETH'],
    listingTime: '2022-01-05T14:30:00.000Z',
    coingeckoId: 'govworld',
    athXManual: 7.05,
    coinmarketcapId: 'govworld',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="GOV"
      address="0xcc7a91413769891de2e9ebbfc96d2eb1874b5760"
      pairAddress="0xeb06bceb8c8dde32682518070fe9b0a0bd3f892d"
    />
  ),
  distribution: {
    type: 'airdrop_them',
    vesting: (
      <>
        <b>Private pool:</b> 5% at TGE, 5% after 72h, then 15% monthly <br />
        <b>Strategic pool:</b> 10% after 7 days, 15% monthly
      </>
    ),
    schedule: {
      '2022-01-05T14:30:00.000Z': 2.3,
      '2022-01-08T14:30:00.000Z': 2.3,
      '2022-01-12T14:30:00.000Z': 5.4,
      '2022-02-08T15:00:00.000Z': 6.9, // private
      '2022-02-12T15:00:00.000Z': 8.1, // strategic
      '2022-03-08T15:00:00.000Z': 6.9,
      '2022-03-12T15:00:00.000Z': 8.1,
      '2022-04-08T15:00:00.000Z': 6.9,
      '2022-04-12T15:00:00.000Z': 8.1,
      '2022-05-08T15:00:00.000Z': 6.9,
      '2022-05-12T15:00:00.000Z': 8.1,
      '2022-06-08T15:00:00.000Z': 6.9,
      '2022-06-12T15:00:00.000Z': 8.1,
      '2022-07-08T15:00:00.000Z': 6.9,
      '2022-07-12T15:00:00.000Z': 8.1,
    },
  },
  social: {
    website: 'https://www.govworld.io/',
    twitter: 'https://twitter.com/govworld1',
    telegram: 'https://t.me/GovWorld_Community',
    telegramAnno: 'https://t.me/GovWorld_Announcements',
    medium: 'https://medium.com/govworld1',
    github: '',
    whitepaper:
      'https://drive.google.com/file/d/1RAo81U1QRwmUNtFCSeZY81QbRBJbHF-C/view',
    announcement:
      'https://trustpad.medium.com/govworld-is-launching-on-trustpad-fb0cca30057f',
  },
  descriptionShort: (
    <p>
      GovWorld is the first DeFi ecosystem of its kind, leveraging the power of
      the most customizable P2P, cross-chain lending protocol on the blockchain.
      Able to collateralize any approved altcoin or NFT for public or private
      loans of USDC, USDT, or DAI.
    </p>
  ),
  description: (
    <p>
      GovWorld is the first DeFi ecosystem of its kind, leveraging the power of
      the most customizable P2P, cross-chain lending protocol on the blockchain.
      Able to collateralize any approved altcoin or NFT for public or private
      loans of USDC, USDT, or DAI.
      <br />
      <br />
      This pool is a combination of $50k strategic ($0.0575) allocation and $50k
      private ($0.0675) allocation.
    </p>
  ),
};
