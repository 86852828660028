import { useEffect, useState } from 'react';

const loadJSON = (key) => {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }
  const value = key && JSON.parse(localStorage.getItem(key));
  return value === null ? true : value;
};

const saveJSON = (key, data) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

export function useBarPersistentDisplayState(key: string) {
  const [display, setDisplay] = useState(undefined);

  useEffect(() => {
    const display = loadJSON(key);
    if (display !== undefined) {
      setDisplay(display);
    }
  }, [key]);

  useEffect(() => {
    if (display !== undefined) {
      saveJSON(key, display);
    }
  }, [key, display]);

  return [display, setDisplay];
}
