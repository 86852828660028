import img from 'pools/images/rod.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const rod: PoolConfig = {
  // id is used in URL
  id: 'rod',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xfDaf36abBEdF4D9E8b1b9e2047eC293168f281d6',
    tokensForSale: toWeiBN('2777777'),
    startDate: '2021-12-29T11:00:00.000Z',
    rate: 27.777,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2777776.999999999999997779'),
    raised: toWeiBN('100002.7720776181733088'),
    baseAllocation: toWeiBN('381.839386485313268702'),
    status: 5,
    levelsOpenAll: false,

    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-27T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6973,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x92da433da84d58dfe2aade1943349e491cbd6820',
    name: 'Rise of Defenders',
    symbol: 'RDR',
    imageSrc: img.src,
    listingTime: '2022-01-05T14:30:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(28_729_167),
    totalSupply: toWeiBN(500_000_000),
    coingeckoId: 'rise-of-defenders',
    coinmarketcapId: 'rise-of-defenders',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="RDR"
      address="0x92da433da84d58dfe2aade1943349e491cbd6820"
      pairAddress="0x92da433da84d58dfe2aade1943349e491cbd6820"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '33,33% at TGE, then linearly for 2 months',
    claimerAddress: '0x8B005D477dbC878a9fAe6dC164C45359dAa3dc40',
    claimerVersion: 'v2',
    schedule: {
      '2022-01-05T14:30:00.000Z': 33.33,
      '2022-02-05T14:30:00.000Z': 33.33,
      '2022-03-05T14:30:00.000Z': 33.34,
    },
  },
  social: {
    website: 'https://risedefenders.io/',
    twitter: 'https://twitter.com/DefendersRise',
    telegram: 'https://t.me/RiseDefendersChat',
    telegramAnno: 'https://t.me/RiseDefendersAnn',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Rise of Defenders want to build a Metaverse NFT Game world. Here players
      can build bases, recruit troops and create a kingdom for themselves.
      Players can also attack, compete with other players with their powerful
      army.
    </p>
  ),
};
