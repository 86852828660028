import { getTokenPriceUsd } from '~features/pools/utils';

import { Token } from '../types';
import {
  bnbImg,
  busdImg,
  ledgityImg,
  nftpadImg,
  tpadImg,
  tpadv1Img,
  usdcImg,
  usdtImg,
} from './images';

export const BUSD: Token = {
  network: 'BSC',
  address:
    process.env.NEXT_PUBLIC_NETWORK === 'live'
      ? '0xe9e7cea3dedca5984780bafc599bd69add087d56'
      : '0x77739ac905a0defbab5129dcb680a8e32cb4fb7d',
  name: 'BUSD Token',
  symbol: 'BUSD',
  imageSrc: busdImg.src,
  decimals: 18,
  price: { usd: 1 },
  priceRefresher: () => Promise.resolve(1),
};
export const USDC: Token = {
  network: 'BSC',
  address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  name: 'USD Coin',
  symbol: 'USDC',
  imageSrc: usdcImg.src,
  decimals: 18,
  price: { usd: 1 },
  priceRefresher: () => Promise.resolve(1),
};
export const USDT: Token = {
  network: 'BSC',
  address: '0x55d398326f99059ff775485246999027b3197955',
  name: 'Tether',
  symbol: 'USDT',
  imageSrc: usdtImg.src,
  decimals: 18,
  price: { usd: 1 },
  priceRefresher: () => Promise.resolve(1),
};
export const BNB: Token = {
  network: 'BSC',
  address: '',
  name: 'BNB',
  symbol: 'BNB',
  imageSrc: bnbImg.src,
  decimals: 18,
  price: { usd: 560 },
  priceRefresher: () => getTokenPriceUsd(),
};
export const TPAD: Token = {
  network: 'BSC',
  address: process.env.NEXT_PUBLIC_TOKEN_ADDRESS,
  name: 'TrustPad',
  symbol: 'TPAD',
  imageSrc: tpadImg.src,
  decimals: 18,
  price: { usd: 0.3 },
  pairAddress: process.env.NEXT_PUBLIC_PAIR_ADDRESS,
  priceRefresher: () => getTokenPriceUsd('trustpad-2-0'),
};
export const TPADV1: Token = {
  network: 'BSC',
  address: '0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C',
  name: 'TrustPad',
  symbol: 'TPAD',
  imageSrc: tpadv1Img.src,
  decimals: 9,
  price: { usd: 0.3 },
  pairAddress: '0x99b981f5913bc1305550ecac8985bc0668fa08ce',
};

export const NFTPAD: Token = {
  network: 'BSC',
  address: '0x4a72AF9609d22Bf2fF227AEC333c7d0860f3dB36',
  name: 'NFTPad.fi',
  symbol: 'NFTPAD',
  imageSrc: nftpadImg.src,
  decimals: 18,
  price: { usd: 0.18 },
  pairAddress: '0x4a24d6f25f72226ac81d9dc3d38f73541c8b764e',
  priceRefresher: () => getTokenPriceUsd('nftpad'),
};

export const LEDGITY: Token = {
  network: 'BSC',
  address: '0x0cbe5c4f318035b866aacfaf7d018fb4c5f920f3',
  name: 'Ledgity',
  symbol: 'LTY',
  imageSrc: ledgityImg.src,
  decimals: 18,
  price: { usd: 0.006 },
  pairAddress: '0x95f69E1E32883a6772FB80810F8F7A76Ff61ab52',
  priceRefresher: () => getTokenPriceUsd('ledgity'),
};
