import img from 'pools/images/daolaunch.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const daolaunch: PoolConfig = {
  // id is used in URL
  id: 'daolaunch',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x54B5506982B53323b9d536c76583e0f4D08CC612',
    tokensForSale: toWeiBN('100000'),
    startDate: '2021-11-17T12:00:00.000Z',
    rate: 1,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('99999.999999999999999956'),
    raised: toWeiBN('99999.999999999999999956'),
    baseAllocation: toWeiBN('14.076660228792155697'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-11-15T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6740,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 80,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x53e4b7aa6caccb9576548be3259e62de4ddd4417',
    name: 'DAOLaunch',
    symbol: 'DAL',
    imageSrc: img.src,
    listingTime: '2021-12-03T12:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(283_014),
    totalSupply: toWeiBN(6_000_000),
    coingeckoId: 'daolaunch',
  },
  finishedInfo: (
    <>
      <PancakeChartFinishedInfo
        symbol="DAL"
        address="0x53e4b7aa6caccb9576548be3259e62de4ddd4417"
        pairAddress="0x85b3bd40853b42ae24d295cd90a234f01b17a3b6"
      />
      <a href="https://www.gate.io/trade/DAL_USDT">Trade on Gate.io.</a>
    </>
  ),
  distribution: {
    type: 'claim_us',
    vesting: '25% TGE, 3 months vested',
    claimerAddress: '0x21F067a817F0DB6793353b1057E0CB41637992B8',
    claimerVersion: 'v2',
    schedule: {
      '2021-12-03T12:00:00.000Z': 25,
      '2022-01-03T12:00:00.000Z': 25,
      '2022-02-03T12:00:00.000Z': 25,
      '2022-03-03T12:00:00.000Z': 25,
    },
  },
  social: {
    website: 'https://daolaunch.net/',
    twitter: 'https://twitter.com/DAOLaunch',
    telegram: 'https://t.me/daolaunchcommunity',
    telegramAnno: 'https://t.me/DAOLaunchOfficial',
    medium: 'https://daolaunch.net/article/',
    github: '',
    whitepaper: 'https://docs.daolaunch.net',
    announcement: '',
  },
  description: (
    <p>
      DAOLaunch is to create an open and inclusive competitive environment for
      startup investment - the Decentralized Venture Capital. Any investors able
      to negotiate preferential investment conditions depending on their
      investment performance recorded on the blockchain. DAOLaunch Investors can
      brand themselves as Decentralized Venture Capitalists and much like
      traditional VCs todays.
    </p>
  ),
};
