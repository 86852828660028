import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/animalialogo.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const animaliaprivate: PoolConfig = {
  // id is used in URL
  id: 'animaliaprivate',
  // public, seed, private, strategic
  access: 'private',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0xb11e4b0E676f9cF878D876550f3f8699617fC42f',
    tokensForSale: toWeiBN(625_000),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-01-15T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 12.5,
    durationHours: 23,
    isProtected: false,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(625_000),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_them',
    network: 'ETH',
    vesting: '10% TGE, 1 month cliff, then linear vesting over 12 months',
    claimUrl: 'https://animalia.games/account/anim',
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0xfbcb5cbedeebcc55dcd136d34db1daaf74cf67e8',
    name: 'Animalia',
    symbol: 'ANIM',
    imageSrc: img.src,
    // Format: "2023-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-02-06T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Uniswap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(7_500_000),
    totalSupply: toWeiBN(108_700_000),
  },
  social: {
    website: 'https://animalia.games/',
    twitter: 'https://twitter.com/Animalia_games',
    telegram: 'https://t.me/Animalia_ANIM',
    telegramAnno: 'https://t.me/Animalia_ANIM_Channel',
    medium: '',
    github: '',
    whitepaper: 'https://whitepaper.animalia.games/',
    announcement: '',
  },
  description: (
    <p>
      Animalia is an independent free-to-play online NFT trading card game
      featuring crypto-inspired meme creatures and gemstones. Powered by the ETH
      & BNB Chain, Animalia gives you complete ownership over your in-game
      collectibles. Collect rare cards, create your own NFTs, build your deck,
      battle with other players, and sell cards to other traders.
    </p>
  ),
};
