import {
  PoolConfig,
  SaleStatus,
  useIsTokenListed,
  usePriceConverters,
} from '@trustpad/launchpad';
import { isNFTPool } from '@trustpad/launchpad/dist/pool/utils';

import { tpadLogoImg, unknownTokenImg } from 'assets';
import Link from 'next/link';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { SocialLinks } from '~common/components/SocialLinks';
import { usePriceCoinGecko } from '~common/hooks/usePriceCoinGecko';
import { useSaleContributionData } from '~common/hooks/useSaleContributionData';
import { DateTimeFormat } from '~config/constants';
import {
  usePool,
  useSaleBaseAllocation,
  useSaleTimeline,
} from '~features/pools';
import { AccessBadge } from '~features/pools/components/AccessBadge';
import { SaleProgressBar } from '~features/pools/components/SaleProgressBar';
import { SaleRelativeTime } from '~features/pools/components/SaleRelativeTime';
import { fNum } from '~utils';

import { getHardCap, getPoolLinkPath } from '../../utils';
import { BlackLevelBadge } from '../BlackLevelBadge';
import { KycOrRegisterButton } from '../KYCVerifyButton/KycOrRegisterButton';
import { StatusBadge } from '../StatusBadge';
import { ChainIcon } from './ChainIcon';
import { PoolRate } from './PoolRate';

function getTokenSymbol(pool: PoolConfig) {
  const { token, fundToken } = pool;
  if (isNFTPool(pool)) {
    return 'INO';
  }
  if (!token.symbol) {
    return 'TBA';
  }
  if (fundToken.symbol !== 'BUSD') {
    return `$${token.symbol} / ${fundToken.symbol}`;
  }
  return `$${token.symbol}`;
}

export function PoolCard({ showNextClaim }: { showNextClaim?: boolean }) {
  const { pool, isSoldOut, isFinished } = usePool();
  const { sale, token, fundToken } = pool;
  const { start } = useSaleTimeline();
  const { baseCurrencyAllocation, isApprox } = useSaleBaseAllocation();
  const { tokensToCurrency } = usePriceConverters();
  const prices = usePriceCoinGecko(pool.token.coingeckoId);
  const { isListed, formatListingTime } = useIsTokenListed();
  const { contribution } = useSaleContributionData(pool.id);

  let athX;
  if (token.athXManual) {
    athX = token.athXManual;
  } else if (prices && sale.rate) {
    athX = prices.athPrice / (1 / sale.rate);
  }

  const showBaseAlloc =
    !isNFTPool(pool) &&
    // pool.access !== 'whitelist' &&
    sale.status >= SaleStatus.register &&
    baseCurrencyAllocation.gtn(0);
  const showAth = isFinished && athX;

  return (
    <Card className="hover:shadow-xl hover:bg-opacity-20 hover:bg-gray-800 bg-dark2 border-mainDark h-full hover:-translate-y-2 transform transition relative overflow-hidden">
      <div className="absolute z-0 min-w-full transform scale-150 -bottom-16 -right-1/4 opacity-30">
        <img
          src={tpadLogoImg.src}
          className="opacity-5 w-full h-full"
          alt="Bg"
        />
      </div>
      <Link
        href={getPoolLinkPath(pool)}
        className="absolute top-0 left-0 h-full w-full z-10"></Link>

      <Card.Body className="space-y-6 flex flex-col justify-between">
        <div className="space-y-3">
          <div className="flex justify-between gap-2">
            <div>
              <h2 className="text-3xl font-semibold font-kgqj">{token.name}</h2>

              <div className="flex items-center space-x-3 flex-wrap">
                {false && <BlackLevelBadge />}

                <StatusBadge pool={pool} isListed={isListed} />

                <AccessBadge pool={pool} />
              </div>
            </div>

            <div className="relative">
              <div className="h-24 w-24 overflow-hidden rounded-full border-2 border-mainDark flex items-center justify-center">
                <img
                  className="w-full"
                  src={token.imageSrc || unknownTokenImg.src}
                  alt={token.name}
                />

                <div className="absolute bg-dark1 rounded-full -bottom-1 -right-1">
                  <ChainIcon chain={token.chain} />
                </div>
                <div className="absolute top-[-5px] right-0">
                  {((sale.status >= SaleStatus.register &&
                    sale.status <= SaleStatus.open) ||
                    pool.id === 'artemisvision') && (
                    <div className="text-2xl">🔥</div>
                  )}

                  {contribution && (
                    <FaCheckCircle className="text-2xl text-green-500" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="pb-3">
            <SocialLinks size="text-2xl" social={pool.social} />
          </div>

          <div
            className="overflow-hidden h-[90px]"
            style={{
              maskImage:
                'linear-gradient(to bottom, black 50%, transparent 100%)',
              WebkitMaskImage:
                'linear-gradient(to bottom, black 50%, transparent 100%)',
            }}>
            <div className="text-sm opacity-70">
              {pool.descriptionShort || pool.description}
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <span>Total Raise</span>
            <span className="text-[1.5rem] -my-[1rem] text-main">
              {getHardCap(sale, fundToken)}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <span>Starts</span>
            <span className="text-main">
              {start ? `${start.format(DateTimeFormat.noYear)}` : 'TBA'}
            </span>
          </div>

          {!isNFTPool(pool) && !(isFinished && !sale.rate) && (
            <div className="flex items-center justify-between">
              <span>Price</span>
              <PoolRate pool={pool} />
            </div>
          )}

          {sale.minBaseAllocation?.gtn(0) && (
            <div>
              <div className="text-sm mb-2">
                Min 1x allocation:{' '}
                <span className="text-main">
                  ${fNum(tokensToCurrency(sale.minBaseAllocation))}
                </span>
              </div>
              <div className="text-sm">⚠️ FCFS registration ⚠️</div>
            </div>
          )}

          <div className="mb-6 pt-3">
            <SaleProgressBar
              topLeft={<SaleRelativeTime isSoldOut={isSoldOut} />}
              hideDetails={sale.status === SaleStatus.finished}
            />
          </div>

          <div className="flex justify-between gap-6">
            {showBaseAlloc && (
              <div>
                <p className="text-sm mb-0">1x {isApprox && '(approx) '}=</p>
                <p className="text-main">${fNum(baseCurrencyAllocation, 2)}</p>
              </div>
            )}

            {!isListed && (
              <div className="text-right ml-auto">
                <p className="text-sm mb-0">Listing Time</p>
                <div className="text-main">{formatListingTime()}</div>
              </div>
            )}

            {showAth && (
              <div className="text-right">
                <p className="text-sm mb-0">ATH X</p>
                <div
                  className={`text ${
                    athX >= 1
                      ? 'bg-opacity-40 text-green-500'
                      : 'bg-opacity-40 text-red-500'
                  } `}>
                  x{athX.toFixed(2)}
                </div>
              </div>
            )}
          </div>
        </div>

        <KycOrRegisterButton pool={pool} center />
      </Card.Body>
    </Card>
  );
}
