import img from 'pools/images/husl.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const husl: PoolConfig = {
  // id is used in URL
  id: 'husl',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xe0ce83feb21010ad047770a95b1303185f9e6284',
    tokensForSale: toWeiBN('400000'),
    startDate: '2021-09-23T12:00:00.000Z',
    rate: 4,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1423,
    tokensSold: toWeiBN('399999.0374276535727916'),
    raised: toWeiBN('99999.7593569133931979'),
    baseAllocation: toWeiBN('70.717614165890027958'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-09-18T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 5365,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'ETH',
    address: '0xa2881f7f441267042f9778ffa0d4f834693426be',
    name: 'The HUSL',
    symbol: 'HUSL',
    imageSrc: img.src,
    decimals: 18,
    listingTime: '2021-09-23T21:00:00.000Z',
    listingRate: 4,
    initialSupply: toWeiBN(1_340_000),
    totalSupply: toWeiBN(100_000_000),
    coingeckoId: 'the-husl',
    coinmarketcapId: 'the-husl',
  },
  distribution: {
    type: 'claim_us',
    vesting: '10% at TGE, 10% on day 30, then 20% every 30 days',
    claimerVersion: 'v1',
    claimerAddress: '0x091aFF21B1fb47D13A425F24eB24D3b00d5A36C0',
    network: 'ETH',
    schedule: {
      '2021-09-23T03:00:00.000Z': 10,
      '2021-10-28T03:00:00.000Z': 10,
      '2021-12-02T03:00:00.000Z': 20,
      '2021-01-06T03:00:00.000Z': 20,
      '2022-02-03T03:00:00.000Z': 20,
      '2022-03-03T03:00:00.000Z': 20,
    },
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="HUSL"
      address="0xa2881f7f441267042f9778ffa0d4f834693426be"
      pairAddress="0x98d266c51fc3c0a0b5a285c481d40b689d18e6f1"
    />
  ),
  social: {
    website: 'https://thehusl.io/',
    twitter: 'https://twitter.com/the_husl',
    telegram: 'https://t.me/TheHUSL',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement:
      'https://trustpad.medium.com/the-husl-is-launching-on-trustpad-a51b314f1c93',
  },
  descriptionShort: (
    <p>
      The HUSL app enables musicians to create, curate, and sell NFT collectible
      items directly to their fans. The enterprise-grade platform helps to
      empower creators to connect with their fans worldwide using blockchain
      technology.
    </p>
  ),
  description: (
    <>
      <p>
        The HUSL app enables musicians to create, curate, and sell NFT
        collectible items directly to their fans. The enterprise-grade platform
        helps to empower creators to connect with their fans worldwide using
        blockchain technology.
      </p>
      <p>
        The HUSL’s mission is to inspire artists to create and distribute their
        best work by combining Music & Digital Art with decentralised finance.
        They believe that fans should empower their favourite artists by owning
        music and participating in unique moments in history. Their NFT market
        is built by musicians for musicians.
      </p>
      <p>
        $HUSL token owners hold votes in the future of the music industry, hold
        stakes in the careers of emerging and established artists, and open
        doors for fans to connect with the artists they love in unprecedented
        ways.
      </p>
    </>
  ),
};
