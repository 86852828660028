import img from 'pools/images/cryptoxpress.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const cryptoxpress: PoolConfig = {
  // id is used in URL
  id: 'cryptoxpress',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x9C4ba370d2Fcb1108255779A5d26d9cF368d8F50',
    tokensForSale: toWeiBN('71429'),
    startDate: '2021-11-12T12:00:00.000Z',
    rate: 0.714286,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('71428.999999999999999993'),
    raised: toWeiBN('100000.559999776000090568'),
    baseAllocation: toWeiBN('11.961504760285617137'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('15'),
    },
    registerDate: '2021-11-10T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 5884,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xaa9826732f3a4973ff8b384b3f4e3c70c2984651',
    name: 'XPRESS Token',
    symbol: 'XPRESS',
    imageSrc: img.src,
    decimals: 18,
    listingTime: '2021-11-16T04:00:00.000Z',
    listingRate: 0,
    initialSupply: toWeiBN(91_000),
    totalSupply: toWeiBN(10_000_000),
    coingeckoId: 'cryptoexpress',
    coinmarketcapId: 'cryptoxpress',
  },
  distribution: {
    type: 'claim_us',
    vesting: '25% at TGE, 25% bi-weekly vesting',
    claimerAddress: '0x52174c1b5c3274f2192e46D5c5A99297530c91B4',
    claimerVersion: 'v2',
    schedule: {
      '2021-11-16T04:00:00.000Z': 25,
      '2021-12-01T04:00:00.000Z': 25,
      '2022-12-16T04:00:00.000Z': 25,
      '2022-12-31T04:00:00.000Z': 25,
    },
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="XPRESS"
      address="0xaa9826732f3a4973ff8b384b3f4e3c70c2984651"
      pairAddress="0xfa6ce62fcb2ad64166b3f3b6781b6b4be29a4b0a"
    />
  ),
  social: {
    website: 'https://cryptoxpress.com/',
    twitter: 'https://twitter.com/crypto_xpress',
    telegram: 'https://t.me/cryptoxpress_chat',
    telegramAnno: 'https://t.me/crypto_xpress',
    medium: 'https://medium.com/@crypto_xpress',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      CryptoXpress helps simpify crypto and provides the best tools to tackle
      the cryptocurrency ecosystem.
    </p>
  ),
};
