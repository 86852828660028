import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/himoworld.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const himoworld: PoolConfig = {
  // id is used in URL
  id: 'himoworld',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x586A6970c54266644fe9BbA2FDFB151bc59402Bc',
    tokensForSale: toWeiBN('2500000'),
    startDate: '2022-01-22T11:00:00.000Z',
    rate: 25,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    refunded: true,
    participants: 0,
    tokensSold: toWeiBN('2500000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('331.608966706459742671'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-20T11:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7595,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x469acf8e1f29c1b5db99394582464fad45a1fc6f',
    name: 'Himo World',
    symbol: 'HIMO',
    imageSrc: img.src,
    listingTime: '2022-01-24T12:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(11_791_667),
    totalSupply: toWeiBN(500_000_000),
    coingeckoId: 'himo-world',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="HIMO"
      address="0x469acf8e1f29c1b5db99394582464fad45a1fc6f"
      pairAddress="0xae9969a099a079b28e7c247a709a754d3f9b31e0"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '30% at TGE, vesting for 7 months',
  },
  social: {
    website: 'https://himo.world/',
    twitter: 'https://twitter.com/himoworldglobal',
    telegram: 'https://t.me/HimoWorldOfficial',
    telegramAnno: 'https://t.me/HimoWorldOfficialANN',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Himo World is an NFT game with a Play-to-Earn feature, in which players
      can engage in battles with others, build their team to their favourite to
      explore the universe, or choose to become observers of the war. This game
      is also providing a true Free-to-Play experience, which a lot of other NFT
      games in the market is lacking at the moment. Setting up in a distant
      realm, the player just awoke his summoning power, and realize they now are
      in the middle of the war between realms, where they fight against each
      other in a battle of strengths and wits.
    </p>
  ),
};
