import { tpadLogoImg } from 'assets';
import React from 'react';

export function PatternImage({ side = 'left', startWith = 'bottom-0' }) {
  const positions =
    side === 'left'
      ? [`left-16`, `left-32`, `left-48`]
      : [`right-16`, `right-32`, `right-48`];
  const secondWith = startWith === 'bottom-0' ? 'top-0' : 'bottom-0';

  return (
    <>
      {positions.map((pos) => (
        <img
          key={pos}
          src={tpadLogoImg.src}
          className={`opacity-10 absolute ${
            positions.indexOf(pos) % 2 == 0 ? startWith : secondWith
          } ${pos}`}
          alt="TrustPad Logo"
          width={100}
          height={100}
        />
      ))}
    </>
  );
}
