import { netvrkImg, transportImgs } from 'pools/finished/netvrk/images';
import React from 'react';

import { BUSD } from '~config/tokens';
import { DescriptionImg } from '~features/pools/components/PoolDescription/DescriptionImg';
import { NftPoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const netvrkTransport: NftPoolConfig = {
  // id is used in URL
  id: 'netvrk-transport',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  isNft: true,
  sale: {
    address: '0xe69ea14Aa98bF933A9818F326Ca79639b1656fF6',
    tokensForSale: toWeiBN('355'),
    startDate: '2021-10-28T12:00:00.000Z',
    rate: 0,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    participants: 317,
    tokensSold: toWeiBN('0.000000000000000319'),
    raised: toWeiBN('620000'),
    baseAllocation: toWeiBN('30000'),
    status: 5,
    registerDate: '2021-10-27T13:00:00.000Z',
    registerDuration: 17,
    fcfsDuration: 24,
    totalWeights: 3543,
    isPublic: false,
    levelsOpenAll: true,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    inventory: [
      {
        id: 'motorcycle',
        name: 'XS Motorcycle',
        image: transportImgs.motorcycle.src,
        supply: 150,
        totalSupply: 0,
        price: toWeiBN(200),
      },
      {
        id: 'roadster',
        name: 'XS Roadster',
        image: transportImgs.roadster.src,
        supply: 75,
        totalSupply: 0,
        price: toWeiBN(1000),
      },
      {
        id: 'truck',
        name: 'XRS Off-Road Truck',
        image: transportImgs.truck.src,
        supply: 60,
        totalSupply: 0,
        price: toWeiBN(2000),
      },
      {
        id: 'falcon',
        name: 'X Falcon',
        image: transportImgs.falcon.src,
        supply: 35,
        totalSupply: 0,
        price: toWeiBN(10000),
      },
      {
        id: 'yacht',
        name: 'XRS Bullet Yacht',
        image: transportImgs.yacht.src,
        supply: 35,
        totalSupply: 0,
        price: toWeiBN(15000),
      },
    ],
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '',
    name: 'Netvrk Transport',
    symbol: '',
    imageSrc: netvrkImg.src,
    decimals: 18,
    listingTime: '2022-01-20T12:00:00.000Z',
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'mint_them',
    vesting:
      'NFTs will be available for minting on Netvrk.co on Official Minting Date.',
  },
  social: {
    twitter: 'https://twitter.com/netvrk1',
    medium: 'https://netvrk.medium.com',
    telegram: 'https://t.me/NetVRk_Official',
    website: 'https://netvrk.co',
    announcement:
      'https://netvrk.medium.com/netvrk-presents-transport-nft-sale-53125ec42ac2',
  },
  descriptionShort: (
    <p>
      The Netvrk team is super honored to announce our first ever Netvrk
      Transport NFTs in collaboration with famous designer, John Park. Known for
      his masterpiece designs for some of the biggest names in the entertainment
      industry, including James Cameron and George Lucas.
    </p>
  ),
  description: (
    <>
      <p>
        The sale has 7 rounds:{' '}
        <a href="https://t.me/TrustPadAnn/848">
          read more about the Transport Sale process
        </a>
      </p>
      <DescriptionImg src={transportImgs.jp1.src} />
      <p>
        The Netvrk team is super honored to announce our first ever Netvrk
        Transport NFTs in collaboration with famous designer, John Park. Known
        for his masterpiece designs for some of the biggest names in the
        entertainment industry, including James Cameron and George Lucas.
      </p>
      <p>
        That being said, these transports aren’t just visual pieces of art, they
        come with utility in Netvrk as well. Owning these transports allows you
        to traverse the metaverse in style: by land, sea, and air. Owners will
        have the ability to teleport all onboard passengers to applicable
        locations across the map. Each transport will also enable the holder to
        get a higher share of staking rewards, as well as a share of Netvrk
        Reserve distributions.
      </p>
      <DescriptionImg src={transportImgs.jp2.src} />
      <DescriptionImg src={transportImgs.jp3.src} />
    </>
  ),
};
