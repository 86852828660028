import { Chain } from '~types';

export type ChartProvider = 'coingecko' | 'dextools' | 'bogged' | 'poocoin';

export function generateUrl({
  provider,
  chain,
  address,
  pairAddress,
}: {
  provider: ChartProvider;
  chain: Chain;
  address: string;
  pairAddress: string;
}) {
  if (!mapping[provider]) {
    throw new Error(`No URL mapping specified for ${provider}`);
  }
  const url = mapping[provider]({ chain, address, pairAddress });
  if (!url) {
    throw new Error(`No URL mapping specified for ${provider} and ${chain}`);
  }

  return url;
}

type UrlGenerator = ({
  chain,
  address,
  pairAddress,
}: {
  chain: Chain;
  address: string;
  pairAddress: string;
}) => string | undefined;

const mapping: Record<ChartProvider, UrlGenerator> = {
  dextools: ({ chain, address, pairAddress }) => {
    const baseUrl = {
      BSC: 'https://www.dextools.io/app/bnb/pair-explorer/',
      ETH: 'https://www.dextools.io/app/uniswap/pair-explorer/',
      POLY: 'https://www.dextools.io/app/polygon/pair-explorer/',
      FTM: 'https://www.dextools.io/app/fantom/pair-explorer/',
      ARB: 'https://www.dextools.io/app/arbitrum/pair-explorer/',
    }[chain];

    return `${baseUrl}${pairAddress || address}`;
  },
  poocoin: ({ address }) => `https://poocoin.app/tokens/${address}`,
  bogged: ({ address }) => `https://charts.bogged.finance/${address}`,
  coingecko: ({ chain, address, pairAddress }) => {
    const chainUrl = {
      ETH: 'eth',
      BSC: 'bsc',
      POLY: 'polygon_pos',
      FTM: 'ftm',
      SOL: 'solana',
      TERRA: 'terra',
      ARB: 'arbitrum',
    };
    const segment = pairAddress ? `pools/${pairAddress}` : `tokens/${address}`;

    return `https://www.geckoterminal.com/${chainUrl[chain]}/${segment}`;
  },
};
