import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/syncity.png';
import mImg from 'pools/images/syncitybig.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { Desc } from '~features/pools/components/PoolDescription';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const syncityCommon = {
  kyc: true,
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0xbc6e06778708177a18210181b073da747c88490a',
    name: 'SYN CITY',
    symbol: 'SYNR',
    imageSrc: img.src,
    listingTime: '2022-01-25T17:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(25_482_046),
    totalSupply: toWeiBN(10_000_000_000),
    coingeckoId: 'syndicate-2',
    coinmarketcapId: 'syn-city',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="SYNR"
      address="0xbc6e06778708177a18210181b073da747c88490a"
      pairAddress="0xd14782a6f2512495584a252ffca49b17486a08d3"
    />
  ),
  descriptionShort: (
    <Desc.Text>
      SYN CITY is the first ever Mafia Metaverse. The Mafia is simply nothing
      without its members, and for that reason, we are introducing the
      revolutionary MAFIA as a DAO (MaaD) system. Players can grind in daily
      events, PvE, PvP, and Syndicate events such as cross-chain Mafia wars.
      Join a syndicate and run businesses, taxing your underlings and paying
      tribute to your bosses.
    </Desc.Text>
  ),
  description: (
    <>
      <Desc.Text>
        SYN CITY is the first ever Mafia Metaverse. The Mafia is simply nothing
        without its members, and for that reason, we are introducing the
        revolutionary MAFIA as a DAO (MaaD) system. Players can grind in daily
        events, PvE, PvP, and Syndicate events such as cross-chain Mafia wars.
        Join a syndicate and run businesses, taxing your underlings and paying
        tribute to your bosses.
      </Desc.Text>
      <Desc.Text>
        $8 million invested by Angels such as Justin Kan (Co-founder of Twitch),
        Do Kwon (Co-founder of Terra), Kieran Warwick (Co-founder of Illuvium),
        Santiago R Santos (Ex-Partner of Parafi Capital), leading VCs including
        Animoca Brands, Spartan Group and HOF and strategic partners including
        Overwolf, Merit Circle, YGG, GuildFi and many more.
      </Desc.Text>
      <Desc.Img src={mImg} />
    </>
  ),
  social: {
    website: 'https://mob.land/',
    twitter: 'https://twitter.com/syncityhq',
    telegram: 'https://t.me/SynCityHQ',
    telegramAnno: 'https://t.me/syncityannouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
};

export const syncity: PoolConfig = {
  ...syncityCommon,
  // id is used in URL
  id: 'syncity',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0x5725ffea1134665C5AB3CD6BB38B880EdF905d13',
    tokensForSale: toWeiBN('10000000'),
    startDate: '2022-01-20T11:00:00.000Z',
    rate: 50,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('10000000'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('1027.49177369705976011'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-18T11:00:00.000Z',
    registerDuration: 44,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 9517,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_merkle',
    vesting: '10% at TGE, rest vested over 9 months',
    // TODO: funded 1M for security
    claimerAddress: '0x6A15dBE9222181De9267920b6DDd4F72108F3b1F',
    network: 'ETH',
    schedule: {
      '2022-01-25T17:00:00.000Z': 10,
      '2022-04-25T17:00:00.000Z': 30,
      '2022-07-25T17:00:00.000Z': 30,
      '2022-10-25T17:00:00.000Z': 30,
    },
  },
};

export const syncityprivate: PoolConfig = {
  ...syncityCommon,
  // id is used in URL
  id: 'syncityprivate',
  // public, seed or private
  access: 'private',
  showToMinLevelMultiplier: 28,
  sale: {
    address: '0x36892Ce3d72f8dCe5fC4932c954f13fEd9C61028',
    tokensForSale: toWeiBN('2083333'),
    startDate: '2022-01-20T11:00:00.000Z',
    rate: 83.3333,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    refunded: true,
    participants: 0,
    tokensSold: toWeiBN('2083332.999999999999994962'),
    raised: toWeiBN('25000.0060000024000009'),
    baseAllocation: toWeiBN('828.033783783783783783'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-18T11:00:00.000Z',
    registerDuration: 44,
    fcfsDuration: 0.22222222222222224,
    totalWeights: 2582,
    minAllowedLevelMultiplier: 28,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting:
      '2% at TGE, total of 10% at month 1, then 9 months cliff, rest vested over 36 months',
    network: 'ETH',
    claimerAddress: '',
    // claimerAddress: '0x5e069655e700F0c6a6adc39c3db4989f3AA99A4C',
  },
};
