import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/menzy.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const menzy: PoolConfig = {
  // id is used in URL
  id: 'menzy',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xc8E12630bff825c205388cc41E609E8D3d694939',
    tokensForSale: toWeiBN('31666667.7'),
    startDate: '2022-07-05T11:00:00.000Z',
    rate: 166.6666,
    durationHours: 12,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('31666667.699999999999991108'),
    raised: toWeiBN('190000.0822000328800131'),
    baseAllocation: toWeiBN('3441.131411370946709554'),
    status: 5,
    registerDate: '2022-07-03T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 9202400,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  finishedInfo: (
    <div>
      <p className="mb-3">
        Trade{' '}
        <a href="https://bybit.com/en-US/trade/spot/MNZ/USDT">
          MNZ-USDT on ByBit
        </a>
        .
      </p>
    </div>
  ),
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '15% TGE, 1 month cliff, 9 months vesting',
    claimerAddress: '0x94355e96ECbc91a32024136E24FC44E3919D264D',
    claimerVersion: 'v3',
    schedule: {
      '2022-07-06T10:10:00.000Z': 15,
      '2022-09-06T10:10:00.000Z': 9.5,
      '2022-10-06T10:10:00.000Z': 9.5,
      '2022-11-06T10:10:00.000Z': 9.5,
      '2022-12-06T10:10:00.000Z': 9.5,
      '2023-01-06T10:10:00.000Z': 9.5,
      '2023-02-06T10:10:00.000Z': 9.5,
      '2023-03-06T10:10:00.000Z': 9.5,
      '2023-04-06T10:10:00.000Z': 9.5,
      '2023-05-06T10:10:00.000Z': 9,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x861f1e1397dad68289e8f6a09a2ebb567f1b895c',
    name: 'Menzy',
    symbol: 'MNZ',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-07-06T10:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'ByBit',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(79_108_000),
    totalSupply: toWeiBN(2_000_000_000),
    coingeckoId: 'menzy',
  },
  social: {
    website: 'https://menzy.io',
    twitter: 'https://twitter.com/MNZToken',
    telegram: 'https://t.me/Menzy_Official',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },

  description: (
    <p>
      Menzy is a move to earn AI fully body movement platform for earning
      passively and an integrated virtual fitness center. Menzy developed a
      unique algorithmic system that detects any sort of activity or sport
      happening with the help of your mobile camera enabled device rewarding you
      for every move you make.
    </p>
  ),
};
