import { utils } from '@trustpad/launchpad';
import {
  multicallTransformed,
  multicallv2,
} from '@trustpad/launchpad/dist/shared/utils/multicall';

export { debug, debugState } from './debug';
export * from './time';

export { multicallv2, multicallTransformed };
export const {
  call,
  fNum,
  getTimeDiffString,
  getTimeDiff,
  toFixedNumber,
  denormalizeDecimals,
  addressShort,
  normalizeDecimals,
  currencyToTokens,
  tokensToCurrency,
  getAddressUrl,
  isValidValue,
  getTradeUrl,
  toWeiBN,
  toEth,
  toEthN,
  getTxUrl,
  toWei,
  getTokenUrl,
  getApr,
  capitalize,
  normalize,
} = utils;

export function formatShort(num, digits = 2) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}
