import img from 'pools/images/bloktopia.jpg';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const bloktopia: PoolConfig = {
  // id is used in URL
  id: 'bloktopia',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xc0d163C947FEd71c088E038aFdAfd15c1B6429EC',
    tokensForSale: toWeiBN('400000000'),
    startDate: '2021-10-06T11:00:00.000Z',
    rate: 4000,
    durationHours: 3,
    hasWhitelist: true,
    isTiered: true,
    participants: 1335,
    tokensSold: toWeiBN('400000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('45000'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-10-01T11:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 15412,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'POLY',
    address: '0x229b1b6c23ff8953d663c4cbb519717e323a0a84',
    name: 'Bloktopia',
    symbol: 'BLOK',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 4000,
    initialSupply: toWeiBN(2_000_000_000),
    totalSupply: toWeiBN(200_000_000_000),
    listingTime: '2021-10-06T15:00:00.000Z',
    coingeckoId: 'bloktopia',
    coinmarketcapId: 'bloktopia',
  },
  distribution: {
    type: 'claim_us',
    network: 'POLY',
    claimerVersion: 'v4',
    claimerAddress: '0xc04c345BD8137231f096607C61213238ceD8db11',
    vesting:
      '10% AIRDROPPED at the listing / TGE, 20% distribution on 2nd, 3rd & 4th & 30% distribution on 5th month.',
    schedule: {
      '2021-10-06T15:00:00.000Z': 10,
      '2021-11-03T20:00:00.000Z': 10,
      '2021-12-11T19:00:00.000Z': 10,
      '2022-02-08T15:00:00.000Z': 20,
      '2022-06-01T17:00:00.000Z': 20,
      '2022-08-16T17:00:00.000Z': 30,
    },
  },
  finishedInfo: (
    <div className="space-y-3">
      <div className="text-center">
        <Button
          title="Buy on Kucoin"
          variant="main"
          href="https://trade.kucoin.com/BLOK-USDT"
          target="_blank"
          className="btn-sm py-2 no-hover">
          Buy BLOK on Kucoin
        </Button>
      </div>

      <QuickswapChartFinishedInfo
        symbol="BLOK"
        address="0x229b1b6c23ff8953d663c4cbb519717e323a0a84"
        pairAddress="0xa022ae9cfadefd62d70b510c27dc3d5db67ca43b"
      />
    </div>
  ),
  social: {
    website: 'https://bloktopia.com/',
    twitter: 'https://twitter.com/bloktopia',
    telegram: 'https://t.me/BloktopiaChat',
    telegramAnno: '',
    medium: 'https://medium.com/@bloktopia',
    github: '',
    whitepaper:
      'https://www.bloktopia.com/wp-content/uploads/2021/09/Bloktopia-Lightpaper-.pdf',
    announcement:
      'https://trustpad.medium.com/bloktopia-blok-are-launching-on-trustpad-8ab095ae5e26',
  },
  description: (
    <p>
      Bloktopia will provide an unprecedented VR experience for the crypto
      community, bringing users together all in one immersive and engaging
      environment.
    </p>
  ),
};
