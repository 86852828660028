import Skeleton from 'react-loading-skeleton';

export default function TokenStatsCard({ label, children }) {
  return (
    <div className="flex flex-col items-center justify-between p-6 xll:mb-0 bg-card-secondary rounded-lg bg-opacity-30">
      <div className="flex items-center font-medium text-xl md:text-4xl brand-text brand-text-shadow">
        {typeof children != undefined ? (
          children
        ) : (
          <Skeleton className="w-20 opacity-10" />
        )}
      </div>
      <div className="text-xs sm:text-sm text-gray-400 text-opacity-80 pt-3">
        {label}
      </div>
    </div>
  );
}
