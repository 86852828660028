import jp1 from 'pools/finished/netvrk/images/Netvrk_NFT_JP_1.jpg';
import jp2 from 'pools/finished/netvrk/images/Netvrk_NFT_JP_2.jpg';
import jp3 from 'pools/finished/netvrk/images/Netvrk_NFT_JP_3.jpg';
import yacht from 'pools/finished/netvrk/images/XRS_Bullet_Yacht_Card_PNG.png';
import truck from 'pools/finished/netvrk/images/XRS_OffRoad_Truck_Card_PNG.png';
import motorcycle from 'pools/finished/netvrk/images/XS_Motorcycle_Card_PNG.png';
import roadster from 'pools/finished/netvrk/images/XS_Roadster_Card_PNG.png';
import falcon from 'pools/finished/netvrk/images/X_Falcon_PNG.png';
import metaImg from 'pools/finished/netvrk/images/metaImg.jpeg';
import land from 'pools/finished/netvrk/images/netvrk-land.jpeg';
import mega from 'pools/finished/netvrk/images/netvrk-mega.jpeg';
import parcels from 'pools/finished/netvrk/images/netvrk-parcels.png';
import netvrkImg from 'pools/finished/netvrk/images/netvrk.png';

export const landImgs = { land, mega, parcels };

export const transportImgs = {
  jp1,
  jp2,
  jp3,
  falcon,
  yacht,
  truck,
  motorcycle,
  roadster,
};

export { netvrkImg, metaImg };
