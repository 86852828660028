import { useLevelPoolsWithUserAmount } from '@trustpad/launchpad';
import { levelPools } from '~config/levelPoolsConfig';
import { toEthN } from '~utils';

export function useMaxUserPool() {
  const poolsWithAmounts = useLevelPoolsWithUserAmount();
  const maxPool = poolsWithAmounts.poolsState
    .filter((p) => levelPools.map((i) => i.id).includes(p.id))
    .sort((a, b) => toEthN(b.amount) - toEthN(a.amount))[0];

  return { poolsWithAmounts, maxPool };
}
