import seoImg from 'pools/images/metabrands-ann.png';
import bannerImg from 'pools/images/metabrands-banner.png';
import img from 'pools/images/metabrands.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { Desc } from '~features/pools/components/PoolDescription';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const metaBrands: PoolConfig = {
  // id is used in URL
  id: 'metaBrands',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x0709aA500853aad0e65d0532F209e5D16E2d9Ee3',
    tokensForSale: toWeiBN('1000000'),
    startDate: '2021-12-11T11:00:00.000Z',
    rate: 10,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('141.671650717703349282'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-09T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6710,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0xd52aae39a2b5cc7812f7b9450ebb61dfef702b15',
    addresses: {
      AVAX: '0x921f99719eb6c01b4b8f0ba7973a7c24891e740a',
    },
    name: 'MetaBrands',
    symbol: 'MAGE',
    imageSrc: img.src,
    listingTime: '2021-12-16T17:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(6_320_000),
    totalSupply: toWeiBN(100_000_000),
    coingeckoId: 'metabrands',
    coinmarketcapId: 'metabrands',
  },
  finishedInfo: (
    <>
      <UniswapChartFinishedInfo
        symbol="MAGE"
        address="0xd52aae39a2b5cc7812f7b9450ebb61dfef702b15"
        pairAddress="0xd52aae39a2b5cc7812f7b9450ebb61dfef702b15"
      />
      <p>
        <a href="https://app.pangolin.exchange/#/swap?inputCurrency=0x921f99719eb6c01b4b8f0ba7973a7c24891e740a">
          Trade on Pangolin
        </a>
      </p>
    </>
  ),
  images: {
    seo: seoImg.src,
  },
  distribution: [
    {
      title: 'TGE batch on ETH',
      type: 'claim_us',
      vesting: '20% - Token Launch (on ETH)',
      network: 'ETH',
      claimerAddress: '0xaaf0a5e10781052cb8f223edffd1ce0824789d8f',
      claimerVersion: 'v2',
      schedule: {
        '2021-12-16T17:00:00.000Z': 20,
        '2022-01-16T17:00:00.000Z': 20,
        '2022-02-16T17:00:00.000Z': 20,
        '2022-03-16T17:00:00.000Z': 20,
        '2022-04-16T17:00:00.000Z': 20,
      },
    },
    {
      title: 'Following batches on AVAX',
      type: 'claim_us',
      vesting: '20% Monthly After',
      network: 'AVAX',
      claimerAddress: '0x5894DCE635C9f207BAE969e299B5CDD41F477798',
      claimerVersion: 'v2',
      schedule: {
        '2021-12-16T17:00:00.000Z': 20,
        '2022-01-16T17:00:00.000Z': 20,
        '2022-02-16T17:00:00.000Z': 20,
        '2022-03-16T17:00:00.000Z': 20,
        '2022-04-16T17:00:00.000Z': 20,
      },
    },
  ],
  social: {
    website: 'https://metabrands.io/',
    twitter: 'http://twitter.com/MetaBrandsio',
    telegram: 'https://t.me/MetaBrands',
    telegramAnno: 'https://t.me/MetaBrandsAnn',
    medium: 'http://medium.com/@metabrandsio',
    discord: 'https://discord.gg/fHvUeYsDDT',
    github: '',
    whitepaper: 'https://whitepaper.metabrands.io/',
    announcement: '',
  },
  descriptionShort: (
    <p>
      A thriving Metaverse Resource DAO that allows instant exposure to the
      top-performing NFTs, play-to-earn models, and player-owned economies.
      MetaBrands connects people with innovative brands that bridges the
      relationship between platforms, ecosystems, and games through shared yield
      for players and DeFi participants in a seamless fashion.
    </p>
  ),
  description: (
    <>
      <p>
        A thriving Metaverse Resource DAO that allows instant exposure to the
        top-performing NFTs, play-to-earn models, and player-owned economies.
        MetaBrands connects people with innovative brands that bridges the
        relationship between platforms, ecosystems, and games through shared
        yield for players and DeFi participants in a seamless fashion.
      </p>
      <p>
        <b>MAGE Utility Token Features:</b> <br />
        NFT Minting: used to mint NFT Relics, Heroes, and more <br />
        Yield: airdropped on a monthly basis in MAGE utility tokens <br />
        Payments: trade and use as a liquid cryptocurrency asset <br />
        Governance: voting and creating proposals <br />
        Staking: earns yield for adding to liquidity pool <br />
        Whitelisting: for internal and external events <br />
        Discounts: on NFT purchases using the MAGE utility token. <br />
        <br />
        The MAGE Token is equipped to better withstand market volatility, due to
        the unique tokenomics model, asset diversification, and exposure in
        various play-to-earn environments. These assets are generating yield in
        direct correlation with the expanding playerbase throughout the rapidly
        evolving metaverse. This provides MetaBrands with monthly resources that
        are used to purchase MAGE tokens off the public market and redistribute
        them among eligible Relic NFT Holders.
      </p>
      <p>
        <b>DAO & Background:</b> <br />
        MetaBrands was established with a large group of individuals around the
        world that have been working in the blockchain and web3 space for many
        years together. DAO Advisors specialize in the fields of web3 and
        blockchain core development, decentralization and governance, finance,
        branding and design, marketing and content creation, gaming and eSports,
        community growth, and positive social influence. Collectively, the
        MetaBrands DAO has over 30+ years of experience in the realm of
        blockchain and cryptocurrency, providing a well-rounded knowledge and
        understanding to navigate the dynamic landscape.
      </p>
      <Desc.Img src={bannerImg} />
      <p>
        <b>Partners:</b> DEXTools, DFV, BlackDragon, DuckDAO, OxBull, GAINS
        associates, DeltaHub Capital, DuckStarter, StakeCube, AltcoinBuzz,
        ZENZO, CoinDreams, Dutch Crypto Investors, OIG, AVStar Capital, AZ
        Ventures, Blkbirds, FishDAO
      </p>
      <p>
        <b>Press Release:</b>{' '}
        <a
          href="https://www.cointelegraph.com/press-releases/metabrands-the-emergence-of-the-metaverse-resource-dao"
          target="_blank"
          rel="noreferrer">
          Coin Telegraph
        </a>{' '}
        |{' '}
        <a
          href="https://cryptoguerrillas.com/metabrands-gateway-to-the-metaverse/"
          target="_blank"
          rel="noreferrer">
          Crypto Guerrillas
        </a>{' '}
        |{' '}
        <a
          href="https://www.cryptoknowmics.com/news/transporting-yourself-into-the-metaverse-through-the-metabrands-vessel"
          target="_blank"
          rel="noreferrer">
          cryptoknowmics
        </a>
      </p>
    </>
  ),
};
