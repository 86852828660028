import img from 'pools/images/defactor.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';
import defactorAnn from '../images/defactorAnn.jpeg';

export const defactor: PoolConfig = {
  // id is used in URL
  id: 'defactor',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xa3aeAeB296176B4BC9eB86c2b0EE6a5043F9aF46',
    tokensForSale: toWeiBN('714286'),
    startDate: '2021-11-09T12:00:00.000Z',
    rate: 14.285714,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('714285.999999999999999241'),
    raised: toWeiBN('50000.021000000420000003'),
    baseAllocation: toWeiBN('136.574760994263862332'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-11-07T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 5266,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xe0bCEEF36F3a6eFDd5EEBFACD591423f8549B9D5',
    name: 'Defactor',
    symbol: 'FACTR',
    imageSrc: img.src,
    listingTime: '2021-11-13T14:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(13_000_000),
    totalSupply: toWeiBN(300_000_000),
    coingeckoId: 'defactor',
    coinmarketcapId: 'defactor',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="FACTR"
      address="0xdefac16715671b7b6aeefe012125f1e19ee4b7d7"
      pairAddress="0x34771e38e414026d2a680f42f28d4773379fc2af"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '3 months (4 unlocks, 25% each, first at TGE, then every 30 days)',
    claimerAddress: '0x9C38F66592bFf96B27caEafe911d4b539532E606',
    claimerVersion: 'v4',
    schedule: {
      '2021-11-13T14:00:00.000Z': 25,
      '2021-12-13T14:00:00.000Z': 25,
      '2022-01-13T14:00:00.000Z': 25,
      '2022-02-13T14:00:00.000Z': 25,
    },
    network: 'POLY',
  },
  images: {
    seo: defactorAnn.src,
  },
  social: {
    website: 'https://defactor.com/',
    twitter: 'https://twitter.com/defactor_',
    telegram: 'https://t.me/defactor_official',
    telegramAnno: '',
    medium: 'https://blog.defactor.com/',
    github: '',
    whitepaper: 'https://defactor.docsend.com/view/ndqzghfjjat5a5ik',
    announcement: '',
  },
  description: (
    <p>
      Defactor is building the bridge between traditional and decentralised
      finance.
    </p>
  ),
};
