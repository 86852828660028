import img from 'pools/images/inery.jpg';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { ChartDropdownButton } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const inery: PoolConfig = {
  // id is used in URL
  id: 'inery',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  noCountries: [],
  sale: {
    address: '0xeeCD638f3059cd849Fa936C769932959f948A245',
    tokensForSale: toWeiBN('647728'),
    startDate: '2022-09-26T20:00:00.000Z',
    rate: 4.545,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    refunded: true,
    participants: 0,
    tokensSold: toWeiBN('647727.99999999999999966'),
    raised: toWeiBN('142514.4114411441144114'),
    baseAllocation: toWeiBN('49.260163569490578531'),
    status: 5,
    registerDate: '2022-09-24T20:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('35'),
    },
    fcfsDuration: 0.6666666666666666,
    totalWeights: 12852030,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '40% TGE, 10% monthly',
    claimerVersion: 'v4',
    claimerAddress: '0x6876be9B5aCD48EBEe5261E47a499C6c452BaF29',
    // took 8,4k of tokens - take it from relinquished later
    schedule: {
      '2022-09-28T12:30:00.000Z': 40,
    },
  },
  finishedInfo: (
    <div className="space-y-3">
      <div className="text-center">
        <Button
          title="Buy on Huobi"
          variant="main"
          href="https://www.huobi.com/exchange/inr_usdt"
          target="_blank"
          className="btn-sm py-2 no-hover">
          Buy on Huobi
        </Button>
      </div>

      <ChartDropdownButton address="0xab725d0a10c3f24725c89f5765ae5794a26c1336" />
    </div>
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xab725d0a10c3f24725c89f5765ae5794a26c1336',
    name: 'Inery',
    symbol: 'INR',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-09-28T12:30:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Lists on Huobi at 13:00 UTC',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(6000000),
    totalSupply: toWeiBN(800_000_000),
    coingeckoId: 'inery',
    coinmarketcapId: 'inery',
  },
  social: {
    website: 'https://inery.io/',
    twitter: 'https://twitter.com/IneryBlockchain',
    telegram: 'https://t.me/inery_blockchain',
    telegramAnno: 'https://t.me/inery_channel',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Inery is layer-1 blockchain designed to offer the solution of
      decentralized database management with the vision to enable a new paradigm
      for data. It lays the foundation for web3 by seamlessly connecting with
      systems, applications, and layer-1 networks. Inery’s database management
      solution (IneryDB) integrates blockchain functionalities with distributed
      database management properties. Built on a decentralized infrastructure,
      IneryDB supports high throughput, low latency, complex query search and
      owner-controlled data assets. IneryDB allows developers and enterprises to
      securely store, access, manage and deliver data on a decentralized
      infrastructure supporting various industries.
    </p>
  ),
};
