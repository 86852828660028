import { NftPoolConfig } from '@trustpad/launchpad';

import tokenImg from 'pools/finished/monsterHunter/images/DxPLYedp_400x400.jpeg';
import monsterL2Img from 'pools/finished/monsterHunter/images/Monster_AirDropLv2_3.png';
import monsterL1Img from 'pools/finished/monsterHunter/images/Monster_AirDrop_3.png';
import React from 'react';

import { BUSD } from '~/config/tokens';
import { toWeiBN } from '~/utils';

// We will distribute the NFT after u guys send us the final list of Users CSV with user:
// Currency, Amount, Address, TXID, Block, Nonce
// i.e. BUSD, 600, 0x123123.., 01232131231, 1231232, 232

export const monsterHunt: NftPoolConfig = {
  // id is used in URL
  id: 'monsterHunt',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  isNft: true,
  sale: {
    address: '0x2e26C486f2C414A86553de3cf72B39a1240bA393',
    tokensForSale: toWeiBN('1000'),
    startDate: '2021-11-03T12:00:00.000Z',
    rate: 0,
    durationHours: 24,
    hasWhitelist: true,
    isTiered: true,
    participants: 720,
    tokensSold: toWeiBN('0.000000000000001'),
    raised: toWeiBN('240000'),
    baseAllocation: toWeiBN('9600'),
    status: 5,
    inventory: [
      {
        id: 'level1',
        name: 'Steinn',
        image: monsterL1Img.src,
        supply: 800,
        totalSupply: 1200,
        price: toWeiBN('150'),
        internalId:
          '0x6c6576656c310000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 800,
        raised: toWeiBN('120000'),
      },
      {
        id: 'level2',
        name: 'Steinar',
        image: monsterL2Img.src,
        supply: 200,
        totalSupply: 300,
        price: toWeiBN('600'),
        internalId:
          '0x6c6576656c320000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 200,
        raised: toWeiBN('120000'),
      },
    ],
    registerDate: '2021-11-01T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 23,
    totalWeights: 4047,
    isPublic: false,
    levelsOpenAll: true,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    address: '',
    name: 'Monster Hunt',
    symbol: '',
    imageSrc: tokenImg.src,
    decimals: 18,
    listingTime: '2021-12-20T17:30:00.000Z',
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'claim_them',
    vesting: 'Distributed in a couple of days after the INO',
    claimUrl: 'https://bcmhunt.com/portfolio',
  },
  social: {
    website: 'https://bcmhunt.com/',
    twitter: 'https://twitter.com/bcmhunt',
    telegram: 'https://t.me/BcmHuntGroup',
    telegramAnno: '',
    discord: 'https://discord.gg/Ee9aJ287J2',
    medium: 'https://blog.bcmhunt.com/',
    github: '',
    whitepaper: '',
    announcement:
      'https://blog.bcmhunt.com/the-genesis-hunt-campaign-awaits-a01e4ab54df3',
  },
  descriptionShort: (
    <p>
      What Are Genesis Monsters?
      <br />
      Among the three classes of monsters that exist in the Blockchain Monster
      Universe, the Genesis species is a group of pre-mined Monsters. They’re
      the first generation that have appeared and every single one of them has
      their own distinct personality and features. They are ascertained to be
      the ancestors to Wild and Artificial Monsters and cannot be caught or
      created from the blockchain timeline. In other words, they are exclusive
      NFTs that are only distributed outside of the game.
    </p>
  ),
  description: (
    <>
      <p>
        Blockchain Monster Hunt is rolling out the 'Genesis Hunt' - our Initial
        NFT Offering campaign in which players can hunt and collect exclusive
        Genesis Monster NFTs.
      </p>
      <p>
        <strong>What Are Genesis Monsters?</strong>
        Among the three classes of monsters that exist in the Blockchain Monster
        Universe, the Genesis species is a group of pre-mined Monsters. <br />
        <br />
        They're the first generation that have appeared and every single one of
        them has their own distinct personality and features. They are
        ascertained to be the ancestors to Wild and Artificial Monsters and
        cannot be caught or created from the blockchain timeline. In other
        words, they are exclusive NFTs that are only distributed outside of the
        game. Should these Genesis Monsters ever be burned in battle, it
        signifies the extinction of a unique Monster that can never return to
        life. <br />
        <br />
        There are currently 16,500 Genesis Monsters and 22 species which can be
        further categorized into 11 GS1 Monsters and 11 GS2 Monsters. Each GS1
        Monster has a total supply of 1200 and each GS2 Monster has a total
        supply of 300.
      </p>
      <p>
        <strong>The Advantages of Owning Genesis Monsters</strong>
        Genesis Monsters will give Blockchain Defenders (players) a headstart
        when Blockchain Monster Hunt officially launches. Let's look at some of
        the advantages of possessing Genesis Monsters!
      </p>
      <p>
        Exclusive Staking Pool
        <br />
        Genesis Monsters possess their very own exclusive staking pool that is
        separated from the rest of the Blockchain Monster Universe. No other
        Monster species can share the same staking pool as these ancestral
        Monsters.
      </p>{' '}
      <p>
        Ability to Create
        <br />
        Genesis Monster species are the ancestors of all other Blockchain
        Monster species, and as such, they are required in order to genetically
        engineer new Monster species. Without Genesis Monsters, players are
        unable to create Artificial Monsters of their own. With the NFT Flash
        Loan feature, players can loan Genesis Monsters to those that need them
        for breeding in exchange for benefits.
      </p>
      <p>
        Catch Assistant <br />
        Like all Monsters in the Blockchain Monster Universe, Genesis Monsters
        can participate in battles with Monsters and also act as a support for
        players when catching other Monsters in the game. Having a Genesis
        Monster increases your chances of catching other Monsters of a certain
        element.
      </p>
    </>
  ),
};
