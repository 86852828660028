import img from 'pools/images/moomonster.jpeg';
import React from 'react';

import { BUSD } from '~/config/tokens';
import { PoolConfig } from '~/features/pools/types';
import { toWeiBN } from '~/utils';
import { PancakeChartFinishedInfo } from '~features/launched';

export const moomonster: PoolConfig = {
  // id is used in URL
  id: 'moomonster',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x3bdF6634Ac2a427B429F52165cBd9710BF35690E',
    tokensForSale: toWeiBN('1333280'),
    startDate: '2021-11-30T12:30:00.000Z',
    rate: 16.666,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1333279.999999999999999388'),
    raised: toWeiBN('80000'),
    baseAllocation: toWeiBN('185.125703037120359955'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('10'),
    },
    registerDate: '2021-11-28T12:30:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 7112,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    address: '0xA29b6f4E762874846c081E20ed1142FF83faaFEF',
    name: 'Moomonster',
    symbol: 'MOO',
    imageSrc: img.src,
    listingTime: '2021-12-02T13:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(4_900_000),
    totalSupply: toWeiBN(170000000),
    coingeckoId: 'moomonster',
    coinmarketcapId: 'moomonster',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="MOO"
      address="0xA29b6f4E762874846c081E20ed1142FF83faaFEF"
      pairAddress="0x72aafe61a2d4fd7faf217197d6448f326444621f"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '20% TGE, linearly over 4 months',
    claimerAddress: '0xC955f026e20599f0200F7337f5034315DF3E3b0e',
    claimerVersion: 'v2',
    schedule: {
      '2021-12-02T13:00:00.000Z': 20,
      '2022-01-02T13:00:00.000Z': 20,
      '2022-02-02T13:00:00.000Z': 20,
      '2022-03-02T13:00:00.000Z': 20,
      '2022-04-02T13:00:00.000Z': 20,
    },
  },
  social: {
    website: 'https://moo-monster.com/',
    twitter: 'https://twitter.com/MooMonsterNFT',
    telegram: 'https://t.me/moomonster_community_inter',
    telegramAnno: 'https://t.me/moomonster_official',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Go on an adventure with Moo monster in the new world "Mooniverse". Collect
      and train Moo for battles against others monster and player. Win the
      battles and claim AFK rewards to earn MooMon Token, NFTs and exchange.
    </p>
  ),
};
