import img from 'pools/images/monstergalaxy.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const monsterGalaxyCommon = {
  fundToken: BUSD,
  kyc: true,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x4301a638FCB45C4bb65230B87F3797C213f832B8',
    name: 'Monster Galaxy',
    symbol: 'GGM',
    imageSrc: img.src,
    listingTime: '2022-01-18T12:05:00.000Z',
    decimals: 18,
    listingRate: 40,
    initialSupply: toWeiBN(21_000_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'monster-galaxy',
    athXManual: 22.8,
  },
  social: {
    website: 'http://www.playmonstergalaxy.com/',
    twitter: 'https://twitter.com/MonsterGalaxy21',
    telegram: 'https://t.me/MonsterGalaxyP2E',
    telegramAnno: 'https://t.me/monstergalaxynews',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  finishedInfo: (
    <>
      <PancakeChartFinishedInfo
        symbol="GGM"
        address="0x4301a638FCB45C4bb65230B87F3797C213f832B8"
        pairAddress="0xdec0f2581529ef0fea650169c4e7c987fcdf720a"
      />
      <a href="https://www.bybit.com/en-US/trade/spot/GGM/USDT">
        Trade on ByBit.
      </a>
    </>
  ),
  description: (
    <p>
      Monster Galaxy is an exciting mobile game +inspired by Pokemon and Monster
      Hunter. The game features epic Monster battles and eye-catching special
      effects, as well as a stunning original soundtrack from Jeremy Soule and
      the band TREAT.
    </p>
  ),
};

export const monstergalaxy: PoolConfig = {
  ...monsterGalaxyCommon,
  // id is used in URL
  id: 'monstergalaxypublic',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0xF7189511E6C0772Ab231A32192E7a479f5B010cE',
    tokensForSale: toWeiBN('4100000'),
    startDate: '2022-01-16T11:00:00.000Z',
    rate: 40,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4099999.9999999999999992'),
    raised: toWeiBN('102499.99999999999999998'),
    baseAllocation: toWeiBN('475.204094066041184354'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-14T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 8263,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '50% unlocked on tge and 50% unlocked by end of month 1',
    claimerAddress: '0xb3568396795a13ab3aD8A90e354926cA5515e073',
    claimerVersion: 'v2',
    schedule: {
      '2022-01-18T12:05:00.000Z': 50,
      '2022-02-18T12:00:00.000Z': 50,
    },
  },
};

export const monstergalaxyprivate: PoolConfig = {
  ...monsterGalaxyCommon,
  token: {
    ...monsterGalaxyCommon.token,
    athXManual: 28.5,
  },
  // id is used in URL
  id: 'monstergalaxyprivate',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x40A31c7FeDeAde6C57D14F5cda788e89181bE61F',
    tokensForSale: toWeiBN('5000000'),
    startDate: '2022-01-16T11:00:00.000Z',
    rate: 50,
    durationHours: 5.25,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('5000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('2183.40611353711790393'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-14T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 2318,
    minAllowedLevelMultiplier: 28,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '5% tge, 1 month cliff, linear monthly unlock',
    claimerAddress: '0xB8d9948C84b13dF9aEFAE62395b3EA128801193E',
    claimerVersion: 'v2',
    schedule: {
      '2022-01-18T12:05:00.000Z': 5,
      '2022-03-18T12:00:00.000Z': 4.32,
      '2022-04-18T12:00:00.000Z': 4.32,
      '2022-05-18T12:00:00.000Z': 4.32,
      '2022-06-18T12:00:00.000Z': 4.32,
      '2022-07-18T12:00:00.000Z': 4.32,
      '2022-08-18T12:00:00.000Z': 4.32,
      '2022-09-18T12:00:00.000Z': 4.32,
      '2022-10-18T12:00:00.000Z': 4.32,
      '2022-11-18T12:00:00.000Z': 4.32,
      '2022-12-18T12:00:00.000Z': 4.32,
      '2023-01-18T12:00:00.000Z': 4.32,
      '2023-02-18T12:00:00.000Z': 4.32,
      '2023-03-18T12:00:00.000Z': 4.32,
      '2023-04-18T12:00:00.000Z': 4.32,
      '2023-05-18T12:00:00.000Z': 4.32,
      '2023-06-18T12:00:00.000Z': 4.32,
      '2023-07-18T12:00:00.000Z': 4.32,
      '2023-08-18T12:00:00.000Z': 4.32,
      '2023-09-18T12:00:00.000Z': 4.32,
      '2023-10-18T12:00:00.000Z': 4.32,
      '2023-11-18T12:00:00.000Z': 4.32,
      '2023-12-18T12:00:00.000Z': 4.28,
    },
  },
};
