/* eslint-disable @next/next/no-img-element */
import {
  adaImg,
  arbitrumImg,
  avaxImg,
  bnbImg,
  dotImg,
  ethImg,
  fantomImg,
  polyImg,
  solImg,
  suiImg,
  terraImg,
  baseImg,
  zksyncImg,
} from './images';

export function ChainIcon({
  chain,
  filterButton,
}: {
  chain: string;
  filterButton?: boolean;
}) {
  if (chain === 'ETH') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-indigo-400 bg-opacity-70 h-7 w-7'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 1 } : { padding: 4 }}>
        <img src={ethImg.src} alt="Ethereum" className="h-full img-white" />
      </div>
    );
  }
  if (chain === 'SOL') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-solana h-7 w-7'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 3 } : { padding: 6 }}>
        <img src={solImg.src} alt="Solana" className="h-full img-white" />
      </div>
    );
  }
  if (chain === 'BSC') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-acc h-7 w-7'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 0 } : { padding: 3 }}>
        <img
          src={bnbImg.src}
          alt="BNB Chain"
          className="h-full w-full img-white"
        />
      </div>
    );
  }
  if (chain === 'DOT') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-polkadot h-7 w-7'
        } flex items-center justify-center`}>
        <img src={dotImg.src} alt="PolkaDot" className="h-full img-white" />
      </div>
    );
  }
  if (chain === 'ADA') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-7 sm:h-7 sm:w-8'
            : 'rounded-full bg-opacity-70 bg-ada h-7 w-8'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 0 } : { padding: 2 }}>
        <img src={adaImg.src} alt="Cardano" className="h-full img-white" />
      </div>
    );
  }
  if (chain === 'POLY') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-polygon h-7 w-7'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 0 } : { padding: 2 }}>
        <img src={polyImg.src} alt="Polygon" className="h-full img-white" />
      </div>
    );
  }
  if (chain === 'TERRA') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-terra h-7 w-7'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 0 } : { padding: 3 }}>
        <img
          src={terraImg.src}
          alt="Terra"
          className="h-full rounded-full img-white"
        />
      </div>
    );
  }
  if (chain === 'FTM') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-blue-700 h-7 w-7'
        } flex items-center justify-center`}
        style={filterButton ? { padding: 0 } : { padding: 2 }}>
        <img
          src={fantomImg.src}
          alt="Fantom"
          className="h-full img-white rounded-full"
        />
      </div>
    );
  }
  if (chain === 'AVAX') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-red-500 h-7 w-7'
        } flex items-center justify-center`}>
        <img
          src={avaxImg.src}
          alt="Avalanche"
          className="h-full rounded-full img-white"
        />
      </div>
    );
  }
  if (chain === 'ARB') {
    return (
      <div
        className={`${
          filterButton
            ? 'h-6 w-6 sm:h-7 sm:w-7'
            : 'rounded-full bg-opacity-70 bg-arbitrum h-7 w-7'
        } flex items-center justify-center`}
        style={{ padding: 2 }}>
        <img
          src={arbitrumImg.src}
          alt="Arbitrum"
          className="h-full rounded-full img-white"
        />
      </div>
    );
  }
  if (chain === 'SUI') {
    return (
      <div
        className="rounded-full h-7 w-7 flex items-center justify-center bg-terra"
        style={{ padding: 4 }}>
        <img src={suiImg.src} alt="Sui" className="h-full rounded-full" />
      </div>
    );
  }

  if (chain === 'BASE') {
    return (
      <div
        className="h-7 w-7 flex items-center justify-center"
        style={{ padding: 0 }}>
        <img src={baseImg.src} alt="Base" className="h-full rounded-full" />
      </div>
    );
  }

  if (chain === 'ZKSYNC') {
    return (
      <div
        className="h-7 w-7 flex items-center justify-center"
        style={{ padding: 0 }}>
        <img src={zksyncImg.src} alt="ZKsync" className="h-full rounded-full" />
      </div>
    );
  }
  return null;
}
