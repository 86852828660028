import img from 'pools/images/sugarbounce.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const sugarbounce: PoolConfig = {
  // id is used in URL
  id: 'sugarbounce',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x228f89aE6396cd012D705F59D65998D71cf1b396',
    tokensForSale: toWeiBN('181819'),
    startDate: '2021-11-19T08:00:00.000Z',
    rate: 1.818181,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('181818.999999999999999932'),
    raised: toWeiBN('100000.495000222750100544'),
    baseAllocation: toWeiBN('28.500615531931836279'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('15'),
    },
    registerDate: '2021-11-17T19:00:00.000Z',
    registerDuration: 35,
    fcfsDuration: 0.25,
    totalWeights: 6291,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x40f906e19B14100D5247686E08053c4873c66192',
    name: 'SugarBounce',
    symbol: 'TIP',
    listingTime: '2021-11-19T15:00:00.000Z',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(621970),
    totalSupply: toWeiBN(1_440_532),
    coingeckoId: 'sugarbounce',
    coinmarketcapId: 'sugarbounce',
  },
  distribution: {
    type: 'claim_us',
    vesting: '25% at TGE, 1 Months cliff and 3 Months vesting',
    claimerAddress: '0xf2a9F5b5b6AD939664D6591aFd489639F494C092',
    claimerVersion: 'v2',
    schedule: {
      '2021-11-19T15:00:00.000Z': 25,
      '2021-12-19T15:00:00.000Z': 25,
      '2022-01-19T15:00:00.000Z': 25,
      '2022-02-19T15:00:00.000Z': 25,
    },
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="TIP"
      address="0x40f906e19B14100D5247686E08053c4873c66192"
      pairAddress="0xdec7422291a996dcb6018efe58aaa57137eaf3ca"
    />
  ),
  social: {
    website: 'https://sugarbounce.com/',
    twitter: 'https://twitter.com/SugarBounceNSFW',
    telegram: 'https://t.me/SugarBounceNSFW',
    telegramAnno: 'https://t.me/SugarBounceAnnouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      SugarBounce is a game-changing ecosystem, ready to present a consummate
      universe for all NSFW entertainment needs to the next generation of
      viewers. SugarBounce aims to be the leaders in decentralized adult content
      distribution.
    </p>
  ),
};
