import img from 'pools/images/metan.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const metan: PoolConfig = {
  // id is used in URL
  id: 'metan',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0xb20eec44ccA6A3B83f20E87cdE28e2e614319451',
    tokensForSale: toWeiBN('1333333'),
    startDate: '2022-02-25T11:00:00.000Z',
    rate: 6.666,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1333332.999999999999999773'),
    raised: toWeiBN('200019.9519951995199521'),
    baseAllocation: toWeiBN('174.823379940844312987'),
    status: 5,
    registerDate: '2022-02-23T14:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7494,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="METAN"
      address="0x879D239BCC0356Cf9df8c90442488bCe99554c66"
      pairAddress="0xe5a2bddd641f320821288a57246749ec3771d163"
    />
  ),
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '20% at TGE, then release 20% on monthly basis',
    claimerAddress: '0x270c7Bc2f14f686791d213c17238e9bA71731bec',
    claimerVersion: 'v2',
    schedule: {
      '2022-02-28T14:00:00.000Z': 20,
      '2022-03-28T14:00:00.000Z': 20,
      '2022-04-28T14:00:00.000Z': 20,
      '2022-05-28T14:00:00.000Z': 20,
      '2022-06-28T14:00:00.000Z': 20,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x879D239BCC0356Cf9df8c90442488bCe99554c66',
    name: 'Metan Evolution',
    symbol: 'METAN',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-02-28T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(2_600_000),
    totalSupply: toWeiBN(100_000_000),
    coingeckoId: 'metan-evolutions',
    athXManual: 17.2,
    coinmarketcapId: 'metan-evolutions',
  },
  social: {
    website: 'https://metanevolutions.com/',
    twitter: 'https://twitter.com/Metanevolution',
    telegram: 'https://t.me/MetanEvolutionsChat',
    telegramAnno: 'https://t.me/Metan_Evolutions',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Metan Evolution is a game application platform on the Blockchain network.
      The game has NFT items with different and unique characteristics, features
      and rarity. Rewards in-game are organized according to GameFi
      architecture, allowing players to collect more NFT items and increase
      their values over time.
    </p>
  ),
};
