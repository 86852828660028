import { GradientButton } from 'src/features/landing/GradientButton';
import { APPLY_FORM } from 'src/config/constants';

export function ApplyForIDO() {
  return (
    <div className="text-center py-6 md:py-12 bg-dark2">
      <h2 className="text-2xl md:text-4xl mb-6">
        Want to launch your project on TrustPad?
      </h2>
      <div className="inline-block">
        <GradientButton
          href={APPLY_FORM}
          gradientStyle="brand-bg-green">
          <i className="las la-space-shuttle la-2x mr-2 text-2xl"></i> Apply to
          Launch
        </GradientButton>
      </div>
    </div>
  );
}
