import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/liquidifty.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const liquidifty: PoolConfig = {
  // id is used in URL
  id: 'liquidifty',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  distribution: {
    type: 'airdrop_them',
    vesting: '50% unlock at TGE, 25% 2nd month, 8.33% next 3 months',
    schedule: {
      '2021-06-15T16:00:00.000Z': 50,
      '2021-07-15T16:00:00.000Z': 25,
      '2021-08-15T16:00:00.000Z': 8.33,
      '2021-09-15T16:00:00.000Z': 8.33,
      '2021-10-15T16:00:00.000Z': 8.34,
    },
  },
  token: {
    address: '0xbd2c43da85d007b0b3cd856fd55c299578d832bc',
    name: 'Liquidifty',
    symbol: 'LQT',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    listingTime: '2021-06-15T16:00:00.000Z',
    pairAddress: '',
    listingRate: 0,
    totalSupply: toWeiBN(''),
    // BSC, ETH, DOT or SOL
    chain: 'BSC',
    coingeckoId: 'liquidifty',
    coinmarketcapId: 'liquidifty',
  },
  sale: {
    address: '0xf31f75204938Ae7883BDb427708e8f79AC51dbA6',
    status: SaleStatus.finished,
    rate: 1,
    tokensForSale: toWeiBN('100000'),
    startDate: '2021-06-15T12:00:00.000Z',
    baseAllocation: toWeiBN('40.851063829787234042'),
    durationHours: 4,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$100,000',
    participants: 694,
    tokensSold: toWeiBN('100000'),
    raised: toWeiBN('100000'),
    update: false,
    levelsOpenAll: true,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-06-14T16:00:00.000Z',
    registerDuration: 19,
    fcfsDuration: 1,
  },
  social: {
    website: 'https://liquidifty.io/',
    twitter: 'https://twitter.com/liquidifty',
    telegram: 'https://t.me/liquidifty',
    medium: 'https://liquidifty.medium.com/',
  },
  description: (
    <div>Liquidifty brings more crosschain liquidity to the NFT market.</div>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="$LQT"
        address="0xbd2c43da85d007b0b3cd856fd55c299578d832bc"
        pairAddress="0x0a0c9448de5ed90dcbc56ea0f11e9337c2b3dc1e"
      />
    </div>
  ),
};
