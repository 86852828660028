import React from 'react';
import { CgClose } from 'react-icons/cg';
import { PatternImage } from '~common/TopBar/PatternImage';
import { useBarPersistentDisplayState } from '~common/TopBar/useBarPersistentDisplayState';

const typeToClass = {
  success: 'bg-green-500 text-black',
  error: '',
  warning: '',
  info: '',
};

export function TopBarAlert({
  k,
  type = 'info',
  withPatten,
  children,
}: {
  k: string;
  type: keyof typeof typeToClass;
  withPatten?: boolean;
  children: React.ReactNode;
}) {
  const [display, setDisplay] = useBarPersistentDisplayState(k);

  if (!display) {
    return null;
  }

  return (
    <div
      className={`${typeToClass[type]} flex overflow-hidden relative justify-end py-3 text-black`}>
      {withPatten && (
        <>
          <PatternImage side="left" startWith="bottom-0" />
          <PatternImage side="right" startWith="top-0" />
        </>
      )}

      <div className="mr-auto ml-auto pl-7 z-10">{children}</div>

      <button
        className="text-xl px-7 text-black hover:text-gray-300"
        onClick={() => setDisplay(false)}>
        <CgClose />
      </button>
    </div>
  );
}
