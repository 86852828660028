import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/ibetyou.svg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const ibetyou: PoolConfig = {
  id: 'ibetyou',
  access: 'public',
  fundToken: BUSD,
  linkToDetailPage: true,
  distribution: {
    type: 'claim_them',
    claimUrl: 'https://claim.ibetyou.xyz/',
    vesting: `Public round: 1 month vesting period, 75% at TGE and 12.5% every 2 weeks. 
  Strategic round: 6 month vesting period, 1 month lock and 20% every month`,
    schedule: {
      '2021-06-14T16:00:00.000Z': 33.375,
      '2021-06-28T16:00:00.000Z': 5.5625,
      '2021-07-14T16:00:00.000Z': 5.5625 + 11.1,
      '2021-08-14T16:00:00.000Z': 11.1,
      '2021-09-14T16:00:00.000Z': 11.1,
      '2021-10-14T16:00:00.000Z': 11.1,
      '2021-11-14T16:00:00.000Z': 11.1,
    },
  },
  sale: {
    address: '0xB9E6049dA894bD65871ea6c0B3a190B3A5781aE5',
    status: SaleStatus.finished,
    rate: 19.762845,
    tokensForSale: toWeiBN('2846428.58'),
    startDate: '2021-06-13T16:00:00.000Z',
    baseAllocation: toWeiBN('1186.738409498207885304'),
    durationHours: 19,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$144,000',
    participants: 620,
    tokensSold: toWeiBN('2846428.58'),
    raised: toWeiBN('144029.292341259570674161'),
    update: false,
    levelsOpenAll: true,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-06-12T08:00:00.000Z',
    registerDuration: 30,
    fcfsDuration: 0,
  },
  token: {
    address: process.env.NEXT_PUBLIC_NETWORK === 'live' ? '' : '',
    name: 'iBetYou',
    symbol: 'IBY',
    imageSrc: img.src,
    decimals: 18,
    type: 'ERC-20',
    listingTime: '2021-06-14T16:00:00.000Z',
    pairAddress: '',
    listingRate: 14.28571429,
    totalSupply: toWeiBN('150,000,000'),
    chain: 'ETH',
    coingeckoId: 'ibetyou',
  },
  social: {
    twitter: 'https://twitter.com/ibetyouxyz',
    medium: 'https://ibetyouxyz.medium.com/',
    telegram: 'https://t.me/IBYXYZ',
    website: 'https://ibetyou.xyz/',
  },
  description: (
    <div>
      Permissionless blockchain protocol that allows you to bet any amount with
      anyone on anything. No limits, no rules, just your imagination.
    </div>
  ),
  finishedInfo: (
    <div className="text-center space-y-3 break-all">
      <div>
        💸 USDT/IBY pair: 👇👇👇
        <br />
        <a href="https://app.sushi.com/swap?inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0x6A68DE599E8E0b1856E322CE5Bd11c5C3C79712B">
          https://app.sushi.com/swap?inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0x6A68DE599E8E0b1856E322CE5Bd11c5C3C79712B
        </a>{' '}
        <br />
        <br />
        📈 DexTools: 👇👇👇
        <br />
        <a href="https://www.dextools.io/app/sushiswap/pair-explorer/0x7a34bbd9db8686bf9c1489a4682825f65eeafa6e">
          https://www.dextools.io/app/sushiswap/pair-explorer/0x7a34bbd9db8686bf9c1489a4682825f65eeafa6e
        </a>
        <br />
        <br />
        Claim your tokens at 👇👇👇
        <br />
        <a href="https://claim.ibetyou.xyz/">https://claim.ibetyou.xyz/</a>
      </div>
      <div>
        This pool has a mix of public and strategic funds. They are vested
        differently. Read more on Medium,{' '}
        <a href="https://trustpad.medium.com/ibetyou-is-launching-its-ido-on-trustpad-e5917ce5f619">
          "Raise Details & Vesting Schedules"
        </a>
      </div>
    </div>
  ),
};
