import { mundo } from 'pools/finished/mundo';
import { twoCrazyCards } from './2crazy';
import { twoCrazy } from './2crazy/2crazy';
import { adroverse } from './adroverse';
import { amasa } from './amasa';
import { animalia } from './animalia';
import { arenumgames } from './arenumgames';
import { ariadne } from './ariadne';
import { ashward } from './ashward';
import { auditchain } from './auditchain';
import { babylons } from './babylons';
import { bithotel } from './bithotel';
import { blokpad } from './blokpad';
import { bloktopia } from './bloktopia';
import { bullieverse } from './bullieverse';
import { bullperks } from './bullperks';
import { cheesus } from './cheesus';
import { continuum } from './continuum';
import { corestarter } from './corestarter';
import { cryptoxpress } from './cryptoxpress';
import { daolaunch } from './daolaunch';
import { defactor } from './defactor';
import { dinoland, dinolandprivate, dinolandseed } from './dinoland';
import { dinoxland } from './dinoxland';
import { duckieland } from './duckieland';
import { elevenminutes, elevenminutesprivate } from './elevenminutes';
import {
  endlessBattlefield,
  endlesssBattlefieldPrivate,
} from './endlessbattlefield';
import { fishingtown } from './fishingtown';
import { flourishing } from './flourishing';
import { fndz } from './fndz';
import { galaxywar } from './galaxywar';
import { gemGuardian } from './gemGuardian';
import { gensokishi } from './gensokishi';
import { govworld } from './govworld';
import { himoworld } from './himoworld';
import { husl } from './husl';
import { ibetyou } from './ibetyou';
import { inpoker } from './inpoker';
import { ioen } from './ioen';
import { kaizen } from './kaizen';
import { ledgity } from './ledgity';
import { liquidifty } from './liquidifty';
import { lockness } from './lockness';
import { lunaverse } from './lunaverse';
import { luxfi } from './luxfi';
import { manufactory, manufactoryCommunity } from './manufactory';
import { mechachain } from './mechachain';
import { merkle } from './merkle';
import { metaBrands } from './metabrands';
import { metan } from './metan';
import { monsterHunt } from './monsterHunter';
import { monsterHuntRest } from './monsterHunter/index-rest';
import { monstergalaxy, monstergalaxyprivate } from './monstergalaxy';
import { monstersClan } from './monstersClan';
import { moomonster } from './moomonster';
import { mymessage } from './mymessage';
import { nestarcade } from './nestarcade';
import { netvrkPools } from './netvrk';
import { nftpad } from './nftpad';
import { niftypays } from './niftypays';
import { nunuspirits } from './nunuspirits';
import { octane } from './octane';
import { onerare } from './onerare';
import { plutos } from './plutos';
import { polkaCipher } from './polkaCipher';
import { polkaFantasyPools } from './polkafantasy';
import { polygen } from './polygen';
import { privi } from './privi';
import { qan } from './qan';
import { rebaked } from './rebaked';
import { rod } from './rod';
import { sidus } from './sidus';
import { snook } from './snook';
import { solchicks } from './solchicks';
import { soldex } from './soldex';
import { soldexCompensation } from './soldex-compensation';
import { spacemisfits, spacemisfitsprivate } from './spacemisfits';
import { sportsicon } from './sportsicon';
import { strongnode } from './strongnode';
import { sugarbounce } from './sugarbounce';
import { syncity, syncityprivate } from './syncity/syncity';
import { tapfantasy, tapfantasyprivate } from './tapfantasy';
import { therealmdefenders } from './therealmdefenders';
import { triall } from './triall';
import { trustfi } from './trustfi';
import { trustpad } from './trustpad';
import { vaulty } from './vaulty';
import { vaultyPrivate } from './vaulty-private';
import { vodra } from './vodra';
import { wallfair } from './wallfair';
import { wastedlands } from './wastedlands';
import { xion } from './xion';
import { yarloo } from './yarloo';
import { unibit } from './unibit'
import { sailwars } from './sailwars'
import { animaliaprivate } from './animaliaprivate';
import { acquire } from './acquire';
import { animalconcerts } from './animalconcerts';
import { arize } from './arize';
import { colizeum } from './colizeum';
import { deadknight } from './deadknight';
import { drunkrobots } from './drunkrobots';
import { finblox } from './finblox';
import { fitburn } from './fitburn';
import { fragmint } from './fragmint';
import { galaxyblitz } from './galaxyblitz';
import { inery } from './inery';
import { jungleroad } from './jungleroad';
import { medievalempires } from './medievalempires';
import { menzy } from './menzy';
import { metawear, metawearPrivate } from './metawear';
import { mizar } from './mizar';
import { nomadexiles, nomadexilesPrivate } from './nomadexiles';
import { penguinkarts } from './penguinkarts';
import { playzap } from './playzap';
import { realmsofethernity } from './realmsofethernity';
import { samurailegends } from './samurailegends';
import { seor, seorWhitelist } from './seor';
import { shush } from './shush';
import { soulsofmetaRerun } from './soulsofmeta_rerun';
import { vrjam } from './vrjam';
import { wizardia, wizardiaPrivate } from './wizardia';
import { honeyland } from './honeyland';
import { orangedx } from './orangedx';
import { sugarkingdomodyssey } from './sugarkingdomodyssey';
import { paysenger } from './paysenger';
import { brc20x } from './brc20x'
import { arqx } from './arqx'
import { dogatoshi } from './dogatoshi';


export const finished = [
  unibit,
  sailwars,
  orangedx,
  sugarkingdomodyssey,
  animaliaprivate,
  seor,
  seorWhitelist,
  wizardia,
  wizardiaPrivate,
  drunkrobots,
  galaxyblitz,
  animalconcerts,
  deadknight,
  colizeum,
  penguinkarts,
  jungleroad,
  nomadexiles,
  nomadexilesPrivate,
  metawear,
  metawearPrivate,
  samurailegends,
  mizar,
  playzap,
  realmsofethernity,
  menzy,
  soulsofmetaRerun,
  shush,
  inery,
  acquire,
  fragmint,
  medievalempires,
  vrjam,
  arize,
  fitburn,
  finblox,
  honeyland,
  ariadne,
  merkle,
  vodra,
  monsterHunt,
  monsterHuntRest,
  monstersClan,
  ...netvrkPools,
  ...polkaFantasyPools,
  husl,
  govworld,
  gemGuardian,
  continuum,
  bloktopia,
  amasa,
  triall,
  polkaCipher,
  qan,
  trustfi,
  trustpad,
  ibetyou,
  bullperks,
  liquidifty,
  plutos,
  twoCrazy,
  vaulty,
  vaultyPrivate,
  babylons,
  nftpad,
  ledgity,
  privi,
  flourishing,
  fndz,
  snook,
  octane,
  xion,
  wallfair,
  ioen,
  rebaked,
  strongnode,
  yarloo,
  sportsicon,
  defactor,
  luxfi,
  corestarter,
  sugarbounce,
  therealmdefenders,
  niftypays,
  cryptoxpress,
  daolaunch,
  kaizen,
  moomonster,
  polygen,
  paysenger,
  solchicks,
  soldex,
  onerare,
  inpoker,
  soldexCompensation,
  sidus,
  cheesus,
  auditchain,
  mymessage,
  fishingtown,
  manufactory,
  manufactoryCommunity,
  metaBrands,
  animalia,
  bithotel,
  rod,
  wastedlands,
  twoCrazyCards,
  lockness,
  dinoxland,
  dinoland,
  galaxywar,
  mechachain,
  arenumgames,
  nestarcade,
  gensokishi,
  himoworld,
  spacemisfits,
  monstergalaxyprivate,
  monstergalaxy,
  syncity,
  tapfantasy,
  syncityprivate,
  endlessBattlefield,
  endlesssBattlefieldPrivate,
  spacemisfitsprivate,
  tapfantasyprivate,
  duckieland,
  elevenminutes,
  elevenminutesprivate,
  lunaverse,
  nunuspirits,
  adroverse,
  dinolandprivate,
  dinolandseed,
  bullieverse,
  blokpad,
  metan,
  mundo,
  ashward,
  brc20x,
  arqx,
  dogatoshi
];
