import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/brc20x.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';
import { LaunchProtocolProtected } from '~features/pools/components/PoolSecondaryBlock/LaunchProtocolProtected';

export const brc20x: PoolConfig = {
  // id is used in URL
  id: 'brc20x',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0x69380197Ba61747a01066200697B560E30D5DE91',
    tokensForSale: toWeiBN(1_666_667),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-04-30T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 8.33333333333,
    durationHours: 23,
    isProtected: false,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(1_666_667),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    claimerVersion: 'v4',
    vesting: '15% released on TGE, then 21.25% monthly',
    claimerAddress: '0x79E261567FCc77c294DA3e35e24c466f284A3e55',
    schedule: {
      '2024-05-02T12:15:00.000Z': 15,
      '2024-06-02T12:15:00.000Z': 21.25,
      '2024-07-02T12:15:00.000Z': 21.25,
      '2024-08-02T12:15:00.000Z': 21.25,
      '2024-09-02T12:15:00.000Z': 21.25,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    extraChains: ['ETH'],
    address: '0x77491cDcbB8320FEEabf21BAC19A00e2e3143708',
    name: 'BRC20X',
    symbol: 'BRCX',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-05-02T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Will be listed on PancakeSwap, UniSwap, BRC-20 DEX and MEXC',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(13_640_000),
    totalSupply: toWeiBN(100_000_000),
  },
  social: {
    website: 'https://brc20x.io/',
    twitter: 'https://twitter.com/brc20x_io',
    telegram: 'https://t.me/brc20x_io',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <>
      <p>
        BRC20X is a complete ecosystem designed to
        improve your crypto journey in BRC-20 blockchain
        and beyond. From the user-focused X Wallet
        and the versatile X CrossChain Bridge to the
        convenience of X Debit Cards and lucrative Staking
        opportunities, along with the potential for Token
        Revenue, each utility is crafted to provide you a
        smooth and complete crypto experience.
      </p>

      <LaunchProtocolProtected period='24 hours' />
    </>
  ),
};