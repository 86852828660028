import { NO_US, SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/mizar.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const mizar: PoolConfig = {
  // id is used in URL
  id: 'mizar',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: NO_US,
  sale: {
    address: '0x9CeA37A4ec6A15e17805287a90e6942E30d8fdfb',
    tokensForSale: toWeiBN('140000000'),
    startDate: '2023-03-06T18:00:00.000Z',
    rate: 500,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('140000000'),
    raised: toWeiBN('280000'),
    baseAllocation: toWeiBN('5477.699652671705869439'),
    status: 5,
    registerDate: '2023-03-04T18:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    fcfsDuration: 1,
    totalWeights: 23732590,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ARB',
    address: '0xBBEa044f9e7c0520195e49Ad1e561572E7E1B948',
    name: 'Mizar',
    symbol: 'MZR',
    imageSrc: img.src,
    listingTime: '2023-03-09T12:00:00.000Z',
    listingInfo:
      'Listing will be 12 UTC on UniSwap; distribution of tokens 12.15 UTC',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(520_357_000),
    totalSupply: toWeiBN(10_000_000_000),
    coingeckoId: 'mizar',
    coinmarketcapId: 'mizar',
  },
  distribution: {
    type: 'claim_us',
    network: 'ARB',
    vesting: '25% unlocked at TGE, 25% every month for 3 months',
    claimerAddress: '0x2260d9472D18A50BC671369FB942452dD192C4Dc',
    claimerVersion: 'v4',
    schedule: {
      '2023-03-09T12:15:00.000Z': 25,
      '': 25,
      ' ': 25,
      '  ': 25,
    },
  },
  social: {
    website: 'https://mizar.com/',
    twitter: 'https://twitter.com/mizar_com',
    telegram: 'https://t.me/mizar_com',
    telegramAnno: '',
    medium: 'https://mizar.com/blog/',
    github: '',
    whitepaper: 'https://docs.mizar.ai/mizar/',
    announcement: '',
  },
  description: (
    <p>
      With over 10,000 active users and millions of volumes traded daily, Mizar
      is the go-to choice for those who demand the best when trading crypto. Say
      goodbye to FOMO and missed opportunities, and embrace hands-free trading
      on your favorite CEX and DEX with advanced bots and smart tools. Share
      your bots with others on the to-be-largest social trading platform in the
      crypto world and earn a passive income. And thanks to the MZR token, you
      can get access to all these features without paying any subscription fee.
    </p>
  ),
};
