import { useEffect, useState } from 'react';
import { fetchNotifications } from './fetchNotifications';
import type { Notification } from './fetchNotifications';
import { useUserExtraData } from '~common/providers/useUserExtraData';

export function useNotifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const { account, lastSeenNotificationDate, updateUserData } =
    useUserExtraData();

  useEffect(() => {
    setUnreadCount(
      notifications.filter(
        (n) =>
          !lastSeenNotificationDate ||
          new Date(n.date).getTime() >
            new Date(lastSeenNotificationDate).getTime(),
      ).length,
    );
  }, [lastSeenNotificationDate, notifications]);

  useEffect(() => {
    fetchNotifications().then(setNotifications);
  }, []);

  useEffect(() => {
    if (!notifications.length || !showNotifications || !account) return;

    const lastNotificationDate = new Date(notifications[0].date);
    if (
      !lastSeenNotificationDate ||
      new Date(lastSeenNotificationDate).getTime() <
        lastNotificationDate.getTime()
    ) {
      updateUserData({ lastSeenNotificationDate: notifications[0].date });
    }
  }, [
    showNotifications,
    notifications,
    account,
    lastSeenNotificationDate,
    updateUserData,
  ]);

  return {
    notifications,
    unreadCount,
    showNotifications,
    onOpen: () => {
      setShowNotifications(!showNotifications);
      setUnreadCount(0);
    },
    onClose: () => setShowNotifications(false),
  };
}
