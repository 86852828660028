import img from 'pools/images/corestarter.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const corestarter: PoolConfig = {
  // id is used in URL
  id: 'corestarter',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xD212C8B21a7fe0Fcd637eEE8636157965c14a047',
    tokensForSale: toWeiBN('1000000'),
    startDate: '2021-11-15T12:00:00.000Z',
    rate: 10,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('999999.99999999999999976'),
    raised: toWeiBN('99999.999999999999999976'),
    baseAllocation: toWeiBN('150.44247787610619469'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('14'),
    },
    registerDate: '2021-11-13T12:00:00.000Z',
    registerDuration: 46,
    fcfsDuration: 1,
    totalWeights: 6590,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: 'G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf',
    name: 'CoreStarter',
    symbol: 'CSTR',
    imageSrc: img.src,
    listingTime: '2021-11-18T12:00:00.000Z',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(6000000),
    totalSupply: toWeiBN(100000000),
    coingeckoId: 'corestarter',
    coinmarketcapId: 'corestarter',
  },
  distribution: {
    type: 'airdrop_them',
    vesting: '50% at TGE 50% after 1 month',
    schedule: {
      '2021-11-18T12:00:00.000Z': 50,
      '2021-12-18T12:00:00.000Z': 50,
    },
  },
  finishedInfo: (
    <div>
      <a href="https://www.gate.io/trade/CSTR_USDT">Trade on Gate.io</a> <br />
      <a href="https://raydium.io/liquidity/?ammId=CqkwivktV3dnEn6mhGKXpXn8i35W8UFNHEadBgvccJ8s">
        Trade on Raydium
      </a>
    </div>
  ),
  social: {
    website: 'https://corestarter.com/',
    twitter: 'https://twitter.com/CoreStarter',
    telegram: 'https://t.me/corestarter_chat',
    telegramAnno: 'https://t.me/corestarter',
    medium: 'https://corestarter.medium.com/',
    github: '',
    whitepaper:
      'https://drive.google.com/file/d/1FBpsb59URy4N4VNWiUrjfjYvfn6OfTVC/view',
    announcement: '',
  },
  description: (
    <p>
      CoreStarter is a cross-chain fundraising launchpad giving power back to
      the small investor. Governance, Staking, Crowdfunding, and NFT marketplace
      are the main use-cases of CoreStarter. The innovation behind CoreStarter
      includes the interoperability of Solana and other blockchains like
      Binance, Ethereum, Matic, Cardano, etc.{' '}
    </p>
  ),
};
