import bnbImg from './bnb.svg';
import busdImg from './busd.svg';
import usdcImg from './usdc.svg';
import usdtImg from './usdt.svg';
import ledgityImg from './ledgity.jpg';
import nftpadImg from './nftpad.jpg';
import tpadv1Img from './tpad1.png';
import tpadImg from './tpad2.png';

export {
  bnbImg,
  busdImg,
  tpadImg,
  tpadv1Img,
  nftpadImg,
  ledgityImg,
  usdcImg,
  usdtImg,
};
