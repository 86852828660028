import img from 'pools/finished/2crazy/images/2CrazyNFT_logo.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { Desc } from '~features/pools/components/PoolDescription';
import { NftPoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const twoCrazyCards: NftPoolConfig = {
  // id is used in URL
  id: '2crazy-cards',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  isNft: true,
  sale: {
    address: '0xB165f66Acff98fC4D2cab971B4cA0479B4B72aE8',
    tokensForSale: toWeiBN('750'),
    startDate: '2022-02-07T11:00:00.000Z',
    rate: 0,
    durationHours: 48,
    hasWhitelist: false,
    isTiered: false,
    participants: 266,
    tokensSold: toWeiBN('0.000000000000000426'),
    raised: toWeiBN('170400'),
    baseAllocation: toWeiBN('3200'),
    status: 5,
    inventory: [
      {
        id: 'mario',
        name: 'The talent Mario Chalmers',
        image: '',
        supply: 750,
        totalSupply: 3000,
        price: toWeiBN('400'),
        internalId:
          '0x6d6172696f000000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 426,
        raised: toWeiBN('170400'),
      },
    ],
    registerDate: '2022-02-05T11:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 0,
    totalWeights: 4010,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('120000'),
    },
    isPublic: true,
    levelsOpenAll: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '',
    name: '2CrazyNFT x Mario Chalmers NFT (Legendary, Epic, Rare or Classic)',
    symbol: '2CRZ',
    imageSrc: img.src,
    decimals: 18,
    listingTime: '2022-02-11T16:30:00.000Z',
    listingInfo: '',
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'mint_them',
    vesting: 'NFTs will be available for minting on Official Minting Date.',
    claimUrl: 'https://app.2crazynft.com/launchpad/claim-mario-chalmers',
    schedule: {
      '2022-02-11T16:30:00.000Z': 100,
    },
  },
  social: {
    website: 'https://www.2crazynft.com/',
    twitter: 'https://twitter.com/2crazylive',
    telegram: 'https://t.me/TwoCrazyNFT',
    medium: 'https://2crazy.medium.com/',
    announcement: '',
  },
  descriptionShort: (
    <p>
      Our vision for 2crazynft is to Integrate between Virtual & Real-world
      Values, We believe that our Project have the potential to Bridge Between
      High profile individual and their fans/followers from all around the
      globe. <br />
      Recently we signed Mario Chalmers on an exclusive NFT collection which
      will features one of his most iconic moments from his basketball career,
      3000 Total NFTs and several real - world value prizes
    </p>
  ),
  description: (
    <>
      <b>
        Note that the rarity level is random, so, more you buy, more likely you
        will get rarer NFTs.
      </b>
      <Desc.Text>
        Almario Vernard "Mario" Chalmers is an NBA American professional
        basketball player for Indios de Mayagüez of the Baloncesto Superior
        Nacional.
      </Desc.Text>
      <Desc.H>Benefits</Desc.H>
      <Desc.Text>
        When you buy a 2CrazyNFT card you become a part of an exclusive members
        club and your 2CrazyNFT card grants access to members-only benefits and
        rewards both on the first purchase of the card and later on down the
        road.
      </Desc.Text>
      <Desc.Text>
        The rewards are split into four rarity levels (Classic, Rare, Epic &
        Legendary). To get rewards of a certain rarity level, you must hold a
        card required by that level and redeem the reward on the 2CrazyNFT
        platform at the appropriate time which will be announced later on after
        the sell. Every 2CrazyNFT holder gets a reward based on the NFT rarity
        he minted. To preserve the NFT value and quality, all 2CrazyNFT are
        dynamic NFTs which means that their holders will get rewards from time
        to time.
      </Desc.Text>
      <table>
        <tr>
          <th>Rarity Level</th>
          <th># of cards</th>
          <th>Reward</th>
        </tr>
        <tr>
          <td>Classic</td>
          <td>1710 cards</td>
          <td>Private Zoom stream with Mario Chalmers</td>
        </tr>
        <tr>
          <td>Rare</td>
          <td>400 cards</td>
          <td>
            Limited edition designed sneakers and T-shirt + official NBA
            basketball signed by Mario Chalmers and other famous NBA Players as
            well
          </td>
        </tr>
        <tr>
          <td>Epic</td>
          <td>45 cards</td>
          <td>
            VIP Seats on Miami Heats Home Game, meeting with Mario Chalmers and
            a few other famous NBA players after the game
          </td>
        </tr>
        <tr>
          <td>Legendary</td>
          <td>5 cards</td>
          <td>
            A fully expense business trip to Miami to hang out with Mario
            Chalmers
          </td>
        </tr>
      </table>
    </>
  ),
};
