import { SaleStatus, useUser } from '@trustpad/launchpad';

import BN from 'bn.js';
import img from 'pools/images/qan.jpg';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaCircleNotch } from 'react-icons/fa';
import { toBN } from 'web3-utils';

import { ConnectWalletButton } from '~common/ConnectButtons/ConnectWalletButton';
import { TELEGRAM_URL, ZERO_BN } from '~config/constants';
import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { fNum, toWeiBN } from '~utils';

export const qan: PoolConfig = {
  // id is used in URL
  id: 'qan',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  token: {
    address: '0xaaa7a10a8ee237ea61e8ac46c50a8db8bcc1baaa',
    name: 'QANplatform',
    symbol: 'QANX',
    imageSrc: img.src,
    decimals: 18,
    listingTime: '2021-06-01T15:00:00.000Z',
    pairAddress: '',
    listingRate: 90.909,
    totalSupply: toWeiBN('3,333,333,000'),
    // BSC, ETH or SOL
    chain: 'BSC',
    extraChains: ['ETH'],
    coingeckoId: 'qanplatform',
    coinmarketcapId: 'qanplatform',
  },
  sale: {
    address: '0x46e8BbCd8e1886a25574743Bf4Dc3816a52E9867',
    status: SaleStatus.finished,
    // Rate tokens per currency, for example: NetVRk is 6.66666 tokens for 1 BUSD
    // Leave empty for TBA
    rate: 90.909,
    // How many tokens are for sale?
    tokensForSale: toWeiBN('18,181,818'),
    // When sale starts? Format: "2021-05-05T18:00:00.000Z", leave empty for TBA
    startDate: '2021-06-01T15:00:00.000Z',
    baseAllocation: toWeiBN('0'),
    // Duration in hours
    durationHours: 7,
    // Is whitelist sale?
    hasWhitelist: true,
    // Can tiers participate?
    isTiered: true,
    // Is public sale? (cancels whitelist and tiers)
    isPublic: false,
    // Total raise, e.g. "$100,000" or "TBA"
    hardCap: '$200,000',
    limits: {
      max: toWeiBN('100'),
      min: ZERO_BN,
    },
    // No need to touch:
    participants: 0,
    tokensSold: toWeiBN('18,181,818'),
    raised: toWeiBN('200000'),
    update: false,
  },
  social: {
    twitter: 'https://twitter.com/QANplatform',
    telegram: 'https://t.me/QANplatform',
    website: 'https://www.qanplatform.com/',
    medium: 'https://qanplatform.medium.com/',
    github: 'https://github.com/QANplatform',
  },
  description: (
    <div>
      Energy-efficient blockchain that is 100x faster than Ethereum. It uses
      lattice cryptography, making it resilient against quantum attacks which
      all other major blockchain networks will be vulnerable to as early as
      2023.
    </div>
  ),
  finishedInfo: function FinishedQAN({ web3, setMessage }) {
    const { account } = useUser();
    const contract = new web3.eth.Contract(
      // @ts-ignore
      abi,
      '0xaaa7a10a8ee237ea61e8ac46c50a8db8bcc1baaa',
    );

    const [unlocked, setUnlocked] = useState<BN>(ZERO_BN);
    const [unlockable, setUnlockable] = useState<BN>(ZERO_BN);
    const [locked, setLocked] = useState<BN>(ZERO_BN);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      if (!web3 || !account) return;
      contract.methods
        .unlockableBalanceOf(account)
        .call()
        .then(toBN)
        .then(setUnlockable);
      contract.methods
        .lockedBalanceOf(account)
        .call()
        .then(toBN)
        .then(setLocked);
      contract.methods
        .unlockedBalanceOf(account)
        .call()
        .then(toBN)
        .then(setUnlocked);
    }, [account, web3]);

    const unlock = () => {
      setIsLoading(true);
      contract.methods
        .unlock(account)
        .send({ from: account })
        .then((tx) => {
          const tokens = toBN(tx.events.Transfer.returnValues['value']);
          setMessage({
            success: true,
            msg: `${fNum(tokens)} QAN tokens unlocked`,
          });
        })
        .finally(() => setIsLoading(false));
    };

    return (
      <div className="text-center">
        <PancakeChartFinishedInfo
          symbol="$QAN"
          address="0xaaa7a10a8ee237ea61e8ac46c50a8db8bcc1baaa"
          pairAddress="0x798bf54a41c8f5baf9b62c72f84ef808ba4a5e79"
        />

        <p className="my-3">
          QAN tokens are locked until 31 Aug. <br />
          You can unlock them daily using the button.
        </p>
        {account && (
          <div className="space-y-1 mb-6 text-left">
            <div>
              Locked:{' '}
              <span className="brand-text text-lg">{fNum(locked)} QAN</span>
            </div>
            <div>
              Unlocked balance:{' '}
              <span className="brand-text text-lg">{fNum(unlocked)} QAN</span>
            </div>
            <div>
              Unlockable:{' '}
              <span className="brand-text text-lg">{fNum(unlockable)} QAN</span>
            </div>
          </div>
        )}

        {account ? (
          <Button variant="main" onClick={unlock} disabled={isLoading}>
            Unlock QAN{' '}
            {isLoading && (
              <FaCircleNotch size={20} className="inline fa-spin" />
            )}
          </Button>
        ) : (
          <ConnectWalletButton />
        )}

        <p className="mt-6">
          <b>If you don't see the full amount of your TrustPad allocation.</b>{' '}
          Due to how QAN implemented the locking system, some addresses didn't
          receive their tokens. If the total amount above doesn't match with
          your TrustPad allocation, please let us know in our{' '}
          <a href={TELEGRAM_URL}>official Telegram Group</a>.
        </p>
      </div>
    );
  },
};

const abi = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint32',
        name: 'hardLockUntil',
        type: 'uint32',
      },
      {
        indexed: false,
        internalType: 'uint32',
        name: 'softLockUntil',
        type: 'uint32',
      },
      {
        indexed: false,
        internalType: 'uint8',
        name: 'allowedHops',
        type: 'uint8',
      },
    ],
    name: 'LockApplied',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'LockRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'getQuantumPubkeyHash',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'lockOf',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'tokenAmount', type: 'uint256' },
          { internalType: 'uint32', name: 'hardLockUntil', type: 'uint32' },
          { internalType: 'uint32', name: 'softLockUntil', type: 'uint32' },
          { internalType: 'uint8', name: 'allowedHops', type: 'uint8' },
          { internalType: 'uint32', name: 'lastUnlock', type: 'uint32' },
          { internalType: 'uint256', name: 'unlockPerSec', type: 'uint256' },
        ],
        internalType: 'struct QANX.Lock',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'lockedBalanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'qPubKeyHash', type: 'bytes32' }],
    name: 'setQuantumPubkeyHash',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint32', name: 'hardLockUntil', type: 'uint32' },
      { internalType: 'uint32', name: 'softLockUntil', type: 'uint32' },
      { internalType: 'uint8', name: 'allowedHops', type: 'uint8' },
    ],
    name: 'transferLocked',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'unlock',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'unlockableBalanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'unlockedBalanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
];
