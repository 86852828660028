import React from 'react';
import { FaCircleNotch, FaSpinner } from 'react-icons/fa';
import { Button, ButtonProps } from './Button';

export function ActionButton({
  isLoading,
  isDisabled,
  onClick,
  className,
  children,
  ...props
}: {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick: VoidFunction;
  className?: string;
  children: React.ReactNode;
} & ButtonProps) {
  return (
    <Button
      type="primary"
      disabled={isDisabled}
      onClick={() => onClick()}
      size={props.size || 'md'}
      className={`relative ${className ? className : ''}`}
      {...props}>
      {isLoading && (
        <FaSpinner className="fa-spin absolute top-0 right-0 text-sm" />
      )}
      {children}
    </Button>
  );
}
