import React from 'react';
import { Chain } from 'src/types';

import { BoggedChartLink } from '~features/launched/BoggedChartLink';
import {
  BuyApeSwapButton,
  BuyPancakeSwapButton,
  BuyQuickswapButton,
  BuyRaydiumButton,
  BuyUniSwapButton,
} from '~features/launched/BuyButtons';
import { ChartDropdownButton } from '~features/launched/chart';

export function PancakeChartFinishedInfo({
  address,
  pairAddress,
  symbol,
  chain,
  swapOn = 'PANCAKE',
}: {
  address?: string;
  pairAddress?: string;
  chain?: Chain;
  symbol: string;
  swapOn?: 'APE' | 'PANCAKE';
}) {
  return (
    <div className="flex space-x-3 items-center justify-center">
      {address &&
        (swapOn === 'PANCAKE' ? (
          <BuyPancakeSwapButton address={address} symbol={symbol} />
        ) : swapOn === 'APE' ? (
          <BuyApeSwapButton address={address} symbol={symbol} />
        ) : null)}

      {(address || pairAddress) && (
        <ChartDropdownButton
          address={address}
          pairAddress={pairAddress}
          chain={chain || 'BSC'}
        />
      )}
    </div>
  );
}

export function UniswapChartFinishedInfo({
  address,
  pairAddress,
  symbol,
  chain = 'ETH',
}: {
  address?: string;
  pairAddress?: string;
  symbol: string;
  chain?: Chain;
}) {
  return (
    <div className="flex space-x-3 items-center justify-center">
      {address && <BuyUniSwapButton address={address} symbol={symbol} />}
      {(address || pairAddress) && (
        <ChartDropdownButton
          address={address}
          pairAddress={pairAddress}
          chain={chain}
        />
      )}
    </div>
  );
}

export function QuickswapChartFinishedInfo({
  address,
  pairAddress,
  symbol,
}: {
  address?: string;
  pairAddress?: string;
  symbol: string;
}) {
  return (
    <div className="flex space-x-3 items-center justify-center">
      {address && <BuyQuickswapButton address={address} symbol={symbol} />}
      {(address || pairAddress) && (
        <ChartDropdownButton
          address={address}
          pairAddress={pairAddress}
          chain="POLY"
        />
      )}
    </div>
  );
}

export function SolanaChartFinishedInfo({
  address,
  pairAddress,
  symbol,
}: {
  address?: string;
  pairAddress?: string;
  symbol: string;
}) {
  return (
    <div className="flex space-x-3 items-center justify-center">
      {address && <BuyRaydiumButton address={address} symbol={symbol} />}
      {(address || pairAddress) && (
        <ChartDropdownButton
          address={address}
          pairAddress={pairAddress}
          chain="SOL"
        />
      )}
    </div>
  );
}
