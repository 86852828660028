import img from 'pools/images/triall.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const triall: PoolConfig = {
  // id is used in URL
  id: 'triall',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: false,
  kyc: true,
  sale: {
    address: '0xdee85d8B693d00Eb03533183B85221ABa4d72cAb',
    status: 5,
    rate: 5,
    tokensForSale: toWeiBN('1000000'),
    startDate: '2021-09-28T08:00:00.000Z',
    baseAllocation: toWeiBN('213.768910326682745012'),
    durationHours: 4,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    participants: 1218,
    tokensSold: toWeiBN('1000000'),
    raised: toWeiBN('200000'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-09-23T12:00:00.000Z',
    registerDuration: 115,
    fcfsDuration: 1,
    totalWeights: 4593,
    levelsOpenAll: true,
    fcfsMultiplier: 10000,
  },
  token: {
    address: '0x58f9102bf53cf186682bd9a281d3cd3c616eec41',
    name: 'Triall',
    symbol: 'TRL',
    imageSrc: img.src,
    decimals: 18,
    totalSupply: toWeiBN(175_000_000),
    initialSupply: toWeiBN(3_741_250),
    listingRate: 0,
    // BSC, ETH, DOT or SOL
    chain: 'ETH',
    listingTime: '2021-09-28T13:05:00.000Z',
    coingeckoId: 'triall',
    coinmarketcapId: 'triall',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="TRL"
      address="0x58f9102bf53cf186682bd9a281d3cd3c616eec41"
      pairAddress="0xd8901e4b3681955635c9b41b144828629d59d739"
    />
  ),
  distribution: {
    network: 'ETH',
    type: 'claim_us',
    vesting: '10% at TGE, 5 months linear',
    claimerVersion: 'v1',
    claimerAddress: '0x23a591b8589a3eAa9269773A91A10Bd323A206a4',
    schedule: {
      '2021-09-28T13:05:00.000Z': 10,
      '2021-10-28T13:05:00.000Z': 18,
      '2021-11-28T13:05:00.000Z': 18,
      '2021-12-28T13:05:00.000Z': 18,
      '2022-01-28T13:05:00.000Z': 18,
      '2022-02-28T13:05:00.000Z': 18,
    },
  },
  social: {
    website: 'https://www.triall.io/',
    twitter: 'https://twitter.com/triallofficial',
    telegram: 'https://t.me/triallcommunity',
    telegramAnno: 'https://t.me/triallofficial',
    medium: 'https://insights.triall.io/',
    whitepaper:
      'https://www.triall.io/wp-content/uploads/2021/04/Triall-Whitepaper-v2.1.pdf',
  },
  description: (
    <div>
      Triall’s mission is to accelerate the introduction of safe and affordable
      vaccines and therapeutics into society by streamlining clinical trials.
    </div>
  ),
};
