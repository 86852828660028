import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/plutos.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const plutos: PoolConfig = {
  id: 'plutos',
  access: 'public',
  fundToken: BUSD,
  linkToDetailPage: true,
  distribution: {
    type: 'claim_us',
    claimerVersion: 'v1',
    claimerAddress: '0x1CBFbA5B8c82196233cEABd3b4D6e9FC5bf9D96B',
    vesting: `Public round ($125k, $0.3 per token): 100% at TGE. Private round ($25k, $0.2 per token): 25% at TGE, 
  monthly vesting over 12 months for the rest starting from the 3rd month after TGE.`,
    schedule: {
      '2021-06-22T15:30:00.000Z': 82.7,
      '2021-08-22T15:30:00.000Z': 1.73,
      '2021-09-22T15:30:00.000Z': 1.73,
      '2021-10-22T15:30:00.000Z': 1.73,
      '2021-11-22T15:30:00.000Z': 1.73,
      '2021-12-22T15:30:00.000Z': 1.73,
      '2022-01-22T15:30:00.000Z': 1.73,
      '2022-02-22T15:30:00.000Z': 1.73,
      '2022-03-22T15:30:00.000Z': 1.73,
      '2022-04-22T15:30:00.000Z': 1.73,
      '2022-05-22T15:30:00.000Z': 1.73,
    },
  },
  sale: {
    address: '0xa8cef7987424a3720d1C89B7A414C3ba1fBc493C',
    status: SaleStatus.finished,
    rate: 3.61111,
    tokensForSale: toWeiBN('541666.66666667'),
    startDate: '2021-06-22T12:00:00.000Z',
    baseAllocation: toWeiBN('213'),
    durationHours: 3,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$150,000',
    participants: 800,
    tokensSold: toWeiBN('541666.66666667'),
    raised: toWeiBN('150000'),
    update: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('150'),
    },
    registerDate: '2021-06-21T12:00:00.000Z',
    registerDuration: 22,
    fcfsDuration: 0.5,
    levelsOpenAll: true,
  },
  token: {
    address: '0x2984f825bfe72e55e1725d5c020258e81ff97450',
    name: 'Plutos Network',
    listingTime: '2021-06-22T12:00:00.000Z',
    symbol: 'PLUT',
    imageSrc: img.src,
    decimals: 18,
    type: 'BSC',
    pairAddress: '',
    listingRate: 0,
    totalSupply: toWeiBN('0'),
    chain: 'BSC',
    coingeckoId: 'plutos-network',
    coinmarketcapId: 'plutos-network',
  },
  social: {
    twitter: 'https://twitter.com/PlutosNetwork',
    medium: 'https://medium.com/plutosnetwork',
    telegram: 'https://t.me/plutosnetwork',
    telegramAnno: 'https://t.me/plutosANN',
    website: 'https://www.plutos.network/',
  },
  description: (
    <div>
      Plutos Network is a cross-chain synthetic issuance and derivative trading
      platform which introduces mining incentives and Staking rewards to users.
    </div>
  ),
  finishedInfo: (
    <div>
      Check out the{' '}
      <a href="https://t.me/plutosANN">
        Plutos Network Announcements official Telegram channel
      </a>{' '}
      for information about PancakeSwap listing. <br />
      <br />
      <PancakeChartFinishedInfo
        symbol="$PLUT"
        address="0x2984f825bfe72e55e1725d5c020258e81ff97450"
        pairAddress="0xaaf4b6838cfb3348340ed9f2346fcc3880f8df9b"
      />
    </div>
  ),
};
