import img from 'pools/images/mundo.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const mundo: PoolConfig = {
  // id is used in URL
  id: 'mundo',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xb0678c797508DA14F3b1e947645bC74b11bF9979',
    tokensForSale: toWeiBN('1055555.45'),
    startDate: '2022-03-17T11:00:00.000Z',
    rate: 5.555,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1055555.449999999999999906'),
    raised: toWeiBN('190018.9828982898289829'),
    baseAllocation: toWeiBN('143.164848864744393369'),
    status: 5,
    registerDate: '2022-03-15T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7235,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '10% at TGE, then 30% per month for 3 months',
    claimerAddress: '0x69D7e15262b7c9c7d2e3801c190A6D0Ed48E0aaf',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-19T17:30:00.000Z': 10,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x44757fa720548d472b5a87a119401627f935a6a2',
    pairAddress: '0x1a94aa94c9d55971f6bf78941ae70892ccb017e8',
    name: 'MUNDO',
    symbol: 'MUNDO',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-19T17:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Pancakeswap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(2242222),
    totalSupply: toWeiBN(200_000_000),
    coingeckoId: 'mundo-token',
    coinmarketcapId: 'mundo',
  },
  social: {
    website: 'https://www.mundo.gg/',
    twitter: 'https://twitter.com/mundo_mmorpg',
    telegram: 'https://t.me/mundo_mmorpg',
    telegramAnno: 'https://t.me/MundoAnnouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      MUNDO is a blockchain-based open world fantasy MMORPG free to play, play
      to earn game where anyone can earn tokens through grinding and skilled pvp
      gameplay. Players can grind 24/7, battle, raise pet companion, raid
      bosses, hunt for rare NFT's in-game and so much more. Our main goal is to
      provide people with a safe and trustworthy place where they can use their
      token for rewards and buying or selling of NFT's.
      <br />
      <br />
      Refunded 90%, see the{' '}
      <a href="https://bscscan.com/tx/0x4f6a30049eec34c64dc2b1cde7506bdf5a14aa77d72a248720517c96546a0f30">
        TX
      </a>
      .
    </p>
  ),
};
