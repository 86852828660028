import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/blokpad.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const blokpad: PoolConfig = {
  // id is used in URL
  id: 'blokpad',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x815C5C4ae7cBf5026f13695a5D5512F2f3554C0f',
    tokensForSale: toWeiBN('80000000'),
    startDate: '2022-01-28T11:00:00.000Z',
    rate: 800,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('80000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('8999.437253798536859876'),
    status: 5,
    registerDate: '2022-01-26T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 8548,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x29132062319aa375e764ef8ef756f2b28c77a9c9',
    name: 'BLOKPAD',
    symbol: 'BPAD',
    imageSrc: img.src,
    listingTime: '2022-01-31T17:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(243_750_000),
    totalSupply: toWeiBN(7_500_000_000),
    coingeckoId: 'blokpad',
    coinmarketcapId: 'blokpad',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="BPAD"
      address="0x29132062319aa375e764ef8ef756f2b28c77a9c9"
      pairAddress="0xdab856d0ae21c73ceb08bed95c02399899102994"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '12.5% monthly',
    claimerVersion: 'v4',
    claimerAddress: '0x87268B84Ab5477Fd15a681836085937e069CAC80',
    // They send us BPAD per batch, sent to the old claimer: 60M
    // claimerAddress: '0xa805ABa1Dd8106b41A676E64FB7db08121292D69', // OLD
    schedule: {
      '2022-01-31T17:30:00.000Z': 12.5,
      '2022-02-28T17:30:00.000Z': 12.5,
      '2022-03-29T15:00:00.000Z': 12.5,
      '2022-04-29T15:00:00.000Z': 12.5,
      '2022-05-31T15:00:00.000Z': 12.5,
      '2022-06-30T15:00:00.000Z': 12.5,
      '2022-07-31T15:00:00.000Z': 12.5,
      '2022-08-31T15:00:00.000Z': 12.5,
    },
  },
  social: {
    website: 'https://bloklaunchpad.com/',
    twitter: 'https://twitter.com/OfficialBLOKPAD',
    telegram: 'https://t.me/OfficialBLOKPADChat',
    telegramAnno: 'https://t.me/OfficialBLOKPADAnn',
    medium: 'https://medium.com/@bloktopia',
    github: '',
    whitepaper:
      'https://www.bloktopia.com/wp-content/uploads/2022/01/Blokpad-Lightpaper-.pdf',
    announcement: '',
  },
  description: (
    <>
      <p>
        BLOKPAD by BLOKTOPIA is a multi-chain launchpad with 2 distinct arms.
        BLOKPAD VIP which incubates, nurtures and launches early stage crypto
        projects with the extensive knowledge and network gained from our own
        launch of BLOKTOPIA. The second is BLOKPAD ESSENTIAL which solely acts
        as a traditional launchpad for crypto projects.
      </p>
      <p>
        $BPAD is the native token of BLOKPAD ESSENTIAL used to gain allocations
        in launching projects, whereas $BLOK is the native tokens used on
        BLOKPAD VIP.
      </p>
      <p>
        When either $BPAD or $BLOK is used to stake to gain entires and
        allocations on the projects launching on BLOKPAD $BPAD will be given as
        staking rewards in both BLOKPAD VIP and BLOKPAD ESSENTIAL.
      </p>
    </>
  ),
};
