import { createClient } from "contentful";

export type Notification = {
  id: string;
  title: string;
  date: string;
  body: any;
};

export async function fetchNotifications(): Promise<Notification[]> {
  const client = createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN
  });
  // This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.
  const response = await client
    .getEntries({
      content_type: "notification",
      order: "-sys.createdAt"
    })
    .catch((err) => {
      console.log(err);
      return { items: [] };
    });

  return response.items.map((v) => ({
    id: v.sys.id,
    date: v.sys.updatedAt,
    ...v.fields
  })) as Notification[];
}