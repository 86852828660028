import { useEffectWithWeb3 } from '@trustpad/launchpad';
import { CONTRACTS } from '@trustpad/launchpad/dist/abi';
import {
  MulticallCall,
  multicallv2,
} from '@trustpad/launchpad/dist/shared/utils/multicall';
import BN from 'bn.js';
import { getServerWeb3 } from 'lib/serverWeb3';
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { toBN } from 'web3-utils';
import { BURN_ADDRESS, BURN_ADDRESS_ZERO, ZERO_BN } from '~config/constants';
import { TPAD, TPADV1 } from '~config/tokens';
import { normalizeDecimals, toEthN } from '~utils';

const TOTAL_SUPPLY = 1_000_000_000;

export function useBurntAmount() {
  const [burntAmount, setBurntAmount] = useState(0);

  useEffectWithWeb3((web3) => {
    const load = () =>
      getTPADv2BurntAmount(web3).then((amount) => {
        setBurntAmount(amount);
      });
    const id = setInterval(load, 1000 * 10);
    load();

    return () => {
      clearInterval(id);
    };
  }, []);

  return {
    amount: burntAmount,
    getPercent: (amount) => (amount / TOTAL_SUPPLY) * 100,
  };
}

async function getTPADv2BurntAmount(web3: Web3): Promise<number> {
  const tokenAddress = TPAD.address;

  const burnTPAD1Calls = [
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: [TPADV1.address],
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: [BURN_ADDRESS],
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: [BURN_ADDRESS_ZERO],
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: ['0xeA981417857cB52B0AFd218F53Ee83cabDC390Ed'], // treasury (ido staking)
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: ['0x1e442E7d3BcDe23fC21b52efbeF10473Ca2dCe46'], // treasury (tpad staking)
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: ['0xb058057bd93b38bb55092e84775967ce8ba8d78d'], // treasury (LP staking)
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: ['0xA37e3b6962de67362325ff5a44fF4414D617A215'], // TPAD staking
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: ['0x2b1AD6eBaD818D5E0B29c694577E70f6E9344E91'], // old IDO staking
    },
    {
      address: TPADV1.address,
      name: 'balanceOf',
      params: ['0xfd657e33808237c060f49a0d0e785bac9968d46a'], // liquidity
    },
  ];
  const [...tpad1Amounts] = (await multicallv2({
    web3,
    abi: CONTRACTS.erc20.abi,
    calls: burnTPAD1Calls,
    options: { unwrapValues: true },
  })) as string[];
  const burntFromTpad1 = normalizeDecimals(
    tpad1Amounts.reduce((acc, value) => acc.add(toBN(value)), ZERO_BN).muln(10),
    TPADV1.decimals,
  );

  const calls: MulticallCall[] = [
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [BURN_ADDRESS],
    },
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [BURN_ADDRESS_ZERO],
    },
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [tokenAddress],
    },
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: ['0x391dcBDdD99400f99fEAA934B22E9D1b131FAFE9'], // burn tokens fees collector
    },
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: ['0x15E7bB0B9ac69Ed455f677C6cC7fB5fe8F4B4Cb8'], // not claimed on the claimer
    },
  ];

  const [...amounts] = (await multicallv2({
    web3,
    abi: CONTRACTS.erc20.abi,
    calls,
    options: { unwrapValues: true },
  })) as string[];

  const burntTpad2 = amounts.reduce(
    (acc, value) => acc.add(toBN(value)),
    ZERO_BN,
  );

  const totalBurnt = burntTpad2.add(burntFromTpad1);

  return toEthN(totalBurnt);
}
