import annImg from 'pools/images/auditchain-ann.jpeg';
import img from 'pools/images/auditchain.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const auditchain: PoolConfig = {
  // id is used in URL
  id: 'auditchain',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xb6b442ebD3761563fdC639668C7c3B6C4E85441E',
    tokensForSale: toWeiBN('1000000'),
    startDate: '2021-12-14T12:00:00.000Z',
    rate: 5,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1000000'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('146.440372484229498347'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-12T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6686,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x91c5a5488c0decde1eacd8a4f10e0942fb925067',
    addresses: {
      ETH: '0xb90cb79b72eb10c39cbdf86e50b1c89f6a235f2e',
    },
    name: 'Auditchain',
    symbol: 'AUDT',
    imageSrc: img.src,
    listingTime: '2022-03-09T16:00:00.000',
    listingInfo:
      'Lists AUDT/USDT on Bittrex. You MUST BRIDGE to ETH before depositing to Bittrex',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(1_975_000),
    totalSupply: toWeiBN(250_000_000),
    coingeckoId: 'auditchain',
  },
  images: {
    seo: annImg.src,
  },
  distribution: {
    type: 'claim_linear',
    network: 'POLY',
    vesting:
      'We refunded 55% according to how much you claimed. The remaining tokens are claimable according to the vesting schedule.',
    claimerAddress: '0x80c683a6C50c1F90952154111691dD18A81b469E',
    config: {
      vestingStartDate: '2022-03-07T16:00:00.000',
      vestingDuration: 365 * 24 * 3600,
    },
  },
  social: {
    website: 'https://auditchain.finance/',
    twitter: 'https://twitter.com/auditchain',
    telegram: 'https://t.me/Auditchain_Community',
    telegramAnno: 'https://t.me/Auditchain',
    medium: '',
    github: '',
    whitepaper: 'https://auditchain.finance/whitepaper-v1.pdf',
    announcement: '',
  },
  description: (
    <p>
      Auditchain is the world’s first decentralized accounting, audit, financial
      reporting, and analysis metaverse that automates and provides proof of
      assurance on the world’s business and financial information. Creators
      write and own accounting and disclosure control NFTs that pay royalties in
      AUDT when they are used on the Auditchain Protocol.
    </p>
  ),
};
