import * as React from 'react';
import CountUp from 'react-countup';
import { FaExclamationTriangle } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { PageLayout } from '~common/PageLayout';
import { StandardCard } from '~common/UI';
import { ZERO_BN } from '~config/constants';
import TokenStatsCard from '~features/landing/TokenStatsCard';
import { BurnClaimer } from '~features/tpadv2/BurnClaimer';
import { useBurnClaimer } from '~features/tpadv2/useBurnClaimer';
import { useBurntAmount } from '~features/tpadv2/useBurntAmount';
import { Countdown, ProgressBar } from '~features/ui';
import { AccordionItem } from '~features/ui/Accordion';
import { fNum, toEthN, toWeiBN } from '~utils';

export const CLAIMER_ADDRESS = '0x15E7bB0B9ac69Ed455f677C6cC7fB5fe8F4B4Cb8';

// Deployer
const deduct = 150_000_000 + 65_331_312 + 10_000_000;
const addBurn = 10_759_598 + 2_125_000;

export function TpadV2Page() {
  const claimer = useBurnClaimer(CLAIMER_ADDRESS);
  const burnt = useBurntAmount();
  const toBurn = claimer.totalStats
    ? 1_000_000_000 -
      (toEthN(
        claimer.totalStats.claimedTokens.add(
          claimer.stakingStats?.claimedTokens || ZERO_BN,
        ),
      ) +
        deduct) +
      toEthN(
        claimer.totalStats.burnedTokens
          .add(claimer.totalStats.collectedFeeTokens)
          .add(claimer.stakingStats?.burnedTokens || ZERO_BN),
      ) +
      addBurn
    : 0;
  const stakingPoolsUserAmount =
    claimer.stakingPools?.reduce(
      (acc, pool) => acc.add(pool.amount),
      ZERO_BN,
    ) || ZERO_BN;
  const burnedTokens =
    claimer.totalStats && claimer.stakingStats
      ? claimer.totalStats.burnedTokens
          .add(claimer.totalStats.collectedFeeTokens)
          .add(claimer.stakingStats.burnedTokens)
      : 0;
  const totalClaimed =
    claimer.totalStats && claimer.stakingStats
      ? claimer.totalStats.claimedTokens
          .add(claimer.stakingStats.claimedTokens)
          .add(toWeiBN(deduct))
      : ZERO_BN;
  const claimedPercent =
    claimer.totalStats && claimer.stakingStats
      ? (toEthN(totalClaimed) /
          toEthN(claimer.totalStats.tokens.add(claimer.stakingStats.tokens))) *
        100
      : 0;

  return (
    <>
      <div className="flex justify-center gap-6 flex-col md:flex-row">
        <div className="py-3 px-9 text-center bg-card-secondary bg-opacity-30 rounded-2xl shadow-2xl highlight-shadow">
          <div className="text-5xl md:text-6xl mb-2">
            <span className="brand-text">
              <CountUp
                start={100}
                end={burnt.getPercent(toBurn)}
                duration={2}
                decimals={1}
              />
              %
            </span>{' '}
            🔥
          </div>
          <div className="text-sm -mt-2 mb-1 secondary-text">
            of the total supply is set to be burned.
          </div>

          <div className="text">
            <CountUp
              formattingFn={(v) => fNum(v, 1).toString()}
              className="brand-text"
              start={0}
              end={toBurn}
              duration={2}
              decimals={0}
            />{' '}
            <span className="">$TPAD V2 tokens in total</span>
          </div>
        </div>

        <div className="py-3 px-9 text-center bg-card-secondary bg-opacity-30 rounded-2xl shadow-2xl highlight-shadow">
          <h4 className="uppercase font-normal text-sm md:text-lg">
            Countdown to the big burn
          </h4>

          <div>MIGRATION IS DONE 🎉</div>
        </div>
      </div>

      <div className="w-full lg:w-1/2 mx-auto my-6">
        <ProgressBar
          progress={claimedPercent}
          height="1.25rem"
          withLabel
          className="bg-mainDark"
        />
      </div>

      <PageLayout
        title="TPAD 2.0 Migration"
        links={[
          {
            name: 'Staking dashboard',
            href: '/levels',
          },
        ]}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2 h-full order-2">
            <StandardCard cardClassName="mb-3 h-full" className="space-y-6">
              <h2>Claim your TPAD V2 tokens</h2>

              <p>
                For all TPAD V1 holders and stakers; please migrate your tokens
                to TPAD V2 below. More details about the migration{' '}
                <a href="https://trustpad.medium.com/tpad-2-0-claiming-details-449d38043cf1">
                  here
                </a>
              </p>

              <p>
                <b>Migration Ends:</b> Jan 10rd, 2024, 15:00 UTC. <br />
                <br />
                Once migration period ends, the unclaimed tokens will be burned
                and taken out of circulation permanently. Make sure to claim
                before the deadline.
              </p>

              <BurnClaimer claimer={claimer} symbol="TPAD" />

              <div>
                <AccordionItem
                  title={
                    <>
                      <FaExclamationTriangle className="text-acc inline-block align-text-top mr-1" />
                      {'  '}
                      IMPORTANT
                    </>
                  }>
                  <div className="space-y-3">
                    <p>
                      If you claim your amount with an early claiming fee, you
                      will lose the fee amount.
                    </p>

                    <p>
                      All the unclaimed tokens will be burned after the claiming
                      window closes, so make sure to claim before the deadline.
                    </p>

                    {/*<p>*/}
                    {/*  The pre-exploit snapshot was taken on Nov 6th. 2023 at*/}
                    {/*  16:34:59 UTC at the{' '}*/}
                    {/*  <a*/}
                    {/*    href="https://bscscan.com/block/33261034"*/}
                    {/*    target="_blank">*/}
                    {/*    block number 33261034*/}
                    {/*  </a>*/}
                    {/*  .*/}
                    {/*</p>*/}
                  </div>
                </AccordionItem>
              </div>
            </StandardCard>
          </div>
          <div className="order-1 md:order-2">
            {claimer.isLoaded ? (
              <div className="grid grid-cols-2 md:grid-cols-1 gap-3 md:gap-6">
                {claimer.userStats && (
                  <TokenStatsCard label="YOUR CLAIMABLE TOKENS">
                    {claimer?.userStats.claimableTokens
                      ? fNum(claimer?.userStats.claimableTokens, 1)
                      : 0}
                  </TokenStatsCard>
                )}
                {claimer.userStats && stakingPoolsUserAmount.gtn(0) && (
                  <TokenStatsCard label="YOUR STAKED TOKENS">
                    {fNum(stakingPoolsUserAmount, 1)}
                  </TokenStatsCard>
                )}
                <div className="secondary-text mx-auto text-2xl leading-none col-span-2 md:col-span-1">
                  STATS
                </div>
                <TokenStatsCard label="TOTAL CLAIMED">
                  {fNum(totalClaimed, 1)}
                </TokenStatsCard>
                <TokenStatsCard label="TOTAL TPAD TO BE BURNED 🔥">
                  {fNum(toBurn, 1)}
                </TokenStatsCard>
              </div>
            ) : (
              <Skeleton count={6} />
            )}
          </div>
        </div>
      </PageLayout>
    </>
  );
}
