import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/vaulty.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig, PoolToken } from '~features/pools/types';

import { Chain } from '../../src/types';
import { toWeiBN } from '../../src/utils';

export const commonVaulty = {
  token: {
    address: '0xb35c9eaa5957563594f7411c6ca2755640253c15',
    name: 'Vaulty',
    symbol: 'VLTY',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingRate: 1.428571,
    totalSupply: toWeiBN(15_000_000),
    // BSC, ETH, DOT or SOL
    chain: 'BSC' as Chain,
    initialSupply: toWeiBN(943_124),
    listingTime: '2021-08-17T14:30:00.000Z',
    coingeckoId: 'vaulty-token',
    coinmarketcapId: 'vaulty-finance',
  } as PoolToken,
  social: {
    website: 'https://vaulty.fi/',
    twitter: 'https://twitter.com/VaultyFi',
    telegram: 'https://t.me/vaultychat_new',
    telegramAnno: 'https://t.me/VaultyANN_new',
    medium: 'https://medium.com/@Vaulty.fi',
    whitepaper: 'https://vaulty.fi/docs/Vaulty.pdf',
  },
  description: (
    <div>
      Vaulty is a decentralized platform to bring automatic asset allocation
      across DeFi instruments. Vaulty allows users to make an automatic
      compounding of earnings from a 10 minute period.
    </div>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="$VLTY"
        address="0xb35c9eaa5957563594f7411c6ca2755640253c15"
        pairAddress="0x8bdffe0f00d0597bf7ac82bb6683060a3e1e905c"
      />
    </div>
  ),
};

export const vaulty: PoolConfig = {
  ...commonVaulty,
  // id is used in URL
  id: 'vaulty',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  sale: {
    address: '0x9709DCd58379147Fe9931bDe6BcF63e8A6bbaD22',
    status: SaleStatus.finished,
    rate: 1.428571,
    tokensForSale: toWeiBN('128571.4'),
    startDate: '2021-08-10T09:00:00.000Z',
    baseAllocation: toWeiBN('88'),
    durationHours: 8,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$90,000',
    participants: 852,
    tokensSold: toWeiBN('128571.4'),
    raised: toWeiBN('90000.00700000210000063'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-08-03T09:00:00.000Z',
    registerDuration: 167,
    fcfsDuration: 0.5,
    levelsOpenAll: true,
  },
  distribution: {
    type: 'claim_us',
    vesting: '25% on TGE, then 25% monthly',
    claimerVersion: 'v1',
    claimerAddress: '0x2F0719efECa7F2D026e27598a26d9D4F0d84a84D',
    schedule: {
      '2021-08-17T14:30:00.000Z': 25,
      '2021-09-17T14:30:00.000Z': 25,
      '2021-10-17T14:30:00.000Z': 25,
      '2021-11-17T14:30:00.000Z': 25,
    },
  },
};
