import { SaleStatus } from '@trustpad/launchpad';

import nftpadVesting from 'pools/images/nftpadVesting.jpg';
import React from 'react';

import { nftpadImg } from '~config/images';
import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { DescriptionImg } from '~features/pools/components/PoolDescription/DescriptionImg';
import { PoolConfig, PoolToken } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const nftpad: PoolConfig = {
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: false,
  token: {
    address: '0x4a72AF9609d22Bf2fF227AEC333c7d0860f3dB36',
    name: 'NFTPad.fi',
    symbol: 'NFTPAD',
    imageSrc: nftpadImg.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingRate: 50,
    totalSupply: toWeiBN(200_000_000),
    // BSC, ETH, DOT or SOL
    chain: 'BSC' as const,
    initialSupply: toWeiBN(2_800_000),
    listingTime: '2021-08-24T19:00:00.000Z',
    coingeckoId: 'nftpad',
    coinmarketcapId: 'nftpad',
  } as PoolToken,
  social: {
    website: 'https://nftpad.fi/',
    twitter: 'https://twitter.com/nftpadofficial',
    telegram: 'https://t.me/NFTPad',
    telegramAnno: 'https://t.me/NFTPad_ANN',
    medium: 'https://medium.com/@NFTPad',
  },
  descriptionShort: (
    <p>
      NFTPad is the first deflationary cross-chain NFT platform allowing users
      to create NFTs in minutes, whilst raising liquidity so that they may be
      traded on AMMs such as PancakeSwap.
      <br />
      <br />
      NFTPad is launching on BNB Chain; a curated 'OpenSea' available on BSC.
    </p>
  ),
  description: (
    <>
      <p>
        NFTPad is the first deflationary cross-chain NFT platform allowing users
        to create NFTs in minutes, whilst raising liquidity so that they may be
        traded on AMMs such as PancakeSwap.
        <br />
        <br />
        NFTPad is launching on BNB Chain; a curated 'OpenSea' available on BSC.
      </p>
      <DescriptionImg src={nftpadVesting.src} />
    </>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="NFTPAD"
        address="0x4a72AF9609d22Bf2fF227AEC333c7d0860f3dB36"
        pairAddress="0x4a24d6f25f72226ac81d9dc3d38f73541c8b764e"
      />
    </div>
  ),
  // id is used in URL
  id: 'nftpad',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x2823109a2Bf9A9A6822FaF9f1683dF92e12b9752',
    status: SaleStatus.finished,
    rate: 55.55,
    tokensForSale: toWeiBN('11111111.11'),
    startDate: '2021-08-24T12:00:00.000Z',
    baseAllocation: toWeiBN('2551.931812381972898484'),
    durationHours: 6,
    hasWhitelist: false,
    isTiered: true,
    isPublic: false,
    participants: 1148,
    tokensSold: toWeiBN('11111111.11'),
    raised: toWeiBN('200020.00198019801980198'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-08-19T12:00:00.000Z',
    registerDuration: 118,
    fcfsDuration: 1,
    levelsOpenAll: true,
  },
  distribution: [
    {
      title: 'IDO Distribution',
      type: 'claim_us',
      vesting:
        '5% at listing, 15% second month, 10% monthly. Seed and Private rounds should claim here',
      // funded ALL 51.4M
      claimerVersion: 'v1',
      claimerAddress: '0xbe9Ff96c1C6e2e9e32991409b56A17B7bD483602',
      schedule: {
        '2021-08-24T19:00:00.000Z': 5,
        '2021-09-24T20:00:00.000Z': 15,
        '2021-10-24T20:00:00.000Z': 10,
        '2021-11-24T20:00:00.000Z': 10,
        '2021-12-24T20:00:00.000Z': 10,
        '2022-01-24T20:00:00.000Z': 10,
        '2022-02-24T20:00:00.000Z': 10,
        '2022-03-24T20:00:00.000Z': 10,
        '2022-04-24T20:00:00.000Z': 10,
        '2022-05-24T20:00:00.000Z': 10,
      },
    },
    {
      title: 'Public IDO Distribution',
      type: 'claim_us',
      vesting: '10% at listing, 5% weekly',
      // funded ALL 5M
      claimerVersion: 'v1',
      claimerAddress: '0xe1269bc657eBce81F7a2c54d9c552B6BE1eD9179',
      schedule: {
        '2021-08-24T19:00:00.000Z': 10,
        '2021-08-31T20:00:00.000Z': 5,
        '2021-09-07T20:00:00.000Z': 5,
        '2021-09-14T20:00:00.000Z': 5,
        '2021-09-21T20:00:00.000Z': 5,
        '2021-09-28T20:00:00.000Z': 5,
        '2021-10-05T20:00:00.000Z': 5,
        '2021-10-12T20:00:00.000Z': 5,
        '2021-10-19T20:00:00.000Z': 5,
        '2021-10-26T20:00:00.000Z': 5,
        '2021-11-02T20:00:00.000Z': 5,
        '2021-11-09T20:00:00.000Z': 5,
        '2021-11-16T20:00:00.000Z': 5,
        '2021-11-23T20:00:00.000Z': 5,
        '2021-11-30T20:00:00.000Z': 5,
        '2021-12-07T20:00:00.000Z': 5,
        '2021-12-14T20:00:00.000Z': 5,
        '2021-12-21T20:00:00.000Z': 5,
        '2021-12-28T20:00:00.000Z': 5,
      },
    },
    {
      title: 'Stakers Distribution',
      type: 'claim_us',
      vesting:
        'For guaranteed level stakers, snapshot was taken on 24 Aug, 10:00 pm UTC. Includes vested TPAD from July and August.',
      // funded ALL 20M
      claimerVersion: 'v1',
      claimerAddress: '0x0FEC6F9065B7C486a9D5aA1e2cA477F8CA2305E7',
      schedule: {
        '2021-08-31T20:00:00.000Z': 3,
        '2021-09-07T20:00:00.000Z': 3,
        '2021-09-14T20:00:00.000Z': 3,
        '2021-09-21T20:00:00.000Z': 3,
        '2021-09-28T20:00:00.000Z': 3,
        '2021-10-05T20:00:00.000Z': 3,
        '2021-10-12T20:00:00.000Z': 3,
        '2021-10-19T20:00:00.000Z': 3,
        '2021-10-26T20:00:00.000Z': 3,
        '2021-11-02T20:00:00.000Z': 3,
        '2021-11-09T20:00:00.000Z': 3,
        '2021-11-16T20:00:00.000Z': 3,
        '2021-11-23T20:00:00.000Z': 3,
        '2021-11-30T20:00:00.000Z': 3,
        '2021-12-07T20:00:00.000Z': 3,
        '2021-12-14T20:00:00.000Z': 3,
        '2021-12-21T20:00:00.000Z': 3,
        '2021-12-28T20:00:00.000Z': 3,
        '2022-01-04T20:00:00.000Z': 3,
        '2022-01-11T20:00:00.000Z': 3,
        '2022-01-18T20:00:00.000Z': 3,
        '2022-01-25T20:00:00.000Z': 3,
        '2022-02-01T20:00:00.000Z': 3,
        '2022-02-08T20:00:00.000Z': 3,
        '2022-02-15T20:00:00.000Z': 3,
        '2022-02-22T20:00:00.000Z': 3,
        '2022-03-01T20:00:00.000Z': 3,
        '2022-03-08T20:00:00.000Z': 3,
        '2022-03-15T20:00:00.000Z': 3,
        '2022-03-22T20:00:00.000Z': 3,
        '2022-03-29T20:00:00.000Z': 3,
        '2022-04-05T20:00:00.000Z': 3,
        '2022-04-12T20:00:00.000Z': 4,
      },
    },
    {
      title: 'Advisors Distribution',
      type: 'claim_us',
      vesting: '1 month cliff, 10% every month',
      // ALL funded
      // new address, where Will has 60%, S and J 20% (and 1st claimed): 0x98704f9acC107C15d2b9De84d7764EBD7298878c
      // this is old where W/S/J: 4/4/2: 0x6384391B854B2786cc50cEe0817b743465b4E5a2
      claimerAddress: '0xc283B603bF7F988C6D9812edE4C2498C96BD2489',
      schedule: {
        '2021-09-24T20:00:00.000Z': 10,
        '2021-10-24T20:00:00.000Z': 10,
        '2021-11-24T20:00:00.000Z': 10,
        '2021-12-24T20:00:00.000Z': 10,
        '2022-01-24T20:00:00.000Z': 10,
        '2022-02-24T20:00:00.000Z': 10,
        '2022-03-24T20:00:00.000Z': 10,
        '2022-04-24T20:00:00.000Z': 10,
        '2022-05-24T20:00:00.000Z': 10,
        '2022-06-24T20:00:00.000Z': 10,
      },
    },
  ],
};
