import { manufactoryCommon } from 'pools/finished/manufactory/manufactory';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const manufactoryCommunity: PoolConfig = {
  ...manufactoryCommon,
  token: {
    ...manufactoryCommon.token,
    name: 'ManuFactory (community)',
  },
  // id is used in URL
  id: 'manufactory-community',
  // public, seed or private
  access: 'whitelist',
  fundToken: BUSD,
  sale: {
    address: '0xb47A0D69C5CB519F797B8526DDd9d456543cd4d7',
    tokensForSale: toWeiBN('500000'),
    startDate: '2021-12-10T12:40:00.000Z',
    rate: 33.333,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: false,
    participants: 0,
    tokensSold: toWeiBN('499999.999999999999998333'),
    raised: toWeiBN('15000.1500015000150001'),
    baseAllocation: toWeiBN('0'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('500'),
    },
    registerDate: '',
    registerDuration: 0,
    fcfsDuration: 0,
    totalWeights: 148,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  description: (
    <>
      <p className="mb-3">
        <b>Community pool, whitelist only. FCFS</b>
      </p>
      <p>
        ManuFactory is a construction and management simulation game focused on
        resource gathering and strategy combined with survival elements; all
        developed in a truly Play-and-Earn mentality inspired by the hit indie
        game Factorio.
      </p>
    </>
  ),
};
