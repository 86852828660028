import { NO_US, SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/lunaverse.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const lunaverse: PoolConfig = {
  // id is used in URL
  id: 'lunaverse',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: NO_US,
  sale: {
    address: '0xb713A119AB8Eb45d40D716E32c534F9e408840b9',
    tokensForSale: toWeiBN('5000000'),
    startDate: '2022-02-03T11:00:00.000Z',
    rate: 25,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('5000000'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('640.604467805519053876'),
    status: 5,
    registerDate: '2022-02-01T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7638,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '10% TGE, then 6 months linear',
    schedule: {
      '2022-02-25T12:30:00.000Z': 10,
      '2022-03-25T12:00:00.000Z': 15,
      '2022-04-25T12:00:00.000Z': 15,
      TBA: 60,
      // '2022-05-25T12:00:00.000Z': 15,
      // '2022-06-25T12:00:00.000Z': 15,
      // '2022-07-25T12:00:00.000Z': 15,
      // '2022-08-25T12:00:00.000Z': 15,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'TERRA',
    address: 'terra15k5r9r8dl8r7xlr29pry8a9w7sghehcnv5mgp6',
    name: 'Lunaverse',
    symbol: 'LUV',
    imageSrc: img.src,
    listingTime: '2022-02-25T12:30:00.000Z',
    listingInfo: (
      <a
        href="https://medium.com/@lunaverse/when-lunaverse-tge-a4866842a937"
        target="_blank"
        rel="noreferrer">
        TerraSwap and Loop Dex
      </a>
    ),
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(58400100),
    totalSupply: toWeiBN(2_000_000_000),
    coinmarketcapId: 'lunaverse',
    // coingeckoId: 'lunaverse',
  },
  finishedInfo: (
    <div>
      <a href="https://medium.com/@lunaverse/when-lunaverse-tge-a4866842a937">
        Read more about TGE and where to trade
      </a>{' '}
      <br />
      <br />
      <a href="https://app.lunaverse.io/">Trade on Lunaverse App</a> <br />{' '}
      <a href="https://app.terraswap.io/swap?to=terra15k5r9r8dl8r7xlr29pry8a9w7sghehcnv5mgp6&type=swap&from=uluna">
        Trade on TerraSwap
      </a>
      <br />
      <a href="https://coinhall.org/charts/terra/terra1hmcd4kwafyydd4mjv2rzhcuuwnfuqc2prkmlhj">
        LiveChart
      </a>
    </div>
  ),
  social: {
    website: 'https://lunaverse.io/',
    twitter: 'https://twitter.com/Lunaverse_io',
    telegram: 'https://t.me/lunaverse_chat',
    telegramAnno: 'https://t.me/lunaverse_io',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Lunaverse is a virtual world built on the Terra blockchain, a combination
      of realistic 3D real city models and NFT properties linked to yield
      bearing deposits on DeFi protocols. Lunaverse is an exact replica of the
      existing world, with 3D city models coming soon to future iterations:
      approximately 7 square miles in San Francisco is where the Lunaverse
      digital economy will be born! Global LOD (level of detail) building models
      and super high resolution 3D models are at our fingertips for a range of
      cities, for our specific use case. Gameplay may include travel adventures
      to these cities, special mining activities and secret scouting missions!
    </p>
  ),
};
