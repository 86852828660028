import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/noi.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const noi: PoolConfig = {
  // id is used in URL
  id: 'noi',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '',
    tokensForSale: toWeiBN(12_499_950),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 166.666,
    durationHours: 23,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(0),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.static,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    claimerVersion: 'v4',
    vesting: '50% at TGE, 50% released after 3 months',
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY, ARB
    chain: 'ETH',
    address: '',
    name: 'NOI Exchange',
    symbol: 'NOI',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(1_700_000_000),
    totalSupply: toWeiBN(10_000_000_000),
  },
  social: {
    website: 'https://noiexchange.net/',
    twitter: 'https://x.com/exchangenoi',
    telegram: 'https://t.me/NOI_Exchange',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      NOI Exchange is creating the first hybrid cryptocurrency exchange with decentralized swap, 
      a dedicated mobile application, and a browser platform for futures transactions. 
      NOI Exchange is poised to disrupt the cryptocurrency market by tackling one of the industry's 
      most significant challenges: the lack of professional phone support. In an environment where 
      customer service often falls short, NOI Exchange sets itself apart by establishing a dedicated 
      call center akin to those found in Forex exchanges and Swiss banks. This ensures our users 
      receive the highest level of support and assistance whenever needed.
    </p>
  ),
};