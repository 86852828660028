import mImg from 'pools/images/mymessage-moonshot.png';
import img from 'pools/images/mymessage.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { Desc } from '~features/pools/components/PoolDescription';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const mymessage: PoolConfig = {
  // id is used in URL
  id: 'mymessage',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x407862bC00Dc853Be96f4E7377dd9D25b15A61aa',
    tokensForSale: toWeiBN('55555555555.56'),
    startDate: '2021-12-18T11:00:00.000Z',
    rate: 555555.555,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('55555555555.559999999939999474'),
    raised: toWeiBN('100000.0001000080000999'),
    baseAllocation: toWeiBN('7617650.830685227928447778'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-16T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7016,
    levelsOpenAll: false,

    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xb192d5fC358D35194282a1a06814aba006198010',
    name: 'myMessage',
    symbol: 'MESA',
    imageSrc: img.src,
    listingTime: '2021-12-18T19:05:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(226_666_666_666),
    totalSupply: toWeiBN(5_000_000_000_000),
    coingeckoId: 'mymessage',
    coinmarketcapId: 'mymessage',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="MESA"
      address="0xb192d5fC358D35194282a1a06814aba006198010"
      pairAddress="0xb192d5fC358D35194282a1a06814aba006198010"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting:
      '15% at TGE, then 45-day cliff, then vesting 25%, 25%, 25% and 10% on a 45-day basis',
    claimerAddress: '0xEc44a732B5A83f93f0C6EDeB80b4df8728554282',
    claimerVersion: 'v2',
    schedule: {
      '2021-12-18T19:05:00.000Z': 15,
      '2022-02-01T19:05:00.000Z': 25,
      '2022-03-18T19:05:00.000Z': 25,
      '2022-05-02T19:05:00.000Z': 25,
      '2022-06-26T19:05:00.000Z': 10,
    },
  },
  social: {
    website: 'https://mymessage.io/',
    twitter: 'https://twitter.com/myMessageOS',
    telegram: 'https://t.me/mymessageEN',
    telegramAnno: 'https://t.me/mymessageANN',
    medium: 'https://medium.com/@myMessage',
    github: '',
    whitepaper: 'https://docs.mymessage.io/mymessage-litepaper/',
    announcement: '',
  },
  descriptionShort: (
    <>
      <p>
        myMessage is the world's first-ever light-grade decentralized
        reddit-like social media and data storage platform built on a
        multi-chain structure, supporting evolving applications including a
        decentralized Thread-reply bulletin board, messaging dApp, developer
        tools and SDKs and Data Portal to provide encrypted data feedings to
        Web3 applications with Quantum-resistant level of security.
      </p>
    </>
  ),
  description: (
    <>
      <p>
        myMessage is the world's first-ever light-grade decentralized
        reddit-like social media and data storage platform built on a
        multi-chain structure, supporting evolving applications including a
        decentralized Thread-reply bulletin board, messaging dApp, developer
        tools and SDKs and Data Portal to provide encrypted data feedings to
        Web3 applications with Quantum-resistant level of security.
      </p>
      <Desc.Img src={mImg} />
    </>
  ),
};
