import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/arqx.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const arqx: PoolConfig = {
  // id is used in URL
  id: 'arqx',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0xee4a9e40e968ec0C6A6DD88fb861137fC5Eb79Ab',
    tokensForSale: toWeiBN(28_571_428.5715),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-05-05T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 142.857142, // 142.857142857
    durationHours: 23,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(28_571_428.5715),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BASE',
    vesting: '20% at TGE, 1 month cliff, 1 month daily linear',
    claimerVersion: 'v2',
    claimerAddress: '0xd49AD0cd6Dd761EaBd82d52a64874f5D67DC96BA',
    config: {
      initialPercent: 20,
      initialUnlockDate: '2024-05-07T16:30:00.000Z',
      vestingStartDate: '2024-06-07T16:30:00.000Z',
      vestingDuration: 30 * 24 * 3600,
      unitOfTime: 86400,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY, ARB, BASE, SUI
    chain: 'BASE',
    address: '0x5e432eeCd01C12ee7071Ee9219C2477a347DA192',
    name: 'ARQx',
    symbol: 'ARQX',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-05-07T16:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Listed on Uniswap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(19_500_000),
    totalSupply: toWeiBN(1_000_000_000),
  },
  social: {
    website: 'https://www.arqx.ai/',
    twitter: 'https://twitter.com/ARQxAI',
    telegram: 'https://t.me/ARQxAI',
    telegramAnno: 'https://t.me/arq_news',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      ARQx was founded to explore the combination of luxury & high technology.
      For thousands of years, the world of jewelry has remained unchanged. Using
      tech like artificial intelligence & interactive NFC chips, ARQx is
      determined to forge ahead: creating the next standard of luxury goods &
      design. What if there was a way to preserve the originality of serious
      designer jewelry, while letting luxury evolve and integrate with the
      virtual world around us? This is the bridge ARQx sets out to build.
    </p>
  ),
};
