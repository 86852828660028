import { ChevronDownIcon } from '@chakra-ui/icons';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { CgChevronDown } from 'react-icons/cg';
import { Button } from '~features/ui';
import { Chain } from '~types';
import { ChartProvider, generateUrl } from './generateUrl';
import geckoImg from './icons/geckoterminal.svg';
import boggedImg from './icons/bogged.webp';
import pooImg from './icons/poocoin.png';
import dextoolsImg from './icons/dextools.png';
import { Menu, Transition } from '@headlessui/react';

export function ChartDropdownButton({
  defaultProvider = 'coingecko',
  chain = 'BSC',
  address,
  pairAddress,
  noDropdown,
}: {
  defaultProvider?: ChartProvider;
  chain?: Chain;
  address: string;
  pairAddress?: string;
  noDropdown?: boolean;
}) {
  const url = generateUrl({
    provider: defaultProvider,
    chain,
    address,
    pairAddress,
  });

  if (noDropdown) {
    return (
      <Button href={url} type="primaryOutline" size="sm">
        {providers[defaultProvider].icon} Chart
      </Button>
    );
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className="inline-flex w-full justify-center">
          <Button
            href={url}
            type="primaryOutline"
            size="sm"
            rightAddon={({ btnClassName }) => (
              <Menu.Button className={btnClassName}>
                <CgChevronDown className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            )}>
            {providers[defaultProvider].icon} Chart
          </Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute left-0 z-10 mt-0 w-full bg-dark1 origin-top-right rounded-md focus:outline-none">
            <div className="inline-flex flex-col w-full">
              {Object.entries(providers)
                .filter(([provider]) => provider !== defaultProvider)
                .filter(
                  ([, config]) =>
                    !config.supportedChains ||
                    config.supportedChains.includes(chain),
                )
                .map(([provider, config]) => (
                  <Button
                    key={provider}
                    href={generateUrl({
                      provider: provider as ChartProvider,
                      chain,
                      address,
                      pairAddress,
                    })}
                    type="primaryOutline"
                    className="w-full"
                    size="sm">
                    {config.icon} {config.name}
                  </Button>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}

const providers: Record<
  ChartProvider,
  { name: string; icon: React.ReactNode; supportedChains?: Chain[] }
> = {
  coingecko: {
    name: 'Coingecko Terminal',
    icon: (
      <img
        src={geckoImg.src}
        alt="Coingecko Terminal"
        className="h-6 inline mr-2"
      />
    ),
  },
  dextools: {
    name: 'DEXTools',
    icon: (
      <img src={dextoolsImg.src} alt="Dextools" className="h-6 inline mr-2" />
    ),
  },
  bogged: {
    name: 'Bogged',
    icon: <img src={boggedImg.src} alt="Bogged" className="h-6 inline mr-2" />,
  },
  poocoin: {
    name: 'PooCoin',
    icon: <img src={pooImg.src} alt="Poocoin" className="h-6 inline mr-2" />,
    supportedChains: ['BSC', 'POLY'],
  },
};
