import {
  landTiers,
  landTiles,
  landTypes,
  landUtilities,
  metaImg,
  tokenImg,
} from 'pools/finished/polkafantasy/images';
import { landInventory } from 'pools/finished/polkafantasy/landInventory';
import React from 'react';

import { BUSD } from '~config/tokens';
import { DescriptionImg } from '~features/pools/components/PoolDescription/DescriptionImg';
import { NftPoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const polkafantasyLand: NftPoolConfig = {
  // id is used in URL
  id: 'polkafantasy-land',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  isNft: true,
  sale: {
    address: '0x64C455EAA2E606A10A580edAb5ad60d514f2f351',
    tokensForSale: toWeiBN('300'),
    startDate: '2021-11-01T12:00:00.000Z',
    rate: 0,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    participants: 286,
    tokensSold: toWeiBN('0.000000000000000288'),
    raised: toWeiBN('652500'),
    baseAllocation: toWeiBN('14400'),
    status: 5,
    inventory: landInventory,
    registerDate: '2021-10-30T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 17,
    totalWeights: 3423,
    isPublic: false,
    levelsOpenAll: true,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    listingTime: '2021-10-30T12:00:00.000Z',
    address: '',
    name: 'PolkaFantasy Land',
    symbol: '',
    imageSrc: tokenImg.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'airdrop_them',
    vesting: '',
  },
  social: {
    website: 'https://polkafantasy.com/',
    twitter: 'https://twitter.com/PolkaFantasy',
    telegram: 'https://t.me/PolkaFantasyEN',
    telegramAnno: 'https://t.me/PolkaFantasyOfficial',
    medium: 'https://polkafantasy.medium.com/',
  },
  images: {
    seo: metaImg.src,
  },
  descriptionShort: (
    <p>
      Fantasy lands are divided into 5 tiers: Common, Rare, Epic, Legendary and
      Mythical. Each tier has additional utilities added on top. Each tier also
      includes 3 ranks of land: The standard rank would be a single tile. The
      next size up is the S-rank which combines 4 tiles of land in a 2x2 format.
      The SS-rank offers more space in a 3x3 tile format and has the highest
      benefits among them all. The rank of land you purchase refers to its size
      and benefits.
    </p>
  ),
  description: (
    <div className="space-y-3">
      <p>
        PolkaFantasy is extremely excited to collaborate with Trustpad as one of
        the launchpads for its first ever Fantasy land sale.
      </p>
      <p>
        Fantasy lands are divided into 5 tiers: Common, Rare, Epic, Legendary
        and Mythical. Each tier has additional utilities added on top. Each tier
        also includes 3 ranks of land: The standard rank would be a single tile.
        The next size up is the S-rank which combines 4 tiles of land in a 2x2
        format. The SS-rank offers more space in a 3x3 tile format and has the
        highest benefits among them all. The rank of land you purchase refers to
        its size and benefits.
      </p>
      <p>
        That being said, the higher ranks offer geographical advantages and
        higher boosting percentages.Fantasy Lands are not only about in-game
        rewards. The land system in applies to every one of PolkaFantasy's
        upcoming blockchain games. Therefore, land will be able to be used
        throughout the whole ecosystem in future, making the ecosystem complete,
        and also providing more advantages to gamers.
      </p>

      <p>
        <a href="https://polkafantasy.medium.com/fantasy-land-presale-for-xp-holders-18446f1f007c">
          Read more about the Land sale
        </a>
      </p>

      <DescriptionImg src={landUtilities.src} />
      <DescriptionImg src={landTiers.src} />
      <DescriptionImg src={landTypes.src} />
      <DescriptionImg src={landTiles.src} />
    </div>
  ),
};
