import certikImg from './certik.svg';
import coingeckoImg from './coingecko.png';
import cmcImg from './coinmarketcap.png';
import cryptoRankImg from './cryptorank.svg';
import metaImg from './metaImg.png';
import quillAuditsImg from './quillaudits.png';
import tpadSmokeImg from './tpad_smoke.png';
import zokyoImg from './zokyo.svg';

export {
  certikImg,
  cmcImg,
  coingeckoImg,
  cryptoRankImg,
  metaImg,
  quillAuditsImg,
  tpadSmokeImg,
  zokyoImg,
};
