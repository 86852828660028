import { SaleStatus } from '@trustpad/launchpad';

import { tokenImg } from 'pools/finished/polkafantasy/images';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const polkaFantasy: PoolConfig = {
  // id is used in URL
  id: 'polkaFantasy',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: false,
  sale: {
    address: '0x083522b61ecb8D156c8A4877Cf69A83defdcA4a7',
    status: SaleStatus.finished,
    rate: 20,
    tokensForSale: toWeiBN('2000000'),
    startDate: '2021-09-08T12:00:00.000Z',
    baseAllocation: toWeiBN('347.158779462817467568'),
    durationHours: 8,
    hasWhitelist: true,
    isTiered: true,
    participants: 1367,
    tokensSold: toWeiBN('1999999.99999999999999888'),
    raised: toWeiBN('99999.999999999999999944'),
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-09-03T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 5473,
  },
  distribution: {
    type: 'claim_them',
    claimUrl: 'https://vesting.polkafantasy.com',
    vesting:
      '25% unlocked day 1, remaining 75% unlocked linearly over 3 months (from TGE) starting on Month 2. ',
    schedule: {
      '2021-09-10T09:15:00.000Z': 25,
      '2021-10-11T09:15:00.000Z': 75,
    },
  },
  token: {
    address: '0x948c70Dc6169Bfb10028FdBE96cbC72E9562b2Ac',
    name: 'PolkaFantasy',
    symbol: 'XP',
    imageSrc: tokenImg.src,
    decimals: 18,
    type: 'ERC-20',
    listingRate: 20,
    totalSupply: toWeiBN(200_000_000),
    // BSC, ETH, DOT or SOL
    chain: 'ETH',
    initialSupply: toWeiBN(6_600_000),
    listingTime: '2021-09-10T09:15:00.000Z',
    coingeckoId: 'polkafantasy',
    coinmarketcapId: 'polkafantasy',
  },
  social: {
    website: 'https://polkafantasy.com/',
    twitter: 'https://twitter.com/PolkaFantasy',
    telegram: 'https://t.me/PolkaFantasyEN',
    telegramAnno: 'https://t.me/PolkaFantasyOfficial',
    medium: 'https://polkafantasy.medium.com/',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="$XP"
      address="0x948c70Dc6169Bfb10028FdBE96cbC72E9562b2Ac"
      pairAddress="0x353a70db3888e957ad2e7444a08a2c9c08a5db47"
    />
  ),
  descriptionShort: (
    <div>
      PolkaFantasy is an NFT cross-chain marketplace for people with a wide
      appreciation for authentic Japanese animation, comics and game (ACG)
      culture. Later this year PolkaFantasy will also launch their very own game
      to fully bring NFT GameFi and play-to-earn mechanism to the platform.
    </div>
  ),
  description: (
    <div className="space-y-3">
      <p>
        Claimer portal is ready!{' '}
        <a href="https://polkafantasy.medium.com/how-to-claim-xp-token-on-linear-unlock-portal-4a06d9304f3b">
          The claim guide is available here
        </a>
      </p>
      <p>
        PolkaFantasy is an NFT cross-chain marketplace for people with a wide
        appreciation for authentic Japanese animation, comics and game (ACG)
        culture. Later this year PolkaFantasy will also launch their very own
        game to fully bring NFT GameFi and play-to-earn mechanism to the
        platform.
      </p>
      <div style={{ padding: '50% 0 0 0', position: 'relative' }}>
        <iframe
          src="https://player.vimeo.com/video/597359860?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=60d53241e5"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          title="en_polkafantasy_1080p.mp4"></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </div>
  ),
};
