import img from 'pools/images/spacemisfits.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const spaceMisfitsCommon = {
  fundToken: BUSD,
  kyc: true,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    extraChains: ['ETH'],
    address: '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
    pairAddress: '0xb2ce64b78236e83bfbc625806116481dc5ffd642',
    name: 'Space Misfits',
    symbol: 'SMCW',
    imageSrc: img.src,
    listingTime: '2022-03-19T13:00:00.000Z',
    listingInfo: 'Pancakeswap, MEXC and Tier 1 CEX',
    decimals: 18,
    listingRate: 6.25,
    initialSupply: toWeiBN(4_100_000),
    totalSupply: toWeiBN(200_000_000),
    coingeckoId: 'space-misfits',
    coinmarketcapId: 'space-misfits',
  },
  social: {
    website: 'https://spacemisfits.com',
    twitter: 'https://twitter.com/SpaceMisfits',
    telegram: 'https://t.me/Spacemisfitsgame',
    telegramAnno: 'https://t.me/smannouncement',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Space Misfits brings an exciting and intense action space game to the
      blockchain. Explore new worlds, battle it out for scarce resources, build
      your fleet and earn a fortune in space.
    </p>
  ),
};

export const spacemisfits: PoolConfig = {
  ...spaceMisfitsCommon,
  // id is used in URL
  id: 'spacemisfits',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0x0BfeeC183251B86520CC597e5B756bCA50A078B5',
    tokensForSale: toWeiBN('1000000'),
    startDate: '2022-01-15T11:00:00.000Z',
    rate: 6.25,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1000000'),
    raised: toWeiBN('160000'),
    baseAllocation: toWeiBN('122.80982222790638189'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('40'),
    },
    registerDate: '2022-01-13T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7875,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '25% at TGE and 25% every 30 days',
    claimerAddress: '0x3A65399a00f6B222dA448053D5F8F67ab7e2113E',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-19T13:05:00.000Z': 25,
      '2022-04-19T13:05:00.000Z': 25,
      '2022-05-19T13:05:00.000Z': 25,
      '2022-06-19T13:05:00.000Z': 25,
    },
  },
};

export const spacemisfitsprivate: PoolConfig = {
  ...spaceMisfitsCommon,
  // id is used in URL
  id: 'spacemisfitsprivate',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x2AD5Df74E355AF933fFa6557Ca1054EF2A01c935',
    tokensForSale: toWeiBN('384615'),
    startDate: '2022-01-15T11:00:00.000Z',
    rate: 7.6923,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('384614.999999999999999972'),
    raised: toWeiBN('50000'),
    baseAllocation: toWeiBN('163.945012787723785166'),
    status: 5,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-13T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 2374,
    minAllowedLevelMultiplier: 28,
  },
  distribution: {
    type: 'claim_us',
    vesting: '10% at TGE and 10% every 3 months',
    claimerAddress: '0xF809DD27aE5212222bd1DA62913eB9Fe3B676Dd8',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-19T13:05:00.000Z': 10,
      '2022-06-19T13:05:00.000Z': 10,
      '2022-09-19T13:05:00.000Z': 10,
      '2022-12-19T13:05:00.000Z': 10,
      '2023-03-19T13:05:00.000Z': 10,
      '2023-06-19T13:05:00.000Z': 10,
      '2023-09-19T13:05:00.000Z': 10,
      '2023-12-19T13:05:00.000Z': 10,
      '2024-03-19T13:05:00.000Z': 10,
      '2024-06-19T13:05:00.000Z': 10,
    },
  },
};
