import { NftPoolConfig } from '@trustpad/launchpad';

import React from 'react';

import { BUSD } from '~/config/tokens';
import { toWeiBN } from '~/utils';

import com1Img from './images/common_1.png';
import logoImg from './images/dinoxlandlogo.jpg';
import leg9Img from './images/legendary_9.png';
import leg16Img from './images/legendary_16.png';
import myth16Img from './images/mythic_16.png';
import rare4Img from './images/rare_4.png';
import rare9Img from './images/rare_9.png';
import uncom1Img from './images/uncommon_1.png';
import uncom4Img from './images/uncommon_4.png';

export const dinoxland: NftPoolConfig = {
  // id is used in URL
  id: 'dinoxland',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  isNft: true,
  sale: {
    address: '0x1aF5aFdeA298E4585Bfb7b6c198535C9dDf1ED42',
    tokensForSale: toWeiBN('968'),
    startDate: '2022-01-10T12:00:00.000Z',
    rate: 0,
    durationHours: 72,
    hasWhitelist: false,
    isTiered: false,
    participants: 649,
    tokensSold: toWeiBN('0.000000000000000968'),
    raised: toWeiBN('261320'),
    baseAllocation: toWeiBN('3040'),
    status: 5,
    inventory: [
      {
        id: 'com1',
        name: 'Common 1x1',
        image: com1Img.src,
        supply: 400,
        totalSupply: 7680,
        price: toWeiBN('190'),
        internalId:
          '0x636f6d3100000000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 400,
        raised: toWeiBN('76000'),
      },
      {
        id: 'uncom1',
        name: 'Uncommon 1x1',
        image: uncom1Img.src,
        supply: 220,
        totalSupply: 3432,
        price: toWeiBN('240'),
        internalId:
          '0x756e636f6d310000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 220,
        raised: toWeiBN('52800'),
      },
      {
        id: 'uncom4',
        name: 'Uncommon 2x2',
        image: uncom4Img.src,
        supply: 140,
        totalSupply: 1848,
        price: toWeiBN('290'),
        internalId:
          '0x756e636f6d340000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 140,
        raised: toWeiBN('40600'),
      },
      {
        id: 'rare4',
        name: 'Rare 2x2',
        image: rare4Img.src,
        supply: 120,
        totalSupply: 1560,
        price: toWeiBN('340'),
        internalId:
          '0x7261726534000000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 120,
        raised: toWeiBN('40800'),
      },
      {
        id: 'rare9',
        name: 'Rare 3x3',
        image: rare9Img.src,
        supply: 52,
        totalSupply: 840,
        price: toWeiBN('390'),
        internalId:
          '0x7261726539000000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 52,
        raised: toWeiBN('20280'),
      },
      {
        id: 'leg9',
        name: 'Legendary 3x3',
        image: leg9Img.src,
        supply: 16,
        totalSupply: 312,
        price: toWeiBN('690'),
        internalId:
          '0x6c65673900000000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 16,
        raised: toWeiBN('11040'),
      },
      {
        id: 'leg16',
        name: 'Legendary 4x4',
        image: leg16Img.src,
        supply: 10,
        totalSupply: 168,
        price: toWeiBN('790'),
        internalId:
          '0x6c65673136000000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 10,
        raised: toWeiBN('7900'),
      },
      {
        id: 'myth16',
        name: 'Mythical 4x4',
        image: myth16Img.src,
        supply: 10,
        totalSupply: 160,
        price: toWeiBN('1190'),
        internalId:
          '0x6d79746831360000000000000000000000000000000000000000000000000000',
        limit: 20,
        sold: 10,
        raised: toWeiBN('11900'),
      },
    ],
    registerDate: '2022-01-08T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0,
    totalWeights: 5930,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('10000'),
    },
    isPublic: true,
    levelsOpenAll: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'POLY',
    address: '',
    name: 'DinoX INO',
    symbol: '',
    imageSrc: logoImg.src,
    decimals: 18,
    listingTime: '2022-01-15T12:00:00.000Z',
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'claim_us',
    vesting: 'during January',
  },
  social: {
    website: 'https://dinox.io/',
    twitter: 'https://twitter.com/DinoXWorld',
    telegram: 'https://t.me/dinoxproject',
    telegramAnno: 'https://t.me/dinoxannouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      DinoX is a combat and strategy game in a sandbox environment. A beloved
      gameplay genre blended with a fan-favorite theme of dinosaurs. Players
      build dino kingdoms, rally resources to expand, build combative skills to
      occupy others, and due to the benefits of the metaverse, retain true
      ownership of the game.
    </p>
  ),
};
