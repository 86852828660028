import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/adroverse.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const adroverse: PoolConfig = {
  // id is used in URL
  id: 'adroverse',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x2d05c955a8ddEF7b06F231EbE0512BB80Cc2d99D',
    tokensForSale: toWeiBN('10000000'),
    startDate: '2022-02-18T11:00:00.000Z',
    rate: 50,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('9999999.999999999999997'),
    raised: toWeiBN('199999.99999999999999994'),
    baseAllocation: toWeiBN('1133.984647592463363572'),
    status: 5,
    registerDate: '2022-02-12T11:00:00.000Z',
    registerDuration: 141,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 8734,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '15% at listing, 25% at next month, 20% monthly for next 3 months',
    claimerVersion: 'v2',
    claimerAddress: '0x763Ad5326cEDFcB79E0B8628208Cb3aEfC45f808',
    schedule: {
      '2022-03-23T16:30:00.000Z': 15,
      '2022-04-23T16:30:00.000Z': 25,
      '2022-05-23T16:30:00.000Z': 20,
      '2022-06-23T16:30:00.000Z': 20,
      '2022-07-23T16:30:00.000Z': 20,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x36F1f32c728C3F330409eC1F0928Fa3aB3C8A76F',
    pairAddress: '0x8d2acccc1aa29ad576ab2ca8f8b5f518d920f444',
    name: 'Adroverse',
    symbol: 'ADR',
    imageSrc: img.src,
    listingTime: '2022-03-23T16:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(17416667),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'adroverse',
    coinmarketcapId: 'adroverse',
  },
  social: {
    website: 'https://adroverse.io/',
    twitter: 'https://twitter.com/adroverse',
    telegram: 'https://t.me/adroversegroup',
    telegramAnno: 'https://t.me/adroversechannel',
    medium: 'https://adroverse.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Adroverse: The Metaverse of Heroes is a series of mobile games and
      applications that simulates the evolution of the Adroverse galaxy after
      the catastrophe. Joining Adroverse metaverse, players take the role of
      31st century doomsday survivors, having to fight for the survival of
      themselves and their fellows, and at the same time, conduct financial and
      economical operations to build their own space cities, in order to conquer
      the great universe again…
    </p>
  ),
};
