import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/sugarkingdom.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const sugarkingdomodyssey: PoolConfig = {
  // id is used in URL
  id: 'sugarkingdomodyssey',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0x54E6796ea95515506478b46ce0c4F23c1eBD5945',
    tokensForSale: toWeiBN(916_667),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-03-31T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 16.666,
    durationHours: 23,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(916_667),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BSC',
    claimerVersion: 'v2',
    vesting: '20% at TGE, 0 months cliff, then daily vesting for 4 months',
    claimerAddress: '0xDC0462aaAE877be2Db857C258cCb8Bc208C757B9',
    config: {
      initialUnlockDate: '2024-04-02T14:15:00.000Z',
      initialPercent: 20,
      vestingDuration: 120 * 24 * 3600,
      vestingStartDate: '2024-04-02T14:16:00.000Z',
      unitOfTime: 86400,
    },

  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x9Bf543D8460583Ff8a669Aae01d9cDbeE4dEfE3c',
    name: 'Sugar Kingdom Odyssey',
    symbol: 'SKO',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-04-02T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: (
      <div>
        Listed on MEXC and PancakeSwap
        <br />
        <strong>Please note:</strong> CEX listing at 2:00 PM UTC, DEX listing at
        2:15 PM UTC, distribution at 2:15 PM UTC
      </div>
    ),
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(6_720_000),
    totalSupply: toWeiBN(100_000_000),
  },
  social: {
    website: 'https://www.sugarkingdom.io/',
    twitter: 'https://twitter.com/SugarKingdomNFT',
    telegram: '',
    telegramAnno: 'https://t.me/SugarKingdomOfficialChat',
    medium: '',
    github: '',
    whitepaper: 'https://docs.sugarkingdom.io/',
    announcement: '',
  },
  description: (
    <p>
      Immerse yourself in the captivating match-3 game, a format beloved by
      millions worldwide. In this delightful challenge, players match colorful,
      sparkling tokens, each symbolizing different cryptocurrencies. As you
      align these tokens, you're not just playing for points, you're navigating
      the vibrant world of crypto in a fun and interactive way. $SUGAR is the
      native token of Sugar Kingdom's ecosystem.
    </p>
  ),
};
