import img from 'pools/images/ariadne.jpg';
import ariadneAnn from 'pools/images/ariadneAnn.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const ariadne: PoolConfig = {
  // id is used in URL
  id: 'ariadne',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x1725D26EE8E614FFa6B8E65B84D55E36bEFCB648',
    tokensForSale: toWeiBN('100000'),
    startDate: '2021-11-04T12:00:00.000Z',
    rate: 2,
    durationHours: 0.7666666666666667,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('100000'),
    raised: toWeiBN('50000'),
    baseAllocation: toWeiBN('39.760805707091900965'),
    status: 5,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('19.8804'),
    },
    registerDate: '2021-11-02T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.5,
    totalWeights: 4816,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    extraChains: ['ETH'],
    address: '0xa0A9961b7477D1a530f06a1ee805d5E532e73d97',
    name: 'Ariadne',
    symbol: 'ARDN',
    listingTime: '2021-11-04T16:00:00.000Z',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(200_000),
    totalSupply: toWeiBN(25_000_000),
    coingeckoId: 'ariadne',
    coinmarketcapId: 'ariadne',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="ARDN"
      address="0xa0A9961b7477D1a530f06a1ee805d5E532e73d97"
      pairAddress="0x2f2ab228263c9a6ee64e41d73dd5b8e1924f386b"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '1/3 at TGE, 2/3 in 6 months',
    claimerVersion: 'v1',
    claimerAddress: '0xD06CbF0508d0aA138C7306e402311c5Ba15E7689',
    schedule: {
      '2021-11-04T16:00:00.000Z': 33,
      '2022-05-04T15:00:00.000Z': 67,
    },
  },
  social: {
    website: 'https://ariadne.finance/',
    twitter: 'https://twitter.com/Ariadne_finance',
    telegram: 'https://t.me/ariadne_finance',
    telegramAnno: '',
    medium: 'https://medium.com/ariadne-project',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  images: {
    seo: ariadneAnn.src,
  },
  description: (
    <p>
      Ariadne is a cross-chain DeFi marketplace that is lowering the fees to
      enter/exit farms on multiple blockchains and saves swaps and bridges costs
      for both EVM and non-EVM chains. Ariadne enables users of any supported
      chain to enter/exit yield farms on a multitude of blockchains, with
      virtually no lower limit of investment, and without having to deal with
      bridges, swaps and slippage, transaction costs on receiving blockchains
      etc.
    </p>
  ),
};
