import img from 'pools/images/kaizen.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const kaizen: PoolConfig = {
  // id is used in URL
  id: 'kaizen',
  // public, seed or private
  access: 'whitelist',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x4807d4D80bA910A192d62C39d3DFE8687E72D8dA',
    tokensForSale: toWeiBN('2500000'),
    startDate: '2021-12-03T17:30:00.000Z',
    rate: 25,
    durationHours: 1.8333333333333333,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2499999.34399735012492'),
    raised: toWeiBN('99999.9737598940049968'),
    baseAllocation: toWeiBN('821.393280632411067193'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-12-01T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0,
    totalWeights: 689,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '',
    name: 'Kaizen',
    symbol: 'KZEN',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(1_000_000_000),
    listingTime: '2022-04-26T00:00:00.000Z',
    coingeckoId: 'kaizen',
  },
  distribution: {
    type: 'claim_them',
    vesting:
      '10% TGE, the rest proportionally 12 months. The claiming will become available on their platform soon',
    claimUrl: 'https://ido.kaizen.finance/allocation',
  },
  social: {
    website: 'https://kaizen.finance/',
    twitter: 'https://twitter.com/kaizen_finance',
    telegram: 'https://t.me/KaizenFinanceOfficial',
    telegramAnno: 'https://t.me/KaizenFinance',
    medium: '',
    github: '',
    whitepaper: 'https://kaizenfinance.medium.com/',
    announcement: '',
  },
  description: (
    <>
      <p className="font-bold mb-2">
        As asked by Kaizen team, this IDO has a <b>capped allocation of $100</b>
        . Top four levels (EXECUTIVE, PARTNER, ORBIT, ASSOCIATE) are capped to
        $100, lower levels have lower allocations.
      </p>
      <p>
        Kaizen.Finance is a crypto token lifecycle management platform where
        projects and DAOs can confidently and reliably generate, issue, and
        manage token offerings using an autonomous full-stack smart-contract
        authoring Kaizen protocol.
      </p>
    </>
  ),
};
