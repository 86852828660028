import { NO_US } from '@trustpad/launchpad';

import img from 'pools/images/elevenminutes.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const elevenMinutesCommon = {
  fundToken: BUSD,
  noCountries: NO_US,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xE8DE5e3689c42729CE53BB950FfF2e625Ccf23A7',
    name: '11 Minutes',
    symbol: 'ELVN',
    imageSrc: img.src,
    listingTime: '2022-03-31T15:00:00.000Z',
    listingInfo: 'PancakeSwap',
    decimals: 18,
    listingRate: 35.0877,
    initialSupply: toWeiBN(25_000_000),
    totalSupply: toWeiBN(1000000000),
    coingeckoId: 'eleventoken',
    coinmarketcapId: '11minutes',
  },
  social: {
    website: 'https://elevenminutes.io/',
    twitter: 'https://twitter.com/11minutesnft',
    telegram: 'https://t.me/minutes11',
    telegramAnno: 'https://t.me/Minutes11Ann',
    medium: '',
    github: '',
    whitepaper:
      'https://whitepaper.elevenminutes.io/introduction/what-is-11minutes',
    announcement: '',
  },
  description: (
    <p>
      11Minutes is not a single game, but a series of games in which the aim is
      to achieve the high score within 11 minutes. First of all, the player must
      have mint a NFT to participate in a game session. Once the Game Starts,
      you have 24 hours to start your own game session and set a high score
      within 11 minutes. We will reveal the game shortly before the start of
      each session. Most of the time, it will be an endless games that is hardly
      more difficult than Flappy Bird. You can restart as often as you like in
      your 11 minutes and break your own high score. The highest rating is
      entered in the leaderboard together with your wallet address and your NFT
      Token ID. Based on your position on the leaderboard, you will receive a
      profit that will differ from session to session.
    </p>
  ),
};

export const elevenminutes: PoolConfig = {
  ...elevenMinutesCommon,
  // id is used in URL
  id: 'elevenminutes',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0x3a2a01ef3dEEf58ff6a8c3DF8f7cC36b7CE5F945',
    tokensForSale: toWeiBN('7017544'),
    startDate: '2022-01-09T11:00:00.000Z',
    rate: 35.0877,
    durationHours: 5,
    hasWhitelist: true,
    refunded: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('7017543.999999999999999525'),
    raised: toWeiBN('200000.1140000627000345'),
    baseAllocation: toWeiBN('1200.045569875504120638'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-07T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 5767,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },

  distribution: {
    type: 'claim_them',
    vesting: '15% at TGE, 1 month lock, 7 months linear vesting',
    claimUrl: 'https://app.elevenminutes.io/wormhole',
  },
};

export const elevenminutesprivate: PoolConfig = {
  ...elevenMinutesCommon,
  // id is used in URL
  id: 'elevenminutesprivate',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x9CAcFA6a58445C9f9053881Bd9478Fb641C7f3ee',
    tokensForSale: toWeiBN('4000000'),
    startDate: '2022-01-09T11:00:00.000Z',
    rate: 40,
    durationHours: 5,
    hasWhitelist: true,
    refunded: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('10000'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-07T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.16666666666666666,
    totalWeights: 1612,
    minAllowedLevelMultiplier: 28,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_them',
    vesting: '10% at TGE, 1 month lock, 8 month vesting',
    claimUrl: 'https://app.elevenminutes.io/wormhole',
  },
};
