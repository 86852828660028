import img from 'pools/images/cheesus.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const cheesus: PoolConfig = {
  // id is used in URL
  id: 'cheesus',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x6e57CcCDb0f016D83EAd287D9b33842B11adfEb0',
    tokensForSale: toWeiBN('1315790'),
    startDate: '2021-11-29T12:00:00.000Z',
    rate: 26.316,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    refunded: true,
    participants: 0,
    tokensSold: toWeiBN('1315789.999999999999999416'),
    raised: toWeiBN('49999.6200030399756802'),
    baseAllocation: toWeiBN('193.49273676360924683'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('7'),
    },
    registerDate: '2021-11-27T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.75,
    totalWeights: 6705,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '',
    name: 'CHEESUS Token',
    symbol: 'CHEESUS',
    imageSrc: img.src,
    listingTime: '2022-01-27T19:00:00.000Z',
    decimals: 8,
    listingRate: 0,
    initialSupply: toWeiBN(13_846_741.15),
    totalSupply: toWeiBN(130_000_000),
    coingeckoId: 'cheesus',
  },
  distribution: {
    type: 'claim_us',
    vesting: '30% at TGE, 30% 1st month, 30% 2nd month, 10% last month',
  },
  social: {
    website: 'https://cheesus.ai/',
    twitter: 'https://twitter.com/CheesusDeFi',
    telegram: 'https://t.me/cheesus_MAIN_eng',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Cheesus is a multichain DeFi analytical tool that helps you find real gems
      without getting caught in any mousetraps.
    </p>
  ),
};
