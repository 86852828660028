import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/aquire.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const acquire: PoolConfig = {
  // id is used in URL
  id: 'acquire',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xf5bAb8A5e635Bba912213d76D7E29B7c8c48C111',
    tokensForSale: toWeiBN('712500'),
    startDate: '2022-09-28T09:00:00.000Z',
    rate: 10,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('712500'),
    raised: toWeiBN('71250'),
    baseAllocation: toWeiBN('99.093485837628543374'),
    status: 5,
    registerDate: '2022-09-28T01:00:00.000Z',
    registerDuration: 7,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.6666666666666666,
    totalWeights: 7522980,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_linear',
    network: 'ETH',
    vesting: '15% at TGE, the remaining over 6 months, daily vesting',
    claimerAddress: '0x75397307c9930abbfa9e6b785a71e543ca15ed82',
    claimerVersion: 'v2',
    config: {
      initialPercent: 15,
      initialUnlockDate: '2022-09-28T15:15:00.000Z',
      vestingStartDate: '2022-09-29T15:15:00.000Z',
      vestingDuration: 6 * 30 * 24 * 60 * 60,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0x4BDCb66B968060D9390C1D12bd29734496205581',
    name: 'acquire.fi',
    symbol: 'ACQ',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-09-28T15:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'On KuCoin',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(300_000_000),
    coingeckoId: 'acquire-fi',
    coinmarketcapId: 'acquire-fi',
  },
  social: {
    website: 'https://acquire.fi/',
    twitter: 'https://twitter.com/Acquire_Fi',
    telegram: 'https://t.me/AcquireFi',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
    discord: 'https://discord.com/invite/uf7hyhbx',
  },
  description: (
    <p>
      Acquire.Fi is where Investing and Web3 intersect, creating a new wealth
      building paradigm for all. Home to exclusive crypto, blockchain, NFT and
      Web3 M&A deal flow while ushering in a new inclusive era of wealth
      creation through acquisitions using blockchain and digital assets.
    </p>
  ),
};
