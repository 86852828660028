import img from 'pools/images/inpoker.jpg';
import React from 'react';

import { BUSD } from '~/config/tokens';
import { PoolConfig } from '~/features/pools/types';
import { toWeiBN } from '~/utils';
import { PancakeChartFinishedInfo } from '~features/launched';

export const inpoker: PoolConfig = {
  // id is used in URL
  id: 'inpoker',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x3849D120F4997BA9D1d2a742bE023313A8A58571',
    tokensForSale: toWeiBN('2500000'),
    startDate: '2021-11-25T11:00:00.000Z',
    rate: 50,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2500000'),
    raised: toWeiBN('50000'),
    baseAllocation: toWeiBN('382.76770494343716101'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('6'),
    },
    registerDate: '2021-11-23T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6462,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xf0f88d49d3fea2ee07002b9565f47666ba7439ea',
    name: 'InPoker',
    symbol: 'INP',
    imageSrc: img.src,
    listingTime: '2021-11-26T15:10:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(15_000_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'inpoker',
    coinmarketcapId: 'inpoker',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="INP"
      address="0xf0f88d49d3fea2ee07002b9565f47666ba7439ea"
      pairAddress="0x20731c25dcb2ab49f335e85cc831cae712cd6807"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: 'TGE at 25%, monthly vest of 75% for 3 months',
    claimerAddress: '0x2Deb9fea44EB3EB204BF21C54B4eB3450C31eb38',
    claimerVersion: 'v2',
    schedule: {
      '2021-11-26T15:10:00.000Z': 25,
      '2021-12-26T15:00:00.000Z': 25,
      '2022-01-26T15:00:00.000Z': 25,
      '2022-02-26T15:00:00.000Z': 25,
    },
  },
  social: {
    website: 'https://inpoker.io/main',
    twitter: 'https://twitter.com/influencerpoker',
    telegram: 'https://t.me/inpokergroup',
    telegramAnno: 'https://t.me/inpokergroup',
    medium: '',
    github: '',
    whitepaper:
      'https://drive.google.com/file/d/1u9HWSJEeE2sy1AdOSYdWv2Q8iRSo8hK4/view',
    announcement: '',
  },
  description: (
    <p>
      InPoker is a fresh new approach to online poker that engages social media
      influencers and offers the first e-sports platform with an integrated DeFi
      protocol that allows players to participate in poker tournaments by
      supplying crypto assets and unique NFT membership that provides free
      entries and exclusive benefits.
    </p>
  ),
};
