import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/polkacipher.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const polkaCipher: PoolConfig = {
  // id is used in URL
  id: 'polkaCipher',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  sale: {
    address: '0xb5C0422Fa4fE116214c094B8d51F2Ac5FA27D9Ea',
    status: SaleStatus.finished,
    rate: 58.823529,
    tokensForSale: toWeiBN('9000000'),
    startDate: '2021-07-20T09:00:00.000Z',
    baseAllocation: toWeiBN('3881'),
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$153,000',
    participants: 815,
    tokensSold: toWeiBN('9000000'),
    raised: toWeiBN('153000.001071000007497'),
    update: false,
    levelsOpenAll: true,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('520'),
    },
    registerDate: '2021-07-13T09:00:00.000Z',
    registerDuration: 167,
    fcfsDuration: 0.5,
  },
  token: {
    address: '0x7428be82fd79d4c98650a7c67de9682a64fcab71',
    name: 'PolkaCipher',
    symbol: 'CPHR',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingRate: 0,
    totalSupply: toWeiBN(''),
    // BSC, ETH, DOT or SOL
    chain: 'BSC',
    extraChains: ['DOT'],
    listingTime: '2021-07-13T17:00:00.000Z',
    initialSupply: toWeiBN(52_760_000),
    coingeckoId: 'polkacipher',
    coinmarketcapId: 'polkacipher',
  },
  social: {
    website: 'https://polkacipher.com/',
    twitter: 'https://twitter.com/polkacipher',
    telegram: 'https://t.me/PolkaCipher',
    telegramAnno: 'https://t.me/PolkaCipherAnn',
    medium: 'https://polkacipher.medium.com/',
    github: '',
  },
  finishedInfo: (
    <>
      <PancakeChartFinishedInfo
        symbol="CPHR"
        address="0x7428be82fd79d4c98650a7c67de9682a64fcab71"
        pairAddress="0x7fcc533f3ad9b97f7abb7c15b80d7108134ab27a"
      />
      <a href="https://www.gate.io/trade/CPHR_USDT">Trade on Gate.io.</a>
    </>
  ),
  description: (
    <div>
      PolkaCipher is a new generation privacy-preserving oracle network focused
      on bringing the use case of private NFTs to off-chain businesses and being
      a bridge for seamless integration to on-chain DeFi apps.
    </div>
  ),
};
