import { useRouter } from 'next/router';
import * as process from 'process';
import { useEffect, useState } from 'react';

export function useFeatureFlag(flag: string) {
  const { query } = useRouter();
  const [flagEnabled, setFlagEnabled] = useState(false);

  useEffect(() => {
    const flagStatus = String(query[flag]);
    if (query[flag] !== undefined) {
      localStorage.setItem(flag, flagStatus);
      setFlagEnabled(flagStatus === '1');
    }
  }, [query, flag]);

  useEffect(() => {
    if (process.browser) {
      setFlagEnabled(localStorage.getItem(flag) === '1');
    }
  }, [flag]);

  return flagEnabled;
}
