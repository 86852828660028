import img from 'pools/images/dinoland.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

const dinolandCommon = {
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x6b9481fb5465ef9ab9347b332058d894ab09b2f6',
    name: 'Dinoland',
    symbol: 'DNL',
    imageSrc: img.src,
    listingTime: '2022-02-09T07:05:00.000Z',
    decimals: 18,
    listingRate: 58.823,
    initialSupply: toWeiBN(17500000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'dinoland',
    coinmarketcapId: 'dinoland',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="DNL"
      address="0x6b9481fb5465ef9ab9347b332058d894ab09b2f6"
      pairAddress="0x6b9481fb5465ef9ab9347b332058d894ab09b2f6"
    />
  ),
  social: {
    website: 'https://dinoland.io/',
    twitter: 'https://twitter.com/dinolandgame',
    telegram: 'https://t.me/dinolandglobal',
    telegramAnno: 'https://t.me/dinolandofficial',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Dinoland Game will bring you to the land of "genuine" Dinos. The dinosaur
      here has a funny, lovely, friendly shape but don’t lower your guard - they
      all have full strength when fighting against the Monsters that attack
      their land.
    </p>
  ),
};

export const dinoland: PoolConfig = {
  ...dinolandCommon,
  // id is used in URL
  id: 'dinoland',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0x3257f8A36F718b34B0104fDdB08Cf00bD386acf8',
    tokensForSale: toWeiBN('7646990'),
    startDate: '2022-01-21T12:00:00.000Z',
    rate: 58.823,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('7646989.999999999999997926'),
    raised: toWeiBN('129999.99999999999999998'),
    baseAllocation: toWeiBN('909.288223627122846163'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-19T11:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 8123,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '18% at TGE, from 2nd month linear vesting in 4 months',
    claimerAddress: '0x3BA9E5cb53d9AC55f795e2B492b94845476C9503',
    claimerVersion: 'v2',
    schedule: {
      '2022-02-09T07:15:00.000Z': 18,
      '2022-03-09T07:15:00.000Z': 20.5,
      '2022-04-09T07:15:00.000Z': 20.5,
      '2022-05-09T07:15:00.000Z': 20.5,
      '2022-06-09T07:15:00.000Z': 20.5,
    },
  },
};

export const dinolandprivate: PoolConfig = {
  ...dinolandCommon,
  // id is used in URL
  id: 'dinolandprivate',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x4c4945f4ddf4640263Bf5e4325Daa30559155026',
    tokensForSale: toWeiBN('1785715'),
    startDate: '2022-01-23T11:00:00.000Z',
    rate: 71.428,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1785714.999999999999999431'),
    raised: toWeiBN('25000.2100016800134401'),
    baseAllocation: toWeiBN('976.868161925601750547'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-21T11:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 1856,
    minAllowedLevelMultiplier: 28,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    // Fully funded
    type: 'claim_us',
    vesting: '9% at TGE, from 2nd month linear vesting in 10 months',
    claimerAddress: '0xD94C19f78f8745C7d763C47bCB93598E2cDA9817',
    claimerVersion: 'v2',
    schedule: {
      '2022-02-09T07:30:00.000Z': 9,
      '2022-03-09T07:15:00.000Z': 9.1,
      '2022-07-09T07:15:00.000Z': 9.1,
      '2022-08-09T07:15:00.000Z': 9.1,
      '2022-09-09T07:15:00.000Z': 9.1,
      '2022-10-09T07:15:00.000Z': 9.1,
      '2022-11-09T07:15:00.000Z': 9.1,
      '2022-12-09T07:15:00.000Z': 9.1,
      '2023-01-09T07:15:00.000Z': 9.1,
      '2023-02-09T07:15:00.000Z': 9.1,
      '2023-03-09T07:15:00.000Z': 9.1,
    },
  },
};

export const dinolandseed: PoolConfig = {
  ...dinolandCommon,
  // id is used in URL
  id: 'dinolandseed',
  // public, seed or private
  access: 'seed',
  sale: {
    address: '0x8A3969c4E829A3f202C5a43c7313FEb0EBFB6740',
    tokensForSale: toWeiBN('2083333'),
    startDate: '2022-01-23T11:00:00.000Z',
    rate: 83.333,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2083332.999999999999998467'),
    raised: toWeiBN('25000.096000384001535988'),
    baseAllocation: toWeiBN('3063.725'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2022-01-21T11:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 680,
    minAllowedLevelMultiplier: 38,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '7% at TGE, from 2nd month linear vesting in 12 months',
    // Fully funded
    claimerAddress: '0x8373E93ef72F98CC1893a3999D75615bE82B3CE0',
    claimerVersion: 'v2',
    schedule: {
      '2022-02-09T07:30:00.000Z': 7,
      '2022-03-09T07:15:00.000Z': 7.75,
      '2022-07-09T07:15:00.000Z': 7.75,
      '2022-08-09T07:15:00.000Z': 7.75,
      '2022-09-09T07:15:00.000Z': 7.75,
      '2022-10-09T07:15:00.000Z': 7.75,
      '2022-11-09T07:15:00.000Z': 7.75,
      '2022-12-09T07:15:00.000Z': 7.75,
      '2023-01-09T07:15:00.000Z': 7.75,
      '2023-02-09T07:15:00.000Z': 7.75,
      '2023-03-09T07:15:00.000Z': 7.75,
      '2023-04-09T07:15:00.000Z': 7.75,
      '2023-05-09T07:15:00.000Z': 7.75,
    },
  },
};
