import React from 'react';
import { Badge } from 'react-bootstrap';

export function CustomBadge({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) {
  return (
    <Badge
      pill
      variant="secondary"
      className={`${className} py-1 px-2 font-normal tracking-wider h-6 leading-4 uppercase`}>
      {children}
    </Badge>
  );
}
