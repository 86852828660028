import img from 'pools/images/polygen.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const polygen: PoolConfig = {
  // id is used in URL
  id: 'polygen',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x3D460751D8abf129eD8497f9c3e5c269e6C53588',
    tokensForSale: toWeiBN('3174000'),
    startDate: '2021-12-07T11:00:00.000Z',
    rate: 40,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('3174000'),
    raised: toWeiBN('79350'),
    baseAllocation: toWeiBN('438.148094055790857309'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-05T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6885,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x01d35cbc2070a3b76693ce2b6364eae24eb88591',
    name: 'Polygen',
    symbol: 'PGEN',
    imageSrc: img.src,
    listingTime: '2021-12-10T13:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(20_000_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'polygen',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '100% tokens unlocked at TGE',
    schedule: {
      '2021-12-10T13:00:00.000Z': 100,
    },
  },
  finishedInfo: (
    <QuickswapChartFinishedInfo
      symbol="PGEN"
      address="0x01d35cbc2070a3b76693ce2b6364eae24eb88591"
      pairAddress="0x01d35cbc2070a3b76693ce2b6364eae24eb88591"
    />
  ),
  social: {
    website: 'https://www.polygen.io/',
    twitter: 'https://twitter.com/Polygen_io',
    telegram: 'https://t.me/polygen_launchpad',
    telegramAnno: 'https://t.me/polygenann',
    medium: 'https://medium.com/@polygen',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Polygen is the Community’s Launchpad, the first truly decentralized
      launchpad where projects are free to innovate, experiment and launch their
      project with no gatekeepers, no whitelist and no whales.
    </p>
  ),
};
