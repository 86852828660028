import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/ashward.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const ashward: PoolConfig = {
  // id is used in URL
  id: 'ashward',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xF62A5Be697066DD6b63102e285C9Ef6E8dDEe393',
    tokensForSale: toWeiBN('4750000'),
    startDate: '2022-03-15T11:00:00.000Z',
    rate: 33.333,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4749999.999999999999998564'),
    raised: toWeiBN('142501.4250142501425014'),
    baseAllocation: toWeiBN('691.955570489844683393'),
    status: 5,
    registerDate: '2022-03-13T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('35'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6780,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: 'TGE 20%, 1 month cliff, vest monthly in 5 months',
    claimerVersion: 'v2',
    claimerAddress: '0x1385B48C1e40058Dd42009A0885BA42B15cC5816',
    schedule: {
      '2022-03-18T16:30:00.000Z': 20,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xf27be4a7b56e892f10861db447dbf0b84adb1706',
    pairAddress: '0x93bf3e1e9e53818be8ad498da9710cf9b49ad7e3',
    name: 'Ashward',
    symbol: 'ASC',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-18T16:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo:
      'Ashward will list on CMC, CoinGecko, PancakeSwap. There is 8% SELLING TAX first 1h since the listing',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(37_100_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'asward-coin',
    coinmarketcapId: 'ashward',
  },
  social: {
    website: 'https://ashward.io/',
    twitter: 'https://twitter.com/ashward_game',
    telegram: 'https://t.me/AshWard_Official',
    telegramAnno: 'https://t.me/AshWard_News',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Ashward is a virtual world where players can own, and monetize their
      gaming experiences in the Binance Smart Chain (BSC) network using ASC, the
      platform's utility token. Players will be able to explore the fantasy
      medieval land, gather the bravest warriors to defeat aggressive bosses or
      engage in fierce battles against other players. This general strategy game
      revolves around the never-ending battle between 3 races: Humans, Fairies
      and Monsters. Each character in the game Ashward has different weapons,
      attributes and parameters so you can use skills and strategies to arrange
      the squad during combat.
      <br />
      <br />
      Refunded 80%, see the{' '}
      <a href="https://bscscan.com/tx/0xdeb44fd59d43b5160c9059d5ff331493774c19c3ef2e67bec029a412b5e6adec">
        TX
      </a>
      .
    </p>
  ),
};
