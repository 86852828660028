import { toast } from 'react-toastify';

export const showMessage = (
  result: { message: string } | { error: string },
) => {
  if ('message' in result) {
    toast.success(result.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    toast.error(result.error, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};
