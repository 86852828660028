import { TransactionReceipt } from '@trustpad/launchpad/dist/types';

import React from 'react';
import { toast } from 'react-toastify';

import { getTxUrl } from '~utils/index';

export function successTx({
  content,
  tx,
}: {
  content: React.ReactNode;
  tx: TransactionReceipt;
}) {
  toast.success(
    <div className="space-y-3">
      <h4 className="text-green-900">Success</h4>
      <p className="text-white">{content}</p>
      <p>
        <a href={getTxUrl(tx.transactionHash)} className="text-green-900">
          Transaction
        </a>
      </p>
    </div>,
  );
}

export function errorTx({
  content,
  txHash,
  error,
}: {
  content: React.ReactNode;
  txHash?: string;
  error?: Error | any;
}) {
  toast.error(
    <div className="space-y-3">
      <h4 className="text-red-000">Failed</h4>
      <p className="text-white">{content}</p>
      {txHash && (
        <p>
          <a href={getTxUrl(txHash)} className="text-red-900">
            Transaction
          </a>
        </p>
      )}
      <p>Error: {error?.message || error?.toString()}</p>
    </div>,
  );
}
