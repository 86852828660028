export function GradientButton({
  href,
  gradientStyle,
  children,
}: {
  href: string;
  gradientStyle: string;
  children: React.ReactNode;
}) {
  return (
    <>
      {/* prettier-ignore-attribute (ClassName) */}
      <a
        href={href}
        className={`
            flex md:inline-flex 
            justify-center items-center 
            text-sm text-white sm:text-base 
            select-none 
            px-5 py-2 my-3
            rounded-[0.3rem]
            duration-200
            no-underline
            brand-shadow
            ${gradientStyle}
        `}
        target="_blank"
        rel="noreferrer">
        {children}
      </a>
    </>
  );
}
