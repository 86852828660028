import { Dialog as BaseDialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';

function Dialog({
  title,
  description,
  initialFocus,
  openButton,
  buttons,
  children,
  initialOpen = false,
}: {
  title: string;
  description?: string;
  initialFocus?: React.MutableRefObject<any>;
  openButton: (open) => React.ReactNode;
  buttons: ({ close }: { close: VoidFunction }) => React.ReactNode;
  children: React.ReactNode;
  initialOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(initialOpen || false);

  return (
    <>
      {openButton(() => setIsOpen(true))}

      <Transition.Root show={isOpen} as={Fragment}>
        <BaseDialog
          as="div"
          className="relative z-50 font-sans"
          initialFocus={initialFocus}
          onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <BaseDialog.Panel className="relative transform overflow-hidden rounded-lg bg-dark2 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-2xl sm:p-6">
                  <div>
                    <div className="text-center">
                      <BaseDialog.Title
                        as="h3"
                        className="text-2xl font-normal leading-6 text-white">
                        {title}
                      </BaseDialog.Title>
                      <div className="mt-6">{children}</div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                    {buttons({ close: () => setIsOpen(false) })}
                  </div>
                </BaseDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </BaseDialog>
      </Transition.Root>
    </>
  );
}

Dialog.Sections = function DialogSections({ children }) {
  return <div className="space-y-8 tracking-wide text-left">{children}</div>;
};

Dialog.SubTitle = function DialogSubTitle({ children }) {
  return (
    <div className="mb-4">
      <h3 className="text-center text-xl mb-0">{children}</h3>
    </div>
  );
};

const statusToIcon = {
  warning: (
    <FaExclamationTriangle className="inline text-yellow-400 text-[1.5rem] flex-shrink-0" />
  ),
  info: (
    <FaInfoCircle className="inline text-indigo-400 text-[1.5rem] flex-shrink-0" />
  ),
};

Dialog.SectionItem = function DialogSectionItem({
  iconStatus,
  children,
}: {
  iconStatus?: keyof typeof statusToIcon;
  children: React.ReactNode;
}) {
  return (
    <div className="relative flex gap-3">
      <div className="-top-4 -left-4 opacity-80 w-6">
        {iconStatus && statusToIcon[iconStatus]}
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

export { Dialog };
