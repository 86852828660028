import Head from 'next/head';

import { metaImg } from './images';

const defaultTitle = 'TrustPad - The Safest Multi-Chain IDO Launchpad';
const defaultDesc =
  'TrustPad is a decentralized multi-chain fundraising platform enabling projects to raise capital and promise safety to early stage participants. Stake TrustPad tokens to get priority-access to promising projects.';
const defaultImg = metaImg.src;

export function AppHead({
  title = defaultTitle,
  description = defaultDesc,
  image = defaultImg,
  url = 'https://trustpad.io/',
}: {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}) {
  return (
    <Head>
      <title key="title">{title}</title>

      <meta name="title" content={title} key="titleMeta" />
      <meta name="description" content={description} key="description" />

      <meta property="og:type" content="website" key="ogWeb" />
      <meta property="og:url" content={url} key="ogUrl" />
      <meta property="og:title" content={title} key="ogTitle" />
      <meta property="og:description" content={description} key="ogDesc" />
      <meta
        property="og:image"
        content={`https://trustpad.io${image}`}
        key="ogImg"
      />

      <meta
        property="twitter:card"
        content="summary_large_image"
        key="twCard"
      />
      <meta property="twitter:url" content={url} key="twUrl" />
      <meta property="twitter:title" content={title} key="twTitle" />
      <meta property="twitter:description" content={description} key="twDesc" />
      <meta
        property="twitter:image"
        content={`https://trustpad.io${image}`}
        key="twImg"
      />

      <link rel="icon" href="/favicon.ico" key="icon" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
        key="iconApple"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
        key="icon32"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
        key="icon16"
      />
    </Head>
  );
}
