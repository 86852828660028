import img from 'pools/images/fragmint.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const fragmint: PoolConfig = {
  // id is used in URL
  id: 'fragmint',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0x3c005A19C2a9c122d224b0f65C7B137f453b4de1',
    tokensForSale: toWeiBN('2375000'),
    startDate: '2022-10-12T18:00:00.000Z',
    rate: 25,
    durationHours: 18,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2375000'),
    raised: toWeiBN('95000'),
    baseAllocation: toWeiBN('235.141064837549701395'),
    status: 5,
    registerDate: '2022-10-10T18:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.6666666666666666,
    totalWeights: 10100320,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    claimerVersion: 'v4',
    vesting: '40% on TGE, then 10% monthly',
    claimerAddress: '0xb77d5B9C582092d4d4524D59A99Bbf0274A7ab97',
    schedule: {
      '2022-10-13T15:30:00.000Z': 40,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x5421f47D926bfcE8631087F8A547022ea3ADf0dE',
    name: 'Fragmint',
    symbol: 'FRAG',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-10-13T15:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(15_625_000),
    totalSupply: toWeiBN(990_000_000),
    coingeckoId: 'fragmint',
  },
  social: {
    website: 'https://fragmint.com/',
    twitter: 'https://twitter.com/Fragmintnft',
    telegram: 'https://t.me/fragmintapp',
    telegramAnno: 'https://t.me/fragmint',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Fragmint was born out of the necessity of being different in a sea of “the
      same”. The team of Fragmint believes it's time to shake the boundaries and
      come up with a curated environment and specific types of projects that do
      not live anywhere else. The Fragmint Platform focuses on building
      independent communities and empowering their creative use of decentralized
      mechanisms while aiming to incentivize contribution in order to generate
      and distribute more value for all participants via FRAG tokens. The $FRAG
      is a deflationary and governance token that gives the users different
      rights to operate within the platform, based on the total quantity of
      FRAGs held.
    </p>
  ),
};
