import { DateTimeFormat } from '@trustpad/launchpad';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { ZERO_BN } from '~config/constants';
import { levelPools } from '~config/levelPoolsConfig';
import { ClaimAllButton } from '~features/claimer/ClaimAllButton';
import { showMessage } from '~features/claimer/utils';
import { useBurnClaimer } from '~features/tpadv2/useBurnClaimer';
import { Dialog } from '~features/ui';
import { fNum } from '~utils';

export function BurnClaimer({
  claimer,
  symbol,
}: {
  claimer: ReturnType<typeof useBurnClaimer>;
  symbol: string;
}) {
  const {
    account,
    totalStats,
    userStats,
    isClaiming,
    config,
    claimAll,
    claimStakingPools,
    nextFeeDecreaseAt,
    stakingPools,
  } = claimer;
  const cancelButtonRef = useRef(null);

  if (!userStats || !totalStats) {
    return <Skeleton height={200} />;
  }

  const fee = totalStats
    ? userStats.claimableTokens.mul(totalStats.currentFeePercent).divn(100)
    : ZERO_BN;
  const deducted = userStats.claimableTokens.sub(fee);
  const hasStakingPools =
    stakingPools?.filter((p) => p.amount.gtn(0)).length > 0;
  const nonMigratedStakingPools = stakingPools?.filter(
    (p) => p.amount.gtn(0) && !p.migrated,
  );

  const claimBtnText = userStats.claimableTokens.gtn(0) ? (
    'MIGRATION IS DONE 🎉'
  ) : (
    <>
      Claimed <FaCheck className="text-green-500 inline align-sub text-sm" />
    </>
  );

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-6 gap-y-3">
        <Box label={`Claimed / Total`}>
          {fNum(userStats.claimedTokens)} / {fNum(userStats.tokens)} {symbol}
        </Box>

        <Box label="Claimable">
          {fNum(userStats.claimableTokens)} {symbol}{' '}
          {userStats.claimableTokens.gtn(0) &&
          totalStats.currentFeePercent.gtn(0) ? (
            <span className="text-red-500 text-xs">
              -{fNum(fee)} {symbol} fee
            </span>
          ) : null}
        </Box>

        <Box
          label={
            nextFeeDecreaseAt
              ? `Current Fee (5% decrease on ${dayjs
                  .unix(nextFeeDecreaseAt)
                  .utc()
                  .format(DateTimeFormat.noYear)})`
              : `Current Fee`
          }>
          <span
            className={
              totalStats.currentFeePercent.gtn(0)
                ? 'text-red-500'
                : 'text-green-500'
            }>
            {totalStats.currentFeePercent.toNumber()}%
          </span>
        </Box>

        <div className="flex items-center pt-4 col-span-2">
          {totalStats.currentFeePercent.gtn(0) ? (
            <Dialog
              openButton={(open) => (
                <ClaimAllButton
                  paused={
                    userStats.claimableTokens.eqn(0) &&
                    totalStats.pausedAt.gtn(0)
                  }
                  disabled={userStats.claimableTokens.eqn(0)}
                  account={account}
                  isClaiming={isClaiming ? -1 : undefined}
                  claim={() => open()}
                  className="w-full h-full">
                  {claimBtnText}
                </ClaimAllButton>
              )}
              title="Claim tokens"
              initialFocus={cancelButtonRef}
              buttons={({ close }) => (
                <>
                  <Button
                    variant="outline-secondary"
                    className="w-full"
                    ref={cancelButtonRef}
                    onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => claimAll().then(showMessage).then(close)}
                    disabled={isClaiming}
                    className="w-full">
                    Claim
                  </Button>
                </>
              )}>
              <Dialog.Sections>
                <Dialog.SectionItem iconStatus="warning">
                  <p>
                    The claiming fee is decreases for 5% daily. Read{' '}
                    <a
                      href="https://trustpad.medium.com/tpad-2-0-claiming-details-449d38043cf1"
                      className="text-white font-normal">
                      about claiming details here
                    </a>
                    . If you claim <b>{fNum(userStats.tokens)} TPAD</b> now:
                  </p>
                  <ul className="list-disc list-inside">
                    <li>
                      you will receive <b>{fNum(deducted)} TPAD</b>
                    </li>
                    <li>
                      you will{' '}
                      <span className="text-red-500">
                        lose {totalStats.currentFeePercent.toNumber()}%
                      </span>{' '}
                      = <b className="text-red-500">{fNum(fee)} TPAD</b>
                    </li>
                  </ul>
                  <p className="mt-4">
                    Are you sure you want to claim? The fee cannot be refunded
                    once the transaction is done.
                  </p>
                </Dialog.SectionItem>
              </Dialog.Sections>
            </Dialog>
          ) : (
            <ClaimAllButton
              paused={
                userStats.claimableTokens.eqn(0) && totalStats.pausedAt.gtn(0)
              }
              disabled={userStats.claimableTokens.eqn(0)}
              account={account}
              isClaiming={isClaiming ? -1 : undefined}
              claim={() => claimAll().then(showMessage)}
              className="w-full h-full">
              {claimBtnText}
            </ClaimAllButton>
          )}
        </div>

        {hasStakingPools && (
          <>
            <div className="col-span-2">
              <Box label="Migrate your staked tokens">
                <div>
                  {stakingPools
                    .filter((p) => p.amount.gtn(0))
                    .map((p, i) =>
                      levelPools.find((v) => v.address === p.pool) ? (
                        <span key={p.pool} className="text-sm">
                          {i !== 0 && <> • </>}
                          {
                            levelPools.find((v) => v.address === p.pool).id
                          }: {fNum(p.amount)} TPAD
                        </span>
                      ) : null,
                    )}
                </div>
                <p>No fee applied on staking pools migration.</p>
              </Box>
            </div>
            <div className="col-span-2">
              <ClaimAllButton
                paused={totalStats.pausedAt.gtn(0)}
                disabled={
                  totalStats.pausedAt.gtn(0) ||
                  nonMigratedStakingPools?.length === 0
                }
                account={account}
                isClaiming={isClaiming ? -1 : undefined}
                claim={() => claimStakingPools().then(showMessage)}
                className="w-full h-full">
                {!nonMigratedStakingPools?.length ? (
                  <>
                    Migrated{' '}
                    <FaCheck className="text-green-500 inline align-sub text-sm" />
                  </>
                ) : (
                  'MIGRATION IS DONE 🎉'
                )}
              </ClaimAllButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Box({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col">
      <div className="mb-2 text-sm text-secondary">{label}</div>
      <div className="flex-grow flex items-center justify-center flex-col p-3 bg-dark2 border border-gray-800 rounded-lg text-white shadow">
        {children}
      </div>
    </div>
  );
}
