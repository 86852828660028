import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/unibit.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const unibit: PoolConfig = {
  // id is used in URL
  id: 'unibit',
  // public, seed, private, strategic
  access: 'kol',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0x3078580A976d347Ab5D9d3Ff6047A688CC9EE7E6',
    tokensForSale: toWeiBN(12_666_666.6),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-04-05T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 133.333,
    durationHours: 23,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(12_666_666.6),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_us',
    network: 'ARB',
    claimerVersion: 'v4',
    vesting: '12% at TGE, 11% monthly for 8 months',
    claimerAddress: '0xb3B414243a816E802D544C51Cb73F325f7AAdbb9',
    schedule: {
      '2024-04-08T10:30:00.000Z': 12,
      '2024-05-08T10:30:00.000Z': 11,
      '2024-06-08T10:30:00.000Z': 11,
      '2024-07-08T10:30:00.000Z': 11,
      '2024-08-08T10:30:00.000Z': 11,
      '2024-09-08T10:30:00.000Z': 11,
      '2024-10-08T10:30:00.000Z': 11,
      '2024-11-08T10:30:00.000Z': 11,
      '2024-12-08T10:30:00.000Z': 11,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ARB',
    address: '0x76BC2e765414E6C8b596c0f52c4240f80268f41D',
    name: 'Unibit',
    symbol: 'UIBT',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-04-08T10:30:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Listing on MEXC and Uniswap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(28_400_000),
    totalSupply: toWeiBN(1_000_000_000),
  },
  social: {
    website: 'https://www.unibit.app/',
    twitter: 'https://twitter.com/Unibit_bridge',
    telegram: 'https://t.me/unibitprotocol',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      UniBit Protocol revolutionizes crosschain transfers by seamlessly bridging
      the gap between Bitcoins BRC20 tokens and the programmable power of EVM
      networks like Ethereum, Avalanche, Optimism, BSC, Arbitrum, BASE ,
      Polygon, Cronos and KAVA. The UIBT token is the lifeblood of the UniBit
      Protocol, playing a crucial role in its operation. As the native utility
      token, UIBT embodies the essence of UniBit Protocols commitment to
      facilitating seamless and efficient cross-chain transfers between Bitcoins
      BRC20 and EVM networks.
    </p>
  ),
};
