import { useWeb3Provider } from '@trustpad/launchpad';

import { Button } from '~features/ui';

import img from './wallet-connect.svg';

export function WalletConnectButton() {
  const { connectWalletConnect } = useWeb3Provider();

  return (
    <Button
      type="walletConnect"
      onClick={() => connectWalletConnect()}
      className="bg-blue-700 hover:bg-blue-900">
      <img
        src={img.src}
        alt="WalletConnect"
        className="inline-block w-6 mr-3"
      />
      <span className="">WalletConnect</span>
    </Button>
  );
}
