import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { FailedModal } from '~common/components/FailedModal';
import { SuccessModal } from '~common/components/SuccessModal';
import { useModalProvider } from '~common/hooks';

// TODO: maybe breadcrumbs
export function PageLayout({
  title,
  links,
  titleExtra,
  children,
}: {
  title?: React.ReactNode;
  links?: { name: string; href: string }[];
  titleExtra?: React.ReactNode;
  children: React.ReactNode;
}) {
  const { message, onModalClose } = useModalProvider();
  const router = useRouter();

  return (
    <>
      <div className="flex md:items-center mb-4 flex-col md:flex-row gap-6">
        <h1 className="text-white text-4xl mb-0">{title}</h1>

        {links && (
          <div className="flex gap-3">
            {links.map((link) => (
              <span key={link.href}>
                <Link
                  href={link.href}
                  className={`text-base no-underline border-b border-b-gray-600 pb-1 hover:text-main hover:border-main ${
                    link.href === router.asPath
                      ? 'text-mainDark'
                      : 'text-secondary'
                  }`}>
                  {link.name}
                </Link>
              </span>
            ))}
          </div>
        )}
        {titleExtra && <div className="md:ml-auto">{titleExtra}</div>}
      </div>

      {children}

      {message && message.success && (
        <SuccessModal message={message} onClose={onModalClose} />
      )}
      {message && !message.success && (
        <FailedModal message={message} onClose={onModalClose} />
      )}
    </>
  );
}
