import React from 'react';

export function LaunchProtocolProtected({ period = '72 hours' }) {
  return (
    <div>
      Your investment is protected, this sale is under the{' '}
      <a
        href="https://trustpad.medium.com/trustpad-introduces-safu-ido-model-903afd518179"
        target="_blank"
        rel="noreferrer">
        SAFU
      </a>{' '}
      rules. Refund is possible within {period} after the listing.
    </div>
  );
}