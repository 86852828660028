import { NftPoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const landInventory: NftPoolConfig['sale']['inventory'] = [
  {
    id: 'common',
    name: 'Common',
    image: '',
    supply: 80,
    totalSupply: 2000,
    price: toWeiBN('300'),
    group: 'Standard',
    internalId:
      '0x636f6d6d6f6e0000000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 80,
    raised: toWeiBN('24000'),
  },
  {
    id: 'rare',
    name: 'Rare',
    image: '',
    supply: 80,
    totalSupply: 1370,
    price: toWeiBN('600'),
    group: 'Standard',
    internalId:
      '0x7261726500000000000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 80,
    raised: toWeiBN('48000'),
  },
  {
    id: 'epic',
    name: 'Epic',
    image: '',
    supply: 30,
    totalSupply: 740,
    price: toWeiBN('2000'),
    group: 'Standard',
    internalId:
      '0x6570696300000000000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 30,
    raised: toWeiBN('60000'),
  },
  {
    id: 'legendary',
    name: 'Legendary',
    image: '',
    supply: 10,
    totalSupply: 15,
    price: toWeiBN('10000'),
    group: 'Standard',
    internalId:
      '0x6c6567656e646172790000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 5,
    raised: toWeiBN('50000'),
  },
  {
    id: 'mythical',
    name: 'Mythical',
    image: '',
    supply: 2,
    totalSupply: 20,
    price: toWeiBN('50000'),
    group: 'Standard',
    internalId:
      '0x6d7974686963616c000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 2,
    raised: toWeiBN('100000'),
  },
  {
    id: 'common-s',
    name: 'Common S',
    image: '',
    supply: 10,
    totalSupply: 500,
    price: toWeiBN('1300'),
    group: 'S rank',
    internalId:
      '0x636f6d6d6f6e2d73000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 10,
    raised: toWeiBN('13000'),
  },
  {
    id: 'rare-s',
    name: 'Rare S',
    image: '',
    supply: 35,
    totalSupply: 270,
    price: toWeiBN('2500'),
    group: 'S rank',
    internalId:
      '0x726172652d730000000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 35,
    raised: toWeiBN('87500'),
  },
  {
    id: 'epic-s',
    name: 'Epic S',
    image: '',
    supply: 7,
    totalSupply: 200,
    price: toWeiBN('9000'),
    group: 'S rank',
    internalId:
      '0x657069632d730000000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 7,
    raised: toWeiBN('63000'),
  },
  {
    id: 'legendary-s',
    name: 'Legendary S',
    image: '',
    supply: 3,
    totalSupply: 100,
    price: toWeiBN('42000'),
    group: 'S rank',
    internalId:
      '0x6c6567656e646172792d73000000000000000000000000000000000000000000',
    limit: 4,
    sold: 0,
    raised: toWeiBN('0'),
  },
  {
    id: 'common-ss',
    name: 'Common SS',
    image: '',
    supply: 20,
    totalSupply: 200,
    price: toWeiBN('2700'),
    group: 'SS rank',
    internalId:
      '0x636f6d6d6f6e2d73730000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 20,
    raised: toWeiBN('54000'),
  },
  {
    id: 'rare-ss',
    name: 'Rare SS',
    image: '',
    supply: 15,
    totalSupply: 100,
    price: toWeiBN('5400'),
    group: 'SS rank',
    internalId:
      '0x726172652d737300000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 15,
    raised: toWeiBN('81000'),
  },
  {
    id: 'epic-ss',
    name: 'Epic SS',
    image: '',
    supply: 5,
    totalSupply: 50,
    price: toWeiBN('18000'),
    group: 'SS rank',
    internalId:
      '0x657069632d737300000000000000000000000000000000000000000000000000',
    limit: 4,
    sold: 4,
    raised: toWeiBN('72000'),
  },
  {
    id: 'legendary-ss',
    name: 'Legendary SS',
    image: '',
    supply: 3,
    totalSupply: 20,
    price: toWeiBN('90000'),
    group: 'SS rank',
    internalId:
      '0x6c6567656e646172792d73730000000000000000000000000000000000000000',
    limit: 4,
    sold: 0,
    raised: toWeiBN('0'),
  },
];
