import React, { MouseEventHandler } from 'react';

export type ButtonProps = {
  size?: keyof typeof sizes;
  type?: keyof typeof types;
  className?: string;
  href?: string;
  children: React.ReactNode;
  rightAddon?:
    | React.ReactNode
    | (({ btnClassName }: { btnClassName: string }) => React.ReactNode);
  disabled?: boolean;
  target?: HTMLLinkElement['target'];
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

const sizes = {
  xs: 'px-2 py-2 text-sm',
  sm: 'px-3 py-2 text-sm leading-4',
  md: 'px-4 py-2 text-base',
  lg: 'px-6 py-3 text-base',
};

const roundedClass = {
  xs: (withAddon: Boolean) => (withAddon ? 'rounded-l' : 'rounded'),
  sm: (withAddon: Boolean) => (withAddon ? 'rounded-l-md' : 'rounded-md'),
  md: (withAddon: Boolean) => (withAddon ? 'rounded-l-md' : 'rounded-md'),
  lg: (withAddon: Boolean) => (withAddon ? 'rounded-l-md' : 'rounded-md'),
};

const addonRounded = {
  xs: 'rounded-r',
  sm: 'rounded-r-md',
  md: 'rounded-r-md',
  lg: 'rounded-r-md',
};

const types = {
  primary:
    'border-transparent bg-button-primary text-white shadow-sm hover:bg-purple-800 border',
  secondary:
    'border-transparent bg-indigo-100 text-indigo-700 hover:bg-indigo-200 border',
  primaryOutline:
    'border-main bg-transparent text-main hover:bg-main hover:text-white border',
  secondaryOutline:
    'border-purple-600 bg-transparent text-purple-600 hover:bg-dark-grayMix hover:border-dark-grayMix border',
  primaryDanger:
    'border-transparent bg-red-600 text-white shadow-sm hover:bg-red-800 border',
  main: 'border-2 border-main hover:border-[#9d51ff] text-white brand-bg brand-shadow',
  walletConnect:
    'w-[177px] border-transparent text-white shadow-sm border text-left',
};

const addonTypes = {
  primary: '',
  secondary: '',
  primaryOutline:
    'border-main bg-transparent text-main hover:bg-main hover:text-white',
  secondaryOutline: '',
};

export function Button({
  size = 'md',
  type = 'primary',
  className = '',
  href,
  children,
  rightAddon,
  target = '_blank',
  disabled,
  onClick,
}: ButtonProps) {
  const borderClassName = rightAddon ? 'border-t border-b border-l' : '';
  const fullClassName = `${sizes[size]} ${types[type]} 
  ${borderClassName} ${
    type === 'main' ? 'rounded-lg' : roundedClass[size](Boolean(rightAddon))
  }
  ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
  inline-flex justify-center items-center no-underline
  hover:text-inherit
  focus:outline-none transition-colors ${className}`;

  const button = href ? (
    <a
      href={href}
      target={target}
      className={fullClassName}
      rel="noreferrer"
      onClick={onClick}>
      {children}{' '}
    </a>
  ) : (
    <button
      type="button"
      className={fullClassName}
      disabled={disabled}
      onClick={onClick}>
      {children}{' '}
    </button>
  );

  return rightAddon ? (
    <div className="flex items-center">
      {button}
      <div
        className={`${addonTypes[type]} ${addonRounded[size]} border h-full`}>
        {typeof rightAddon === 'function'
          ? rightAddon({ btnClassName: 'py-2 px-1' })
          : rightAddon}
      </div>
    </div>
  ) : (
    button
  );
}
