import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/babylons.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const babylons: PoolConfig = {
  // id is used in URL
  id: 'babylons',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  // Should click on card open the pool page?
  linkToDetailPage: true,
  sale: {
    address: '0x6286918293478F231dc6fAAF780c740E7BC522d5',
    status: SaleStatus.finished,
    rate: 25,
    tokensForSale: toWeiBN('3750000'),
    startDate: '2021-08-16T09:00:00.000Z',
    baseAllocation: toWeiBN('1108.288345506510003175'),
    durationHours: 10.5,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$150,000',
    participants: 1005,
    tokensSold: toWeiBN('3750000'),
    raised: toWeiBN('150000'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-08-09T12:00:00.000Z',
    registerDuration: 159.5,
    fcfsDuration: 3,
    levelsOpenAll: true,
  },
  token: {
    address: '0xec15a508a187e8ddfe572a5423faa82bbdd65120',
    name: 'Babylons',
    symbol: 'BABI',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingRate: 25,
    totalSupply: toWeiBN(''),
    // BSC, ETH, DOT or SOL
    chain: 'BSC',
    initialSupply: toWeiBN(3_685_500),
    listingTime: '2021-08-18T22:00:00.000Z',
    coingeckoId: 'babylons',
    coinmarketcapId: 'babylons',
  },
  distribution: {
    type: 'claim_them',
    claimUrl: 'http://app.babylons.io/i/claim',
    vesting: '20% at TGE, and 20% each 2 weeks',
    schedule: {
      '2021-08-18T22:00:00.000Z': 20,
      '2021-09-01T22:00:00.000Z': 20,
      '2021-09-15T22:00:00.000Z': 20,
      '2021-09-29T22:00:00.000Z': 20,
      '2021-10-13T22:00:00.000Z': 20,
    },
  },
  social: {
    website: 'https://babylons.io/',
    twitter: 'https://twitter.com/BabylonsNFT',
    telegram: 'https://t.me/babylonsNFT',
    telegramAnno: 'https://t.me/babylonsNFT_Ann',
    medium: 'https://blog.babylons.io',
    whitepaper: 'https://babylons.io/assets/litepaper.pdf',
  },
  description: (
    <div>
      Babylons is a next generation NFT marketplace with low-cost minting,
      weekly rewards, physical art gallery connections and and a DAO governance
      that puts the power in the hands of the community.
    </div>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="$BABI"
        address="0xec15a508a187e8ddfe572a5423faa82bbdd65120"
        swapOn="APE"
      />
    </div>
  ),
};
