import { useWeb3Provider } from '@trustpad/launchpad';

import { TOKEN_ADDRESS } from '~config/addresses';
import { useERC20 } from '~features/pools';

export function useTrustpadToken() {
  const { web3 } = useWeb3Provider();
  const token = useERC20(TOKEN_ADDRESS, web3);

  return token;
}
