import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/xraise.jpeg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const xraise: PoolConfig = {
  // id is used in URL
  id: 'xraise',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0xB9FEAffF8fD7Acb6f419e2b5a23A8CACFF11A43A',
    tokensForSale: toWeiBN(13_333_334),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-06-29T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 66.666,
    durationHours: 23,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(0),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.static,
  },
  distribution: {
    type: 'claim_us',
    network: 'ZKSYNC',
    claimerVersion: 'v4',
    claimerAddress: '',
    vesting: '100% at TGE',
    schedule: {
      '2024-07-04T14:00:00.000Z': 100,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY, ARB
    chain: 'ZKSYNC',
    address: '',
    name: 'xRaise',
    symbol: 'RAISE',
    imageSrc: img.src,
    // Format: "2024-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-07-04T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Listed on SyncSwap',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(89_000_000),
    totalSupply: toWeiBN(1_000_000_000),
  },
  social: {
    website: 'https://www.xraise.io/',
    twitter: 'https://x.com/xraise_fi/',
    telegram: '',
    telegramAnno: 'https://t.me/xRaise_announcements/',
    medium: 'https://xraise-blog.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      xRaise is a smart contract wallet and a suite of plug-in gaming SDKs
      designed for dApps and games. Powered by zkSync, it leverages zkSync’s
      native Account Abstraction features such as Transaction Batching, Session
      Keys, Paymasters, Social Recovery, Subscriptions, and more to elevate the
      user experience. Additionally, the integration of the xRaise wallet with
      Passkey ensures passwordless and secure authentication, providing an even
      smoother user experience. The RAISE token will be one of the payment
      options for transaction fees along with other tokens like USDC, and USDT
      (the list is subject to change). Users opting out to pay with RAISE will
      not be charged an additional paymaster fee on top of the network fee so,
      paying with RAISE will be a relatively cheaper option concerning gas fees.
    </p>
  ),
};
