import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';
import luxfiImg from '../images/luxfi.jpeg';
import luxfiSeoImg from '../images/luxfiSeo.jpeg';

export const luxfi: PoolConfig = {
  // id is used in URL
  id: 'luxfi',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xdbd6c2C36710A4a5729063e6f7A9B46f351d22c2',
    tokensForSale: toWeiBN('700000'),
    startDate: '2021-11-08T16:00:00.000Z',
    rate: 10,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('699999.99999999999999928'),
    raised: toWeiBN('69999.999999999999999928'),
    baseAllocation: toWeiBN('126.384601416379153804'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-11-06T16:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 5255,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0xa799c4adcf62e025ce4d8abe6a77cebc487d772a',
    name: 'LuxFi',
    symbol: 'LXF',
    imageSrc: luxfiImg.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(4_096_386),
    totalSupply: toWeiBN(100_000_000),
    listingTime: '2021-11-09T13:30:00.000Z',
    coingeckoId: 'luxfi',
    coinmarketcapId: 'luxfi',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="LXF"
      address="0xa799c4adcf62e025ce4d8abe6a77cebc487d772a"
      pairAddress="0x7b034bba28ec5f3a4f844ca1ee7178af06f7d0e9"
    />
  ),
  distribution: {
    type: 'airdrop_them',
    vesting: '100% unlock day 1',
    schedule: {
      '2021-11-09T13:30:00.000Z': 100,
    },
  },
  social: {
    website: 'https://www.luxfi.io/',
    twitter: 'https://twitter.com/luxfiofficial',
    telegram: 'https://t.me/Luxfiofficial',
    telegramAnno: '',
    medium: 'https://luxfiofficial.medium.com/',
    github: '',
    whitepaper: 'https://www.luxfi.io/static/media/Lightpaper.0f9a0a6b.pdf',
    announcement: '',
  },
  images: {
    seo: luxfiSeoImg.src,
  },
  description: (
    <p>
      LuxFi is the world’s first asset-backed NFT marketplace for luxury assets,
      where people can buy, sell and invest in luxury assets using
      cryptocurrencies and traditional payments. They eliminate counterfeiting
      while minting an NFT on a multi-chain blockchain network, with a focus on
      luxury assets that hold value well and have a high reselling value.
      Leveraging on a big data intelligence system and unique algorithm for
      automated data collecting and data processing, the value of each NFT on
      their platform is backed by real-world data.
    </p>
  ),
};
