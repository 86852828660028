import { SaleStatus } from '@trustpad/launchpad';

import { netvrkImg } from 'pools/finished/netvrk/images';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const netvrk: PoolConfig = {
  id: 'netvrk',
  access: 'public',
  fundToken: BUSD,
  linkToDetailPage: true,
  sale: {
    address: '0xE658eA65D3620E357bDF9a70F97Fb3E81D24FD7a',
    status: SaleStatus.finished,
    rate: 6.666666,
    tokensForSale: toWeiBN('666,666'),
    startDate: '2021-05-21T12:00:00.000Z',
    baseAllocation: toWeiBN('237'),
    durationHours: 1.5,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$100,000 USD',
    participants: 0,
    tokensSold: toWeiBN('666,666'),
    raised: toWeiBN('100,000'),
    update: false,
  },
  token: {
    address: '0xFc0d6Cf33e38bcE7CA7D89c0E292274031b7157A',
    name: 'NetVRk',
    symbol: 'NTVRK',
    imageSrc: netvrkImg.src,
    decimals: 18,
    type: 'ERC-20',
    listingTime: '2021-05-21T12:00:00.000Z',
    pairAddress: '',
    listingRate: 0,
    totalSupply: toWeiBN('100,000,000'),
    chain: 'ETH',
    coingeckoId: 'netvrk',
    coinmarketcapId: 'netvrk',
  },
  social: {
    twitter: 'https://twitter.com/netvrk1',
    medium: 'https://netvrk.medium.com',
    telegram: 'https://t.me/NetVRk_Official',
    website: 'https://netvrk.co',
  },
  description: (
    <div>
      Netvrk is a social Virtual Reality platform on the blockchain, with tools
      that allow you to easily create, share, and monetize your creations.
    </div>
  ),
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="$NTVRK"
      address="0xFc0d6Cf33e38bcE7CA7D89c0E292274031b7157A"
      pairAddress="0xddf8390ced9fad414b1ca1dd4fe14f881c2cfa70"
    />
  ),
};
