import { isNFTSale } from '@trustpad/launchpad/dist/pool/utils';

import * as React from 'react';

import { TMain } from '~common/UI';
import { usePool } from '~features/pools';
import { ProgressBar } from '~features/ui';
import { fNum, toEthN, toFixedNumber } from '~utils';

export function SaleProgressBar({
  topLeft,
  hideDetails,
}: {
  topLeft?: React.ReactElement;
  hideDetails?: boolean;
}) {
  const { pool } = usePool();
  const { sale, fundToken, token } = pool;

  const filled = toEthN(sale.tokensSold);
  const progress = isNFTSale(sale)
    ? (sale.tokensSold.toNumber() * 100) / toEthN(sale.tokensForSale)
    : (toEthN(sale.tokensSold) * 100) / toEthN(sale.tokensForSale);
  const progressFormatted = progress ? toFixedNumber(progress, 2) : 0;

  return (
    <div className="space-y-1">
      <div className="flex justify-between text-sm tracking-wide space-x-3">
        <div className="">{topLeft}</div>

        <div className="font-medium opacity-90 text-right">
          <TMain>{progressFormatted}%</TMain>
        </div>
      </div>

      <ProgressBar progress={progress} height="1rem" />

      {!hideDetails && (
        <div className="flex justify-between text-sm tracking-wide space-x-3">
          <div className="text-ignored opacity-90 flex-shrink-0">
            {fNum(sale.raised)} {fundToken.symbol}
          </div>
          <div className="text-ignored opacity-90 text-right">
            {isNFTSale(sale) ? sale.tokensSold.toString() : fNum(filled)} /{' '}
            {fNum(sale.tokensForSale)} {token.symbol}
          </div>
        </div>
      )}
    </div>
  );
}
