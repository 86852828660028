import React from 'react';
import cmcImg from './icons/coinmarketcap.png';
import coingeckoImg from './icons/coingecko.png';

export function CoingeckoLink({
  id,
  className,
  children,
}: {
  id: string;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <a
      href={`https://www.coingecko.com/en/coins/${id}`}
      rel="noopener noreferrer nofollow"
      target="_blank"
      className={className}>
      <img src={coingeckoImg.src} className="h-6 inline-block mr-2" />{' '}
      {children}
    </a>
  );
}

export function CoinMarketCapLink({
  id,
  className,
  children,
}: {
  id: string;
  className?: string;

  children?: React.ReactNode;
}) {
  return (
    <a
      href={`https://coinmarketcap.com/currencies/${id}/`}
      rel="noopener noreferrer nofollow"
      target="_blank"
      className={className}>
      <img src={cmcImg.src} className="h-6 inline-block mr-2 filter invert" />{' '}
      {children}
    </a>
  );
}
