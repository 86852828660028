import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/bullieverse.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const bullieverse: PoolConfig = {
  // id is used in URL
  id: 'bullieverse',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x0874dEce31bb2b65DA56978Aeb20B2452f3A805C',
    tokensForSale: toWeiBN('2500000'),
    startDate: '2022-02-12T11:00:00.000Z',
    rate: 25,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2500000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('739.981785063752276867'),
    status: 5,
    registerDate: '2022-02-10T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('25'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 3542,
    minAllowedLevelMultiplier: 22,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_them',
    vesting: '8% at TGE and linear release over one year',
    claimUrl: 'https://token.bullieverse.com/',
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x9f95e17b2668AFE01F8fbD157068b0a4405Cc08D',
    pairAddress: '0xb82b5d256a7809c29dcf09b7c623823a81ec6e5c',
    name: 'Bullieverse',
    symbol: 'BULL',
    imageSrc: img.src,
    listingTime: '2022-03-09T13:00:00.000Z',
    decimals: 18,
    listingRate: 20,
    initialSupply: toWeiBN(14650000),
    totalSupply: toWeiBN(1000000000),
    coingeckoId: 'bullieverse',
    coinmarketcapId: 'bullieverse',
  },
  social: {
    website: 'https://bullieverse.com/',
    twitter: 'https://twitter.com/bullieverse',
    telegram: 'https://t.me/Bullieverse',
    telegramAnno: 'https://t.me/Bullieverseannouncements',
    medium: '',
    github: '',
    whitepaper:
      'https://docs.bullieverse.com/white-paper/foreward/the-rise-of-metaverse',
    announcement: '',
  },
  description: (
    <p>
      Bullieverse is an Open Metaverse developed for the community of players
      and creators. It is built for the Web 3.0 citizen to have an immersive
      environment. As a result, gamers enjoy an out-of-the-world experience
      combined with a transparent and fair monetization mechanism that underpins
      our play-to-earn economy. Furthermore, it is powered by an easy-to-build
      'low code' platform for community members creating and publishing games.
      The result is a high-quality gaming platform, where users come to both
      play and earn, but more importantly, experience it.
    </p>
  ),
};
