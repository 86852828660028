import img from 'pools/images/ioen.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const ioen: PoolConfig = {
  // id is used in URL
  id: 'ioen',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x4a6bB442e66c5507d591f62EAC1f46623efbb2B7',
    tokensForSale: toWeiBN('9600000'),
    startDate: '2021-10-13T12:00:00.000Z',
    rate: 40,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 923,
    tokensSold: toWeiBN('9600000'),
    raised: toWeiBN('240000'),
    baseAllocation: toWeiBN('2589.658965896589658965'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-10-08T12:00:00.000Z',
    registerDuration: 114,
    fcfsDuration: 1,
    totalWeights: 3636,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xd0e9c8f5fae381459cf07ec506c1d2896e8b5df6',
    name: 'IOEN',
    listingTime: '2021-10-13T12:00:00.000Z',
    symbol: 'IOEN',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(6000000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'internet-of-energy-network',
  },
  distribution: {
    type: 'claim_them',
    claimUrl: 'https://ido.ioen.tech/',
    vesting:
      '100% distributed, with 70% locked for 3 month with linear release',
  },
  finishedInfo: (
    <QuickswapChartFinishedInfo
      symbol="IOEN"
      address="0xd0e9c8f5fae381459cf07ec506c1d2896e8b5df6"
      pairAddress="0xd0e9c8f5fae381459cf07ec506c1d2896e8b5df6"
    />
  ),
  social: {
    website: 'https://www.ioen.tech/',
    twitter: 'https://twitter.com/IOEN_tech',
    telegram: 'https://t.me/IOEN_global',
    telegramAnno: '',
    medium: 'https://medium.com/internet-of-energy-network',
    github: '',
    whitepaper:
      'https://drive.google.com/file/d/16ew9ABs0mOSPyGI0JhLOjWdZ59Y34SDU/view',
    announcement:
      'https://trustpad.medium.com/ioen-are-launching-on-trustpad-d2d9ae94fc7',
  },
  description: (
    <p>
      The Internet of Energy Network is an interconnected system of virtual
      microgrids that facilitate transactions within and between local energy
      ecosystems: from the appliance level, to energy generation, storage, and
      consumption.
    </p>
  ),
};
