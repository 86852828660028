import img from 'pools/images/merkle.jpg';
import metaImg from 'pools/images/merkleAnn.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const merkle: PoolConfig = {
  // id is used in URL
  id: 'merkle',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xAaE7EAFc10058cbA5aa89a70d34864468b93490A',
    tokensForSale: toWeiBN('666666.666'),
    startDate: '2021-10-29T12:00:00.000Z',
    rate: 6.666,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1199,
    tokensSold: toWeiBN('666666.665999999999999951'),
    raised: toWeiBN('100010.000900090009000949'),
    baseAllocation: toWeiBN('137.227994536667367093'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('20'),
    },
    registerDate: '2021-10-27T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 4787,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x000000000ca5171087c18fb271ca844a2370fc0a',
    name: 'Merkle',
    symbol: 'MERKLE',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(3_200_000),
    totalSupply: toWeiBN(60_000_000),
    listingTime: '2021-11-03T14:00:00.000Z',
    coingeckoId: 'merkle-network',
    coinmarketcapId: 'merkle-network',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="MERKLE"
      address="0x000000000ca5171087c18fb271ca844a2370fc0a"
      pairAddress="0xa9afc52e9d83dce0506bc1f09a3bb49f0012be16"
    />
  ),
  distribution: {
    type: 'claim_them',
    vesting: '20% at TGE, 1% released each day for 80 days',
    claimUrl: 'https://merkle.network/release.html',
  },
  social: {
    website: 'https://merkle.network/',
    twitter: 'https://twitter.com/merkleNetwork',
    telegram: 'https://t.me/merkleNetwork',
    telegramAnno: '',
    medium: 'https://medium.com/ethverse',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  images: {
    seo: metaImg.src,
  },
  description: (
    <p>
      Merkle Network is a Web3.0 interoperability protocol which enables cross
      chain transfers of blockchain assets and data between multiple
      blockchains.
    </p>
  ),
};
