import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/nomadexiles.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

const nomadexilesCommon = {
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x085D15DB9c7Cd3Df188422f88Ec41ec573D691b9',
    name: 'Nomad Exiles',
    symbol: 'PRIDE',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-04-02T13:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 10,
    initialSupply: toWeiBN(3_697_200),
    totalSupply: toWeiBN(250_000_000),
    coingeckoId: 'nomad-exiles',
    coinmarketcapId: 'nomad-exiles',
  },
  social: {
    website: 'https://pridemetaverse.io/',
    twitter: 'https://twitter.com/NomadExiles',
    telegram: 'https://t.me/NomadExilesChat',
    telegramAnno: 'https://t.me/NomadExiles',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      The Nomad Exiles project is a vivid example of a modern approach to
      creating gaming metaverses. This is a full-fledged multiplayer online game
      that brings classic RPG gameplay and advanced lore with many secrets and
      plot twists to the world of blockchain games. But the game also has a
      variety of Play-to-Earn mechanics that allow every player to find a way to
      earn money that they will like: from selling game valuables in the form of
      NFT to receiving prize tokens for winning PvP battles.
    </p>
  ),
};

export const nomadexiles: PoolConfig = {
  ...nomadexilesCommon,
  // id is used in URL
  id: 'nomadexiles',
  // public, seed or private
  access: 'public',
  sale: {
    address: '0x1eB70cf8dA9E9F32de3765aDA31551C1d8147c35',
    tokensForSale: toWeiBN('1900000'),
    startDate: '2022-03-23T11:00:00.000Z',
    rate: 10,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1900000'),
    raised: toWeiBN('190000'),
    baseAllocation: toWeiBN('263.178741178165058332'),
    status: 5,
    registerDate: '2022-03-21T11:00:00.000Z',
    registerDuration: 45,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('75'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7009,
    minAllowedLevelMultiplier: 0,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '10% on TGE and after 10% monthly from the second month',
    claimerAddress: '0x786F536cC507BEf41b1F67932059cAcDb89bF35b',
    claimerVersion: 'v2',
    schedule: {
      '2022-04-02T13:00:00.000Z': 10,
      '2022-05-02T13:00:00.000Z': 10,
      '2022-06-01T13:00:00.000Z': 10,
      '2022-07-01T13:00:00.000Z': 10,
      '2022-07-31T13:00:00.000Z': 10,
      '2022-08-30T13:00:00.000Z': 10,
      '2022-09-29T13:00:00.000Z': 10,
      '2022-10-29T13:00:00.000Z': 10,
      '2022-11-28T13:00:00.000Z': 10,
      '2022-12-28T13:00:00.000Z': 10,
    },
  },
};

export const nomadexilesPrivate: PoolConfig = {
  ...nomadexilesCommon,
  // id is used in URL
  id: 'nomadexilesprivate',
  // public, seed or private
  access: 'private',
  sale: {
    address: '0x537fAEc4597858129C59881501d8a7e46D2cfef1',
    tokensForSale: toWeiBN('678571.429'),
    startDate: '2022-03-23T11:00:00.000Z',
    rate: 14.285,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('678571.428999999999998592'),
    raised: toWeiBN('47502.3751487574378718'),
    baseAllocation: toWeiBN('240.798945706174591909'),
    status: 5,
    registerDate: '2022-03-21T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 2856,
    minAllowedLevelMultiplier: 22,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting:
      '5% on TGE, then 4 months cliff, then monthly linear unlock for 12 months',
    claimerAddress: '0xf7f8Ec47c7979a47DfC203A91CFBD4CA755319b6',
    claimerVersion: 'v2',
    schedule: {
      '2022-04-02T13:00:00.000Z': 5,
      '2022-07-31T13:00:00.000Z': 8.1,
      '2022-08-30T13:00:00.000Z': 7.9,
      '2022-09-29T13:00:00.000Z': 7.9,
      '2022-10-29T13:00:00.000Z': 7.9,
      '2022-11-28T13:00:00.000Z': 7.9,
      '2022-12-28T13:00:00.000Z': 7.9,
      '2023-01-27T13:00:00.000Z': 7.9,
      '2023-02-27T13:00:00.000Z': 7.9,
      '2023-03-30T13:00:00.000Z': 7.9,
      '2023-04-29T13:00:00.000Z': 7.9,
      '2023-05-29T13:00:00.000Z': 7.9,
      '2023-06-28T13:00:00.000Z': 7.9,
    },
  },
};
