import { SaleStatus } from '@trustpad/launchpad';

import tpadVesting from 'pools/images/tpadVesting.jpeg';
import img from 'pools/images/trustpad.png';
import React from 'react';

import { ZERO_BN } from '~config/constants';
import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { DescriptionImg } from '~features/pools/components/PoolDescription/DescriptionImg';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const trustpad: PoolConfig = {
  id: 'trustpad-public',
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '',
    status: SaleStatus.finished,
    rate: 10,
    baseAllocation: ZERO_BN,
    tokensForSale: toWeiBN('5,000,000'),
    startDate: '2021-05-05T18:00:00.000Z',
    durationHours: 16,
    hasWhitelist: false,
    isTiered: false,
    isPublic: true,
    participants: 0,
    tokensSold: toWeiBN('5,000,000'),
    raised: toWeiBN('500000'),
    limits: { max: toWeiBN(0.77), min: toWeiBN(0) },
    update: false,
  },
  token: {
    chain: 'BSC',
    address:
      process.env.NEXT_PUBLIC_NETWORK === 'live'
        ? '0xDee01138696f928abEDd617bf29065b9b3D1Caa4'
        : '0xBb3848FFD318c5E510CDeE3049aE9Da89563D33b',
    pairAddress: '',
    name: 'TrustPad',
    symbol: 'TPAD',
    imageSrc: img.src,
    listingTime: '2021-05-05T18:00:00.000Z',
    decimals: 9,
    listingRate: 100,
    totalSupply: toWeiBN('100,000,000'),
    coingeckoId: 'trustpad-2-0',
    coinmarketcapId: 'trustpad-new',
  },
  social: {
    twitter: 'https://twitter.com/TrustPad',
    medium: 'https://medium.com/@TrustPad',
    telegram: 'https://t.me/TrustPad',
    website: 'https://trustpad.io',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: 'First week of each month',
  },
  descriptionShort: (
    <p>
      TrustPad is a decentralized multi-chain fundraising platform enabling
      projects to raise capital and promise safety to early stage investors.
    </p>
  ),
  description: (
    <>
      <p>
        TrustPad is a decentralized multi-chain fundraising platform enabling
        projects to raise capital and promise safety to early stage investors.
      </p>

      <DescriptionImg src={tpadVesting.src} />
    </>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="$TPAD"
        address="0x7029F86dC4634c5d59eE3F1578c193783505e2c1"
        pairAddress="0x526588aaf41cb2abd812fb592a5c81a1f2ca0b40"
      />
    </div>
  ),
};
