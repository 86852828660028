import img from 'pools/images/wallfair.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

// 100$ whitelist
export const wallfair: PoolConfig = {
  // id is used in URL
  id: 'wallfair',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x8913Ec136F8B8655B9AF39Ca27AAd5a4F9F5c543',
    tokensForSale: toWeiBN('5000000'),
    startDate: '2021-10-12T12:00:00.000Z',
    rate: 25,
    durationHours: 3,
    hasWhitelist: true,
    isTiered: true,
    participants: 1471,
    tokensSold: toWeiBN('5000000'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('1000.4577'),
    status: 5,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-10-07T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 0.5,
    totalWeights: 3679,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0xc6065b9fc8171ad3d29bad510709249681758972',
    name: 'Wallfair Token',
    symbol: 'WFAIR',
    imageSrc: img.src,
    listingTime: '2021-10-12T12:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(20_637_500),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'wallfair',
    coinmarketcapId: 'wallfair',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="WFAIR"
      address="0xc6065b9fc8171ad3d29bad510709249681758972"
      pairAddress="0x85cbb3c053a7b88e14bc318e7b6017452fc95325"
    />
  ),
  distribution: {
    type: 'claim_them',
    vesting: '25% TGE, 4 months linear',
    claimUrl:
      'https://wallfair.medium.com/wallfair-token-claim-guide-1d64238c944f',
  },
  social: {
    website: 'https://wallfair.io/',
    twitter: 'https://twitter.com/joinwallfair',
    telegram: 'https://t.me/wallfairians',
    telegramAnno: 'https://t.me/wallfair_updates',
    medium: 'https://wallfair.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Wallfair is a social event trading platform that democratizes the creative
      economy and enables real-time speculation on live events using an in-game
      token. The WFAIR token is the internal digital currency that powers the
      Wallfair platform.
    </p>
  ),
};
