import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/sailwars.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const sailwars: PoolConfig = {
  // id is used in URL
  id: 'sailwars',
  // public, seed, private, strategic
  access: 'public',
  fundToken: USDT,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0x1e3d45117408293c12674F20f2ADe3324acEdAee',
    tokensForSale: toWeiBN(200_000_000),
    // Format: "2024-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2024-02-03T13:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 1000,
    durationHours: 24,
    isProtected: true,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(200_000_000),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_linear',
    network: 'POLY',
    claimerVersion: 'v2',
    vesting:
      '25% unlocked at TGE, 3 months cliff with linear unlock over 3 months',
    claimerAddress: '0x2191181698413A22B407b1139f5588667F496884',
    config: {
      initialUnlockDate: '2024-02-06T04:00:00.000Z',
      initialPercent: 25,
      vestingDuration: 90 * 24 * 3600,
      vestingStartDate: '2024-05-06T05:00:00.000Z',
      unitOfTime: 86400,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x1c356b463ce3E46CF494F5fdD2172bDffe5C688D',
    name: 'Sailwars',
    symbol: 'SWT',
    imageSrc: img.src,
    // Format: "2023-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2024-02-06T05:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'The token will be listed only on MEXC and BingX initially',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(225_000_000),
    totalSupply: toWeiBN(10_000_000_000),
  },
  social: {
    website: 'https://sailwars.com/#/',
    twitter: 'https://twitter.com/Sailwars',
    telegram: '',
    telegramAnno: '',
    medium: 'https://medium.com/@sailwars_game',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      SailWars is a exciting new Web3 game which immerses players in an
      naval-inspired virtual world, where adventurers can embark on a dynamic
      journey through diverse civilizations, transcending the boundaries of land
      and sea. Engage in strategic warfare, navigate uncharted territories,
      unveil hidden secrets, and embark on thrilling treasure hunts alongside a
      vibrant community of like-minded explorers. As a Web3 game, Sailwars
      allows you to convert your in-game assets such as heroes, ships, and
      weapons into Non-Fungible Tokens (NFTs) for trading on the open market.
      Furthermore, as you progress and enhance your skills, you can earn tokens
      as rewards by participating in PvE (Player versus Environment), PvP
      (Player versus Player), and GvG (Guild versus Guild) battles. This not
      only offers a thrilling and rich gaming experience but also provides
      opportunities for profit.
    </p>
  ),
};
