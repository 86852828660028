import img from 'pools/images/bithotel.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const bithotel: PoolConfig = {
  // id is used in URL
  id: 'bithotel',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x1f3C219db2980e7A0D830ed6f29f5b494AC7F341',
    tokensForSale: toWeiBN('10588235'),
    startDate: '2021-12-22T11:00:00.000Z',
    rate: 58.823,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('10588234.999999999999994937'),
    raised: toWeiBN('180001.6150145351308161'),
    baseAllocation: toWeiBN('1467.444048467569493941'),
    status: 5,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-20T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7057,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x57bc18f6177cdaffb34ace048745bc913a1b1b54',
    name: 'BitHotel',
    symbol: 'BTH',
    imageSrc: img.src,
    listingTime: '2021-12-23T15:00:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(21_000_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'bit-hotel',
    coinmarketcapId: 'bit-hotel',
  },
  distribution: {
    type: 'claim_them',
    vesting: '33% TGE, 33% Monthly through DLV',
    claimUrl: 'https://investors.bithotel.io',
    schedule: {
      '2021-12-23T15:00:00.000Z': 33,
    },
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="BTH"
      address="0x57bc18f6177cdaffb34ace048745bc913a1b1b54"
      pairAddress="0x57bc18f6177cdaffb34ace048745bc913a1b1b54"
    />
  ),
  social: {
    website: 'https://bithotel.io/',
    twitter: 'https://twitter.com/playbithotel',
    telegram: 'https://t.me/bithotelcommunity',
    telegramAnno: 'https://t.me/bithotel',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Bit Hotel is an online social NFT game that will use Bit Hotel Coin as its
      in-game currency. Players collect NFT items and characters and are able to
      display them in their room or in mini-games. Each room and common room
      functions as a chat room where you can hang out, chat or trade with other
      players. Next to that players can battle it out in one of the mini-games
      to earn token rewards, and withdraw them to their wallets.
    </p>
  ),
};
