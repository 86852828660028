import img from 'pools/images/medievalempires.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const medievalempires: PoolConfig = {
  // id is used in URL
  id: 'medievalempires',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x5CB913798870FEC4a39F0CbeeB78B71672F15c51',
    tokensForSale: toWeiBN('26388888'),
    startDate: '2022-12-17T18:00:00.000Z',
    rate: 111.111,
    durationHours: 22,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('26388887.999999999999999814'),
    raised: toWeiBN('237500.2295002295002295'),
    baseAllocation: toWeiBN('2258.381035318544702647'),
    status: 5,
    registerDate: '2022-12-15T18:00:00.000Z',
    registerDuration: 47,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    fcfsDuration: 2,
    totalWeights: 11682350,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_linear',
    network: 'POLY',
    claimerVersion: 'v2',
    claimerAddress: '0x3CAf6f728563658ce10CE954aC79d7DE6c1D2BB6',
    config: {
      initialUnlockDate: '2022-12-19T12:00:00.000Z',
      initialPercent: 25,
      vestingDuration: 6 * 30 * 24 * 60 * 60,
      vestingStartDate: '2022-12-19T12:00:01.000Z',
    },
    vesting: '25% unlock at TGE, then vesting for 6 months',
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D',
    name: 'Medieval Empires',
    symbol: 'MEE',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-12-19T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Listing on ByBit and QuickSwap (DEX)',
    coingeckoId: 'medieval-empires',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(96_666_666.667),
    totalSupply: toWeiBN(3_000_000_000),
    coinmarketcapId: 'medieval-empires',
  },
  // need:
  // - respect default DEX per network, be able to override (e.g. Uniswap POLY instead of Quickswap)
  // - specify pairs on gate.io, bybit, huobi
  // - specify pairAddress
  // TODO: introduce "trading" config
  // trading: {
  //   pairAddress: '0x7e00fdce3ef6d3de0650f98dff5d267cd51ca577',
  //   bybit: 'MEE/USDT',
  // },
  finishedInfo: (
    <div>
      <QuickswapChartFinishedInfo
        symbol="MEE"
        address="0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D"
        pairAddress="0x7e00fdce3ef6d3de0650f98dff5d267cd51ca577"
      />
      <p>
        <a href="https://www.bybit.com/en-US/trade/spot/MEE/USDT">
          Trade on ByBit
        </a>
      </p>
    </div>
  ),
  social: {
    website: 'https://www.medievalempires.com/',
    twitter: 'https://twitter.com/MedievalEmpires',
    telegram: 'https://t.me/Medievalempireschat',
    telegramAnno: 'https://t.me/MedievalempiresAnnouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Medieval Empires is an elaborate, multi-player online strategy game set in
      what is now modern-day Turkey during the late 13th century. The storyline
      of the game initially focuses on Ertuğrul Gazi, a tribal warrior and a
      celebrated leader of the Kayi tribe. The game is set in a passive PvE area
      and starts with players building towns while trading, fighting, and
      forging alliances. Continuous battles will help expand the world map into
      an ever-evolving game universe. Goals for players include daily missions,
      general tasks, and story missions, with progression based on the
      successful completion of these tasks and missions. Medieval Empires is
      being built on the Polygon blockchain, which provides several great
      features, including player reach, developer ecosystem, low gas fees,
      security, and speed. In addition to that, it is also eco-friendly in
      comparison to other blockchains. The game will be available on PC
      (Windows), MAC (OSX), and Mobile (iOS and Android), with all platforms
      being fully interoperable.
    </p>
  ),
};
