import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/SEOR.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const seorCommon = {
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x800a25741A414Ea6E6e2B382435081A479A8Cc3c',
    name: 'SEOR',
    symbol: 'SEOR',
    imageSrc: img.src,
    listingTime: '2022-07-07T08:00:00.000Z',
    listingInfo:
      'On ByBit (deposits open 8:00 UTC, trading starts at 10:00 UTC)',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(21_500_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'seor-network',
    coinmarketcapId: 'seor',
  },
  social: {
    website: 'https://www.seor.io/',
    twitter: 'https://twitter.com/SEOR001',
    telegram: 'https://t.me/SeorGroup',
    telegramAnno: 'https://t.me/SEORNetwork',
    medium: 'https://medium.com/@SEOR',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      SEOR is the next generation of decentralized Web3.0 application technology
      development infrastructure, which aims to provide users and developers of
      Web3.0 with an easy-to-use blockchain technology development platform.
    </p>
  ),
  finishedInfo: (
    <div>
      <p className="mb-3">
        Trade{' '}
        <a href="https://bybit.com/en-US/trade/spot/SEOR/USDT">
          SEOR-USDT on ByBit
        </a>
        .
      </p>
    </div>
  ),
};

export const seor: PoolConfig = {
  ...seorCommon,
  // id is used in URL
  id: 'SEORPrivate',
  // public, seed or private
  access: 'private',
  kyc: true,
  sale: {
    address: '0x2705cB6eA184723b900455857BE8C9b11FC8D58C',
    tokensForSale: toWeiBN('2111111'),
    startDate: '2022-07-04T11:00:00.000Z',
    rate: 22.222,
    durationHours: 12,
    hasWhitelist: true,
    refunded: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2111110.999999999999999477'),
    raised: toWeiBN('95000.945009450094500927'),
    baseAllocation: toWeiBN('600'),
    status: 5,
    registerDate: '2022-07-02T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('0'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7339140,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  description: (
    <p>
      SEOR is the next generation of decentralized Web3.0 application technology
      development infrastructure, which aims to provide users and developers of
      Web3.0 with an easy-to-use blockchain technology development platform.
      <br />
      <br />
      <a
        href="https://bscscan.com/tx/0xa66bab8844dd56434ab8346206291be88a1e85044b4bb896037732effda8f2a1"
        target="_blank"
        rel="noreferrer">
        Refunded due to failing the Safeguard Rules
      </a>
    </p>
  ),
  distribution: {
    type: 'claim_us' as const,
    vesting: '20% upon exchange listing, 8% per month thereafter',
    claimerAddress: '0x94456F3A392339b7abB9ca1Bb3CD0aFd3E5bE312',
    claimerVersion: 'v3',
    schedule: {
      '2022-07-07T08:00:00.000Z': 20,
      '2022-08-07T10:00:00.000Z': 8,
      '2022-09-07T10:00:00.000Z': 8,
      '2022-10-07T10:00:00.000Z': 8,
      '2022-11-07T10:00:00.000Z': 8,
      '2022-12-07T10:00:00.000Z': 8,
      '2023-01-07T10:00:00.000Z': 8,
      '2023-02-07T10:00:00.000Z': 8,
      '2023-03-07T10:00:00.000Z': 8,
      '2023-04-07T10:00:00.000Z': 8,
      '2023-05-07T10:00:00.000Z': 8,
    },
  },
};

export const seorWhitelist: PoolConfig = {
  ...seorCommon,
  // id is used in URL
  id: 'SEOR',
  // public, seed or private
  access: 'whitelist',
  kyc: false,
  sale: {
    address: '0xeEa55FBa6625200ac2cbB3C7bB2CA1378A5F7241',
    tokensForSale: toWeiBN('1055555'),
    startDate: '2022-07-04T11:00:00.000Z',
    rate: 22.222,
    durationHours: 27,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('1055554.999999999999999895'),
    raised: toWeiBN('47500.450004500045000453'),
    baseAllocation: toWeiBN('1800'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('1000'),
    },
    registerDate: '2022-07-02T11:00:00.000Z',
    registerDuration: 45,
    fcfsDuration: 16,
    totalWeights: 6844840,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  description: (
    <p>
      SEOR is the next generation of decentralized Web3.0 application technology
      development infrastructure, which aims to provide users and developers of
      Web3.0 with an easy-to-use blockchain technology development platform.
      <br />
      <br />
      <a
        href="https://bscscan.com/tx/0x0c23e92a7750ebca13b5304d56fb6321a24af39c0d5115d76df71d210d088541"
        target="_blank"
        rel="noreferrer">
        Refunded due to failing the Safeguard Rules
      </a>
    </p>
  ),
  distribution: [
    {
      title: 'TGE batch',
      type: 'claim_us' as const,
      vesting: '10% on TGE, 2 month cliff, then 15% on a monthly basis',
      claimerAddress: '0x9F37e3b45766429b74E8cAF3E9b49345994E3223',
      claimerVersion: 'v3',
      schedule: {
        '2022-07-07T08:00:00.000Z': 10,
      },
    },
    {
      title: 'Following batches',
      type: 'claim_us' as const,
      vesting: '10% on TGE, 2 month cliff, then 15% on a monthly basis',
      claimerAddress: '0xA2C21BA0a1513267DE0142D38CC8CE1dc34a8cA0',
      claimerVersion: 'v3',
      schedule: {
        '2022-07-07T08:00:00.000Z': 10,
        '2022-10-07T10:00:00.000Z': 15,
        '    ': 15,
        '   ': 15,
        '  ': 15,
        ' ': 15,
        '': 15,
      },
    },
  ],
};
