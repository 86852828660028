import React from 'react';
import { CgClose } from 'react-icons/cg';
import { PatternImage } from '~common/TopBar/PatternImage';
import { useBarPersistentDisplayState } from '~common/TopBar/useBarPersistentDisplayState';

export function PhishingBar() {
  const [display, setDisplay] = useBarPersistentDisplayState('phishing');

  if (!display) {
    return null;
  }

  return (
    <div className="flex overflow-hidden relative justify-end bg-mainDark text-white/90 tracking-wide py-3">
      <PatternImage side="left" startWith="bottom-0" />
      <PatternImage side="right" startWith="top-0" />

      <div className="mr-auto ml-auto pl-7 z-10">
        <b> Phishing Warning:</b> please make sure you're visiting{' '}
        <b> https://trustpad.io</b> - check the URL carefully
      </div>

      <button
        className="text-xl px-7 text-black hover:text-gray-300"
        onClick={() => setDisplay(false)}>
        <CgClose />
      </button>
    </div>
  );
}
