import Link from 'next/link';
import React from 'react';
import { CgClose } from 'react-icons/cg';
import { PatternImage } from '~common/TopBar/PatternImage';
import { useBarPersistentDisplayState } from '~common/TopBar/useBarPersistentDisplayState';

export function ImportantBar() {
  const [display, setDisplay] = useBarPersistentDisplayState('important2');

  if (!display) {
    return null;
  }

  return (
    <div className="flex overflow-hidden relative justify-end bg-green-500 text-black py-3">
      <PatternImage side="left" startWith="bottom-0" />
      <PatternImage side="right" startWith="top-0" />

      <div className="mr-auto ml-auto pl-7 z-10">
        LP FARMING CAMPAIGN (TPAD-BNB) WITH HIGH APR IS NOW LIVE! 🔥{' '}
        <Link href={'/staking'} className="text-white">
          FARM HERE
        </Link>
      </div>

      <button
        className="text-xl px-7 text-black hover:text-gray-300"
        onClick={() => setDisplay(false)}>
        <CgClose />
      </button>
    </div>
  );
}
