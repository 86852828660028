import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/honeyland.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const honeyland: PoolConfig = {
  // id is used in URL
  id: 'honeyland',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0xf54A11035535c7558E3f2D990EDF3147b9EB6f38',
    tokensForSale: toWeiBN('4071428.3'),
    startDate: '2023-03-15T14:00:00.000Z',
    rate: 28.571,
    durationHours: 23,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4071428.299999999999997503'),
    raised: toWeiBN('142502.1280319204788071'),
    baseAllocation: toWeiBN('262.541522786993599974'),
    status: 5,
    registerDate: '2023-03-12T14:00:00.000Z',
    registerDuration: 69,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.6666666666666666,
    totalWeights: 15538950,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_server',
    claimerVersion: 'v4',
    vesting: '25% on TGE, then 25% monthly',
    schedule: {
      '2023-03-16T14:00:00.000Z': 25,
      '2023-04-16T14:00:00.000Z': 25,
      '2023-05-16T14:00:00.000Z': 25,
      '2023-06-16T14:00:00.000Z': 25,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: '3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug',
    name: 'Honeyland',
    symbol: 'HXD',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2023-03-16T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(3571428),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'honeyland-honey',
    coinmarketcapId: 'honeyland',
  },
  social: {
    website: 'https://honey.land/',
    twitter: 'https://twitter.com/HoneylandGameFi',
    telegram: '',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: 'https://docs.honey.land/whitepaper/honey-token',
    announcement: '',
  },
  description: (
    <p>
      Honeyland is a mobile-first blockchain strategy game where players assume
      the role of a Beekeeper to manage their colony of Bees and Lands. Players
      can choose a universe to explore and send their Bees on missions to
      compete against other players for NFTs and the scarce Honeyland resource
      HONEY. As players earn HONEY and NFTs, they can use these to grow and
      strengthen their colony of Bees, purchase more NFTs, participate in the
      Honeyland economy, or trade them on exchanges and marketplaces.
    </p>
  ),
};
