import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const onerare: PoolConfig = {
  // id is used in URL
  id: 'onerare',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xe281F53EC88C88BCC0254CFEfB16529fd3d1b71f',
    tokensForSale: toWeiBN('500000'),
    startDate: '2021-11-27T12:00:00.000Z',
    rate: 5,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('499999.99999999999999994'),
    raised: toWeiBN('99999.999999999999999988'),
    baseAllocation: toWeiBN('72.632190586868099941'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-11-25T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6893,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xff2382bd52efacef02cc895bcbfc4618608aa56f',
    name: 'OneRare',
    symbol: 'ORARE',
    imageSrc: '/tokens/onerare.jpg',
    listingTime: '2021-11-30T12:15:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(1_375_000),
    totalSupply: toWeiBN(100_000_000),
    coingeckoId: 'onerare',
    coinmarketcapId: 'onerare',
  },
  finishedInfo: (
    <>
      <QuickswapChartFinishedInfo
        symbol="ORARE"
        address="0xff2382bd52efacef02cc895bcbfc4618608aa56f"
        pairAddress="0xff2382bd52efacef02cc895bcbfc4618608aa56f"
      />
      <div>
        Also tradeable on{' '}
        <a href="https://www.mexc.com/exchange/ORARE_USDT">MEXC</a>
      </div>
    </>
  ),
  distribution: {
    type: 'claim_us',
    vesting: '50% TGE, 50% after 1 Month',
    network: 'POLY',
    claimerAddress: '0xfAfd3E2f3DEb190C0b34fd10297095F4a0a2ad9F',
    claimerVersion: 'v2',
    schedule: {
      '2021-11-30T12:15:00.000Z': 50,
      '2021-12-30T12:15:00.000Z': 50,
    },
  },
  social: {
    website: 'https://onerare.io/',
    twitter: 'https://twitter.com/onerarenft',
    telegram: 'https://t.me/onerarenft',
    telegramAnno: 'https://t.me/onerareofficial',
    medium: '',
    github: '',
    whitepaper: 'https://onerare.gitbook.io',
    announcement: '',
  },
  description: (
    <p>
      OneRare is creating the World’s first Food Metaverse, aka, Foodverse. Food
      has a language of its own, making the world a happier place. To celebrate
      this love for food, OneRare is building the first tokenization layer that
      celebrates Food in Web3 - creating an immersive and gamified experience
      for users worldwide.
    </p>
  ),
};
