import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/arize.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const arize: PoolConfig = {
  // id is used in URL
  id: 'arize',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xcb2d674Ac8737E754351B2bB3961Dc120CfE33aa',
    tokensForSale: toWeiBN('4750000'),
    startDate: '2022-04-21T11:00:00.000Z',
    rate: 100,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4750000'),
    raised: toWeiBN('47500'),
    baseAllocation: toWeiBN('793.385668949390345749'),
    status: 5,
    registerDate: '2022-04-19T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6203,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '10% unlock on TGE, 6 months linear vesting',
    claimerVersion: 'v3',
    claimerAddress: '0x2C81babdDC9286873a4D17daF1cbD77821159114',
    schedule: {
      '2022-04-22T12:00:00.000Z': 10,
      // '2022-05-22T12:00:00.000Z': 15,
      // '2022-06-22T12:00:00.000Z': 15,
      // '2022-07-22T12:00:00.000Z': 15,
      // '2022-08-22T12:00:00.000Z': 15,
      // '2022-09-22T12:00:00.000Z': 15,
      // '2022-10-22T12:00:00.000Z': 15,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xc10375092343fa84b80d4bdca94488fe3c52101f',
    name: 'ARize',
    symbol: 'ARZ',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-04-22T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(25_000_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'arize',
  },
  social: {
    website: 'https://arize.io',
    twitter: 'https://twitter.com/Official_ARize',
    telegram: '',
    telegramAnno: 'https://t.me/arize_ann',
    medium: 'https://blog.arize.io',
    github: '',
    whitepaper: 'https://arize.io/whitepaper/welcome-to-arize/',
    announcement: '',
    voting:
      'https://snapshot.org/#/trustpad.io/proposal/0xe5b87503c6a166a55cce12ce6ee85adb23b041aa4b5aa91e15ca6a6aeb9b3417',
  },
  description: (
    <p>
      ARIze platform has a unique ability to connect 3D creators to businesses,
      in order to create enhanced 3D experiences (through technology built
      around Augmented Reality, Virtual Reality, Metaverses, and even full-blown
      games built on top of the ARize platform framework) to be shared with
      their audiences with a click of a button, across all devices available in
      the market, without having to download a specific App or integrate any
      special technology.
      <br />
      <br />
      Refunded 90%, see the{' '}
      <a href="https://bscscan.com/tx/0xaa355c7cf6e994b952089cf96ebfc9490aca208381833cba73813b1c71b7f0da">
        TX
      </a>
      .
    </p>
  ),
};
