import Link from 'next/link';
import React from 'react';
import { ImportantBar, ProposalVotingBar, PhishingBar } from '~common/TopBar';
import { TopBarAlert } from '~features/ui';

export function TopBarAlerts() {
  return (
    <>
      {false && <ProposalVotingBar />}
      <PhishingBar />
      {/* <ImportantBar /> */}
      {/*<TopBarAlert type="success" withPatten k="v2">*/}
      {/*  <Link href="/tpadv2" className="text-black/90">*/}
      {/*    MIGRATION IS NOW LIVE! MIGRATE TO TPAD 2.0 NOW*/}
      {/*  </Link>*/}
      {/*</TopBarAlert>*/}
    </>
  );
}
