import img from 'pools/images/solchicks.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const solchicks: PoolConfig = {
  // id is used in URL
  id: 'solchicks',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xa5a7F6d4Dd3C0De1a1CB31808A45B772C937A477',
    tokensForSale: toWeiBN('16000000'),
    startDate: '2021-11-26T11:00:00.000Z',
    rate: 20,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('16000000'),
    raised: toWeiBN('800000'),
    baseAllocation: toWeiBN('2071.859428271702071859'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-11-24T11:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 7674,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: 'cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2',
    name: 'Solchicks',
    symbol: 'CHICKS',
    imageSrc: img.src,
    listingTime: '2021-12-13T14:00:00.000Z',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(180_000_000),
    totalSupply: toWeiBN(10_000_000_000),
    coingeckoId: 'solchicks-token',
    coinmarketcapId: 'solchicks-token',
  },
  distribution: {
    type: 'claim_them',
    claimUrl: 'https://www.solchicks.io/chicks',
    vesting: (
      <>
        25% TGE unlock and then 90 day linear unlock. How to claim:{' '}
        <a
          href="https://www.youtube.com/watch?v=q0rurUFUNB4"
          target="_blank"
          rel="noreferrer">
          Youtube
        </a>
      </>
    ),
    schedule: {
      '2021-12-13T14:00:00.000Z': 25,
      '2022-01-13T14:00:00.000Z': 25,
      '2022-02-13T14:00:00.000Z': 25,
      '2022-03-13T14:00:00.000Z': 25,
    },
  },
  finishedInfo: (
    <>
      <a
        href="https://www.mexc.com/exchange/CHICKS_USDT"
        target="_blank"
        rel="noreferrer">
        Trade on MEXC
      </a>
    </>
  ),
  social: {
    website: 'https://www.solchicks.io/',
    twitter: 'https://twitter.com/SolChicksNFT',
    telegram: 'https://t.me/solchicksNFT',
    telegramAnno: 'https://t.me/solchicksannouncements',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Solchicks mission is simple: to be the leading fantasy NFT PvP and P2E
      gaming ecosystem on the Solana blockchain. We built our game around
      adorable SolChick NFT collectibles, where players use their SolChicks as
      their characters in a unique gaming metaverse.
    </p>
  ),
};
