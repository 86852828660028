import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/paysenger.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const paysenger: PoolConfig = {
  // id is used in URL
  id: 'paysenger',
  // public, seed or private
  access: 'public',
  fundToken: USDT,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0xeDc7BEaD4e0c9e180C3FA76eE776088116590d4a',
    tokensForSale: toWeiBN(2_250_000),
    // Format: "2023-01-10T14:00:00.000Z", leave empty for TBA
    startDate: '2023-06-01T14:00:00.000Z',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 16.666,
    durationHours: 23,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(2_250_000),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.finished,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BSC',
    claimerVersion: 'v2',
    vesting: '40% at TGE, then vesting for 6 months',
    claimerAddress: '0x141dfA2055813683D068a527eb924D9cd1a3B294',
    config: {
      initialUnlockDate: '2023-07-06T10:15:00.000Z',
      initialPercent: 40,
      vestingDuration: 183 * 24 * 3600,
      vestingStartDate: '2023-07-07T10:00:00.000Z',
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x44a21B3577924DCD2e9C81A3347D204C36a55466',
    name: 'EGO Paysenger',
    symbol: 'EGO',
    imageSrc: img.src,
    // Format: "2023-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2023-07-06T10:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Kucoin, Bybit',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(4_866_486.426),
    totalSupply: toWeiBN(333_000_000),
  },
  social: {
    website: 'https://egoco.in/',
    twitter: 'https://twitter.com/Ego_Paysenger',
    telegram: 'https://t.me/egocoin_en',
    telegramAnno: 'https://t.me/ego_paysenger_en',
    medium: '',
    github: '',
    whitepaper: 'https://docs.paysenger.com/',
    announcement: '',
  },
  description: (
    <p>
      Paysenger is a revolutionary collaboration platform for content creators,
      fans, and brands, bringing together the best tools for content
      monetization and audience engagement. On the platform, users can send
      authors paid requests for exclusive content, tips, or expert opinions as
      well as consume paid content on a subscription basis. Paysenger expands
      the paid creator audience by allowing users to support creators and
      commission their content with EGO tokens that can be earned by completing
      tasks on the platform. Paysenger is powered by Polygon and accelerated by
      ConsenSys.
    </p>
  ),
};
