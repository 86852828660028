import img from 'pools/images/vodra.jpg';
import metaImg from 'pools/images/vodraAnn.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const vodra: PoolConfig = {
  // id is used in URL
  id: 'vodra',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x7019433E4274892B5C97aFA89d782Ce836F17c24',
    tokensForSale: toWeiBN('10000000'),
    startDate: '2021-10-26T12:00:00.000Z',
    rate: 86.956522,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1223,
    tokensSold: toWeiBN('9999999.99999999999999641'),
    raised: toWeiBN('114999.999655000001034964'),
    baseAllocation: toWeiBN('1922.2703758038585209'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-10-24T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 4976,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'ETH',
    address: '0xed3d4e446a96dc3b181b64b75c3c70da41dc3cbe',
    name: 'Vodra',
    symbol: 'VDR',
    imageSrc: img.src,
    decimals: 18,
    listingTime: '2021-10-29T07:00:00.000Z',
    listingRate: 0,
    initialSupply: toWeiBN(22_000_000),
    totalSupply: toWeiBN(2_000_000_000),
    coingeckoId: 'vodra',
    coinmarketcapId: 'vodra',
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="VDR"
      address="0xed3d4e446a96dc3b181b64b75c3c70da41dc3cbe"
      pairAddress="0xe9b23a19bc1d2b18ea172f5c4f93570f6b3b4ddb"
    />
  ),
  distribution: {
    type: 'airdrop_us',
    vesting: '50% at TGE, 50% in a month',
    schedule: {
      '2021-10-29T07:00:00.000Z': 50,
      '2021-11-29T07:00:00.000Z': 50,
    },
  },
  social: {
    website: 'https://vodra.io',
    twitter: 'https://twitter.com/VodraToken',
    telegram: 'https://t.me/vodratoken',
    telegramAnno: '',
    medium: 'https://vodra.medium.com/',
    github: '',
    whitepaper: 'https://vodra.io/VodraWhitepaper.pdf',
    announcement:
      'https://trustpad.medium.com/vodra-is-launching-on-trustpad-99fcf661e9c3',
  },
  images: {
    seo: metaImg.src,
  },
  description: (
    <p>
      With Vodra, there is a new and unprecedented way for content creators,
      their audiences, and anyone else who believes in free and open media to
      benefit from its growth. Vodra is a decentralized donation platform that
      allows content creators to be supported by their audiences directly.
    </p>
  ),
};
