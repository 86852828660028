import img from 'pools/images/soldex.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { SolanaChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const soldex: PoolConfig = {
  // id is used in URL
  id: 'soldex',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x4004484006d508331Be85eCde1dD599143976F61',
    tokensForSale: toWeiBN('3333332'),
    startDate: '2021-12-08T12:00:00.000Z',
    rate: 8.33333,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('3333331.999999999999999925'),
    raised: toWeiBN('400000.0000000000000001'),
    baseAllocation: toWeiBN('465.415824144350565659'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-12-06T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6983,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: 'CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn',
    name: 'SOLDEX',
    symbol: 'SOLX',
    imageSrc: img.src,
    decimals: 9,
    listingRate: 0,
    listingTime: '2021-12-10T12:30:00.000Z',
    listingInfo:
      'MEXC Global (https://www.mexc.com ): 10th December 13:00 UTC, Raydium (https://raydium.io/): 10th December 14:00 UTC',
    initialSupply: toWeiBN(52_000_000),
    totalSupply: toWeiBN(500000000),
    coingeckoId: 'soldex',
    coinmarketcapId: 'soldex',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '20% at listing, 8 months vesting, monthly distribution',
    schedule: {
      '2021-12-10T12:30:00.000Z': 20,
      '2022-01-10T22:00:00.000Z': 10,
      '2022-02-10T22:00:00.000Z': 10,
      '2022-03-10T22:00:00.000Z': 10,
      '2022-04-10T22:00:00.000Z': 10,
      '2022-05-10T22:00:00.000Z': 10,
      '2022-06-10T22:00:00.000Z': 10,
      '2022-07-10T22:00:00.000Z': 10,
      '2022-08-10T22:00:00.000Z': 10,
    },
  },
  finishedInfo: (
    <SolanaChartFinishedInfo
      symbol="SOLX"
      address={'CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn'}
    />
  ),
  social: {
    website: 'https://soldex.ai/',
    twitter: 'https://twitter.com/soldexai',
    telegram: 'https://t.me/Soldexai',
    telegramAnno: 'https://t.me/soldexann',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Soldex.ai works on all market conditions 24/7 with emotionless
      machine-learning and neural network algorithms. It’s like hiring an expert
      to do crypto trading for you while you can sit back and watch.
    </p>
  ),
};
