import { NftPoolConfig } from '@trustpad/launchpad';

import React from 'react';

import { BUSD } from '~config/tokens';
import { toWeiBN } from '~utils';

import logoImg from './logo.png';

export const monstersClan: NftPoolConfig = {
  // id is used in URL
  id: 'monstersClan',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  isNft: true,
  sale: {
    address: '0x0b01B408d431E34DDCB2D51f8666F8e570E0CA19',
    tokensForSale: toWeiBN('903'),
    startDate: '2021-11-02T12:00:00.000Z',
    rate: 0,
    durationHours: 24,
    hasWhitelist: true,
    isTiered: true,
    participants: 852,
    tokensSold: toWeiBN('0.000000000000000863'),
    raised: toWeiBN('233648.62'),
    baseAllocation: toWeiBN('5760'),
    status: 5,
    inventory: [
      {
        id: 'AlohaIsland',
        name: 'Aloha Island',
        supply: 59,
        price: toWeiBN('700'),
        group: 'Lands',
        internalId:
          '0x416c6f686149736c616e64000000000000000000000000000000000000000000',
        limit: 10,
        sold: 59,
        raised: toWeiBN('41300'),
      },
      {
        id: 'MonstrutoIsland',
        name: 'Monstruto Island',
        supply: 12,
        price: toWeiBN('1200'),
        group: 'Lands',
        internalId:
          '0x4d6f6e73747275746f49736c616e640000000000000000000000000000000000',
        limit: 10,
        sold: 12,
        raised: toWeiBN('14400'),
      },
      {
        id: 'HowlersTropic Island',
        name: 'Howlers Tropic Island',
        supply: 8,
        price: toWeiBN('1800'),
        group: 'Lands',
        internalId:
          '0x486f776c65727354726f7069632049736c616e64000000000000000000000000',
        limit: 10,
        sold: 8,
        raised: toWeiBN('14400'),
      },
      {
        id: 'BloodhillIsland',
        name: 'Bloodhill Island',
        supply: 6,
        price: toWeiBN('2500'),
        group: 'Lands',
        internalId:
          '0x426c6f6f6468696c6c49736c616e640000000000000000000000000000000000',
        limit: 10,
        sold: 6,
        raised: toWeiBN('15000'),
      },
      {
        id: 'FeedLaboratory',
        name: 'Feed Laboratory',
        supply: 42,
        price: toWeiBN('1000'),
        group: 'Laboratories',
        internalId:
          '0x466565644c61626f7261746f7279000000000000000000000000000000000000',
        limit: 10,
        sold: 21,
        raised: toWeiBN('21000'),
      },
      {
        id: 'MonsterLaboratory',
        name: 'Monster Laboratory',
        supply: 24,
        price: toWeiBN('2500'),
        group: 'Laboratories',
        internalId:
          '0x4d6f6e737465724c61626f7261746f7279000000000000000000000000000000',
        limit: 10,
        sold: 5,
        raised: toWeiBN('12500'),
      },
      {
        id: 'MultipurposeLaboratory',
        name: 'Multipurpose Laboratory',
        supply: 18,
        price: toWeiBN('3000'),
        group: 'Laboratories',
        internalId:
          '0x4d756c7469707572706f73654c61626f7261746f727900000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('54000'),
      },
      {
        id: 'BasicCave',
        name: 'Basic Cave',
        supply: 42,
        price: toWeiBN('70'),
        group: 'Caves',
        internalId:
          '0x4261736963436176650000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 42,
        raised: toWeiBN('2940'),
      },
      {
        id: 'StandardCave',
        name: 'Standard Cave',
        supply: 18,
        price: toWeiBN('120'),
        group: 'Caves',
        internalId:
          '0x5374616e64617264436176650000000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('2160'),
      },
      {
        id: 'SuperCave',
        name: 'Super Cave',
        supply: 18,
        price: toWeiBN('200'),
        group: 'Caves',
        internalId:
          '0x5375706572436176650000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('3600'),
      },
      {
        id: 'PremiumCave',
        name: 'Premium Cave',
        supply: 12,
        price: toWeiBN('500'),
        group: 'Caves',
        internalId:
          '0x5072656d69756d43617665000000000000000000000000000000000000000000',
        limit: 10,
        sold: 12,
        raised: toWeiBN('6000'),
      },
      {
        id: 'CrispyBoogers',
        name: 'Crispy Boogers',
        supply: 29,
        price: toWeiBN('0.5'),
        group: 'Feeds',
        internalId:
          '0x437269737079426f6f6765727300000000000000000000000000000000000000',
        limit: 10,
        sold: 29,
        raised: toWeiBN('14.5'),
      },
      {
        id: 'ChilledMucus',
        name: 'Chilled Mucus',
        supply: 29,
        price: toWeiBN('1.25'),
        group: 'Feeds',
        internalId:
          '0x4368696c6c65644d756375730000000000000000000000000000000000000000',
        limit: 10,
        sold: 29,
        raised: toWeiBN('36.25'),
      },
      {
        id: 'PoopyShake',
        name: 'Poopy Shake',
        supply: 29,
        price: toWeiBN('2.99'),
        group: 'Feeds',
        internalId:
          '0x506f6f70795368616b6500000000000000000000000000000000000000000000',
        limit: 10,
        sold: 29,
        raised: toWeiBN('86.71'),
      },
      {
        id: 'Poopcorns',
        name: 'Poopcorns',
        supply: 28,
        price: toWeiBN('3.99'),
        group: 'Feeds',
        internalId:
          '0x506f6f70636f726e730000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 28,
        raised: toWeiBN('111.72'),
      },
      {
        id: 'Urinade',
        name: 'Urinade',
        supply: 27,
        price: toWeiBN('1.99'),
        group: 'Feeds',
        internalId:
          '0x5572696e61646500000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 27,
        raised: toWeiBN('53.73'),
      },
      {
        id: 'Vomitella',
        name: 'Vomitella',
        supply: 27,
        price: toWeiBN('3.99'),
        group: 'Feeds',
        internalId:
          '0x566f6d6974656c6c610000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 27,
        raised: toWeiBN('107.73'),
      },
      {
        id: 'Puketea',
        name: 'Puketea',
        supply: 30,
        price: toWeiBN('0.99'),
        group: 'Feeds',
        internalId:
          '0x50756b6574656100000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 30,
        raised: toWeiBN('29.7'),
      },
      {
        id: 'NinjaPotion',
        name: 'Ninja Potion',
        supply: 24,
        price: toWeiBN('6.99'),
        group: 'Potions',
        internalId:
          '0x4e696e6a61506f74696f6e000000000000000000000000000000000000000000',
        limit: 10,
        sold: 24,
        raised: toWeiBN('167.76'),
      },
      {
        id: 'BloodrootPotion',
        name: 'Bloodroot Potion',
        supply: 18,
        price: toWeiBN('11.99'),
        group: 'Potions',
        internalId:
          '0x426c6f6f64726f6f74506f74696f6e0000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('215.82'),
      },
      {
        id: 'FiendPotion',
        name: 'Fiend Potion',
        supply: 15,
        price: toWeiBN('16.99'),
        group: 'Potions',
        internalId:
          '0x4669656e64506f74696f6e000000000000000000000000000000000000000000',
        limit: 10,
        sold: 15,
        raised: toWeiBN('254.85'),
      },
      {
        id: 'MightyPotion',
        name: 'Mighty Potion',
        supply: 15,
        price: toWeiBN('24.99'),
        group: 'Potions',
        internalId:
          '0x4d6967687479506f74696f6e0000000000000000000000000000000000000000',
        limit: 10,
        sold: 15,
        raised: toWeiBN('374.85'),
      },
      {
        id: 'PotionCauldron',
        name: 'Potion Cauldron',
        supply: 30,
        price: toWeiBN('500'),
        group: 'Potion Cauldrons',
        internalId:
          '0x506f74696f6e4361756c64726f6e000000000000000000000000000000000000',
        limit: 10,
        sold: 30,
        raised: toWeiBN('15000'),
      },
      {
        id: 'BasicCoin Box',
        name: 'Basic Coin Box',
        supply: 18,
        price: toWeiBN('20'),
        group: 'Coin Boxes',
        internalId:
          '0x4261736963436f696e20426f7800000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('360'),
      },
      {
        id: 'StandardCoin Box',
        name: 'Standard Coin Box',
        supply: 18,
        price: toWeiBN('35'),
        group: 'Coin Boxes',
        internalId:
          '0x5374616e64617264436f696e20426f7800000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('630'),
      },
      {
        id: 'SuperCoin Box',
        name: 'Super Coin Box',
        supply: 18,
        price: toWeiBN('50'),
        group: 'Coin Boxes',
        internalId:
          '0x5375706572436f696e20426f7800000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('900'),
      },
      {
        id: 'PremiumCoin Box',
        name: 'Premium Coin Box',
        supply: 18,
        price: toWeiBN('70'),
        group: 'Coin Boxes',
        internalId:
          '0x5072656d69756d436f696e20426f780000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('1260'),
      },
      {
        id: 'Cyclopster',
        name: 'Cyclopster',
        supply: 5,
        price: toWeiBN('200'),
        group: 'Monsters',
        internalId:
          '0x4379636c6f707374657200000000000000000000000000000000000000000000',
        limit: 10,
        sold: 5,
        raised: toWeiBN('1000'),
      },
      {
        id: 'Devilichie',
        name: 'Devilichie',
        supply: 5,
        price: toWeiBN('150'),
        group: 'Monsters',
        internalId:
          '0x446576696c696368696500000000000000000000000000000000000000000000',
        limit: 10,
        sold: 5,
        raised: toWeiBN('750'),
      },
      {
        id: 'MightyHunter',
        name: 'Mighty Hunter',
        supply: 5,
        price: toWeiBN('170'),
        group: 'Monsters',
        internalId:
          '0x4d696768747948756e7465720000000000000000000000000000000000000000',
        limit: 10,
        sold: 5,
        raised: toWeiBN('850'),
      },
      {
        id: 'Rootreecia',
        name: 'Rootreecia',
        supply: 16,
        price: toWeiBN('100'),
        group: 'Monsters',
        internalId:
          '0x526f6f7472656563696100000000000000000000000000000000000000000000',
        limit: 10,
        sold: 16,
        raised: toWeiBN('1600'),
      },
      {
        id: 'Rudy',
        name: 'Rudy',
        supply: 15,
        price: toWeiBN('70'),
        group: 'Monsters',
        internalId:
          '0x5275647900000000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 15,
        raised: toWeiBN('1050'),
      },
      {
        id: 'GrumpyEngineer',
        name: 'Grumpy Engineer',
        supply: 15,
        price: toWeiBN('100'),
        group: 'Monsters',
        internalId:
          '0x4772756d7079456e67696e656572000000000000000000000000000000000000',
        limit: 10,
        sold: 15,
        raised: toWeiBN('1500'),
      },
      {
        id: 'GrislyGorilla',
        name: 'Grisly Gorilla',
        supply: 15,
        price: toWeiBN('150'),
        group: 'Monsters',
        internalId:
          '0x477269736c79476f72696c6c6100000000000000000000000000000000000000',
        limit: 10,
        sold: 15,
        raised: toWeiBN('2250'),
      },
      {
        id: 'Snakegator',
        name: 'Snakegator',
        supply: 15,
        price: toWeiBN('35'),
        group: 'Monsters',
        internalId:
          '0x536e616b656761746f7200000000000000000000000000000000000000000000',
        limit: 10,
        sold: 15,
        raised: toWeiBN('525'),
      },
      {
        id: 'VasukiDragon',
        name: 'Vasuki Dragon',
        supply: 24,
        price: toWeiBN('60'),
        group: 'Monsters',
        internalId:
          '0x566173756b69447261676f6e0000000000000000000000000000000000000000',
        limit: 10,
        sold: 24,
        raised: toWeiBN('1440'),
      },
      {
        id: 'Clapperclaw',
        name: 'Clapperclaw',
        supply: 18,
        price: toWeiBN('80'),
        group: 'Monsters',
        internalId:
          '0x436c6170706572636c6177000000000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('1440'),
      },
      {
        id: 'DROGON',
        name: 'DROGON',
        supply: 18,
        price: toWeiBN('150'),
        group: 'Monsters',
        internalId:
          '0x44524f474f4e0000000000000000000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('2700'),
      },
      {
        id: 'TheDelirious Dragon',
        name: 'The Delirious Dragon',
        supply: 18,
        price: toWeiBN('100'),
        group: 'Monsters',
        internalId:
          '0x54686544656c6972696f757320447261676f6e00000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('1800'),
      },
      {
        id: 'Wickgrinchy',
        name: 'Wickgrinchy',
        supply: 18,
        price: toWeiBN('140'),
        group: 'Monsters',
        internalId:
          '0x5769636b6772696e636879000000000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('2520'),
      },
      {
        id: 'QueenSpido',
        name: 'Queen Spido',
        supply: 18,
        price: toWeiBN('150'),
        group: 'Monsters',
        internalId:
          '0x517565656e537069646f00000000000000000000000000000000000000000000',
        limit: 10,
        sold: 18,
        raised: toWeiBN('2700'),
      },
      {
        id: 'Lordof the Potions',
        name: 'Lord of the Potions',
        supply: 30,
        price: toWeiBN('80'),
        group: 'Monsters',
        internalId:
          '0x4c6f72646f662074686520506f74696f6e730000000000000000000000000000',
        limit: 10,
        sold: 30,
        raised: toWeiBN('2400'),
      },
      {
        id: 'Mr.Foodles',
        name: 'Mr. Foodles',
        supply: 6,
        price: toWeiBN('70'),
        group: 'Monsters',
        internalId:
          '0x4d722e466f6f646c657300000000000000000000000000000000000000000000',
        limit: 10,
        sold: 6,
        raised: toWeiBN('420'),
      },
      {
        id: 'TarantulaSaurus',
        name: 'Tarantula Saurus',
        supply: 30,
        price: toWeiBN('60'),
        group: 'Monsters',
        internalId:
          '0x546172616e74756c615361757275730000000000000000000000000000000000',
        limit: 10,
        sold: 30,
        raised: toWeiBN('1800'),
      },
    ],
    registerDate: '2021-10-28T12:00:00.000Z',
    registerDuration: 114,
    fcfsDuration: 23,
    totalWeights: 4839,
    isPublic: false,
    levelsOpenAll: true,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '',
    name: 'Monsters Clan',
    symbol: '',
    imageSrc: logoImg.src,
    decimals: 18,
    listingRate: 0,
    listingTime: '2021-11-25T12:00:00.000Z',
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'airdrop_them',
    vesting: 'November 25th',
  },
  social: {
    website: 'https://monstersclan.com/',
    twitter: 'https://twitter.com/_Monstersclan',
    telegram: 'https://t.me/MONSofficial',
    telegramAnno: 'https://t.me/MONS_Ann',
    medium: 'https://monstersclan.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  descriptionShort: (
    <p>
      Monsters Clan is a first ever third person NFT game, allow players to
      create, collect, trade, train, and play their Monsters to win fights and
      earn.
    </p>
  ),
  description: (
    <>
      <p>
        Monsters Clan is a first ever third person NFT game, allow players to
        create, collect, trade, train, and play their Monsters to win fights and
        earn. Moreover, Monsters Clan offers users to earn passive income in
        $MONS by renting out their NFTs to other game players. The objective
        behind Monsters Clan is to introduce a concept of gaming using
        Blockchain technology and set up a platform for players where players
        could unleash their Monsters to fight for rewards.
      </p>
      <p>
        This game is about to change the whole landscape of playing games
        because Monsters Clan is built on Blockchain. Things are going to become
        way adventurous as Monsters will be competing against each other in the
        battle for their pride and glory. Monsters Clan gives players the
        freedom to fight or trade their own monsters as per their game strategy.
        Owning a Monster is the way to start your journey in Monsters Clan.
      </p>
      <div>
        <iframe
          src="https://drive.google.com/file/d/1huuf9b_u_LpCWt7NDuA4RQujgt4zULtc/preview"
          width="640"
          height="480"
          allow="autoplay"></iframe>
      </div>
    </>
  ),
};
