import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/vrjam.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { UniswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const vrjam: PoolConfig = {
  // id is used in URL
  id: 'vrjam',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  noCountries: [],
  sale: {
    address: '0xe0f400b42A66952D3559FA1D72B296Ee736D26E7',
    tokensForSale: toWeiBN('4750000'),
    startDate: '2022-11-28T18:00:00.000Z',
    rate: 25,
    durationHours: 23,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('4750000'),
    raised: toWeiBN('190000'),
    baseAllocation: toWeiBN('1264'),
    status: 5,
    registerDate: '2022-11-26T18:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 4,
    totalWeights: 10886400,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_linear',
    network: 'POLY',
    claimerVersion: 'v2',
    vesting: '20% on TGE, then 7 months linear vesting',
    claimerAddress: '0x359Db7FA343606cFdC93715EF64E10c47545FDE9',
    config: {
      initialPercent: 20,
      initialUnlockDate: '2022-11-30T12:35:00.000Z',
      vestingStartDate: '2022-11-30T12:35:01.000Z',
      vestingDuration: 7 * 30 * 24 * 60 * 60,
    },
  },
  finishedInfo: (
    <UniswapChartFinishedInfo
      symbol="VRJAM"
      address="0xe84c2edca71fc3cc570c70665178d90c8721623b"
      pairAddress="0xe84c2edca71fc3cc570c70665178d90c8721623b"
    />
  ),
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0xe84c2edca71fc3cc570c70665178d90c8721623b',
    name: 'VRJAM',
    symbol: 'VRJAM',
    imageSrc: img.src,
    // Format: "2022-01-10T00:00:00.000Z", leave empty for TBA. Leave 00:00 time for day only
    listingTime: '2022-11-30T12:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo:
      'Listing on Gate.io and MEXC at 12:00 UTC, Uniswap at 12:30 AM UTC',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(13_050_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'vrjam',
  },
  social: {
    website: 'https://vrjam.com/',
    twitter: 'https://twitter.com/vrjam_',
    telegram: 'https://t.me/VRJAMOfficial',
    telegramAnno: '',
    medium:
      'https://trustpad.medium.com/vrjam-is-launching-on-trustpad-caa2bc16a502',
    github: '',
    whitepaper: '',
    announcement:
      'https://medium.com/@info_44811/vrjam-the-uks-leading-immersive-live-events-platform-launches-vrjam-coin-d7857e838ecd',
  },
  description: (
    <p>
      VRJAM was founded in 2017 with a vision to combine blockchain and
      immersive technology to transform human experience in digital space.
      Today, VRJAM is an immersive, live experience platform built on the Matic
      blockchain designed to magnify the connectedness of human beings in
      virtual space. It will help transform the metaverse for the better and
      create new ways to enhance and value human experience. VRJAM is a
      multiplayer social gaming and live events platform built to empower global
      brands and premium content creators to create immersive real-time consumer
      experiences inside virtual worlds. <br />
      <br />
      <b>Seed round investors in VRJAM:</b>
      <br />
      NGC ventures <br />
      Sky Vision Capital <br />
      DWF Labs <br />
      EnjFi <br />
      Gate.io Ventures <br />
      Animal Concerts <br />
      Metastreet <br />
      AU21 <br />
      Stablenode <br />
      Axia 8 <br />
      Enjin <br />
      Panony <br />
      8 Rings <br />
      TPS Capital <br />
      Oracle Investment Group <br />
      <br />
      <b>Other links:</b> <br />
      <a href="https://vrjam.com/downloads/">
        Download the VRJAM platform
      </a>{' '}
      <br />
      <a href="https://vrjam.net/signup/">Create VRJAM account</a> <br />
      <a href="https://vrjam.com/create-avatar/">Create VRJAM avatar</a> <br />
    </p>
  ),
};
