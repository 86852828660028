import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/metaclash.jpg';
import React from 'react';

import { USDT } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const metaclash: PoolConfig = {
  // id is used in URL
  id: 'metaclash',
  // public, seed or private
  access: 'public',
  fundToken: USDT,
  kyc: true,
  noCountries: [],
  sale: {
    address: '',
    tokensForSale: toWeiBN(266666667),
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    startDate: '',
    // Empty=TBA, tokens per currency, rate = 1$ / token price
    rate: 1333.33333333,
    durationHours: 5,
    // No need to touch:
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN(0),
    raised: toWeiBN(0),
    baseAllocation: toWeiBN(0),
    status: SaleStatus.static,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '10% at TGE, then 15% per month',
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '',
    name: 'MetaClash',
    symbol: 'PCORE',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(1708000000),
    totalSupply: toWeiBN(100000000000),
  },
  social: {
    website: 'https://www.metaclash.online/',
    twitter: 'https://twitter.com/MetaClashOnline',
    telegram: 'https://t.me/MetaClash',
    telegramAnno: 'https://t.me/MetaClash_Announcements',
    medium: 'https://medium.com/metaclash-digital-avatars-of-destruction',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      MetaClash is a blockchain game built on Unreal Engine to create a
      hyper-realistic science fantasy Metaverse. MetaClash has developed an
      innovative new approach to NFT based games. To create a secure ecosystem
      of DAOs and Tokens, MetaClash uses blockchain technology to provide a
      seamless play and earn gaming experience for players. MetaClash shall
      construct a digital world for the community, and by the community. It will
      be an entire ecosystem of play and earn games, realistic visual event
      grounds, and ever-evolving entertainment. Fully decentralized DAOs will
      govern the ecosystem, which rewards users based on their skills, effort,
      and contributions.
    </p>
  ),
};
