import { SaleStatus, useUser } from '@trustpad/launchpad';

import React from 'react';

import { PoolConfig } from '../../types';
import { SaleRegisterButton } from 'src/features/pools/components/SaleRegisterButton/SaleRegisterButton';
import { KYCVerifyIDOButton } from './KYCVerifyIDOButton';

export function KycOrRegisterButton({
  pool,
  center,
}: {
  pool: PoolConfig;
  center?: boolean;
}) {
  const { sale } = pool;
  const {
    kyc: { status },
  } = useUser();

  if (sale.status === SaleStatus.static) {
    if (pool.kyc && status !== 'valid') {
      return <KYCVerifyIDOButton center={center} />;
    }

    return null;
  }

  if (sale.status === SaleStatus.register) {
    return pool.kyc ? (
      <KYCVerifyIDOButton center={center}>
        <SaleRegisterButton center={center} />
      </KYCVerifyIDOButton>
    ) : (
      <SaleRegisterButton center={center} />
    );
  }

  return null;
}
