import img from 'pools/images/gemGuardian.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const gemGuardian: PoolConfig = {
  // id is used in URL
  id: 'gemGuardian',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x93d80cD2b6e1cA00aBd5178Eb8A15C424f784432',
    tokensForSale: toWeiBN('1000000'),
    startDate: '2021-09-25T12:00:00.000Z',
    rate: 10,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1534,
    tokensSold: toWeiBN('1000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('151.638689048760991207'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-09-20T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 6255,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    address: '0x885c5fb8f0e67b2b0cf3a437e6cc6ebc0f9f9014',
    name: 'GemGuardian',
    symbol: 'GEMG',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 10,
    totalSupply: toWeiBN(100_000_000),
    initialSupply: toWeiBN(2_100_000),
    listingTime: '2021-09-26T16:00:00.000Z',
    coingeckoId: 'gemguardian',
    coinmarketcapId: 'gemguardian',
  },
  distribution: {
    type: 'claim_us',
    vesting: '16.66% TGE, 16.66% for 5 months',
    claimerVersion: 'v1',
    claimerAddress: '0x40700e3B56591755BD12D38bCCeBEB05e73651A6',
    schedule: {
      '2021-09-26T16:00:00.000Z': 16.66,
      '2021-10-26T16:00:00.000Z': 16.66,
      '2021-11-26T16:00:00.000Z': 16.66,
      '2021-12-26T16:00:00.000Z': 16.66,
      '2022-01-26T16:00:00.000Z': 16.66,
      '2022-02-26T16:00:00.000Z': 16.7,
    },
  },
  social: {
    website: 'https://gemguardian.io/',
    twitter: 'https://twitter.com/GemGuardianNFT',
    telegram: 'https://t.me/GemGuardianOfficialChat',
    telegramAnno: '',
    medium: 'https://gemguardian.medium.com',
    github: '',
    whitepaper: '',
    announcement:
      'https://trustpad.medium.com/gemguardian-gemg-is-launching-on-trustpad-c870651a5fe6',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="GEMG"
      address="0x885c5fb8f0e67b2b0cf3a437e6cc6ebc0f9f9014"
      pairAddress="0x2f2ef96cee3226f3038b3a6f387a61687dac90a2"
    />
  ),
  description: (
    <p>
      GemGuardian is an infinitely scalable fantasy based NFT Card Game on the
      BSC Network. Utilising a Dual Token Economy to promote price
      sustainability, but at the same time keeping the game fun and rewarding.
      Players acquire Guardians and Dragons to battle and earn in-game rewards
      that can be staked for a lucrative APY.
    </p>
  ),
};
