import img from 'pools/images/galaxyblitz.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const galaxyblitz: PoolConfig = {
  // id is used in URL
  id: 'galaxyblitz',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0x118cF83939a62a3893620863f2a718f53993fC14',
    tokensForSale: toWeiBN('190000'),
    startDate: '2022-03-22T11:00:00.000Z',
    rate: 1,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('190000'),
    raised: toWeiBN('190000'),
    baseAllocation: toWeiBN('25.626644509070765821'),
    status: 5,
    registerDate: '2022-03-20T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7321,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    network: 'BSC',
    vesting: '25% at TGE, then 25% per month',
    claimerAddress: '0x1282C47742387D3c5E457aec4c8da2c1E1ABCf48',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-23T02:15:00.000Z': 25,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0xe6906717f129427eebade5406de68cadd57aa0c0',
    name: 'Galaxy Blitz',
    symbol: 'MIT',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-23T02:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(250000),
    totalSupply: toWeiBN(100000000),
    coinmarketcapId: 'galaxy-blitz',
    coingeckoId: 'galaxy-blitz',
  },
  social: {
    website: 'https://galaxyblitz.world/',
    twitter: 'https://twitter.com/GalaxyBlitzGame',
    telegram: 'https://t.me/galaxyblitz',
    telegramAnno: 'https://t.me/galaxyblitzofficialchannel',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Galaxy Blitz is a Play-To-Earn combat strategy NFT game where you lead the
      descendants of humanity to forge a new empire. Galaxy Blitz combines the
      use of real-world, usable tokens with high-octane gameplay and extensive
      history and lore for a unique player experience. Players will use MIT, the
      in-game governance token, to buy in-game tokens and NFTs that can be used
      in the game.
      <br />
      <br />
      Refunded 75%, see the{' '}
      <a href="https://bscscan.com/tx/0xd6b5e648fc561ca9575e4ab8410a1cb198f856dea99504b043f2c84c91d28162">
        TX
      </a>
      .
    </p>
  ),
};
