import img from 'pools/images/metawear.jpg';
import React from 'react';
import { Button } from 'react-bootstrap';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

const metawearCommon = {
  fundToken: BUSD,
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x9d39ef3bbca5927909dde44476656b81bbe4ee75',
    pairAddress: '0x4cb93abfb9662e264fd9fa5db1f1a19fae98a9b0',
    name: 'MetaWEAR',
    symbol: 'WEAR',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-03-22T09:30:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: 'Will be listed on Pancakeswap and Gate.io',
    decimals: 18,
    listingRate: 200,
    initialSupply: toWeiBN(86_250_000),
    totalSupply: toWeiBN(5_000_000_000),
    coingeckoId: 'metawear',
  },
  finishedInfo: (
    <>
      <Button href="https://www.gate.io/trade/WEAR_USDT" className="mb-3">
        Trade WEAR/USDT on Gate.io
      </Button>

      <PancakeChartFinishedInfo
        symbol="WEAR"
        address="0x9d39ef3bbca5927909dde44476656b81bbe4ee75"
        pairAddress="0x4cb93abfb9662e264fd9fa5db1f1a19fae98a9b0"
      />
    </>
  ),
  social: {
    website: 'https://www.metaversewear.io/',
    twitter: 'https://twitter.com/metaweartoken',
    telegram: 'https://t.me/metaweartoken',
    telegramAnno: '',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      MetaWear is providing the integration of the textile industry existing in
      the real world, Designers, Manufacturers, Well-Known Brands, Existing
      Designs, Advertising and Marketing Organizations, Fashion Shows, Fairs,
      Shopping Centers and Sales Points and Stores into the world of METAVERSE
      with all their players and elements, thanks to ultra-realistic v
      irtualization technologies and digital design teams. MetaWear implements
      unmatched new ideas and practices in sectors Strategic Purchasing, Design,
      Production Quality Control, Marketing and Sales branches by supporting
      with cutting-edge digital technologies in one of the world's largest
      industries. MetaWear is taking the role of playmaker in transformation to
      Web 3.0 in textile sector.
    </p>
  ),
};

export const metawear: PoolConfig = {
  ...metawearCommon,
  // id is used in URL
  id: 'metawear',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x80BA25422f9c2fd93b3E65d75D4b4fc9d26beEFa',
    tokensForSale: toWeiBN('38000000'),
    startDate: '2022-03-20T11:00:00.000Z',
    rate: 200,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('38000000'),
    raised: toWeiBN('190000'),
    baseAllocation: toWeiBN('4972.007464676086376966'),
    status: 5,
    registerDate: '2022-03-18T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('35'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7650,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: [
    {
      type: 'claim_linear',
      network: 'BSC',
      vesting:
        '15% at TGE - vested daily starting day 1 over 6 months (6 months total)',
      claimerAddress: '0x4E03763553fc83cA27AC0a542DcB26caC84aDdf7',
      config: {
        initialPercent: 15,
        initialUnlockDate: '2022-03-22T10:00:00.000Z',
        vestingDuration: 6 * 30 * 24 * 3600,
        vestingStartDate: '2022-03-23T10:00:00.000Z',
      },
    },
  ],
};

export const metawearPrivate: PoolConfig = {
  ...metawearCommon,
  // id is used in URL
  id: 'metawearprivate',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x28a072602cf6FE11bda942cf3387B14317D8c7Ea',
    tokensForSale: toWeiBN('14285714'),
    startDate: '2022-03-20T11:00:00.000Z',
    rate: 285.714,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('14285713.999999999999999875'),
    raised: toWeiBN('50000.049000049000049'),
    baseAllocation: toWeiBN('5135.051761322789360172'),
    status: 5,
    registerDate: '2022-03-18T11:00:00.000Z',
    registerDuration: 45,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 2810,
    minAllowedLevelMultiplier: 22,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BSC',
    vesting: '5% at TGE, 1 month cliff, then daily vesting for 17 months',
    claimerAddress: '0x400814cD41bFC2452DF87c46A6Dca66fc1161c6E',
    config: {
      initialPercent: 5,
      initialUnlockDate: '2022-03-22T10:00:00.000Z',
      vestingDuration: 17 * 30 * 24 * 3600,
      vestingStartDate: '2022-03-23T10:00:00.000Z',
    },
  },
};
