import Link from 'next/link';
import { FaCalendar } from 'react-icons/fa';

import { BuyPancakeSwapButton } from '~features/launched/BuyButtons';

import { Navbar } from './Navbar';

export function DefaultNavbar({
  onNavClick,
  pools = true,
  levels = true,
  staking = true,
  account = true,
}: {
  pools?: boolean;
  levels?: boolean;
  staking?: boolean;
  account?: boolean;
  onNavClick?: (tab: string) => void;
}) {
  return (
    <Navbar>
      {pools && (
        <Link
          href="/#pools"
          className="nav-link text-white"
          onClick={() => onNavClick?.('pools')}>
          Pools 🔥
        </Link>
      )}

      {levels && (
        <Link href="/levels" className="nav-link text-white">
          Levels
        </Link>
      )}

      {staking && (
        <Link href="/staking" className="nav-link text-white">
          Farm 🌾
        </Link>
      )}

      {account && (
        <Link href="/account" className="nav-link text-white">
          Account
        </Link>
      )}

      <Link href="/schedule" className="nav-link text-white">
        <FaCalendar />
      </Link>

      <div className="ml-4">
        <BuyPancakeSwapButton />
      </div>
    </Navbar>
  );
}
