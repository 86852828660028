import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export function AccordionItem({
  expanded: initExpanded,
  title,
  titleClassName = '',
  contentClassName = '',
  children,
}: {
  expanded?: boolean;
  title: string | React.ReactNode;
  titleClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState(initExpanded);

  return (
    <div className="first:border-none first:pt-0 last:pb-0 space-y-3 pb-4 overflow-hidden">
      <div
        className="flex gap-4 items-center justify-between cursor-pointer bg-white/5 rounded-lg px-4 py-3"
        onClick={() => setExpanded(!expanded)}>
        <div
          className={`text-lg ${titleClassName} ${
            expanded ? '' : 'opacity-80'
          }`}>
          {title}
        </div>
        <div>{expanded ? <FaChevronUp /> : <FaChevronDown />}</div>
      </div>
      <div className={`${contentClassName} ${expanded ? 'px-6' : 'hidden'}`}>
        {children}
      </div>
    </div>
  );
}
