import { NO_US, SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/duckieland.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const duckieland: PoolConfig = {
  // id is used in URL
  id: 'duckieland',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  noCountries: NO_US,
  sale: {
    address: '0xA3e2819dB32C0dC00785Ab5831d0861655A7a896',
    tokensForSale: toWeiBN('400000'),
    startDate: '2022-02-19T11:00:00.000Z',
    rate: 2,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('399999.999999999999999896'),
    raised: toWeiBN('199999.999999999999999948'),
    baseAllocation: toWeiBN('50.537773746274458986'),
    status: 5,
    registerDate: '2022-02-17T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7801,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x7a9c8d33963aecca9a821802adfaf5bd9392351f',
    pairAddress: '0x83db9bd858924ee07addec1196c1e3a9473a51f5',
    name: 'Duckie Land',
    symbol: 'MMETA',
    imageSrc: img.src,
    listingTime: '2022-02-28T15:00:00.000Z',
    listingInfo: 'Pancakeswap',
    decimals: 18,
    listingRate: 2,
    initialSupply: toWeiBN(400_000),
    totalSupply: toWeiBN(100000000),
    athXManual: 8.16,
    coingeckoId: 'duckie-land-multi-metaverse',
    coinmarketcapId: 'duckie-land',
  },
  distribution: {
    type: 'claim_us',
    vesting: '20% unlocked at TGE, then unlocked monthly for 4 months',
    claimerAddress: '0x00F3DaBf6e5Ca8b15e036d5FdEa8a3918a160BaE',
    claimerVersion: 'v2',
    schedule: {
      '2022-02-28T15:30:00.000Z': 20,
      '2022-03-28T15:00:00.000Z': 20,
      '2022-04-28T15:00:00.000Z': 20,
      '2022-05-28T15:00:00.000Z': 20,
      '2022-06-28T15:00:00.000Z': 20,
    },
  },
  social: {
    website: 'https://duckie.land/',
    twitter: 'https://twitter.com/duckienft',
    telegram: 'https://t.me/duckienft_global',
    telegramAnno: 'https://t.me/duckienft',
    medium: '',
    github: '',
    whitepaper: 'https://whitepaper.duckie.land/',
    announcement: '',
  },
  description: (
    <p>
      Duckie Land is a metaverse multiplatform (PC, Android, IOS and VR)
      multiplayer online game that runs on the blockchain, and each Duckie is a
      non-fungible token or NFT. Millions of people can participate in the NFT
      world and earn reward tokens through skilful gameplay and contribution to
      the ecosystem. Duckie Land gives gamers ownership of in-game assets and
      allows them to increase their value by actively playing the game. By
      participating in the in-game economy, players will receive rewards and
      create more value for other players and the ecosystem. Duckie Land is
      inspired by the love of every duck meme, duck emoji, duck character and
      everything about ducks. In the land of Duckie, players can collect,
      battle, farm, craft, and become the duckie lord. <br />
      <br />
      <a href="https://github.com/solidproof/projects/blob/main/Duckieland/SmartContract_Audit_Solidproof_Duckieland.pdf">
        Audited by SOLIDProof
      </a>
    </p>
  ),
};
