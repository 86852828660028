import React from 'react';
import { tpadSmokeImg } from './images';

export function SmokeBg({ children }: { children: React.ReactNode }) {
  return (
    <div
      className="bg-no-repeat bg-cover relative"
      style={{ backgroundImage: `url("${tpadSmokeImg.src}")` }}>
      {children}
    </div>
  );
}
