import img from 'pools/images/yarloo.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const yarloo: PoolConfig = {
  // id is used in URL
  id: 'yarloo',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0x773EE2523B195e4CD8ceAE4ce53654e60F201EC1',
    tokensForSale: toWeiBN('357142.85'),
    startDate: '2021-10-24T12:00:00.000Z',
    rate: 2.857143,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1069,
    tokensSold: toWeiBN('357142.849999999999999725'),
    raised: toWeiBN('124999.99125000043749996'),
    baseAllocation: toWeiBN('83.770602574519230769'),
    status: 5,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('30'),
    },
    registerDate: '2021-10-22T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 4234,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    extraChains: ['POLY'],
    address: '0x843cbc1732ae7d7ba0533c6380989dacec315ffe',
    name: 'Yarloo',
    symbol: 'YARL',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(848571.43),
    totalSupply: toWeiBN(25_000_000),
    listingTime: '2021-10-27T12:00:00.000Z',
    coingeckoId: 'yarloo',
    coinmarketcapId: 'yarloo',
  },
  distribution: {
    type: 'claim_them',
    vesting: '20% unlocked at listing, 1 month cliff, 3 months linear unlock',
    claimUrl: 'http://app.synapse.network/vesting',
  },
  finishedInfo: (
    <>
      <PancakeChartFinishedInfo
        symbol="YARL"
        address="0x843cbc1732ae7d7ba0533c6380989dacec315ffe"
        pairAddress="0x2dc3a32895d13732a151a17c0f40e695c73ad797"
      />
    </>
  ),
  social: {
    website: 'https://yarloo.io/',
    twitter: 'https://twitter.com/yarloo_rst',
    telegram: 'https://t.me/yarloochat',
    telegramAnno: 'https://t.me/yarlannouncements',
    medium: 'https://medium.com/@yarloo',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Yarloo are crypto and gaming veterans who have poured their passion into
      bringing a unique take on crypto NFT gaming, by empowering other P2E
      companies that want to provide amazing quality to their user-base.
    </p>
  ),
};
