import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getTimeDiff } from '~utils';

export function Countdown({
  date,
  endText = 'complete',
  className,
}: {
  date: string | number;
  endText?: string;
  className?: string;
}) {
  const [now, setNow] = useState(null);

  useEffect(() => {
    if (!date) return;
    const id = setInterval(() => setNow(dayjs()), 1000);
    return () => clearInterval(id);
  }, [date]);

  if (!date) return null;

  if (!now) return <Skeleton height="2rem" />;

  const untilEnd = getTimeDiff(
    now,
    typeof date === 'number' ? dayjs.unix(date) : dayjs(date),
  );

  if (untilEnd.asSeconds() <= 0) {
    return <span>{endText}</span>;
  }

  return (
    <div className={`inline-grid grid-cols-4 gap-2 ${className}`}>
      <TimeItem value={untilEnd.days()} label="days" />
      <TimeItem value={untilEnd.hours()} label="hours" />
      <TimeItem value={untilEnd.minutes()} label="minutes" />
      <TimeItem value={untilEnd.seconds()} label="seconds" />
    </div>
  );
}

function TimeItem({ value, label }: { value: number; label: string }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <span className="text-3xl font-semibold">{value}</span>
      <span className="text-sm uppercase secondary-text">{label}</span>
    </div>
  );
}
