import img from 'pools/images/jungleroad.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const jungleroad: PoolConfig = {
  // id is used in URL
  id: 'jungleroad',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    address: '0x9eC850bd6D1049458FfCb4FB86563332078cFb85',
    tokensForSale: toWeiBN('3510870'),
    startDate: '2022-04-16T11:00:00.000Z',
    rate: 21.739,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('3510869.999999999999999203'),
    raised: toWeiBN('161500.9890059340356042'),
    baseAllocation: toWeiBN('680.675939610647596344'),
    status: 5,
    registerDate: '2022-04-14T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('40'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 5062,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_linear',
    network: 'BSC',
    vesting: '25% unlock at TGE, then 25% per month for 3 months',
    claimerAddress: '0xf2a2267a812b36FB59281c97e73CBDd4868C89A3',
    config: {
      initialPercent: 25,
      initialUnlockDate: '2022-04-18T14:15:00.000Z',
      vestingDuration: 3 * 30 * 24 * 3600,
      vestingStartDate: '2022-04-18T14:15:01.000Z',
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x926f2764f4e9be54fbd8f81b6f26c8e908e199c0',
    name: 'Jungle Road',
    symbol: 'JGRD',
    imageSrc: img.src,
    // Format: "2022-01-10T11:00:00.000Z", leave empty for TBA
    listingTime: '2022-04-18T14:00:00.000Z',
    // Additional information about listing: where lists, on which platforms for example
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(7_140_000),
    totalSupply: toWeiBN(1_000_000_000),
  },
  social: {
    website: 'https://www.jungleroad.io/',
    twitter: 'https://twitter.com/JungleroadP2E',
    telegram: '',
    telegramAnno: 'https://t.me/jungleroadofficial',
    medium: 'https://medium.com/@jungleroad.gamefi',
    github: '',
    whitepaper: 'https://files.jungleroad.io/Jungleroad_whitepaper_V1.0.10.pdf',
    announcement: '',
  },
  description: (
    <p>
      Here at Jungle Road, players can explore wild landscapes with their very
      own unique, strong, and diverse animal gods. Each character comes from a
      different origin and is on a quest to reach Nirvana. In these maps, you
      will come across rivers and lands of all sorts, filled with wild animal
      attacks and many obstacles to overcome. This journey will not be easy nor
      safe. This blockchain game will take you back to the good old days of when
      you were a kid and would escape your daily life chores and just have fun.
      But now with NFTs, we are able to do this and also earn as we play.
    </p>
  ),
};
