import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/octane.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const octane: PoolConfig = {
  // id is used in URL
  id: 'octane',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0xe3C6a632f5009CDA2C6eAe9dAF5e495eb5eAbF90',
    tokensForSale: toWeiBN('757575.75757576'),
    startDate: '2021-09-17T12:00:00.000Z',
    rate: 3.030303,
    durationHours: 8,
    hasWhitelist: true,
    isTiered: true,
    participants: 1397,
    tokensSold: toWeiBN('757575.75757575999999993'),
    raised: toWeiBN('250000.002500000825000101'),
    baseAllocation: toWeiBN('136.05559689830134904'),
    status: SaleStatus.finished,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-09-12T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 5327,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    address: '0x5416ab2b4b5a40f740b67a83dc5939591b5c08be',
    name: 'Octane',
    symbol: 'OCTANE',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 3.030303,
    initialSupply: toWeiBN(4_800_000),
    totalSupply: toWeiBN(100_000_000),
    listingTime: '2021-09-21T16:30:00.000Z',
    listingInfo: 'September 21th, 16:00 UTC',
    coingeckoId: 'octane-protocol-token',
    coinmarketcapId: 'octane-finance',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '100% at TGE',
    schedule: {
      '2021-09-21T16:30:00.000Z': 100,
    },
  },
  social: {
    website: 'https://octane.finance/',
    twitter: 'https://twitter.com/octane_finance',
    telegram: 'https://t.me/octanefinance',
    telegramAnno: 'https://t.me/octaneannouncements',
    medium: 'https://octanefinance.medium.com/',
    announcement:
      'https://trustpad.medium.com/octane-is-launching-on-trustpad-56eb17af33dd',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="OCTANE"
      address="0x5416ab2b4b5a40f740b67a83dc5939591b5c08be"
      pairAddress="0x39bfae0c96bdc69dc657e381c272997f66cbf9c1"
    />
  ),
  descriptionShort: (
    <p>
      Octane is a fully decentralised and rewarding limit order platform driven
      by a network of executors. The goal is to provide users a seamless
      experience of limit orders by removing the friction associated with the
      current platforms.
    </p>
  ),
  description: (
    <>
      <p>
        Octane is a fully decentralised and rewarding limit order platform
        driven by a network of executors. The goal is to provide users a
        seamless experience of limit orders by removing the friction associated
        with the current platforms. Similar to play to earn models, users can
        execute to earn, creating a sustainable ecosystem where entrants into
        trading can learn more about the operations behind transactions and
        build their market knowledge.
      </p>
      <p>
        You can test everything out for yourself today with Octane’s live beta
        platform on testnet at{' '}
        <a href="https://beta.octane.finance/">beta.octane.finance</a>.
      </p>
    </>
  ),
};
