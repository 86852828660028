import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCircleNotch } from 'react-icons/fa';

export function ClaimAllButton({
  className = '',
  paused,
  claim,
  disabled,
  children,
  account,
  isClaiming,
}: {
  className?: string;
  paused: boolean;
  disabled: boolean;
  children?: React.ReactNode;
  account: string;
  isClaiming?: number;
  claim: () => Promise<void>;
}) {
  if (!account) {
    return (
      <Button variant="outline-light" size="sm" disabled className={className}>
        Connect wallet first
      </Button>
    );
  }

  if (paused) {
    return (
      <Button variant="outline-light" size="sm" disabled className={className}>
        Paused
      </Button>
    );
  }

  return (
    <Button
      variant={!disabled ? 'outline-primary' : 'outline-secondary'}
      size="sm"
      onClick={claim}
      disabled={disabled || isClaiming !== undefined}
      className={className}>
      {children || 'Claim all'}
      {isClaiming !== undefined && (
        <FaCircleNotch className="inline-block animate animate-spin ml-2" />
      )}
    </Button>
  );
}
