import { useUser } from '@trustpad/launchpad';
import { KYCStatus } from '@trustpad/launchpad/dist/shared/hooks/useUser/kyc';

import constate from 'constate';
import { DBUserSaleContribution } from 'lib/types';
import { useEffect, useState } from 'react';

export type UserExtraData = {
  kyc: {
    status: KYCStatus;
    formUrl: string;
    country?: string;
  };
  contributions: DBUserSaleContribution[];
  contributionsByPool?: Record<string, DBUserSaleContribution>;
  lastSeenNotificationDate?: string;
};

type Getter = (account: string) => Promise<UserExtraData>;

// TODO: caching...
function useUserExtraDataRaw({
  getUserData,
  updateUserData,
}: {
  getUserData: Getter;
  updateUserData: (
    account,
    data: Partial<UserExtraData>,
  ) => Promise<UserExtraData>;
}) {
  const { account } = useUser();
  const [data, setData] = useState<UserExtraData>({
    kyc: { status: 'nothing', formUrl: '', country: '' },
    contributions: [],
    contributionsByPool: {},
  });

  useEffect(() => {
    if (!account) return;
    getUserData(account).then(
      ({ kyc, contributions, lastSeenNotificationDate }) =>
        setData({
          kyc,
          contributions: contributions || [],
          contributionsByPool: contributions?.reduce(
            (acc, c) => ({
              ...acc,
              [c.poolId]: c,
            }),
            {},
          ),
          lastSeenNotificationDate,
        }),
    );
  }, [account]);

  return {
    account,
    ...data,
    updateUserData: (payload: Partial<UserExtraData>) =>
      updateUserData(account, payload).then((newData) => {
        if (newData) {
          setData({ ...data, ...newData });
        }
        return newData;
      }),
  };
}

const [UserExtraDataProvider, useUserExtraData] = constate(useUserExtraDataRaw);

export { UserExtraDataProvider, useUserExtraData };
