import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

type AlertType = 'success' | 'error' | 'warning' | 'info';

const typeToClass = {
  success: 'bg-green-800 text-white',
  error: 'bg-red-900 text-white',
  warning: 'bg-yellow-500 text-black',
  info: 'bg-purple-900 text-white',
};

export function Alert({
  type = 'info',
  className = '',
  dismissible = false,
  children,
}: {
  type?: AlertType;
  dismissible?: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  const [closed, setClosed] = useState(false);
  if (closed) return null;

  return (
    <div
      className={`${typeToClass[type]} relative rounded py-3 px-4 mb-4 ${className}`}
      role="alert">
      {children}

      {dismissible && (
        <button
          onClick={() => setClosed(true)}
          className="absolute right-0 top-0 py-3 px-4 opacity-50">
          <FaTimes />
        </button>
      )}
    </div>
  );
}
