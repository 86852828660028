import img from 'pools/images/nestarcade.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { SolanaChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const nestarcade: PoolConfig = {
  // id is used in URL
  id: 'nestarcade',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    refunded: true,
    address: '0x962e3D36EC4b75b99EbD34b5ae0E01CFEC62223F',
    tokensForSale: toWeiBN('20000000'),
    startDate: '2022-03-06T11:00:00.000Z',
    rate: 100,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('20000000'),
    raised: toWeiBN('200000'),
    baseAllocation: toWeiBN('2683.787899764640730998'),
    status: 5,
    registerDate: '2022-03-04T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 7415,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'SOL',
    address: 'Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV',
    name: 'Nest Arcade',
    symbol: 'NESTA',
    imageSrc: img.src,
    listingTime: '2022-03-08T14:00:00.000Z',
    listingInfo: '',
    decimals: 9,
    listingRate: 0,
    initialSupply: toWeiBN(36_700_000),
    totalSupply: toWeiBN(1_000_000_000),
    coingeckoId: 'nest-arcade',
    coinmarketcapId: 'nest-arcade',
  },

  finishedInfo: (
    <>
      <p>
        Trade{' '}
        <a href="https://www.mexc.com/exchange/NESTA_USDT">
          NESTA/USDT on MEXC
        </a>
      </p>
      <SolanaChartFinishedInfo
        symbol="NESTA"
        address={'Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV'}
      />
    </>
  ),

  distribution: {
    type: 'airdrop_us',
    vesting: '20% at TGE and the rest linearly over 4 months (refunded 80%)',
    schedule: {
      '2022-03-08T14:20:00.000Z': 20,
    },
  },
  social: {
    website: 'https://nestarcade.io/',
    twitter: 'https://twitter.com/thenestarcade',
    telegram: 'https://t.me/nestarcade',
    telegramAnno: 'https://t.me/nestarcadenews',
    medium: '',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Nest will be the first play-to-earn arcade app on the blockchain. The goal
      is to massively accelerate blockchain adoption through a remarkably simple
      app such as Netflix but for mini-medium scale games. Players will be able
      to own their in-game characters using NFTS and play them in a variety of
      Play To Earn games. Nest will use it's own SPL token ($FTH) for the
      projects currency as well as Solana.
      <br />
      <br />
      We refunded 80%, check the{' '}
      <a
        href="https://bscscan.com/tx/0x6a574160e6aca41d64264d60d1fff7d9e4c400cbb9972950269584fe0e4dd954"
        target="_blank"
        rel="noreferrer">
        refund transaction
      </a>
    </p>
  ),
};
