import img from 'pools/images/fishingtown.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const fishingtown: PoolConfig = {
  // id is used in URL
  id: 'fishingtown',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x778af5d3Ae9aD89c0EA07603b5Fa9dFaD8f341c1',
    tokensForSale: toWeiBN('2000000'),
    startDate: '2021-12-12T14:00:00.000Z',
    rate: 20,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('2000000'),
    raised: toWeiBN('100000'),
    baseAllocation: toWeiBN('275.681950087057457922'),
    status: 5,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-12-10T14:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 1,
    totalWeights: 6906,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    address: '0x87BE0b856960f57fB0104Ef0922Cd1DACBd37F64',
    name: 'Fishing Town',
    symbol: 'FHTN',
    imageSrc: img.src,
    listingTime: '2021-12-23T13:10:00.000Z',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(10_520_000),
    totalSupply: toWeiBN(100_000_000),
    athXManual: 19.4,
    coingeckoId: 'fishing-town',
    coinmarketcapId: 'fishingtown',
  },
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="FHTN"
      address="0x87BE0b856960f57fB0104Ef0922Cd1DACBd37F64"
      pairAddress="0x87BE0b856960f57fB0104Ef0922Cd1DACBd37F64"
    />
  ),
  distribution: {
    type: 'claim_us',
    vesting: '25% at listing, remaining distributed every month for 3 months',
    claimerAddress: '0xa0a1EF9EC11487c2Bd7E54D8EBdB0AC11146396B',
    claimUrl: 'https://investors.bithotel.io',
    schedule: {
      '2021-12-23T13:10:00.000Z': 25,
      '2022-01-23T13:00:00.000Z': 25,
      '2022-02-23T13:00:00.000Z': 25,
      '2022-03-23T13:00:00.000Z': 25,
    },
  },
  social: {
    website: 'https://fishingtown.io/',
    twitter: 'https://twitter.com/FishingTownbsc',
    telegram: 'https://t.me/fishingtownofficial',
    telegramAnno: 'https://t.me/fishingtownannouncement',
    medium: 'https://fishingtown-io.medium.com/',
    github: '',
    whitepaper: '',
    announcement: '',
  },
  description: (
    <p>
      Fishing Town has established a digital platform universe of unique digital
      assets by combining the blockchain-based gaming world with NFTin a
      next-generation concept. Fishing Town aims to be the NFTs game that
      combines the jolliness of gameplay with the remembrance of old school
      gaming based on reality-related aquatic animals that bring players
      experience to the next level.
    </p>
  ),
};
