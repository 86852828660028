import type { Tier } from '@trustpad/launchpad';

const days7 = 7 * 24 * 3600;
const days60 = 60 * 24 * 3600;

export const tiers = {
  none: {
    id: 'none',
    name: 'none',
    minAmount: 0,
    multiplier: 0,
    lockingPeriod: '',
    lockingPeriodSec: 0,
    random: false,
    odds: 0,
    vip: false,
  } as Tier,
  // Guaranteed
  partaker: {
    id: 'partaker',
    name: 'partaker',
    minAmount: 20_000,
    multiplier: 0.5,
  } as Tier,
  prospect: {
    id: 'prospect',
    name: 'prospect',
    minAmount: 40_000,
    multiplier: 1,
  } as Tier,
  rookie: {
    id: 'rookie',
    name: 'rookie',
    minAmount: 60_000,
    multiplier: 1.5,
  } as Tier,
  affiliate: {
    id: 'affiliate',
    name: 'affiliate',
    minAmount: 100_000,
    multiplier: 2.5,
  } as Tier,
  member: {
    id: 'member',
    name: 'member',
    minAmount: 150_000,
    multiplier: 4,
  } as Tier,
  club: {
    id: 'club',
    name: 'club',
    minAmount: 250_000,
    multiplier: 7,
  } as Tier,
  contributor: {
    id: 'contributor',
    name: 'contributor',
    minAmount: 350_000,
    multiplier: 10,
  } as Tier,
  // All-Access Guaranteed (AAG)
  associate: {
    id: 'associate',
    name: 'associate',
    minAmount: 500_000,
    multiplier: 15,
    aag: true,
  } as Tier,
  manager: {
    id: 'manager',
    name: 'manager',
    minAmount: 750_000,
    multiplier: 23,
    aag: true,
  } as Tier,
  executive: {
    id: 'executive',
    name: 'executive',
    minAmount: 1_000_000,
    multiplier: 31,
    aag: true,
  } as Tier,
  chief: {
    id: 'chief',
    name: 'chief',
    minAmount: 1_250_000,
    multiplier: 39,
    aag: true,
  } as Tier,
  partner: {
    id: 'partner',
    name: 'partner',
    minAmount: 1_500_000,
    multiplier: 47,
    aag: true,
  } as Tier,
  orbit: {
    id: 'orbit',
    name: 'orbit',
    minAmount: 2_000_000,
    multiplier: 63,
    aag: true,
  } as Tier,
  // Super All-Access Guaranteed (SAAG)
  black: {
    id: 'black',
    name: 'black',
    minAmount: 2_500_000,
    multiplier: 79,
    vip: true,
    aag: true,
  } as Tier,
  jetBlack: {
    id: 'jetBlack',
    name: 'jet black',
    minAmount: 3_750_000,
    multiplier: 119,
    vip: true,
    aag: true,
  } as Tier,
  obsidian: {
    id: 'obsidian',
    name: 'obsidian',
    minAmount: 5_000_000,
    multiplier: 160,
    vip: true,
    aag: true,
  } as Tier,
  thermosphere: {
    id: 'thermosphere',
    name: 'thermosphere',
    minAmount: 6_250_000,
    multiplier: 201,
    vip: true,
    aag: true,
  } as Tier,
  outerSpace: {
    id: 'outerSpace',
    name: 'outer space',
    minAmount: 7_500_000,
    multiplier: 244,
    vip: true,
    aag: true,
  } as Tier,
  singularity: {
    id: 'singularity',
    name: 'singularity',
    minAmount: 10_000_000,
    multiplier: 333,
    vip: true,
    aag: true,
  } as Tier,
};

// List of old levels and their values. Must be sorted by __migratedAt ASC
export const oldLevels = [
  {
    __migratedAt: 1702745240,
    chance: {
      id: 'chance',
      name: 'chance',
      minAmount: 30000,
      multiplier: 1,
      // fcfsMultiplier: 2,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: true,
      odds: 20,
      vip: false,
    } as Tier,
    prospect: {
      id: 'prospect',
      name: 'prospect',
      minAmount: 60000,
      multiplier: 2,
      // fcfsMultiplier: 3,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: true,
      odds: 30,
      vip: false,
    } as Tier,
    affiliate: {
      id: 'affiliate',
      name: 'affiliate',
      minAmount: 100000,
      multiplier: 2,
      // fcfsMultiplier: 3,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
      vip: false,
    } as Tier,
    // guaranteed
    member: {
      id: 'member',
      name: 'member',
      minAmount: 150000,
      multiplier: 4,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
      vip: false,
    } as Tier,
    club: {
      id: 'club',
      name: 'club',
      minAmount: 250000,
      multiplier: 7,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
    } as Tier,
    associate: {
      id: 'associate',
      name: 'associate',
      minAmount: 450000,
      multiplier: 13,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
      vip: false,
      aag: true,
    } as Tier,
    manager: {
      id: 'manager',
      name: 'manager',
      minAmount: 650000,
      multiplier: 19,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
      vip: false,
      aag: true,
    } as Tier,
    executive: {
      id: 'executive',
      name: 'executive',
      minAmount: 900000,
      multiplier: 27,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      aag: true,
      random: false,
      odds: 0,
    } as Tier,
    chief: {
      id: 'chief',
      name: 'chief',
      minAmount: 1200000,
      multiplier: 36,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      aag: true,
      random: false,
      odds: 0,
    } as Tier,
    partner: {
      id: 'partner',
      name: 'partner',
      minAmount: 1500000,
      multiplier: 46,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
      vip: false,
      aag: true,
    } as Tier,
    orbit: {
      id: 'orbit',
      name: 'orbit',
      minAmount: 1850000,
      multiplier: 57,
      lockingPeriod: '7 days',
      lockingPeriodSec: days7,
      random: false,
      odds: 0,
      vip: false,
      aag: true,
    } as Tier,
    black: {
      id: 'black',
      name: 'black',
      minAmount: 2500000,
      multiplier: 78,
      lockingPeriod: '60 days',
      lockingPeriodSec: days60,
      random: false,
      odds: 0,
      vip: true,
      aag: true,
    } as Tier,
    jetBlack: {
      id: 'jetBlack',
      name: 'jet black',
      minAmount: 3750000,
      multiplier: 118,
      lockingPeriod: '60 days',
      lockingPeriodSec: days60,
      random: false,
      odds: 0,
      vip: true,
      aag: true,
    } as Tier,
    obsidian: {
      id: 'obsidian',
      name: 'obsidian',
      minAmount: 5000000,
      multiplier: 160,
      lockingPeriod: '60 days',
      lockingPeriodSec: days60,
      random: false,
      odds: 0,
      vip: true,
      aag: true,
    } as Tier,
    thermosphere: {
      id: 'thermosphere',
      name: 'thermosphere',
      minAmount: 6250000,
      multiplier: 201,
      lockingPeriod: '60 days',
      lockingPeriodSec: days60,
      random: false,
      odds: 0,
      vip: true,
      aag: true,
    } as Tier,
    outerSpace: {
      id: 'outerSpace',
      name: 'outer space',
      minAmount: 7500000,
      multiplier: 244,
      lockingPeriod: '60 days',
      lockingPeriodSec: days60,
      random: false,
      odds: 0,
      vip: true,
      aag: true,
    } as Tier,
    singularity: {
      id: 'singularity',
      name: 'singularity',
      minAmount: 10000000,
      multiplier: 333,
      lockingPeriod: '60 days',
      lockingPeriodSec: days60,
      random: false,
      odds: 0,
      vip: true,
      aag: true,
    } as Tier,
  },
  {
    __migratedAt: 1650924360,
    chance: {
      id: 'chance',
      name: 'chance',
      minAmount: 20000,
      multiplier: 1,
      lockingPeriod: '5 days',
      random: true,
      odds: 10,
    },
    prospect: {
      id: 'prospect',
      name: 'prospect',
      minAmount: 40000,
      multiplier: 2,
      lockingPeriod: '5 days',
      random: true,
      odds: 25,
    },
    affiliate: {
      id: 'affiliate',
      name: 'affiliate',
      minAmount: 80000,
      multiplier: 2,
      lockingPeriod: '5 days',
      random: true,
      odds: 50,
    },
    // guaranteed
    member: {
      id: 'member',
      name: 'member',
      minAmount: 100000,
      multiplier: 2,
      lockingPeriod: '5 days',
      random: false,
      odds: 0,
    },
    club: {
      id: 'club',
      name: 'club',
      minAmount: 160000,
      multiplier: 3,
      lockingPeriod: '5 days',
      random: false,
      odds: 0,
    },
    associate: {
      id: 'associate',
      name: 'associate',
      minAmount: 300000,
      multiplier: 6,
      lockingPeriod: '5 days',
      random: false,
      odds: 0,
    },
    executive: {
      id: 'executive',
      name: 'executive',
      minAmount: 600000,
      multiplier: 14,
      lockingPeriod: '5 days',
      random: false,
      odds: 0,
    },
    partner: {
      id: 'partner',
      name: 'partner',
      minAmount: 1000000,
      multiplier: 22,
      lockingPeriod: '5 days',
      random: false,
      odds: 0,
    },
    orbit: {
      id: 'orbit',
      name: 'orbit',
      minAmount: 1250000,
      multiplier: 28,
      lockingPeriod: '5 days',
      random: false,
      odds: 0,
    },
    black: {
      id: 'black',
      name: 'black',
      minAmount: 2500000,
      multiplier: 78,
      lockingPeriod: '60 days',
      random: false,
      odds: 0,
    },
    obsidian: {
      id: 'obsidian',
      name: 'obsidian',
      minAmount: 5000000,
      multiplier: 160,
      lockingPeriod: '60 days',
      random: false,
      odds: 0,
    },
  },
];
