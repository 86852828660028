import img from 'pools/images/snook.jpeg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig, SaleStatus } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const snook: PoolConfig = {
  // id is used in URL
  id: 'snook',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  sale: {
    address: '0xC466471629Cfcd5A1e1a7b518FE2E79Af143DB64',
    status: SaleStatus.finished,
    rate: 3.125,
    tokensForSale: toWeiBN('625000'),
    startDate: '2021-09-06T12:00:00.000Z',
    baseAllocation: toWeiBN('162.591789052069425901'),
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    participants: 1012,
    tokensSold: toWeiBN('624999.999999999999999985'),
    raised: toWeiBN('200000.000000000000000073'),
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('100'),
    },
    registerDate: '2021-09-01T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 4407,
    levelsOpenAll: true,

    fcfsMultiplier: 10000,
  },
  token: {
    address: '0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309',
    name: 'Snook',
    symbol: 'SNK',
    imageSrc: img.src,
    decimals: 18,
    pairAddress: '',
    listingRate: 2.5,
    totalSupply: toWeiBN(40_000_000),
    initialSupply: toWeiBN(2_331_531),
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'POLY',
    listingTime: '2021-09-07T11:00:00.000Z',
    coingeckoId: 'snook',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '50% at TGE, 50% month later',
    schedule: {
      '2021-09-07T11:00:00.000Z': 50,
      '2021-10-07T12:00:00.000Z': 50,
    },
  },
  social: {
    website: 'https://www.playsnook.com/',
    twitter: 'https://twitter.com/Playsnook_com',
    telegram: 'https://t.me/playsnook',
    telegramAnno: 'https://t.me/playsnook_com',
    medium: 'https://playsnook.medium.com/',
    whitepaper: 'https://whitepaper.playsnook.com/',
    announcement:
      'https://trustpad.medium.com/snook-snk-is-launching-its-ido-on-trustpad-7fe86ac68719',
  },
  descriptionShort: (
    <div>
      Snook is much more than a play-to-earn NFT game. It employs a unique
      Skill-based Value Metric (SVM) for setting NFT value based on measurable,
      objective attributes. Since NFT value is proportional to rarity, and
      rarity in Snook is skill, meaning that the abilities of the player define
      their NFT value.
    </div>
  ),
  description: (
    <div>
      Snook is much more than a play-to-earn NFT game. It employs a unique
      Skill-based Value Metric (SVM) for setting NFT value based on measurable,
      objective attributes. Since NFT value is proportional to rarity, and
      rarity in Snook is skill, meaning that the abilities of the player define
      their NFT value. <br />
      <br />
      The distribution happens on Polygon network to the same address you used
      to fund your allocation. <br />
      <a href="https://medium.com/crypto-and-defi/how-to-sent-matic-from-bsc-to-polygon-4bcd815ca49e">
        How to setup Polygon and fund the wallet
      </a>{' '}
    </div>
  ),
  finishedInfo: (
    <div>
      <QuickswapChartFinishedInfo
        symbol="SNK"
        address="0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309"
        pairAddress="0xd8770553b6daebeb77b29959d9589638e812b5cc"
      />
      <p className="mt-3 text-center">
        Also <a href="https://www.gate.io/trade/SNK_USDT">buy SNK on Gate.io</a>
      </p>
    </div>
  ),
};
