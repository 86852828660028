import { NftPoolConfig } from '@trustpad/launchpad';

import aridropImg from 'pools/finished/animalia/images/airdrop.jpeg';
import logoImg from 'pools/finished/animalia/images/animalialogo.jpg';
import React from 'react';

import { BUSD } from '~/config/tokens';
import { toWeiBN } from '~/utils';
import { Desc } from '~features/pools/components/PoolDescription';

import toroImg from './images/toro.jpg';
import ursaImg from './images/ursa.jpg';

export const animalia: NftPoolConfig = {
  // id is used in URL
  id: 'animalia',
  // public, seed or private
  access: 'private',
  fundToken: BUSD,
  isNft: true,
  kyc: true,
  sale: {
    address: '0x17f3d3D480768b4f70e799EF640BB87CB841a0D5',
    tokensForSale: toWeiBN('250'),
    startDate: '2021-12-17T12:00:00.000Z',
    rate: 0,
    durationHours: 24,
    hasWhitelist: true,
    isTiered: true,
    participants: 245,
    tokensSold: toWeiBN('0.00000000000000025'),
    raised: toWeiBN('217500'),
    baseAllocation: toWeiBN('3480'),
    status: 5,
    inventory: [
      {
        id: 'ursa',
        name: 'Ursa',
        image: ursaImg.src,
        supply: 125,
        totalSupply: 1050,
        price: toWeiBN('870'),
        internalId:
          '0x7572736100000000000000000000000000000000000000000000000000000000',
        limit: 0,
        sold: 125,
        raised: toWeiBN('108750'),
      },
      {
        id: 'toro',
        name: 'Toro',
        image: toroImg.src,
        supply: 125,
        totalSupply: 1050,
        price: toWeiBN('870'),
        internalId:
          '0x746f726f00000000000000000000000000000000000000000000000000000000',
        limit: 0,
        sold: 125,
        raised: toWeiBN('108750'),
      },
    ],
    registerDate: '2021-12-15T12:00:00.000Z',
    registerDuration: 42,
    fcfsDuration: 0,
    totalWeights: 5776,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('0'),
    },
    isPublic: false,
    levelsOpenAll: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    address: '',
    name: 'Animalia',
    symbol: '',
    // listingTime: '2022-01-06T12:00:00.000Z',
    listingTime: '',
    listingInfo: '',
    imageSrc: logoImg.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(0),
    totalSupply: toWeiBN(0),
  },
  distribution: {
    type: 'airdrop_them',
    vesting: '6th January',
  },
  social: {
    website: 'https://animalia.games/',
    twitter: 'https://twitter.com/Animalia_games',
    telegram: 'https://t.me/Animalia_ANIM',
    telegramAnno: 'https://t.me/Animalia_ANIM_Channel',
    medium: '',
    github: '',
    whitepaper: 'https://whitepaper.animalia.games/',
    announcement: '',
  },
  descriptionShort: (
    <>
      <p>
        Animalia is an independent free-to-play online NFT trading card game
        featuring crypto-inspired meme creatures and gemstones. Powered by the
        BNB Chain, Animalia gives you complete ownership over your in-game
        collectibles. Collect rare cards, create your own NFTs, build your deck,
        battle with other players and sell cards to other traders.
      </p>
      <p>
        Items are chosen randomly.{' '}
        <b>First 3 hours are for stakers, then sale opens for public.</b>
      </p>
    </>
  ),
  description: (
    <>
      <p>
        Animalia is an independent free-to-play online NFT trading card game
        featuring crypto-inspired meme creatures and gemstones. Powered by the
        BNB Chain, Animalia gives you complete ownership over your in-game
        collectibles. Collect rare cards, create your own NFTs, build your deck,
        battle with other players and sell cards to other traders.
      </p>
      <p>
        Items are chosen randomly.{' '}
        <b>First 3 hours are for stakers, then sale opens for public.</b>
      </p>
      <Desc.Img src={aridropImg.src} />
    </>
  ),
};
