import { chains } from '@trustpad/launchpad';
import {
  ChainContractRegistry,
  ChainNodes,
} from '@trustpad/launchpad/dist/LaunchpadProvider';
import { DefaultSaleConfig } from '@trustpad/launchpad/dist/pool/types';

import { IS_LIVE_NETWORK } from '~config/constants';

export const defaultChain = chains.BSC;

export const registry: ChainContractRegistry = {
  // Live BSC
  56: {
    token: process.env.NEXT_PUBLIC_TOKEN_ADDRESS,
    levelManager: {
      version: 'v4',
      addresses: [process.env.LEVEL_MANAGER_ADDRESS],
    },
    levelStaking: {
      version: 'v2',
      addresses: [
        // 7 days (marked as feeless and excluded)
        '0x9c9a7eF597E9E3077Ec65635D73083121CB79E1D',
        // 14 days (marked as feeless and excluded)
        '0xb2C1cBA9A69DC66bF91829E7d730C52D0334bE73',
        // 21 days (marked as feeless and excluded)
        '0xeC8Bd0a072882C6e7bF5205Ff9196C43714BAD9A',
        // 30 days (marked as feeless and excluded)
        '0x85478b312Fd074F0e7dD03352B8565969dE7437d',
      ],
    },
    // Treasury: 0x5317093305b6bcc5574978b3f8A77c93cdF70e94 (marked as feeless)
  },
  // Test BSC
  97: {
    token: process.env.NEXT_PUBLIC_TOKEN_ADDRESS,
    levelManager: {
      version: 'v4',
      addresses: ['0xfd1EFdc42DB94515B5444ce90149A63741b4A289'],
    },
    levelStaking: {
      version: 'v2',
      addresses: ['0x215E20Ee8818Ee4346473ef780ad517ed7553136'],
    },
  },
};

export const chainNodes: ChainNodes = {
  1: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
  56: process.env.NEXT_PUBLIC_BSC_RPC,
  137: chains.POLY.urls[0],
};

export const chainsConfig = [
  IS_LIVE_NETWORK ? chains.BSC : chains.BSC_TEST,
  IS_LIVE_NETWORK
    ? {
        ...chains.ETH,
        urls: [
          `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
        ],
      }
    : {
        ...chains.ETH_ROPSTEN,
        urls: [
          `https://ropsten.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID_TEST}`,
        ],
      },
  chains.POLY,
  chains.AVAX,
  chains.ARB,
  chains.BASE,
  chains.ZKSYNC,
];

export const defaultSale: DefaultSaleConfig = {
  registration: { startHours: 48, endHours: 3 },
  fcfs: { durationHours: 40 / 60 },
};
