import * as Sentry from '@sentry/browser';
import {
  FullWeb3Provider,
  GasPriceProvider,
  LaunchpadProvider,
  LevelPoolsWithUserAmountProvider,
  useLaunchpadProvider,
  UserProvider,
  UserTierProvider,
  useUser,
  useWeb3Provider,
} from '@trustpad/launchpad';
import {
  enableDebug,
  setDigitSeparator,
} from '@trustpad/launchpad/dist/shared/utils';
import { toggleProfilerOutput } from '@trustpad/launchpad/dist/shared/utils/profile';

import axios from 'axios';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'styles/styles.scss';
import { useCustomRemoveChildHandler } from '~common/hooks/useCustomRemoveChildHandler';

import { AppHead } from '~common/BaseLayout';
import {
  UserExtraData,
  UserExtraDataProvider,
} from '~common/providers/useUserExtraData';
import { levelPools, LPLevelPool } from '~config/levelPoolsConfig';
import { poolsConfig } from '~config/poolsConfig';
import { tiers } from '~config/tiers';
import { TPAD, TPADV1 } from '~config/tokens';
import {
  chainNodes,
  chainsConfig,
  defaultChain,
  defaultSale,
  registry,
} from '~config/launchpadConfig';
import { debug } from '~utils';

enableDebug(process.env.NODE_ENV === 'development');
toggleProfilerOutput(process.env.PROFIE === '1');
setDigitSeparator(' ');

const getKYCStatus = (account: string) =>
  axios
    .get('/api/kyc', {
      params: { userRef: account },
    })
    .then(({ data }) => data)
    .catch((e) => {
      console.error('KYC fail', e);
    });

const getUserData = (account: string) => {
  if (!account) return;
  return axios
    .get('/api/user', {
      params: { account, withContributions: '1' },
    })
    .then(({ data }) => ({ ...data.user } as UserExtraData))
    .catch((e) => {
      console.error('Extra user data failed', e);
      return { kyc: undefined, contributions: [] };
    });
};

const updateUserData = (account: string, data: Partial<UserExtraData>) => {
  debug('updateUserData: PATCH', account, data);
  if (!account) return;
  return axios
    .patch(`/api/user`, data, {
      params: { account },
    })
    .then(({ data }) => {
      debug('updateUserData: PATCH success', data);
      return { ...data.user } as UserExtraData;
    })
    .catch((e) => {
      console.error('Extra user data PATCH failed', e);
      return { kyc: undefined, contributions: [] };
    });
};

// TODO: include LP back
// const poolsWithLp = [...levelPools, LPLevelPool];
const poolsWithLp = [...levelPools];

function Trustpad({
  Component,
  pageProps,
}: {
  Component: any;
  pageProps: Record<string, unknown>;
}) {
  const ignoreDefaultChainSwitch = Component.ignoreDefaultChainSwitch || false;

  useCustomRemoveChildHandler();

  return (
    <LaunchpadProvider
      chains={chainsConfig}
      salePools={poolsConfig}
      levels={tiers}
      defaultChain={defaultChain.symbol}
      mainToken={TPAD}
      registry={registry}
      chainNodes={chainNodes}
      defaultSale={defaultSale}>
      <FullWeb3Provider>
        <UserProvider getKYCStatus={getKYCStatus} kycCacheKeyBase="wc-2">
          <UserExtraDataProvider
            getUserData={getUserData}
            updateUserData={updateUserData}>
            <UserTierProvider>
              <GasPriceProvider>
                <LevelPoolsWithUserAmountProvider levelPools={poolsWithLp}>
                  <Subscribers />

                  {!ignoreDefaultChainSwitch && <DefaultChainRequester />}
                  <AppHead />

                  <SkeletonTheme baseColor="#495057" highlightColor="#6c757d">
                    <Component {...pageProps} />
                  </SkeletonTheme>
                </LevelPoolsWithUserAmountProvider>
              </GasPriceProvider>
            </UserTierProvider>
          </UserExtraDataProvider>
        </UserProvider>
      </FullWeb3Provider>
    </LaunchpadProvider>
  );
}

export default Trustpad;

function Subscribers() {
  const { connectedChain } = useWeb3Provider();
  const {
    account,
    kyc: { country, status },
  } = useUser();

  useEffect(() => {
    if (!account) return;
    Sentry.setUser({ id: account });
    Sentry.setContext('userKyc', { kycStatus: status, kycCountry: country });
  }, [account, country, status]);
  useEffect(() => {
    Sentry.setContext('chain', { connectedChain });
  }, [connectedChain]);

  return null;
}

// TODO: can handle changing for Claimer too probably
function DefaultChainRequester() {
  const { defaultChain } = useLaunchpadProvider();
  const { targetChain, connectedChain, switchToChain } = useWeb3Provider();
  const { pathname } = useRouter();

  useEffect(() => {
    if (
      targetChain.symbol !== defaultChain.symbol ||
      connectedChain.symbol !== targetChain.symbol
    ) {
      // @ts-expect-error
      switchToChain(defaultChain.symbol);
    }
  }, [
    targetChain.symbol,
    defaultChain.symbol,
    connectedChain.symbol,
    pathname,
  ]);

  return null;
}
