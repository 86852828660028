import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/nunuspirits.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

// Send the funds here (BSC):
// 0x0E13d5bb4D90eF5d6DA570AE3A91a1a343845DaF
export const nunuspirits: PoolConfig = {
  // id is used in URL
  id: 'nunuspirits',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: true,
  noCountries: [],
  sale: {
    refunded: true,
    address: '0xbcfC4531ed670FcE12E934A28079283c630b40e9',
    tokensForSale: toWeiBN('3333333'),
    startDate: '2022-01-27T11:00:00.000Z',
    rate: 16.666,
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    participants: 0,
    tokensSold: toWeiBN('3333332.999999999999998694'),
    raised: toWeiBN('200007.9803192127685107'),
    baseAllocation: toWeiBN('468.705364868762618978'),
    status: 5,
    registerDate: '2022-01-25T11:00:00.000Z',
    registerDuration: 45,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    fcfsDuration: 0.3333333333333333,
    totalWeights: 6990,
    minAllowedLevelMultiplier: 0,
    levelsOpenAll: false,
    isPublic: false,
    fcfsMultiplier: 0,
    minBaseAllocation: toWeiBN('0'),
    reachedMinBaseAllocation: false,
  },
  distribution: {
    type: 'claim_us',
    vesting: '20% at TGE, 3 month cliff, then 4.44% each month',
    claimerAddress: '0x3BC29c9f9b3a0eA85B87480106F47082a4474932',
    claimerVersion: 'v2',
    schedule: {
      '2022-03-30T14:00:00.000Z': 20,
    },
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'BSC',
    pairAddress: '0xbb245052f31ae018129f9943b2585548b08c8081',
    address: '0x3a2927e68749dd6ad0a568d7c05b587863c0bc10',
    name: 'Nunu Spirits',
    symbol: 'NNT',
    imageSrc: img.src,
    listingTime: '2022-03-30T14:00:00.000Z',
    listingInfo: '',
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(16350000),
    totalSupply: toWeiBN(150000000),
    coingeckoId: 'nunu-spirits',
    coinmarketcapId: 'nunu-spirits',
  },
  social: {
    website: 'https://nunuspirits.io/',
    twitter: 'https://twitter.com/nunuspiritsnft',
    telegram: 'https://t.me/nunuspirits_nft',
    telegramAnno: 'https://t.me/NunuSpirits',
    medium: '',
    github: '',
    whitepaper: 'https://nunuspirits.io/whitepaper.pdf',
    announcement: '',
  },
  description: (
    <p>
      Nunu Spirits is the first NFT game where you can plant trees in the real
      world! Nunu Spirits merges the joy of casual gaming with the play2earn
      capacity of blockchain and turns it all into ecological action. Players
      collect and earn 3D NFTs, called Nunus, in a super fun game world where
      every Nunu has the chance to become a real tree, planted in the real
      world. The team bringing you Nunu Spirits is well versed in both games and
      crypto and most recently has been working on one of the most successful
      NFT projects of 2021.
      <br />
      <br />
      Refunded 90%, see the{' '}
      <a href="https://bscscan.com/tx/0x1e856651be7d379af8fd812e116b01a3eaf50257948cde1cf480ed9b0a5e795a">
        TX
      </a>
      .
    </p>
  ),
};
