import { TransactionMessage } from '@trustpad/launchpad/dist/staking/types';

import constate from 'constate';
import { useState } from 'react';

function useModal<T extends TransactionMessage>() {
  const [message, setMessage] = useState<T>();
  const onModalClose = () => setMessage(undefined);

  return { message, setMessage, onModalClose };
}

const [ModalProvider, useModalProvider] = constate(useModal);
export { ModalProvider, useModalProvider };
