import React from 'react';
import { FaBell } from 'react-icons/fa';
import { NotificationsPanel } from './NotificationsPanels';
import { useNotifications } from './useNotifications';

export function Notifications() {
  const { unreadCount, notifications, showNotifications, onOpen, onClose } =
    useNotifications();

  if (!notifications.length) {
    return null;
  }

  return (
    <div className="relative inline-block ml-3">
      <button onClick={onOpen}>
        {unreadCount > 0 && (
          <div className="absolute -right-1 -top-2 bg-red-700 rounded-full w-4 h-4 flex items-center justify-center">
            <span className="text-[0.75em] md:text-xs text-white leading-none">
              {unreadCount}
            </span>
          </div>
        )}

        <FaBell
          className={`text-xl ${
            unreadCount > 0 ? 'text-white/60' : 'text-white/30'
          }`}
        />
      </button>

      {showNotifications && (
        <NotificationsPanel notifications={notifications} onClose={onClose} />
      )}
    </div>
  );
}
