import img from 'pools/images/continuum.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { QuickswapChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';
import { toWeiBN } from '~utils';

export const continuum: PoolConfig = {
  // id is used in URL
  id: 'continuum',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  sale: {
    address: '0x170eb58986a40E24dAf9d4eB7AC96C28b2413017',
    tokensForSale: toWeiBN('6666666'),
    startDate: '2021-10-03T12:00:00.000Z',
    rate: 66.666,
    durationHours: 6,
    hasWhitelist: true,
    isTiered: true,
    participants: 1458,
    tokensSold: toWeiBN('6666665.906828967079339931'),
    raised: toWeiBN('100000.9886123206293964'),
    baseAllocation: toWeiBN('973.012137041020125979'),
    status: 5,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('50'),
    },
    registerDate: '2021-09-28T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 6551,
    levelsOpenAll: true,
    isPublic: false,
    fcfsMultiplier: 10000,
  },
  token: {
    // BSC, ETH, DOT, SOL, ADA, POLY
    chain: 'POLY',
    address: '0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a',
    name: 'Continuum World',
    symbol: 'UM',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 0,
    initialSupply: toWeiBN(56_250_000),
    totalSupply: toWeiBN(1_000_000_000),
    listingTime: '2021-10-04T12:00:00.000Z',
    coingeckoId: 'continuum-world',
    coinmarketcapId: 'continuum-world',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '50% TGE, 50% 30 days',
    schedule: {
      '2021-10-04T12:00:00.000Z': 50,
      '2021-11-03T16:00:00.000Z': 50,
    },
  },
  social: {
    website: 'https://continuum.world/',
    twitter: 'https://twitter.com/continuumworld',
    telegram: 'https://t.me/continuumworldofficial',
    telegramAnno: '',
    medium: 'https://medium.com/@continuumworld',
    github: '',
    whitepaper: '',
    announcement:
      'https://trustpad.medium.com/continuum-world-um-is-launching-its-igo-on-trustpad-9c97bece00a9',
  },
  finishedInfo: (
    <QuickswapChartFinishedInfo
      symbol="UM"
      address="0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a"
      pairAddress="0x78413ed015b19766c8881f6f1bb9011ce95ec786"
    />
  ),
  descriptionShort: (
    <p>
      Continuum World is a (jargon alert) Free-2-Play & Play-2-Earn MMO
      (Massively Multiplayer Online) game where players can explore a new world,
      own land, build buildings to collect resources, level up, compete,
      socialise and earn the game’s own Token $UM.
    </p>
  ),
  description: (
    <>
      <a href="https://medium.com/@continuumworld/how-to-purchase-our-um-tokens-19ac918e8d61">
        Guide: How to buy UM tokens
      </a>
      <p>
        Continuum World is a Free-2-Play & Play-2-Earn MMO (Massively
        Multiplayer Online) game where players can explore a new world, own
        land, build buildings to collect resources, level up, compete, socialise
        and earn the game’s own Token $UM.
      </p>
      <p>
        Players will be able to earn by: <br />
        - Gathering resources while exploring the world.
        <br />
        - Purchasing and farming NFT Lands with unique buildings.
        <br />
        - Levelling up their NFT UMi (avatar) with tools and custom inventory
        and selling it on the marketplace.
        <br />
        - Participating into activities such as races with world’s native fauna.
        <br />- Staking the game token $UM which will give rights to fee sharing
        and governance rights.
      </p>

      <iframe
        title="vimeo-player"
        src="https://player.vimeo.com/video/598675735?h=2ef7083238"
        width="640"
        height="360"
        frameBorder="0"
        allowFullScreen></iframe>

      <iframe
        title="vimeo-player"
        src="https://player.vimeo.com/video/603731631?h=78af7ddc34"
        width="640"
        height="360"
        frameBorder="0"
        allowFullScreen></iframe>

      <a href="https://medium.com/seedify/explorers-of-the-metaverse-join-us-on-an-adventure-into-continuum-world-17f2b3647097">
        Learn more about the game
      </a>
    </>
  ),
};
