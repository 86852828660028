import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/fndz.jpg';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const fndz: PoolConfig = {
  // id is used in URL
  id: 'fndz',
  // public, seed or private
  access: 'public',
  fundToken: BUSD,
  kyc: false,
  sale: {
    address: '0x0fAa21A7d664826Ea07563e9D91009724A6ef9F8',
    tokensForSale: toWeiBN('2000000'),
    startDate: '2021-09-14T12:00:00.000Z',
    rate: 4.166,
    durationHours: 8,
    hasWhitelist: true,
    isTiered: true,
    participants: 1477,
    tokensSold: toWeiBN('1999999.799632077728543515'),
    raised: toWeiBN('480076.7641939696900009'),
    baseAllocation: toWeiBN('473.385610111813320369'),
    status: SaleStatus.finished,
    levelsOpenAll: true,

    isPublic: false,
    fcfsMultiplier: 10000,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-09-09T12:00:00.000Z',
    registerDuration: 119,
    fcfsDuration: 1,
    totalWeights: 4146,
  },
  token: {
    address: '',
    name: 'FNDZ',
    symbol: 'FNDZ',
    imageSrc: img.src,
    decimals: 18,
    listingRate: 4.166,
    initialSupply: toWeiBN(7_800_000),
    totalSupply: toWeiBN(100_000_000),
    // BSC, ETH, DOT, SOL, ADA, POLYGON
    chain: 'BSC',
    listingTime: '2021-09-15T14:00:00.000Z',
    coingeckoId: 'fndz-token',
  },
  distribution: {
    type: 'airdrop_us',
    vesting: '100% on TGE',
  },
  social: {
    website: 'https://fndz.io/',
    twitter: 'https://twitter.com/FNDZtoken',
    telegram: 'https://t.me/fndztoken',
    telegramAnno: 'https://t.me/fndz_community',
    medium: 'https://medium.com/fndz/',
    whitepaper:
      'https://fndz.io/wp-content/uploads/2021/07/FNDZ-Cryptoken-Whitepaper-07-19-2021.pdf',
    discord: 'https://discord.gg/k9EGm2TYmt',
    instagram: 'https://www.instagram.com/fndzofficial/',
  },
  description: (
    <p>
      FNDZ is the first DeFi, blockchain-based social trading platform that
      facilitates copy trading by connecting retail investors with a network of
      experienced, proven, and profitable traders. It offers an easy-to-use,
      transparent, and secure way of profiting from the experience of more
      senior traders.
    </p>
  ),
  finishedInfo: (
    <PancakeChartFinishedInfo
      symbol="FNDZ"
      address="0x7754c0584372D29510C019136220f91e25a8f706"
      pairAddress="0x5e2c18c17b1ce4b7636918633e923ef7ec2a234c"
    />
  ),
};
