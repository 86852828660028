import BN from 'bn.js';
import { toBN, toWei } from 'web3-utils';

export const ZERO_BN = new BN('0');
// Used to divide big numbers, like currency / tokens
export const DEC_NORM = new BN('10').pow(new BN('18'));

export const IS_LIVE_NETWORK = process.env.NEXT_PUBLIC_NETWORK === 'live';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const TWITTER_URL = 'https://twitter.com/TrustPad';
export const TELEGRAM_URL = 'https://t.me/TrustPad';
export const TELEGRAM_ANNO_URL = 'https://t.me/TrustPadAnn';
export const MEDIUM_URL = 'https://trustpad.medium.com/';
export const DISCORD_URL = 'https://discord.gg/4AG5B8Y8gW';
export const IDO_ACCESS_GUIDE_URL =
  'https://docs.trustpad.io/trustpad/how-to-participate-in-an-ido';

export const APPLY_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSc7zsEarZefY11P52QTLVdLaN8G1cUAVA8BBp42ZHzWU2J2tg/viewform?usp=sf_link';

export const fetchIntervalsMs = {
  init: 10 * 60 * 1000,
  regular: 2 * 60 * 1000,
};

export const BSC_BLOCK_TIME = 3;
export const BLOCKS_PER_DAY = 28800;
export const BLOCKS_PER_YEAR = new BN((60 / BSC_BLOCK_TIME) * 60 * 24 * 365); // 10512000

export const BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD';
export const BURN_ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export const APPROVE_VALUE = toBN(toWei('1000000000000000000'));

export enum DateTimeFormat {
  full = 'MMM D, YYYY HH:mm UTC',
  noYear = 'MMM D, HH:mm UTC',
  time = 'HH:mm UTC',
}
