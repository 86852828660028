//Use only logos with transparent background for correct presentation in both ChainDropdownFilter and PoolCard
import adaImg from './ada.png';
import arbitrumImg from './arbitrum.png';
import avaxImg from './avax.png';
import bnbImg from './bnb.png';
import dotImg from './dot.svg';
import ethImg from './eth.png';
import fantomImg from './fantom.png';
import nearImg from './near.png';
import polyImg from './polygon.png';
import solImg from './solana.png';
import terraImg from './terra.svg';
import suiImg from './sui.png';
import baseImg from './base.png';
import zksyncImg from './zksync.png';

export {
  adaImg,
  dotImg,
  ethImg,
  bnbImg,
  solImg,
  polyImg,
  terraImg,
  fantomImg,
  avaxImg,
  nearImg,
  arbitrumImg,
  suiImg,
  baseImg,
  zksyncImg,
};
