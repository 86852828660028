import { SaleStatus } from '@trustpad/launchpad';

import img from 'pools/images/xion.png';
import React from 'react';

import { BUSD } from '~config/tokens';
import { PancakeChartFinishedInfo } from '~features/launched';
import { PoolConfig } from '~features/pools/types';

import { toWeiBN } from '../../src/utils';

export const xion: PoolConfig = {
  id: 'xion-v2',
  access: 'public',
  fundToken: BUSD,
  linkToDetailPage: true,
  distribution: {
    type: 'airdrop_them',
    schedule: {
      '2021-07-07T16:00:00.000Z': 50,
      '2021-07-14T16:00:00.000Z': 25,
      '2021-07-28T16:00:00.000Z': 25,
    },
  },
  sale: {
    address: '0x1BA5d976C76D9B89612aB50f0C24CD9Af9A04A7f',
    status: SaleStatus.finished,
    rate: 5.555555,
    tokensForSale: toWeiBN('700000'),
    startDate: '2021-07-07T08:00:00.000Z',
    baseAllocation: toWeiBN('571'),
    durationHours: 5,
    hasWhitelist: true,
    isTiered: true,
    isPublic: false,
    hardCap: '$126,000',
    participants: 848,
    tokensSold: toWeiBN('700000'),
    raised: toWeiBN('126000'),
    update: false,
    limits: {
      min: toWeiBN('0'),
      max: toWeiBN('200'),
    },
    registerDate: '2021-07-02T08:00:00.000Z',
    registerDuration: 118,
    fcfsDuration: 0.5,
    levelsOpenAll: true,
  },
  token: {
    address: '0xc25af3123d2420054c8fcd144c21113aa2853f39',
    name: 'Xion Finance',
    symbol: 'XGT',
    imageSrc: img.src,
    decimals: 18,
    type: 'BEP-20',
    pairAddress: '',
    listingRate: 5,
    totalSupply: toWeiBN(''),
    initialSupply: toWeiBN('1,375,000'),
    chain: 'BSC',
    listingTime: '2021-07-07T16:00:00.000Z',
    coingeckoId: 'xion-finance',
    coinmarketcapId: 'xion-finance',
  },
  social: {
    twitter: 'https://twitter.com/xionfinance',
    medium: 'https://xionglobal.medium.com/',
    telegram: 'https://t.me/xionglobal',
    website: 'https://xion.finance/',
    github: 'https://github.com/xion-global',
  },
  description: (
    <div>
      Next generation of payment portals. Bringing cross chain decentralised
      payments, loyalty and financial rewards to a global commerce industry.
      Think 'Stripe' for Web3.
    </div>
  ),
  finishedInfo: (
    <div>
      <PancakeChartFinishedInfo
        symbol="$XGT"
        address="0xc25af3123d2420054c8fcd144c21113aa2853f39"
        pairAddress="0x4de0ed25fed42de0d22fa9181aed7f4e42b583e9"
      />
    </div>
  ),
};
